import { DateRange } from '@angular/material/datepicker';
import { Moment } from 'moment';

export enum TimeRangeType {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  MINUTES = 'minutes',
  HOURS = 'hours',
  DAYS = 'days',
  WEEKS = 'weeks',
  MONTHS = 'months',
  YEAR = 'year',
  CUSTOM = 'custom',
}

export interface TimeRangeTypeEvent {
  apply?: boolean;
  quantity?: number;
  range: DateRange<Moment>;
  type: TimeRangeType;
}
