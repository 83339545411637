import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatColumnDef, MatNoDataRow, MatTable } from '@angular/material/table';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { SpxVirtualObservableDataSource } from '../../../collections';
import { SelectionDirective } from '../../directives';
import { SpxRowDirective } from '../../directives/row/row.directive';
import { Column, ColumnConfig, TableConfig } from '../../model';
import { TABLE_INJECTION_TOKEN } from '../table/table.component';

/**
 * A virtualized table component built on top of mat-table.
 *
 * Virtualized tables do not render all rows at once, instead they track
 * the scroll and dynamically re-render to show only those rows, that are
 * currently in view.
 *
 * There are certain limitations in comparison to `spx-table`:
 * * Each row must have a constant, pre-defined height.
 * * No support for sorting.
 * * No support for expandable rows.
 * * No built-in support for a loading spinner.
 *
 * These should only be used when dealing with large tables that for some
 * reason can not easily support pagination.
 *
 * ### Example
 * ```html
 * <spx-virtualized-table
 *  [dataSource]="dataSource"
 *  [dataRowHeight]="48"
 *  [headerHeight]="56"
 * >
 *    ...
 * </spx-virtualized-table>
 * ```
 */
@Component({
  selector: 'spx-virtualized-table',
  templateUrl: './virtualized-table.component.html',
  styleUrls: ['./virtualized-table.component.scss'],
  providers: [
    {
      provide: TABLE_INJECTION_TOKEN,
      useExisting: VirtualizedTableComponent,
    },
  ],
})
export class VirtualizedTableComponent<T> implements AfterViewInit {
  @ViewChild(CdkVirtualScrollViewport, { static: true }) scrollViewport!: CdkVirtualScrollViewport;
  @ViewChild(MatTable, { static: true }) matTable!: MatTable<T>;
  @ViewChildren(SpxRowDirective) rowDefs!: QueryList<SpxRowDirective<T>>;
  @ContentChildren(MatColumnDef) columnDefs!: QueryList<MatColumnDef>;
  @ContentChild(MatNoDataRow) noDataRow!: MatNoDataRow;

  @Input() dataSource!: TableVirtualScrollDataSource<T> | SpxVirtualObservableDataSource<T>;
  @Input() displayedColumns: string[] = [];

  @Input() set config(config: TableConfig) {
    this.tableConfig = config;
    config.columns.forEach((columnConfig: ColumnConfig) => this.addDisplayedColumn(columnConfig.name));
  }

  @Input() public dataRowHeight = 48;
  @Input() public headerHeight = 56;

  @Output() rowClicked = new EventEmitter<T>();

  public isLoadingNextBatch = false;
  public didReachEndOfDataSource = false;

  public tableConfig: TableConfig = {} as TableConfig;
  public paginator = false;
  public pageSizeOptions: number[] = [];
  public selectionDirective?: SelectionDirective<T>;

  public ngAfterViewInit() {
    // Load first batch if using lazy data source.
    if (this.dataSource instanceof SpxVirtualObservableDataSource) {
      this.handleTableScrolled();
    }
  }

  public addColumn(column: Column): void {
    this.addDisplayedColumn(column.name);
    this.matTable.addColumnDef(column.columnDef);
  }

  public removeColumn(column: Column): void {
    this.removeDisplayedColumn(column.name);
    this.matTable.removeColumnDef(column.columnDef);
  }

  public onRowClicked(row: T): void {
    this.rowClicked.emit(row);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public trackBy(index: number, element: any) {
    return element ? element.name : index;
  }

  public addDisplayedColumn(name: string): void {
    const index = this.displayedColumns.findIndex((displayedColumn: string) => displayedColumn === name);
    if (index === -1) {
      this.displayedColumns.push(name);
    }
  }

  public removeDisplayedColumn(name: string): void {
    const index = this.displayedColumns.findIndex((displayedColumn: string) => displayedColumn === name);
    if (index !== -1) {
      this.displayedColumns.splice(index, 1);
    }
  }

  public handleTableScrolled() {
    if (this.dataSource instanceof SpxVirtualObservableDataSource) {
      if (this.isLoadingNextBatch || this.didReachEndOfDataSource) {
        return;
      }

      const range = this.scrollViewport.getRenderedRange();
      if (range.end >= this.dataSource.data.length) {
        this.isLoadingNextBatch = true;
        this.dataSource
          .fetchNextBatch()
          .then((batch) => {
            this.didReachEndOfDataSource = batch.length === 0;
          })
          .finally(() => {
            this.isLoadingNextBatch = false;
          });
      }
    }
  }

  public isObservableAndNotAtEnd(): boolean {
    return this.dataSource instanceof SpxVirtualObservableDataSource && !this.didReachEndOfDataSource;
  }
}
