import { Inject, Injectable } from '@angular/core';
import { HotjarFunction } from '../model/hotjar-function';
import { HJ_FUNCTION } from '../providers/hotjar-function.provider';

@Injectable({
  providedIn: 'root',
})
export class HotjarService {
  constructor(@Inject(HJ_FUNCTION) private _hj: HotjarFunction) {}

  /**
   * Triggers an event to hotjar
   *
   * @param action Name of the action
   */
  public event(action: string) {
    if (this._hj) {
      this._hj('event', action);
    }
  }

  /**
   * Triggers a virtual page state change
   *
   * @param url Relative path to page state change
   */
  public stateChange(url: string) {
    if (this._hj) {
      this._hj('stateChange', url);
    }
  }
}
