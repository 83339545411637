import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PlacementCodeService } from '../shared/placementcode/placementcode.service';
import { GeolocationService } from '../shared/services/geolocation.service';
import { SessionidService } from '../shared/services/sessionid.service';

export const NO_SESSION_TRACKING = new HttpContextToken<boolean>(() => false);
@Injectable()
export class HttprestInterceptor implements HttpInterceptor {
  constructor(
    private geolocation: GeolocationService,
    private sessionId: SessionidService,
    private placementCode: PlacementCodeService,
  ) {}
  // send frontend session-id ,geolocation and device placement-code (if existing) headers with every service-point HTTP request
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.context.get(NO_SESSION_TRACKING)) {
      return next.handle(request);
    }

    request = request.clone({
      setHeaders: {
        'X-Frontend-Session-Id': this.sessionId.getSessionId(),
      },
    });

    if (this.placementCode.getPlacementCode()) {
      request = request.clone({
        setHeaders: {
          'X-Placement-Code': this.placementCode.getPlacementCode(),
          'X-Frontend-Session-Id': this.sessionId.getSessionId(),
        },
      });
    }
    //set and send geolocation header with every http call made
    this.geolocation.setLocation();

    if (this.geolocation.getLocationApiString() !== '') {
      request = request.clone({
        setHeaders: {
          Geolocation: this.geolocation.getLocationApiString(),
        },
      });
    } else {
      request = request.clone({
        setHeaders: {
          'X-Frontend-Session-Id': this.sessionId.getSessionId(),
        },
      });
    }

    return next.handle(request);
  }
}
