import { BaseAlarm } from './base-alarm.model';
import { ThresholdAlarmConfiguration } from './dto/config-alarm.dto';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class AlarmHistory extends BaseAlarm {
  alarmConfigurationId?: string;
  endAt: number;
  heartbeat: boolean;
  pastCount: number;
  startAt: number;
  timeInNanoSeconds: number;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class AlertHistoryResponseModel extends AlarmHistory {
  activeAlertId: string;
  alarmConfigurations: Array<ThresholdAlarmConfiguration>;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class AlertsHistoryCSVHeaders {
  Level: string;
  Type: string;
  Name: string;
  Description: string;
  Start: string;
  'Sortable Start Date (Unix Timestamp ms)': number;
  End: string;
  'Sortable End Date (Unix Timestamp ms)': number;
  Status: string;
}
