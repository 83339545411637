/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class BasicGroup {
  id: string;
  name: string;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class Group extends BasicGroup {
  description: string;
  rights: string[];
  children: Array<unknown>;
  parents: Array<unknown>;
  devicesCount?: number;
  usersCount?: number;
  groupRoleTree?: unknown;
}
