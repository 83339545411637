import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseModule, ThemingService } from '@ird/ng-base';
import { SharedDomainNgModule } from '@ird/ng-common';
import { IsExternalUrlPipe } from '@ird/ng-core';
import { TranslateModule } from '@ngx-translate/core';
import { WarrantyTermsComponent } from './components/warranty-terms/warranty-terms.component';
import { BackbuttonDirective } from './directives/backbutton.directive';
import { ImageComponent } from './image/image.component';
import { MaterialModule } from './material.module';
import { LanguageCodePipe } from './pipes/language-code.pipe';
import { PlacementCodeService } from './placementcode/placementcode.service';
import { GeolocationService } from './services/geolocation.service';
import { InteractionService } from './services/interaction.service';
import { KpiLogsService } from './services/kpi-logs.service';
import { LayoutService } from './services/layout.service';
import { NavigationService } from './services/navigation.service';
import { SpUserService } from './services/sp-user.service';
import { UrlService } from './services/url.service';
@NgModule({
  declarations: [ImageComponent, LanguageCodePipe, BackbuttonDirective, WarrantyTermsComponent],
  providers: [
    GeolocationService,
    PlacementCodeService,
    IsExternalUrlPipe,
    ThemingService,
    UrlService,
    LayoutService,
    KpiLogsService,
    NavigationService,
    SpUserService,
    InteractionService,
  ],
  imports: [CommonModule, TranslateModule, BaseModule, ReactiveFormsModule, FormsModule, SharedDomainNgModule],
  exports: [
    ImageComponent,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    BaseModule,
    MaterialModule,
    LanguageCodePipe,
    BackbuttonDirective,
    SharedDomainNgModule,
    WarrantyTermsComponent,
  ],
})
export class SharedModule {}
