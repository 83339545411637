/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface UserGroup {
  id: string;
  name: string;
  parent: UserGroup;
  children: Array<UserGroup>;
  childrenCount?: number;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface UserGroupDto {
  id: string;
  name: string;
  parent: UserGroupDto;
  children: Array<UserGroupDto>;
}
