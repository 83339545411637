import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogAction, DialogService, NotificationService, NotificationType } from '@ird/ng-base';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';

type Unit = 'B' | 'KB' | 'MB' | 'GB';

@Component({
  selector: 'shared-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent implements OnInit, OnChanges {
  @Input() accept!: string[];
  @Input() disabled = false;
  @Input() file!: File;
  @Input() hint!: string;
  @Input() maxSize!: number;
  @Input() progress!: number;
  @Input() title!: string;

  @Output() fileChange = new EventEmitter<File>();

  @ViewChild('imgUpload') imageControl!: ElementRef;

  public url: any;
  public fileOver = false;
  public displayedSize!: number;
  public displayedUnit!: Unit;
  public PDF = 'application/pdf';

  private disabledState$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(
    private cd: DomSanitizer,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private dialogService: DialogService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: {
      dialogData: { accept: Array<string>; maxSize: number };
      actions: DialogAction[];
    },
  ) {
    if (data?.dialogData?.accept || data?.dialogData?.maxSize) {
      this.fileChange = null;
      this.accept = data.dialogData.accept;
      this.maxSize = data.dialogData.maxSize;

      this.data.actions = [
        {
          text: 'BUTTON_CANCEL',
          type: 'mat-stroked-button',
          color: 'primary',
          action: () => {
            this.dialogService.close(false);
            return Subscription.EMPTY;
          },
        },
        {
          text: 'BUTTON_SAVE',
          type: 'mat-raised-button',
          color: 'primary',
          action: () => {
            this.dialogService.close(this.file);
            return Subscription.EMPTY;
          },
          disable: () => {
            return this.disabledState$.asObservable();
          },
        },
      ];
    }
  }

  ngOnInit() {
    if (!this.maxSize) {
      return;
    }
    const { size, unit } = FileUploaderComponent.getUnit(this.maxSize);
    this.displayedSize = size;
    this.displayedUnit = unit;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.file) {
      this.readFile(this.file);
    }
  }

  onFileChange($event: any): void {
    if ($event.target.files.length > 0) {
      const file = $event.target.files[0];
      if (!this.validateExtension(file)) {
        return;
      }
      if (!this.validateSize(file)) {
        this.imageControl.nativeElement.value = null;
        return;
      }
      this.file = file;
      if (this.fileChange) {
        this.fileChange.emit(this.file);
      }
      this.readFile(file);
      this.disabledState$.next(false);
    }
  }

  onFileDropped(files: FileList): void {
    if (files.length !== 1) {
      return;
    }

    if (!this.validateExtension(files.item(0))) {
      return;
    }
    if (!this.validateSize(files.item(0))) {
      return;
    }
    this.file = files.item(0);
    if (this.fileChange) {
      this.fileChange.emit(this.file);
    }
    this.readFile(files.item(0));
    this.disabledState$.next(false);
  }

  removeImage(): void {
    this.reset();
    this.fileChange?.emit(this.file);
    this.disabledState$.next(true);
  }

  reset() {
    this.file = null;
    this.url = null;
    if (this.imageControl) {
      this.imageControl.nativeElement.value = null;
    }
  }

  private validateExtension(file: File): boolean {
    if (!file || !this.accept) {
      return true;
    }

    const ext = file.type;

    const isAcceptedType = this.accept.find((acceptType) => {
      const splitRegex = new RegExp('[./]');
      const acceptParts = acceptType.split(splitRegex);
      const fileTypeParts = ext.split(splitRegex);
      const isWildcard = acceptParts[acceptParts.length - 1] === '*';

      const compareResultArray = acceptParts.filter((part, index) => {
        return part?.length > 0 && !fileTypeParts[index]?.includes(part) && (index !== acceptParts.length - 1 || !isWildcard);
      });

      return compareResultArray.length === 0;
    });

    if (isAcceptedType) {
      return true;
    }

    this.notificationService.showNotification({ message: 'FILE.UNSUPPORTED_EXTENSION', type: NotificationType.Error });
    return false;
  }

  private validateSize(file: File): boolean {
    if (!file) {
      return true;
    }

    const size = file.size;

    if (this.maxSize < size) {
      const formatted = FileUploaderComponent.getUnit(size);
      this.notificationService.showNotification({
        message:
          this.translate.instant('FILE_UPLOADER.SIZE_TOO_BIG') +
          ' ' +
          this.translate.instant('FILE_UPLOADER.SIZE_IS') +
          ' ' +
          formatted.size +
          formatted.unit,
        type: NotificationType.Error,
      });
      return false;
    }
    return true;
  }

  readFile(file: File): void {
    if (!file) {
      return;
    }

    if (this.file) {
      const reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        this.url = this.cd.bypassSecurityTrustResourceUrl(reader.result as string);
      };
    } else {
      this.url = null;
    }
  }

  public getSizeLabel(size: number): string {
    if (!size) {
      return '';
    }

    const { size: parsedSize, unit } = FileUploaderComponent.getUnit(size);
    return parsedSize.toLocaleString() + ' ' + unit;
  }

  private static getUnit(size: number): { size: number; unit: Unit } {
    let count = 0;
    while (size > 1024) {
      size = size / 1024;
      count++;
    }
    size = Number(size.toFixed(2));
    let unit: Unit;
    switch (count) {
      case 1:
        unit = 'KB';
        break;
      case 2:
        unit = 'MB';
        break;
      case 3:
        unit = 'GB';
        break;
      default:
        unit = 'B';
    }
    return { size, unit };
  }

  public getAcceptedExtensionsHint(): string {
    let result = '';
    this.accept?.forEach((ext) => {
      if (ext?.length < 2) {
        return;
      }
      const suffix = ext.startsWith('.') ? ext.slice(1) : ext;
      result = result + suffix.toUpperCase() + ', ';
    });
    if (!this.maxSize && result?.length > 1) {
      return result.slice(0, result.length - 2);
    }
    return result;
  }
}
