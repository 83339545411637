import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UrlService {
  private regExp =
    /^(https?:\/\/)?[a-zA-Z0-9-]{1,}\.ir-connect\.net\/(.*\/)?[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}(\?.*)?$/;

  public isValid(url: string): boolean {
    return this.regExp.test(url);
  }
}
