import { AfterContentInit, Directive, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'spx-table[sort]',
})
export class SortDirective<T> implements OnChanges, AfterContentInit {
  @Input() set sort(sort: Sort) {
    this._sort = sort;
  }

  @Output() sortChange: EventEmitter<Sort> = new EventEmitter<Sort>();

  private _sort!: Sort;

  constructor(private matSort: MatSort) {
    matSort.initialized.subscribe(() => {
      this.matSort.sortChange.emit(this._sort);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sort) {
      if (this.matSort.active !== this._sort.active || this.matSort.direction !== this._sort.direction) {
        this.matSort.active = this._sort.active;
        this.matSort.direction = this._sort.direction;
        this.matSort.sortChange.emit(this._sort);
      }
    }
  }

  ngAfterContentInit(): void {
    this.matSort.sortChange.subscribe((sort: Sort) => {
      if (this._sort.active !== sort.active || this._sort.direction !== sort.direction) {
        this._sort = sort;
        this.sortChange.emit(sort);
      }
    });
  }
}
