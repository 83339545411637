import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PricespiderConfig } from '../model/pricespider-config.model';

export abstract class PricespiderConfigLoader {
  abstract getConfig(): Observable<PricespiderConfig>;
}

@Injectable()
export class PricespiderConfigFakeLoader extends PricespiderConfigLoader {
  override getConfig(): Observable<PricespiderConfig> {
    return of({} as PricespiderConfig);
  }
}
