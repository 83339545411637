import { HttpClient, HttpContext, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Login } from '@ird/ng-common';
import { PathReplaceUtils } from '@ird/shared-utils';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { USE_LLT_TOKEN } from '../../interceptor/token.interceptor';
import { UserProfile } from '../models/user-profile';

@Injectable({
  providedIn: 'root',
})
export class AuthRepositoryService {
  constructor(private http: HttpClient) {}

  getLoginToken(sendUsername: string, sendPassword: string): Observable<Login> {
    const body = { password: sendPassword, username: sendUsername };
    return this.http.post<Login>(environment.auth.login, body, { context: new HttpContext().set(USE_LLT_TOKEN, true) });
  }

  logout(): Observable<void> {
    return this.http.post<void>(environment.auth.logout, undefined);
  }

  resetPassword(email: string): Observable<HttpResponse<void>> {
    const url = environment.auth.passwordReset.replace('{username}', email);
    const body = { username: email };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post<void>(url, body, { headers, observe: 'response' });
  }

  resetNewPassword(newPassword: string, confirmPassword: string, username: string, resetToken: string): Observable<any> {
    const endpoint = PathReplaceUtils.replace(environment.auth.newPassword, { username, resetToken });

    const body = { password1: newPassword, password2: confirmPassword };

    return this.http.post(endpoint, body, { observe: 'response' });
  }

  getUserProfile(): Observable<UserProfile> {
    const path = environment.user.userProfile;
    return this.http.get<UserProfile>(path);
  }
}
