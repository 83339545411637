import { SecureAction } from '../secure-actions';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class OpcuaSecureAction extends SecureAction {
  public static readonly SERVER_VIEW: SecureAction = new OpcuaSecureAction('admin:opcua:servers|view');
  public static readonly CLIENT_VIEW: SecureAction = new OpcuaSecureAction('admin:opcua:clients|view');
  public static readonly SERVER_EDIT: SecureAction = new OpcuaSecureAction('admin:opcua:servers|edit');
  public static readonly SERVER_DELETE: SecureAction = new OpcuaSecureAction('admin:opcua:servers|delete');
  public static readonly CLIENT_EDIT_NAMESPACE: SecureAction = new OpcuaSecureAction('admin:opcua:clients|edit_namespace');
  public static readonly EDIT_MONITORED_NODE: SecureAction = new OpcuaSecureAction('admin:opcua:clients|edit_monitored_node');
  public static readonly EDIT_LOGS: SecureAction = new OpcuaSecureAction('admin:opcua:clients|edit_logs');
  public static readonly VIEW_CONFIG: SecureAction = new OpcuaSecureAction('admin:opcua:clients|view_config');
  public static readonly VIEW_NODE: SecureAction = new OpcuaSecureAction('admin:opcua:clients|view_node');
  public static readonly EDIT_NODE: SecureAction = new OpcuaSecureAction('admin:opcua:clients|edit_node');
  public static readonly EDIT_DEVICE: SecureAction = new OpcuaSecureAction('admin:opcua:clients|edit_device');

  private constructor(name: string) {
    super(name);
  }
}
