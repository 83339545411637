import { Injectable } from '@angular/core';
import { SepoInteractionEnum, SepoInteractions, ServicePointFeatures } from '@deprecated/api-interfaces';
import { Observable } from 'rxjs';
import { InteractionRepository } from '../repositories/interaction.repository';
import { TenantConfigService } from './tenant-config.service';

@Injectable({
  providedIn: 'root',
})
export class InteractionService {
  public static readonly featureEvents = new Map<ServicePointFeatures, SepoInteractionEnum>([
    [ServicePointFeatures.WARRANTY_EXTENSION, SepoInteractionEnum.EXTENDED_WARRANTY],
    [ServicePointFeatures.TUBE_SETTINGS_TILE, SepoInteractionEnum.TUBE_SETTINGS],
  ]);

  constructor(
    private tenantConfigService: TenantConfigService,
    private interactionRepository: InteractionRepository,
  ) {}

  public createInteractions(sepoInteraction: SepoInteractions): Observable<SepoInteractions> {
    return this.interactionRepository.createSepoInteractionsData(sepoInteraction);
  }

  public checkFeatureEvent(feature: ServicePointFeatures, deviceId: string): void {
    const event = InteractionService.featureEvents.get(feature);
    const tenantId = this.tenantConfigService.getTenantId();
    if (event) {
      const interactionData: SepoInteractions = {
        deviceId,
        event,
        id: '',
        createdDate: '',
        createdBy: '',
        tenantId,
      };
      this.createInteractions(interactionData).subscribe();
    }
  }
}
