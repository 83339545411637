import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TenantSettings } from '@deprecated/api-interfaces';
import { Observable } from 'rxjs';
import { ContactPerson } from '../models/contact-person.model';
import { ExtendedMatColorTheme } from '../models/tenant-configuration';
import { Tenant } from '../models/tenant.model';

@Injectable()
export class TenantSettingsRepositoryHttp {
  readonly headers = {
    'Content-Type': 'application/json',
  };

  constructor(
    private http: HttpClient,
    @Inject('ENVIRONMENT') private config: any,
  ) {}

  getTenantSettings(): Observable<TenantSettings> {
    const path = this.config.ui.tenant.get;

    return this.http.get<TenantSettings>(path, {
      observe: 'body',
      headers: this.headers,
    });
  }

  updateTenantSettings(settings: ExtendedMatColorTheme): Observable<any> {
    const path = this.config.ui.tenant.get;

    return this.http.post(path, settings, {
      observe: 'body',
      headers: this.headers,
    });
  }

  getTenantToken(tenantId: string): Observable<any> {
    const path = this.config.tenant.getToken.replace('{tenantId}', tenantId);

    return this.http.post(path, null, {
      responseType: 'text',
    });
  }

  createTenant(tenant: Tenant): any {
    const path = this.config.tenant.post;

    return this.http.put<Tenant>(path, tenant, {
      headers: this.headers,
    });
  }

  editTenant(tenant: Tenant): Observable<Tenant> {
    const { id, ...rest } = tenant;
    const path = this.config.tenant.patch.replace('{id}', tenant.id);

    return this.http.post<Tenant>(path, rest, {
      headers: this.headers,
    });
  }

  public addContactPerson(contactPerson: ContactPerson): Observable<string> {
    const path = this.config.ui.tenant.contactPerson.post;

    return this.http.post<string>(path, contactPerson);
  }
}
