/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogAction, DialogService } from '@ird/ng-base';
import { NEVER } from 'rxjs';

@Component({
  templateUrl: './pwa-countdown-dialog.component.html',
})
export class PwaCountdownDialogComponent implements OnInit, OnDestroy {
  public newVersion: string;
  public oldVersion: string;
  public reloadIn: number;

  private interval: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogService: DialogService,
  ) {
    this.newVersion = this.data.newVersion;
    this.oldVersion = this.data.oldVersion;

    this.reloadIn = this.data.forceTimeout / 1000;

    const actions: DialogAction[] = [
      {
        text: 'SPX.PWA.FORCE_UPDATE.ACTION',
        type: 'mat-raised-button',
        color: 'primary',
        action: () => {
          this.close();
          return NEVER.subscribe();
        },
      },
    ];

    this.data.actions = actions;
  }

  ngOnInit(): void {
    this.interval = setInterval(() => {
      if (this.reloadIn > 1) {
        this.reloadIn = this.reloadIn - 1;
      } else {
        this.close();
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  public close() {
    this.dialogService.close();
  }
}
