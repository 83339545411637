import { DOCUMENT } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { UserSettings } from '@deprecated/api-interfaces';
import { ThemingService } from '@ird/ng-base';
import { MatColorTheme } from '@ird/shared-base';
import { Observable, ReplaySubject, firstValueFrom, forkJoin, map, of } from 'rxjs';
import { Log, LogLevel, Login, PagedData, RabbitPermissions, User, UserCreate } from '../models';
import { UserRepositoryHttp } from '../repositories';
import { LocaleService } from './locale.service';
import { LoginService } from './login.service';
import { TenantService } from './tenant.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  user: User;
  settings: UserSettings;
  ignore_user_settings = false;

  protected userEvent$ = new ReplaySubject<User>(1);
  protected actions: string[] = [];

  constructor(
    protected userRepository: UserRepositoryHttp,
    protected loginService: LoginService,
    protected tenantService: TenantService,
    protected themingService: ThemingService,
    protected localeService: LocaleService,
    @Inject(DOCUMENT) protected _document: Document,
  ) {
    this.ignore_user_settings = localStorage.getItem('ignore_user_settings') === 'true';

    this.loginService.onLogout(() => {
      this.clearUser();
    });
  }

  onUserEvent(): ReplaySubject<User> {
    return this.userEvent$;
  }

  getUser(): User {
    return this.user;
  }

  /**
   * Sets the user into the service
   *
   * @param user User or Login object
   */
  setUser(user: User): void {
    this.user = user;
    this.userEvent$.next(this.user);
  }

  getUserActions(): string[] {
    return this.actions;
  }

  clearUser(): void {
    this.user = null;
    this.userEvent$.next(this.user);
    this.actions.length = 0;
  }

  isResourceBasedRolesActiveByUserEvent(): Observable<boolean> {
    return this.userEvent$.asObservable().pipe(
      map(() => {
        return this.isResourceBasedRolesActive();
      }),
    );
  }

  isResourceBasedRolesActive(): boolean {
    return (
      this.user?.metadata.USER_MANAGEMENT_V2 === 'true' ||
      this.user?.metadata.USER_MANAGEMENT_2 === 'true' ||
      localStorage.getItem('user_management_2') === 'true'
    );
  }

  initUser(): Promise<Login> {
    return new Promise((resolve, reject) => {
      if (localStorage.getItem('token')) {
        this.userRepository.getUser().subscribe({
          next: (response: Login) => {
            localStorage.setItem('expires_at', response?.authentication?.validTo + '');
            this.initApplication(response);
            resolve(response);
          },
          error: (error) => {
            reject(error);
          },
        });
      } else {
        reject();
      }
    });
  }

  initApplication(data: Login) {
    this.ignore_user_settings = localStorage.getItem('ignore_user_settings') === 'true';
    this.tenantService.setTenantSettings(null);

    if (this.tenantService.isSeepexTenant(data.feUser.tenantId)) {
      this._document.body.querySelector('app-root')?.classList.add('theme-seepex');
    } else {
      this._document.body.querySelector('app-root')?.classList.remove('theme-seepex');
    }

    this.settings = (JSON.parse(data.settings.settings) ?? {}) as UserSettings;

    // set the locale for user if not set
    this.settings.locale = this.settings.locale ?? navigator.language;

    // updating the locale if en-US -> en
    this.settings.locale = this.localeService.seperateLocaleFromBrowserLanguage(this.settings.locale);

    //set the timezone for user if not set
    this.settings.timeZone = this.settings.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;

    // set the user actions in the action service
    this.actions = data.actions;

    // load the theme for the tenant of the user
    this.themingService.loadTheme(data.tenantSettings?.themeDefinition as MatColorTheme);

    // set the tenant settings in the tenant service
    this.tenantService.setTenantSettings({
      ...this.tenantService.getTenantSettings(),
      ...data.tenantSettings,
    });

    this.setUser(data.feUser);
  }

  setUserInSerivce(user: User) {
    if (user) {
      this.user = user;
    }
  }

  getIgnoreSettingsFlag(): boolean {
    return this.ignore_user_settings;
  }

  saveUser(user: User): Observable<void> {
    return this.userRepository.saveUserData(user);
  }

  saveMetadata(userId: string, metadata: Map<string, string>): Observable<HttpResponse<void>> {
    return this.userRepository.saveMetadata(userId, metadata);
  }

  getAllUsers(page = 0, pageSize = 20, query?: string, sort?: Sort) {
    return this.userRepository.getAllUsers(page, pageSize, query, sort);
  }

  getUserById(userId: string): Observable<User> {
    return this.userRepository.getUserById(userId);
  }

  deleteUser(userId: string): Observable<void> {
    return this.userRepository.deleteUser(userId);
  }

  unlockUser(userId: string): Observable<void> {
    return this.userRepository.unlockUser(userId);
  }

  getUsersByIds(ids: string[] = []): Observable<User[]> {
    return forkJoin(ids.map((id: string) => this.getUserById(id)));
  }

  changePassword(userId: string, password: string, passwordRepeat: string, oldPassword?: string): Observable<HttpResponse<any>> {
    return this.userRepository.changePassword(userId, password, passwordRepeat, oldPassword);
  }

  createUser(user: UserCreate): Observable<HttpResponse<string>> {
    return this.userRepository.createUser(user);
  }

  async getUserSettings(): Promise<UserSettings> {
    this.ignore_user_settings = localStorage.getItem('ignore_user_settings') === 'true';
    if (!this.ignore_user_settings) {
      if (!this.settings) {
        try {
          this.settings = await firstValueFrom(this.loadUserSettings());
        } catch (error) {
          this.settings = new UserSettings();
        }
      }
    }
    return this.settings;
  }

  saveUserSettings(settings: UserSettings): Observable<UserSettings> {
    this.ignore_user_settings = localStorage.getItem('ignore_user_settings') === 'true';
    if (!this.ignore_user_settings) {
      return this.userRepository.saveUserSettings(settings).pipe(
        map(() => {
          this.settings = settings;
          return this.settings;
        }),
      );
    } else {
      return of(this.settings);
    }
  }

  getUsersByGroup(groupId: string, page = 0, size = 50): Observable<PagedData<User>> {
    return this.userRepository.getUsersByGroup(groupId, page, size);
  }

  getUsersCountForGroupsIds(groupsIds: string[]): Observable<any> {
    return this.userRepository.getUsersCountForGroupsIds(groupsIds);
  }

  assignMqttRights(userId: string, mqttPassword: string): Observable<any> {
    return this.userRepository.assignMqttRights(userId, mqttPassword);
  }

  removeMqttRight(userId: string): Observable<any> {
    return this.userRepository.removeMqttRights(userId);
  }

  getMqttDetails(userId: string): Observable<RabbitPermissions> {
    return this.userRepository.getMqttDetails(userId);
  }

  addMqttDevice(userId: string, deviceId: string): Observable<any> {
    return this.userRepository.addMqttDevice(userId, deviceId);
  }

  removeMqttDevice(userId: string, deviceId: string): Observable<any> {
    return this.userRepository.removeMqttDevice(userId, deviceId);
  }

  getUserLogs(
    userId: string,
    startDate: string,
    endDate: string,
    page: number,
    pageSize: number,
    type?: LogLevel,
    query?: string,
  ): Observable<PagedData<Log>> {
    return this.userRepository.getUserLogs(userId, startDate, endDate, page, pageSize, type, query);
  }

  alignUserTenantToKeycloakRealm(userId: string): Observable<HttpResponse<boolean>> {
    return this.userRepository.alignUserTenantToKeycloakRealm(userId);
  }

  migrateUserToKeyCloak(userName: string): Observable<void> {
    return this.userRepository.migrateUserToKeyCloak(userName);
  }

  isUM2User(user: User): boolean {
    return user?.metadata.USER_MANAGEMENT_V2 === 'true' || user?.metadata.USER_MANAGEMENT_2 === 'true';
  }

  isLoggedInUserUM2User(): boolean {
    return this.isUM2User(this.user);
  }

  private loadUserSettings(): Observable<UserSettings> {
    return this.userRepository.getUserSettings();
  }
}
