import { User } from '../user/user.model';
import { Organization } from './organization.model';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface Tenant {
  id: string;
  name: string;
  organisations: Organization[];
  childCount?: number;
  admins?: User[];
}
