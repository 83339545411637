import { DefaultRangeValues, TimeRangeOption, TimeRangeOptionCalc, TimeRangeOptions } from '../time-range';

export class ChartHelper {
  public static isTimeRangeOption = (option: string | TimeRangeOption): boolean =>
    Object.values(TimeRangeOptions).some((opt) => option === opt);

  public static getChartTimeRange(
    optionName: TimeRangeOption | string,
    startTimestamp = new Date().valueOf(),
  ): {
    from: number;
    to: number;
  } {
    if (!ChartHelper.isTimeRangeOption(optionName)) {
      const date = new Date();
      date.setUTCHours(0, 0, 0, 0);
      return { from: date.valueOf(), to: new Date().valueOf() };
    }

    const minDate = TimeRangeOptionCalc.subtract[optionName](startTimestamp, DefaultRangeValues[optionName]);
    let maxDate = TimeRangeOptionCalc.add[optionName](startTimestamp, DefaultRangeValues[optionName]);

    if (maxDate.valueOf() > new Date().valueOf()) {
      maxDate = new Date();
    }

    return { from: minDate.valueOf(), to: maxDate.valueOf() };
  }
}
