import { InjectionToken } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';

export const DEFAULT_DIALOG_CONFIG: MatDialogConfig = {
  autoFocus: false,
  minWidth: '30%',
  panelClass: 'spx-dialog',
  data: {
    confirmTranslateKey: 'SPX.BASE.DIALOG.ACTION.CONFIRM',
    rejectTranslateKey: 'SPX.BASE.DIALOG.ACTION.REJECT',
    closeTranslateKey: 'SPX.BASE.DIALOG.ACTION.CLOSE',
  },
};

export const DIALOG_CONFIG = new InjectionToken<MatDialogConfig>('dialog_config');
