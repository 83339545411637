/* eslint-disable @typescript-eslint/no-explicit-any */
import { BehaviorSubject } from 'rxjs';
import { FreescoutConfig } from './model/freescout-config.model';
import { FreescoutConfigLoader } from './providers/freescout-config.provder';

/**
 * This is the usersnap initializer, which should only be called once on app initialization
 *
 * @param config Usersnap Config
 * @param document Document instance
 * @param userdataLoader Usersnap User Data Loader to provide Usersnap User Data for initialization
 */
export function freescoutInitializer(document: Document, loader: FreescoutConfigLoader) {
  return () => {
    let initialized = false;
    // if document is missing, log the error and return
    if (!document || !document.defaultView) {
      console.error('Please make sure the environment is a browser. Was not possible to access `document` instance');
      return;
    }

    loader.getConfig().subscribe((config) => {
      if (!initialized) {
        init(config);
      }
    });

    function init(config: FreescoutConfig) {
      const conf = {
        color: config.color,
        position: config.position === 'bottom_left' ? 'bl' : config.position === 'bottom_right' ? 'br' : 'hidden',
        locale: config.locale,
        id: config.id,
      };
      if (config.showCategories) {
        (conf as any).show_categories = '1';
      }
      (window as any).FreeScoutW = { s: conf, showing: new BehaviorSubject<boolean>(false) };

      // Create the freescout script tag and append it to the head
      const head = document.querySelector('head');
      const script = document.createElement('script');
      script.id = 'freescout-w';
      script.defer = false;
      script.type = 'text/javascript';
      script.src = config.url;
      script.onload = () => {
        const btn = document.getElementById('fsw-btn');
        if (btn) {
          if (config.hideDefault) {
            btn.style.display = 'none';
          }
          const freeScout = (window as any).FreeScoutW;

          freeScout.minimize = new Proxy(freeScout.minimize, {
            apply: function (target, thisArg, args) {
              target.apply(thisArg, args);
              if (config.hideDefault) {
                btn.style.display = 'none';
              }

              freeScout.showing.next(false);
            },
          });

          freeScout.show = new Proxy(freeScout.show, {
            apply: function (target, thisArg, args) {
              target.apply(thisArg, args);
              if (config.hideDefault) {
                btn.style.display = 'none';
              }

              freeScout.showing.next(true);
            },
          });
        }
      };
      head?.appendChild(script);

      initialized = true;
    }
  };
}
