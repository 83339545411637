import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join',
})
export class ArrayJoinPipePipe implements PipeTransform {
  transform(input: Array<any>, sep = ', '): string {
    return input.filter(Boolean).join(sep);
  }
}
