export enum FeatureFlag {
  USERMANAGEMENT_V2 = 'usermanagement-v2',
  UM2_PATH = 'usermanagement-v2-path',
  FARO = 'faro',
  HOTJAR = 'hotjar',
  USERSNAP = 'usersnap',
  FREESCOUT_KNOWLEDGEBASE = 'freescout-knowledgebase',
  PRICESPIDER = 'pricespider',
  CONNECTED_AIR_SOLUTIONS_OVERVIEW = 'connected-air-solutions-overview',
  MONIROTING_NO_30_S_BUCKETS = 'drop-30s-buckets',
}
