import { PermissionGroup } from './permission-group.model';
import { RecipientSettings } from './recipient-settings.model';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface Team {
  id: string;
  name: string;
  parentId: string;
  permissionGroups: PermissionGroup[];
  settings: RecipientSettings;
  path?: string;
}

export type TeamDto = Omit<Team, 'permissionGroups' | 'settings'>;
