/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class TabConfig {
  deviceId: string;
  features: Array<TabFeature>;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class TabFeature {
  name: string;
  active: boolean;
  children: Array<TabFeature>;
}
