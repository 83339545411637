import { SecureAction } from '../secure-actions';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class UserEditSecureAction extends SecureAction {
  public static readonly METADATA_VIEW: SecureAction = new UserEditSecureAction('admin:users:edit:metadata|view');
  public static readonly METADATA_SAVE: SecureAction = new UserEditSecureAction('admin:users:edit:metadata|save');
  public static readonly METADATA_ADD_ENTRY: SecureAction = new UserEditSecureAction('admin:users:edit:metadata|add');
  public static readonly EDIT_KEYCLOAK_REALM: SecureAction = new UserEditSecureAction('admin:keycloak:realm|edit');
  public static readonly IMPERSONATE: SecureAction = new UserEditSecureAction('admin:users:edit|impersonate');
  public static readonly USER_DETAIL_MIGRATE_USER_KEYCLOAK: SecureAction = new UserEditSecureAction(
    'user_management:users:keycloak|migrate',
  );
  public static readonly USER_DETAILS_SWITCH_TENANT: SecureAction = new UserEditSecureAction('user_management:users:keycloak:tenant|sync');
  public static readonly USER_MQTT_TOPIC_RIGHT_VIEW: SecureAction = new UserEditSecureAction('admin:users:edit:mqtt|view');
  public static readonly USER_MQTT_TOPIC_RIGHT_EDIT: SecureAction = new UserEditSecureAction('admin:users:edit:mqtt|edit');

  private constructor(name: string) {
    super(name);
  }
}
