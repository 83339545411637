import extensionMap from './extensionMap';
import mimeMap from './mimeMap';

export default class MimeResolver {
  static extMap = extensionMap;

  static mimeMap = mimeMap;

  static getType(ext: string) {
    return MimeResolver.extMap[ext];
  }

  static getExtByMime(mime: string) {
    return MimeResolver.mimeMap[mime];
  }
}
