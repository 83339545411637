/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DashboardDonutConfigModel {
  label?: string;
  sourceId?: string;
  totalValueSource?: boolean;
  isCustomLabel?: boolean;
  colorCode?: string;
  sensorTypeId?: string;
}
