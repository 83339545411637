import { SecureAction } from './secure-actions';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class PlatformSecureAction extends SecureAction {
  public static readonly USER_SNAP: SecureAction = new PlatformSecureAction('platform:feedback|create');
  public static readonly FREESCOUT_KNOWLEDGEBASE: SecureAction = new PlatformSecureAction('platform|help');

  private constructor(name: string) {
    super(name);
  }
}
