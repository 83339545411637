import { Sensor } from '../sensor';
import { SourceType, ThresholdLevel } from '../shared';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class BaseAlarm {
  acknowledged!: boolean;
  description!: string;
  icon?: string;
  id!: string;
  level!: ThresholdLevel;
  metadata?: Record<string, string>;
  name!: string;
  reason!: string;
  sensor?: Sensor;
  sourceDescription!: string;
  sourceType!: SourceType;
  sourceId!: string;
  text?: string;
  thresholdMax!: number;
  thresholdMin!: number;
  value!: number;
  unit?: string;
}
