import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Login } from '../models';

@Injectable()
export class LoginRepositoryHttp {
  constructor(
    private http: HttpClient,
    @Inject('ENVIRONMENT') private config: any,
  ) {}

  /**
   * Logs a user into SCS
   *
   * @param username User name
   * @param password Password
   * @returns User login response stream
   */
  login(username: string, password: string): Observable<Login> {
    return this.http.post<Login>(`${this.config.login.apiEndpoint}`, { password, username });
  }

  loginV4(username: string, password: string): Observable<Login> {
    return this.http.post<Login>(`${this.config.login.v4}`, { password, username });
  }

  logout(): Observable<void> {
    return this.http.post<void>(`${this.config.login.logout}`, undefined);
  }

  getImpersonatedToken(sendUsername: string): Observable<string> {
    const options = {
      'Content-Type': 'text/plain',
    };

    const path = this.config.user.getImpersonatedToken.replace('{username}', sendUsername);

    return this.http.get(path, {
      observe: 'body',
      headers: options,
      responseType: 'text',
    });
  }
}
