/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class Formula {
  calculateHistoric: boolean;
  description: string;
  deviceId: string;
  formula: string;
  formulaJson: string;
  groups: Array<string>;
  id: string;
  idAsString: string;
  inputs: Array<string>;
  isLive: boolean;
  isTemporal: boolean;
  lastActiveNanoSeconds: number;
  nanoSinceEpoch: number;
  scriptIds: Array<string>;
  time: string;
  unit: string;
  usernames: Array<string>;
  visualFormula: string;
  type: string;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DerivedType extends Formula {
  name?: string;
  lastActiveNanos?: number;
  metadata?: { [k: string]: string };
}
