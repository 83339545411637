import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UsersnapUserData } from '../model/usersnap-userdata';

/**
 * Abstract Usersnap User Data Loader class. This needs to be implemented,
 * if a custom User Data Loader will be provided
 */
export abstract class UsersnapUserDataLoader {
  abstract getUserdata(): Observable<UsersnapUserData>;

  isEnabled(): Observable<boolean> {
    return of(true);
  }
}

/**
 * This loader is just a placeholder that does nothing, in case you don't need a loader at all
 */
@Injectable()
export class UsersnapUserDataFakeLoader extends UsersnapUserDataLoader {
  getUserdata(): Observable<UsersnapUserData> {
    return of({} as UsersnapUserData);
  }
}
