/* eslint-disable @typescript-eslint/no-explicit-any */
import { PricespiderConfig } from './model/pricespider-config.model';
import { PricespiderConfigLoader } from './providers';

/**
 * This is the usersnap initializer, which should only be called once on app initialization
 *
 * @param config Usersnap Config
 * @param document Document instance
 * @param userdataLoader Usersnap User Data Loader to provide Usersnap User Data for initialization
 */
export function pricespiderInitializer(document: Document, loader: PricespiderConfigLoader) {
  return () => {
    let initialized = false;
    // if document is missing, log the error and return
    if (!document || !document.defaultView) {
      console.error('Please make sure the environment is a browser. Was not possible to access `document` instance');
      return;
    }

    loader.getConfig().subscribe((config) => {
      if (!initialized) {
        init(config);
      }
    });

    function init(config: PricespiderConfig) {
      if (!config?.apiKey) {
        console.error('Empty api key for Pricespider. Please provide one when importing the PricespiderModule.forRoot()');
        return;
      }

      // Create the pricespider script tag and append it to the head
      const head = document.querySelector('head');

      const keyMeta = document.createElement('meta');
      keyMeta.name = 'ps-key';
      keyMeta.content = config.apiKey;

      const countryMeta = document.createElement('meta');
      countryMeta.name = 'ps-country';
      countryMeta.content = config.country;

      const languageMeta = document.createElement('meta');
      languageMeta.name = 'ps-language';
      languageMeta.content = config.language;

      const widgetScript = document.createElement('script');
      widgetScript.type = 'text/javascript';
      widgetScript.src = 'https://cdn.pricespider.com/1/lib/ps-widget.js';

      head?.appendChild(keyMeta);
      head?.appendChild(countryMeta);
      head?.appendChild(languageMeta);
      head?.appendChild(widgetScript);

      const apiKeySplitted = config.apiKey.split('-');
      if (apiKeySplitted.length === 2) {
        const prefix = apiKeySplitted[0];

        const trackingScript = document.createElement('script');
        trackingScript.type = 'text/javascript';
        trackingScript.src = `//cdn.pricespider.com/1/${prefix}/ps-utid.js`;

        head?.appendChild(trackingScript);
      }

      initialized = true;
    }
  };
}
