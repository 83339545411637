import { PagedData } from '@ird/shared-base';
import { Manufacturer } from '../manufacturer';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface BrandModel {
  id: string;
  name: string;
  description?: string;
  manufacturers?: Array<Manufacturer>;
  selected?: boolean;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface BrandModelSelection {
  brandModelArray: PagedData<BrandModel>;
  selectedBrandsCount?: number;
  allBrandsCount?: number;
}
