import { inject, InjectionToken } from '@angular/core';
import { HotjarFunction } from '../model/hotjar-function';
import { HJ_WINDOW } from './hotjar-window.provider';

/**
 * Checks if the hotjar function is available, otherwise null
 */
export function getHotjarFunction(window: (Window & { hj?: HotjarFunction }) | null): HotjarFunction | null {
  return window && window.hj ? window.hj : null;
}

/**
 * Provides the hotjar function if available
 */
export const HJ_FUNCTION = new InjectionToken<HotjarFunction | null>('hj-function', {
  providedIn: 'root',
  factory: () => getHotjarFunction(inject(HJ_WINDOW)),
});
