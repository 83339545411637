export default function endpoints() {
  const csApiUrl = '/api';
  const nestUrl = '/servicepoint/api';
  const spUrl = '/sepo/api';

  const createUrl = (urlPath: string, urlPrefix: string) => {
    if (!urlPrefix.endsWith('/')) {
      urlPrefix = urlPrefix + '/';
    }

    const url =
      urlPrefix +
      urlPath
        .split('/')
        .filter((el) => el.length > 0)
        .join('/');

    if (urlPath.endsWith('/')) {
      return url + '/';
    }
    return url;
  };

  const createNestUrl = (urlPath: string) => createUrl(urlPath, nestUrl);
  const createCsUrl = (urlPath: string) => createUrl(urlPath, csApiUrl);
  const createSpUrl = (urlPath: string) => createUrl(urlPath, spUrl);

  return {
    assetOverview: {
      assets: createNestUrl('/v1/asset-overview/assets'),
      tenants: createNestUrl('/v1/asset-overview/tenants'),
    },
    api: {
      scs: csApiUrl,
      nest: nestUrl,
      sp: spUrl,
    },
    auth: {
      login: createNestUrl('/v1/login'),
      logout: createNestUrl('/v1/logout'),
      profile: createNestUrl('v1/profile'),
      passwordReset: createCsUrl('/servicepoint/v1/user/{username}/resetpassword'),
      newPassword: createCsUrl('/v2/user/{username}/resetpassword/{resetToken}'),
    },
    user: {
      apiEndpoint: createNestUrl('v1/profile'),
      userProfile: createCsUrl('/v4/user/profile'),
    },
    contact: {
      sendContact: createNestUrl('/v1/contact'),
      sendFile: createSpUrl('/v1/contact/{contactSessionId}/attachments/'),
    },
    device: {
      getDevice: (assetId: string) => createNestUrl(`/v1/asset/${assetId}`),
      getSparepartKits: createCsUrl('/servicepoint/v1/device/{deviceId}/sparepartkits'),
      createDevice: createCsUrl('/servicepoint/v1/device/'),
      findDeviceByNameAndTenantID: createNestUrl('/v1/qr-assignment/model'),
      findDeviceBySerialNrAndTenantID: createNestUrl('/v1/qr-assignment/device/serial-number'),
      addToPrivateTeam: createNestUrl('/v1/private-asset/device/{deviceId}'),
      findDeviceIdBySerialNumber: (serialNumber: string, tenantId: string) =>
        createNestUrl(`/v1/asset/serial-number/${serialNumber}/tenant/${tenantId}`),
    },
    warranty: {
      getWarrantyStatus: createNestUrl('/v1/warranty/status/{deviceId}/device/{tenantId}/tenant'),
      checkAndActivateExtendedWarranty: createNestUrl('/v1/warranty/extended-warranty'),
    },
    file: {
      getManufacturerFileData: createCsUrl('/servicepoint/v2/file/device/{deviceId}/manufacturer'),
      getManufacturerFile: createCsUrl('/servicepoint/v1/file/device/{deviceId}/manufacturer/{fileId}'),
      getPreviewImage: createCsUrl('/servicepoint/v1/file/spareparts/{sparePartKitUUID}/previewimages'),
      getDocumentsByCategory: createCsUrl('/servicepoint/v1/file/device/{deviceId}/manufacturer/category'),
      getSparpartkitImage: (sparepartkitId: string, imageId: string) => createCsUrl(`/v1/file/sparepartkit/${sparepartkitId}/${imageId}`),
      userNotes: {
        getFile: createCsUrl('/v1/file/usernote/{noteId}/file/{fileId}'),
        uploadFile: createCsUrl('/v1/file/usernote/{noteId}/files'),
        getThumbnailImage: createCsUrl('/v1/file/usernote/{noteId}/thumbnail/{fileId}'),
        deleteAllFiles: createCsUrl('/v1/file/usernote/{noteId}/files'),
        deleteOneFile: createCsUrl('/v1/file/usernote/{noteId}/image/{fileId}'),
      },
      device: {
        getDeviceThumbnailImage: createCsUrl('/v1/file/device/{deviceId}/thumbnail/{thumbnailId}'),
      },
    },
    kpi: {
      init: createNestUrl('/v1/init-logs'),
      website: createNestUrl('/v1/website-logs'),
    },
    qrAssignment: {
      getModelsByTenant: createNestUrl('/v1/qr-assignment/tenant/{tenantId}/models'),
    },
    registration: {
      register: createNestUrl('/v1/onboarding/register'),
      resendEmail: createNestUrl('/v1/onboarding/resend-activation'),
      processUserActivation: createNestUrl('/v1/onboarding/process-activation'),
    },
    scan: {
      scanEvent: createSpUrl('/v1/pumplocations/{deviceId}'),
    },
    tenant: {
      configuration: createCsUrl('/servicepoint/v1/tenant/configuration'),
      tenantLogo: createCsUrl('/ui/v1/file/tenant/theme/logo/{fileId}'),
      tenantImageData: createCsUrl('/ui/v1/file/tenant/theme/logo/{fileId}'),
    },
    unleash: {
      get: createNestUrl('/v1/unleash/{id}'),
    },
    interaction: {
      sepoInteractions: createNestUrl('/v1/interaction'),
    },
    quote: {
      request: createNestUrl('/v1/quote-request'),
    },
    ui: {
      tenant: {
        defaultDeviceImage: {
          get: createCsUrl('/ui/v1/file/tenant/theme/img/default/device/{fileId}'),
        },
      },
    },
  };
}
