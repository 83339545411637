import { IrdClassMetadata, IrdClassMetadataImpl } from './metadata';
import { IrdFormatter, IrdParser, IrdProperty, IrdPropertyImpl, IrdPropertyType } from './property';
import { IrdValidator } from './validator';

/**
 * @see https://dzone.com/articles/nested-builder
 */
export class IrdClassMetadataBuilder<T> {
  private instance: IrdClassMetadata<T>;

  constructor(primaryKey: keyof T, translationKeyPrefix: string) {
    this.instance = new IrdClassMetadataImpl(primaryKey, translationKeyPrefix);
  }

  public setMapper(mapper: any): this {
    this.instance.mapper = mapper;
    return this;
  }

  public withProperties(properties: Record<keyof T, IrdProperty<T, IrdPropertyType>>): this {
    this.instance.properties = properties;
    return this;
  }

  public addProperties() {
    return new PropertySetBuilder(this.instance.translationKeyPrefix, this);
  }

  public build(): IrdClassMetadata<T> {
    return this.instance;
  }
}

class PropertySetBuilder<T> {
  private instance: Record<keyof T, IrdProperty<T, IrdPropertyType>> = {} as Record<keyof T, IrdProperty<T, IrdPropertyType>>; //TODO type conversion;

  constructor(
    private translationKeyPrefix,
    private metadataBuilder: IrdClassMetadataBuilder<T>,
  ) {}

  public withProperty<O>(property: IrdProperty<T, O>): this {
    this.instance[property.name] = property;
    return this;
  }

  public addProperty<O>(name: keyof T, type: IrdPropertyType): PropertyBuilder<T, O> {
    return new PropertyBuilder(name, type, this.translationKeyPrefix, this);
  }

  private build(): Record<keyof T, IrdProperty<T, IrdPropertyType>> {
    return this.instance;
  }

  public done(): IrdClassMetadataBuilder<T> {
    this.metadataBuilder.withProperties(this.build());
    return this.metadataBuilder;
  }
}

class PropertyBuilder<T, O> {
  private instance: IrdProperty<T, O>;

  constructor(
    name: keyof T,
    type: IrdPropertyType,
    translationKeyPrefix: string,
    private propertySetBuilder: PropertySetBuilder<T>,
  ) {
    this.instance = new IrdPropertyImpl(name, type, translationKeyPrefix);
  }

  public addValidator(validator: IrdValidator): this {
    this.instance.validator.push(validator);
    return this;
  }

  public addAsyncValidator(asyncValidator: IrdValidator): this {
    this.instance.asyncValidator.push(asyncValidator);
    return this;
  }

  public addFormatter(formatter: IrdFormatter): this {
    this.instance.formatter.push(formatter);
    return this;
  }

  public addParser(parser: IrdParser): this {
    this.instance.parser.push(parser);
    return this;
  }

  public hideInUi(): this {
    this.instance.showInUi = false;
    return this;
  }

  public showInUi(): this {
    this.instance.showInUi = true;
    return this;
  }

  public isArray(): this {
    this.instance.isArray = true;
    return this;
  }

  public isObject(): this {
    this.instance.isArray = false;
    return this;
  }

  private build(): IrdProperty<T, O> {
    return this.instance;
  }

  public done(): PropertySetBuilder<T> {
    this.propertySetBuilder.withProperty(this.build());
    return this.propertySetBuilder;
  }
}
