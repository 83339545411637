// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host shared-icon-picker {
  height: 30%;
  margin-top: 1rem;
}
:host mat-form-field {
  width: 100%;
  margin-bottom: 0.5rem;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImdlbmVyaWMtZWRpdC1kaWFsb2cuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0U7RUFDRSxXQUFBO0VBQ0EsZ0JBQUE7QUFBSjtBQUdFO0VBQ0UsV0FBQTtFQUNBLHFCQUFBO0FBREoiLCJmaWxlIjoiZ2VuZXJpYy1lZGl0LWRpYWxvZy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgc2hhcmVkLWljb24tcGlja2VyIHtcbiAgICBoZWlnaHQ6IDMwJTtcbiAgICBtYXJnaW4tdG9wOiAxcmVtO1xuICB9XG5cbiAgbWF0LWZvcm0tZmllbGQge1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIG1hcmdpbi1ib3R0b206IDAuNXJlbTtcbiAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/shared/ng/common/src/lib/components/generic-edit-dialog/generic-edit-dialog.component.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,gBAAA;AAAJ;AAGE;EACE,WAAA;EACA,qBAAA;AADJ;AACA,giBAAgiB","sourcesContent":[":host {\n  shared-icon-picker {\n    height: 30%;\n    margin-top: 1rem;\n  }\n\n  mat-form-field {\n    width: 100%;\n    margin-bottom: 0.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
