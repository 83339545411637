import { DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MonoTypeOperatorFunction, ObservableInput, takeUntil } from 'rxjs';

export function takeUntilDestroyedOrObservable<T>(notifier: ObservableInput<any> | DestroyRef): MonoTypeOperatorFunction<T> {
  if (notifier instanceof DestroyRef) {
    return (source) => {
      return source.pipe(takeUntilDestroyed(notifier));
    };
  } else {
    return (source) => {
      return source.pipe(takeUntil(notifier));
    };
  }
}
