import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TenantSettings } from '@deprecated/api-interfaces';
import { Observable, ReplaySubject, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ContactPerson, Tenant } from '../models';
import { FileRepositoryUi, TenantRepositoryHttp, TenantSettingsRepositoryHttp } from '../repositories';

const CONVERSION_FACTORS: { [unit: string]: number } = {
  seconds: 1e9, // 1 second = 1e9 nanoseconds
  minutes: 60e9, // 1 minute = 60 seconds = 60 * 1e9 nanoseconds
  hours: 3600e9, // 1 hour = 3600 seconds = 3600 * 1e9 nanoseconds
  days: 86400e9, // 1 day = 86400 seconds = 86400 * 1e9 nanoseconds
};
@Injectable({ providedIn: 'root' })
export class TenantService {
  // tenant setting variable
  private tenantSettings: TenantSettings;
  private $tenantSettingsChange: ReplaySubject<TenantSettings> = new ReplaySubject(1);
  private $tenantListChange: ReplaySubject<void> = new ReplaySubject(1);
  private $tenantChange: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    @Inject('ENVIRONMENT') private config: any,
    private tenantRepository: TenantRepositoryHttp,
    private fileRepositoryUI: FileRepositoryUi,
    private tenantSettingsRepository: TenantSettingsRepositoryHttp,
  ) {}

  /**
   * Sets the tenant settings
   *
   * @param settings Tenant Settings
   * @param force remove all tenant settings
   */
  public setTenantSettings(settings: TenantSettings, force = false): void {
    if (settings?.tenantName || settings?.tenantId || force) {
      this.tenantSettings = {
        ...settings,
        tenantName: settings?.tenantName ?? this.tenantSettings?.tenantName,
        brandName: settings?.brandName ?? this.tenantSettings?.brandName,
        tenantCompanyName: settings?.tenantCompanyName ?? this.tenantSettings?.tenantCompanyName,
        tenantAddress: settings?.tenantAddress ?? this.tenantSettings?.tenantAddress,
        tenantEmail: settings?.tenantEmail ?? this.tenantSettings?.tenantEmail,
        hosts: settings?.hosts ?? this.tenantSettings?.hosts,
      };

      if (settings.logoFileId && settings.tenantId) {
        this.fileRepositoryUI.getTenantImageData(settings.logoFileId).subscribe({
          next: (res) => {
            const reader = new FileReader();

            reader.addEventListener(
              'load',
              () => {
                this.setNavigationTenantImage(reader.result.toString());
                this.$tenantSettingsChange.next(settings);
              },
              false,
            );

            reader.addEventListener('error', () => {
              this.$tenantSettingsChange.next(settings);
            });

            reader.readAsDataURL(res.body);
          },
          error: () => {
            this.$tenantSettingsChange.next(settings);
          },
        });
      } else {
        this.setNavigationTenantImage(null);
        this.$tenantSettingsChange.next(settings);
      }
    }
  }

  public setNavigationTenantImage(url: string): void {
    if (url) {
      this._document.body.style.setProperty('--logo-url', 'url("' + url + '")');
    } else {
      this._document.body.style.removeProperty('--logo-url');
    }
  }

  public changeSettingsEvent(): ReplaySubject<TenantSettings> {
    return this.$tenantSettingsChange;
  }

  public tenantListChange(): ReplaySubject<void> {
    return this.$tenantListChange;
  }

  public tenantChange(): ReplaySubject<boolean> {
    return this.$tenantChange;
  }

  public evokeTenantListChange(): void {
    this.$tenantListChange.next();
  }

  public getTenantSettings(): TenantSettings {
    return this.tenantSettings;
  }

  getTenantId(): string {
    return this.tenantSettings?.tenantId;
  }

  getTenantBrandName(): string {
    return this.tenantSettings.brandName;
  }

  isSeepexTenant(tenantId?: string): boolean {
    return (tenantId ?? this.tenantSettings?.tenantId) === 'a259c0d9-2770-4c7b-a420-c14e249d021b';
  }

  isITSTenant(tenantId?: string): boolean {
    return (
      (tenantId ?? this.tenantSettings?.tenantId) === '852490fa-7c1e-4c84-a29e-d18f3ffb1b4a' || // Prod: IR-ITS/A-ConnectedAirSolutions
      (tenantId ?? this.tenantSettings?.tenantId) === 'f39e6a32-d427-4cd2-a6dd-1a8b8f0858ac' || // Prod: IR-ITS/A-Ingersoll Rand
      (tenantId ?? this.tenantSettings?.tenantId) === 'd7d49e88-8206-4988-951d-41f5bba61b69'
    ); // Prod: IR-ITS/X-ConnectedAirSolutions
  }

  isConnectedAirSolutions(): boolean {
    return location.origin === this.config.connectedAirSolutionsDomain;
  }

  getTenants(): Observable<Tenant[]> {
    return this.tenantRepository.getTenants();
  }

  getTenantToken(tenantId: string): Observable<any> {
    this.$tenantChange.next(true);
    if (this.tenantSettings?.tenantId === tenantId) {
      return of(localStorage.getItem('token')).pipe(tap(() => this.$tenantChange.next(false)));
    }
    return this.tenantRepository.getTenantToken(tenantId).pipe(tap(() => this.$tenantChange.next(false)));
  }

  createTenant(tenant: Tenant): Observable<Tenant> {
    return this.tenantRepository.createTenant(tenant);
  }

  editTenant(tenant: Tenant): Observable<Tenant> {
    return this.tenantRepository.editTenant(tenant);
  }

  public previewImage(
    file: File,
    name: string,
    defaultDeviceImage: File,
    defaultDeviceImageName: string,
    defaultEmailImage: File,
    defaultEmailImageName: string,
    tenantName: string,
    brandName: string,
    tenantCompanyName: string,
    tenantAddress: string,
    tenantEmail: string,
    hosts: string[],
  ) {
    return () => {
      return {
        file,
        name,
        defaultDeviceImage,
        defaultDeviceImageName,
        defaultEmailImage,
        defaultEmailImageName,
        tenantName,
        brandName,
        tenantCompanyName,
        tenantAddress,
        tenantEmail,
        hosts,
      };
    };
  }

  public addContactPerson(contactPerson: ContactPerson): Observable<string> {
    return this.tenantSettingsRepository.addContactPerson(contactPerson);
  }

  public convertTimeoutDurationDynamic(nanoseconds: number): { value: number; unit: string } {
    if (nanoseconds) {
      const units = ['days', 'hours', 'minutes'];

      for (const unit of units) {
        if (nanoseconds >= CONVERSION_FACTORS[unit]) {
          const convertedValue = nanoseconds / CONVERSION_FACTORS[unit];
          if (Number.isInteger(convertedValue)) {
            return { value: convertedValue, unit };
          }
        }
      }
      return { value: nanoseconds / CONVERSION_FACTORS.seconds, unit: 'seconds' };
    }
    return { value: 30, unit: 'minutes' };
  }
}
