/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class Operator {
  exampleData: string;
  name: string;
  id: string;
  identifier: string;
  inputs: string[] = [];
  isPublic: boolean;
  script: string;
  tenantId: string;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class OperatorDto extends Operator {
  attached = false;
}
