import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PlacementCodeService {
  placementCode: string;

  setPlacementcode(placementCode: string): void {
    this.placementCode = placementCode;
    localStorage.removeItem('placementCode');
    localStorage.setItem('placementCode', placementCode);
  }

  getPlacementCode(): string | null {
    return this.placementCode ?? localStorage.getItem('placementCode');
  }
}
