// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host mat-form-field {
  width: 100%;
}
:host .metadata-cards {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  list-style: none;
  margin: 16px 0 0;
  padding: 0;
}
:host .new-metadata-card {
  margin: 16px 0;
}
:host mat-card {
  padding: 16px 16px 0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1ldGFkYXRhLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFO0VBQ0UsV0FBQTtBQUFKO0FBR0U7RUFDRSxhQUFBO0VBQ0EsU0FBQTtFQUNBLDREQUFBO0VBQ0EsZ0JBQUE7RUFDQSxnQkFBQTtFQUNBLFVBQUE7QUFESjtBQUlFO0VBQ0UsY0FBQTtBQUZKO0FBS0U7RUFDRSxvQkFBQTtBQUhKIiwiZmlsZSI6Im1ldGFkYXRhLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICBtYXQtZm9ybS1maWVsZCB7XG4gICAgd2lkdGg6IDEwMCU7XG4gIH1cblxuICAubWV0YWRhdGEtY2FyZHMge1xuICAgIGRpc3BsYXk6IGdyaWQ7XG4gICAgZ2FwOiAxNnB4O1xuICAgIGdyaWQtdGVtcGxhdGUtY29sdW1uczogcmVwZWF0KGF1dG8tZmlsbCwgbWlubWF4KDM1MHB4LCAxZnIpKTtcbiAgICBsaXN0LXN0eWxlOiBub25lO1xuICAgIG1hcmdpbjogMTZweCAwIDA7XG4gICAgcGFkZGluZzogMDtcbiAgfVxuXG4gIC5uZXctbWV0YWRhdGEtY2FyZCB7XG4gICAgbWFyZ2luOiAxNnB4IDA7XG4gIH1cblxuICBtYXQtY2FyZCB7XG4gICAgcGFkZGluZzogMTZweCAxNnB4IDA7XG4gIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/shared/ng/common/src/lib/components/metadata/metadata.component.scss"],"names":[],"mappings":"AACE;EACE,WAAA;AAAJ;AAGE;EACE,aAAA;EACA,SAAA;EACA,4DAAA;EACA,gBAAA;EACA,gBAAA;EACA,UAAA;AADJ;AAIE;EACE,cAAA;AAFJ;AAKE;EACE,oBAAA;AAHJ;AACA,w2BAAw2B","sourcesContent":[":host {\n  mat-form-field {\n    width: 100%;\n  }\n\n  .metadata-cards {\n    display: grid;\n    gap: 16px;\n    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));\n    list-style: none;\n    margin: 16px 0 0;\n    padding: 0;\n  }\n\n  .new-metadata-card {\n    margin: 16px 0;\n  }\n\n  mat-card {\n    padding: 16px 16px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
