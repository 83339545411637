import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class UnleashRepository {
  constructor(private httpClient: HttpClient) {}
  isFeatureFlagEnabled(flagName: string, onTenant = false): Observable<boolean> {
    const options = onTenant
      ? {
          params: new HttpParams({ fromString: 'tenantFeature=true' }),
        }
      : {};

    const endpoint = environment.unleash.get.replace('{id}', flagName);
    return this.httpClient.get<boolean>(endpoint, options);
  }
}
