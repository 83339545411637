import { ThresholdLevel } from '../shared';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface AlertDefaultTextSet {
  description?: string;
  recommendedActions?: string;
  sensorType?: string;
  tenantId?: string;
  alertType: ThresholdLevel;
  typicalReasons?: string;
}
