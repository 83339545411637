/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class KpiData {
  deviceId: string;
  kpiName: string;
  kpiVersion: string;
  timeInNanoSeconds: number;
  value: number;
  year: number;
}
