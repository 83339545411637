import { ComponentType } from "@angular/cdk/portal";
import { DialogAction } from "./dialog.actions";
import { DialogIcon } from './dialog.icon';

export interface DialogData<T> {
  title?: string;
  icon?: DialogIcon,
  subtitle?: string;
  content?: string;
  component?: ComponentType<T>;
  actions?: DialogAction[];
}
