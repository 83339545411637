// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  width: 100%;
  /* stylelint-disable-next-line */
}
:host a {
  color: inherit;
  text-decoration: none;
}
:host .clickable {
  cursor: pointer;
}
:host .hide-in-percy {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 50px;
}
:host spx-icon ::ng-deep mat-icon {
  height: 18px;
  width: 18px;
}
:host .type-icon {
  margin-right: 0.5rem;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImJyZWFkY3J1bWJzLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsY0FBQTtFQUNBLFdBQUE7RUFrQkEsZ0NBQUE7QUFoQkY7QUFBRTtFQUNFLGNBQUE7RUFDQSxxQkFBQTtBQUVKO0FBQ0U7RUFDRSxlQUFBO0FBQ0o7QUFFRTtFQUNFLGdCQUFBO0VBQ0EsdUJBQUE7RUFDQSxtQkFBQTtFQUNBLFdBQUE7QUFBSjtBQUlFO0VBQ0UsWUFBQTtFQUNBLFdBQUE7QUFGSjtBQUtFO0VBQ0Usb0JBQUE7QUFISiIsImZpbGUiOiJicmVhZGNydW1icy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgZGlzcGxheTogYmxvY2s7XG4gIHdpZHRoOiAxMDAlO1xuXG4gIGEge1xuICAgIGNvbG9yOiBpbmhlcml0O1xuICAgIHRleHQtZGVjb3JhdGlvbjogbm9uZTtcbiAgfVxuXG4gIC5jbGlja2FibGUge1xuICAgIGN1cnNvcjogcG9pbnRlcjtcbiAgfVxuXG4gIC5oaWRlLWluLXBlcmN5IHtcbiAgICBvdmVyZmxvdzogaGlkZGVuO1xuICAgIHRleHQtb3ZlcmZsb3c6IGVsbGlwc2lzO1xuICAgIHdoaXRlLXNwYWNlOiBub3dyYXA7XG4gICAgd2lkdGg6IDUwcHg7XG4gIH1cblxuICAvKiBzdHlsZWxpbnQtZGlzYWJsZS1uZXh0LWxpbmUgKi9cbiAgc3B4LWljb24gOjpuZy1kZWVwIG1hdC1pY29uIHtcbiAgICBoZWlnaHQ6IDE4cHg7XG4gICAgd2lkdGg6IDE4cHg7XG4gIH1cblxuICAudHlwZS1pY29uIHtcbiAgICBtYXJnaW4tcmlnaHQ6IDAuNXJlbTtcbiAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/shared/ng/base/src/lib/base/breadcrumbs/breadcrumbs.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,WAAA;EAkBA,gCAAA;AAhBF;AAAE;EACE,cAAA;EACA,qBAAA;AAEJ;AACE;EACE,eAAA;AACJ;AAEE;EACE,gBAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;AAAJ;AAIE;EACE,YAAA;EACA,WAAA;AAFJ;AAKE;EACE,oBAAA;AAHJ;AACA,gkCAAgkC","sourcesContent":[":host {\n  display: block;\n  width: 100%;\n\n  a {\n    color: inherit;\n    text-decoration: none;\n  }\n\n  .clickable {\n    cursor: pointer;\n  }\n\n  .hide-in-percy {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    width: 50px;\n  }\n\n  /* stylelint-disable-next-line */\n  spx-icon ::ng-deep mat-icon {\n    height: 18px;\n    width: 18px;\n  }\n\n  .type-icon {\n    margin-right: 0.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
