import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnChanges {
  @Input() src: string;
  @Input() alt = '';
  @Input() fitImage = true;

  blob: Promise<string>;

  constructor(private http: HttpClient) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.src.currentValue !== changes.src.previousValue) {
      this.fetchImageBlob();
    }
  }

  private async fetchImageBlob() {
    if (this.src) {
      const imageBlob = await firstValueFrom(this.http.get(this.src, { responseType: 'blob' }));

      const reader = new FileReader();
      this.blob = new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(imageBlob);
      });
    } else {
      this.blob = undefined;
    }
  }
}
