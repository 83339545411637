/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DynamicThresholdModel {
  name: string;
  value: number;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DynamicLimitsModel {
  lower: DynamicThresholdModel;
  upper: DynamicThresholdModel;
}
