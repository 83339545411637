// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  height: 100%;
}

mat-form-field {
  position: relative;
}
mat-form-field:nth-child(2) {
  margin-left: 24px;
}

spx-icon-button {
  bottom: 6px;
  position: absolute;
  right: 0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRhdGUtZmlsdGVyLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsWUFBQTtBQUNGOztBQUVBO0VBQ0Usa0JBQUE7QUFDRjtBQUNFO0VBQ0UsaUJBQUE7QUFDSjs7QUFHQTtFQUNFLFdBQUE7RUFDQSxrQkFBQTtFQUNBLFFBQUE7QUFBRiIsImZpbGUiOiJkYXRlLWZpbHRlci5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi53cmFwcGVyIHtcbiAgaGVpZ2h0OiAxMDAlO1xufVxuXG5tYXQtZm9ybS1maWVsZCB7XG4gIHBvc2l0aW9uOiByZWxhdGl2ZTtcblxuICAmOm50aC1jaGlsZCgyKSB7XG4gICAgbWFyZ2luLWxlZnQ6IDI0cHg7XG4gIH1cbn1cblxuc3B4LWljb24tYnV0dG9uIHtcbiAgYm90dG9tOiA2cHg7XG4gIHBvc2l0aW9uOiBhYnNvbHV0ZTtcbiAgcmlnaHQ6IDA7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/shared/ng/base/src/lib/base/date-filter/date-filter.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,kBAAA;AACF;AACE;EACE,iBAAA;AACJ;;AAGA;EACE,WAAA;EACA,kBAAA;EACA,QAAA;AAAF;AACA,woBAAwoB","sourcesContent":[".wrapper {\n  height: 100%;\n}\n\nmat-form-field {\n  position: relative;\n\n  &:nth-child(2) {\n    margin-left: 24px;\n  }\n}\n\nspx-icon-button {\n  bottom: 6px;\n  position: absolute;\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
