import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmDialogService, DialogConfirmActionResult, DialogService, SpxObservableDataSource } from '@ird/ng-base';
import { GenericEditDialogComponent } from '../generic-edit-dialog';
import { IrdClassMetadata, SUCCESS_MSG_DURATION, UUID } from '@ird/shared-base';
import { GenericEditDialogData } from '../../models/generic-edit-dialog-data.model';
import { SearchConfig } from '../search-bar';
import { catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { GenericAdminOptions, PagedData } from '../../models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { CrudService } from '../../services';

@Component({
  selector: 'shared-generic-admin-page',
  templateUrl: './generic-admin-page.component.html',
  styleUrls: ['./generic-admin-page.component.scss'],
})
export class GenericAdminPageComponent<Dto> implements OnInit {
  @Input() crudService!: CrudService<Dto>;
  @Input() metadata: IrdClassMetadata<Dto>;
  @Input() options: GenericAdminOptions;
  @Input() defaultValues: Dto;
  @Input() translationRoot: string;

  @Output() loadingError = new EventEmitter<unknown>();

  isLoading: boolean = false;
  dataSource: SpxObservableDataSource<any> = new SpxObservableDataSource<unknown>(this.load());
  searchConfig: SearchConfig;

  constructor(
    private dialogService: DialogService,
    private snackbar: MatSnackBar,
    private translateService: TranslateService,
    private confirmDialogService: ConfirmDialogService,
  ) {}

  ngOnInit() {
    this.searchConfig = {
      title: `${this.translationRoot}.TITLE`,
      add: {
        action: () => {
          this.openUpdateDialog();
        },
      },
      search: {
        label: `${this.translationRoot}.SEARCH_PLACEHOLDER`,
      },
    }
  }

  onChangeSearch($event: unknown) {
    this.dataSource.update({ filter: $event['search'] });
  }

  openUpdateDialog(dto?: Dto): void {
    this.dialogService
      .openDialogWithComponent(GenericEditDialogComponent, 'Edit', '', {
        data: this.createEditDialogConfig(dto),
      })
      .subscribe((result: Dto) => {
        if (result) {
          if (result['id']) {
            this.update(result);
          } else {
            this.create(result);
          }
        }
      });
  }

  openDeleteDialog(dto: Dto): void {
    this.confirmDialogService
      .openConfirmDialog(
        this.translateService.instant(`${this.translationRoot}.DIALOG_DELETE_CONTENT`),
        this.translateService.instant(`${this.translationRoot}.DIALOG_DELETE_TITLE`),
      )
      .subscribe((confirmResult: DialogConfirmActionResult) => {
        if (confirmResult.confirmed) {
          this.delete(dto['id']);
        }
      });
  }

  private createEditDialogConfig(dto?: Dto): GenericEditDialogData<Dto> {
    return {
      dtoId: dto ? dto['id'] : '',
      data: dto ?? this.defaultValues,
      metadata: this.metadata,
      options: this.options,
    };
  }

  private load() {
    return (query, sort, pagination) => {
      this.isLoading = true;

      return this.crudService.getAll(query, pagination?.index, pagination?.size, sort?.sortOrders).pipe(
        catchError((error) => {
          this.playErrorSequence(error);
          return of({ content: [] } as PagedData<unknown>);
        }),
        finalize(() => {
          this.isLoading = false;
        }),
      );
    };
  }

  private update(item: Dto): void {
    this.crudService.update(item).subscribe({
      next: () => {
        this.dataSource.update();
        this.snackbar.open(this.translateService.instant(`${this.translationRoot}.CREATE_SUCCESS`), '', { duration: SUCCESS_MSG_DURATION });
      },
      error: (error) => this.playErrorSequence(error),
    });
  }

  private create(item: Dto): void {
    this.crudService.create(item).subscribe({
      next: () => {
        this.dataSource.update();
        this.snackbar.open(this.translateService.instant(`${this.translationRoot}.CREATE_SUCCESS`), '', { duration: SUCCESS_MSG_DURATION });
      },
      error: (error) => this.playErrorSequence(error),
    });
  }

  private delete(id: UUID): void {
    this.crudService.delete(id).subscribe({
      next: () => {
        this.dataSource.update();
        this.snackbar.open(this.translateService.instant(`${this.translationRoot}.DELETE_SUCCESS`), '', { duration: SUCCESS_MSG_DURATION });
      },
      error: (error) => this.playErrorSequence(error),
    });
  }

  private playErrorSequence(error: unknown) {
    this.loadingError.emit(error);
  }
}
