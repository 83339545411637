// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: inherit;
}

div {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  height: 100%;
  width: 100%;
}

.hide-click {
  cursor: default;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRldmljZS1pbWFnZS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGVBQUE7QUFDRjs7QUFFQTtFQUNFLGtDQUFBO0VBQ0EsNEJBQUE7RUFDQSx3QkFBQTtFQUNBLGVBQUE7RUFDQSxZQUFBO0VBQ0EsV0FBQTtBQUNGOztBQUVBO0VBQ0UsZUFBQTtBQUNGIiwiZmlsZSI6ImRldmljZS1pbWFnZS5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgaGVpZ2h0OiBpbmhlcml0O1xufVxuXG5kaXYge1xuICBiYWNrZ3JvdW5kLXBvc2l0aW9uOiBjZW50ZXIgY2VudGVyO1xuICBiYWNrZ3JvdW5kLXJlcGVhdDogbm8tcmVwZWF0O1xuICBiYWNrZ3JvdW5kLXNpemU6IGNvbnRhaW47XG4gIGN1cnNvcjogcG9pbnRlcjtcbiAgaGVpZ2h0OiAxMDAlO1xuICB3aWR0aDogMTAwJTtcbn1cblxuLmhpZGUtY2xpY2sge1xuICBjdXJzb3I6IGRlZmF1bHQ7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/shared/ng/common/src/lib/components/device-image/device-image.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAEA;EACE,kCAAA;EACA,4BAAA;EACA,wBAAA;EACA,eAAA;EACA,YAAA;EACA,WAAA;AACF;;AAEA;EACE,eAAA;AACF;AACA,osBAAosB","sourcesContent":[":host {\n  height: inherit;\n}\n\ndiv {\n  background-position: center center;\n  background-repeat: no-repeat;\n  background-size: contain;\n  cursor: pointer;\n  height: 100%;\n  width: 100%;\n}\n\n.hide-click {\n  cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
