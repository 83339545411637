import { Device } from '../../device';
import { SourceType, ThresholdLevel } from '../../shared';
import { USER_MANAGEMENT_NODE_TYPE, UserManagementElementType } from '../../user-management';
import { AvailableIntelligentAlert } from '../intelligent.model';

class SharedAlarmConfigurationModel {
  autoack: boolean;
  description?: string;
  descriptionEnabled?: boolean;
  deviceId: string;
  enabled?: boolean;
  emails: Array<string>;
  frequency = '1d';
  id?: string;
  mobiles: Array<string>;
  name: string;
  recommendedActions?: string;
  recommendedActionsEnabled?: boolean;
  sourceType?: SourceType;
  sourceName?: string;
  tenantId?: string;
  type?: ThresholdLevel;
  typicalReasons?: string;
  typicalReasonsEnabled?: boolean;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class AlarmSourceConfig {
  id?: string;
  sourceId?: string;
  sourceType: SourceType;
  name?: string;
  type?: string;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class TemplateAlarmSourceConfig {
  sourceTypeId: string;
  sourceType: SourceType;
  feGroupingId: string;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class BaseAlarmConfiguration extends SharedAlarmConfigurationModel {
  sourceId?: string;
  sourceDescription?: string;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class ThresholdAlarmConfiguration extends BaseAlarmConfiguration {
  device?: Device;
  numValuesToTrigger: number;
  thresholdMax: number;
  thresholdMin: number;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class HeartbeatAlarmConfig extends BaseAlarmConfiguration {
  alarmSources: Array<AlarmSourceConfig>;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class ThresholdAlarmConfigurationGroup {
  alarmConfigurations: Array<ThresholdAlarmConfiguration>;
  alarmSource?: AlarmSourceConfig;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class IntelligentAlarmConfigurationResponse {
  id: string;
  deviceId: string;
  intelligentAlarmId: string;
  intelligentAlarm: AvailableIntelligentAlert;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class IntelligentAlarmConfiguration {
  alarmSource: AlarmSourceConfig;
  alarmConfiguration: BaseAlarmConfiguration;
  intelligentAlarmConfiguration?: IntelligentAlarmConfigurationResponse;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class AlertConfigTemplate extends SharedAlarmConfigurationModel {
  cronExpression: string;
  feGroupingId?: string;
  numValuesToTrigger: number;
  principalAssociations?: Array<AlertConfigTemplatePrincipalAssociation>;
  sourceTypeId: string;
  thresholdMax: number;
  thresholdMin: number;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class AlertConfigTemplateGroup {
  templateDtos: Array<AlertConfigTemplate>;
  templateAlarmSource: TemplateAlarmSourceConfig;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class AlertConfigTemplatePrincipalAssociation {
  alarmConfigTemplateId: string;
  emails: Array<string>;
  enabled: boolean;
  mobiles: Array<string>;
  notifyPrincipalThresholdContacts: boolean;
  principalId: string;
  principalType: UserManagementElementType;
  userIds?: Array<string>;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class PrincipalToNodeType {
  static [UserManagementElementType.ORGANISATION] = USER_MANAGEMENT_NODE_TYPE.ORGANIZATION;
  static [UserManagementElementType.TEAM] = USER_MANAGEMENT_NODE_TYPE.TEAM;
  static [UserManagementElementType.TENANT] = USER_MANAGEMENT_NODE_TYPE.TENANT;
}
