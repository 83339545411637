import { Injectable } from '@angular/core';
import { Country, Subdivision } from '../models';
import countryListJson from '../repositories/countrydata/countries.json';
import subdivisionListJson from '../repositories/countrydata/subdivisions.json';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  getCountryList(): Country[] {
    return countryListJson;
  }

  getStatesList(): Subdivision[] {
    return subdivisionListJson;
  }

  getUSAStatesList(): Subdivision[] {
    const usStates: Subdivision[] = [];
    subdivisionListJson.forEach((subdivision) => {
      if (subdivision.country === 'US') {
        usStates.push(subdivision);
      }
    });
    return usStates;
  }

  getCanadaStatesList(): Subdivision[] {
    const canadaStates: Subdivision[] = [];
    subdivisionListJson.forEach((subdivision) => {
      if (subdivision.country === 'CA') {
        canadaStates.push(subdivision);
      }
    });
    return canadaStates;
  }

  getIndiaStatesList(): Subdivision[] {
    const indiaStates: Subdivision[] = [];
    subdivisionListJson.forEach((subdivision) => {
      if (subdivision.country === 'IN') {
        indiaStates.push(subdivision);
      }
    });
    return indiaStates;
  }
}
