import { FeatureStatusEnum } from '../../shared/feature-status.enum';
import { WarningStatusEnum } from './warning-status.enum';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class AnomalyCardInfo {
  isInstalled: boolean;
  featureStatus: FeatureStatusEnum;
  warningStatus: WarningStatusEnum = WarningStatusEnum.NO_STATUS;
}
