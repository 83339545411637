import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SepoInteractions } from '@deprecated/api-interfaces';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InteractionRepository {
  constructor(private http: HttpClient) {}

  createSepoInteractionsData(sepoInteraction: SepoInteractions): Observable<SepoInteractions> {
    const path = environment.interaction.sepoInteractions;
    return this.http.post<SepoInteractions>(path, sepoInteraction);
  }
}
