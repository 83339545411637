import { Formula } from '../formula';
import { BaseAlarm } from './base-alarm.model';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class RaisedAlarm extends BaseAlarm {
  affected?: { count: number; total: number };
  alarmConfigurationId?: string;
  derivedValueConfiguration?: Formula;
  heartbeat: boolean;
  nanoSinceEpoch: number;
}
