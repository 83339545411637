import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

import { Observable } from 'rxjs';
import { USE_LLT_TOKEN } from '../../interceptor/token.interceptor';

@Injectable({ providedIn: 'root' })
export class KpiLogRepository {
  constructor(private http: HttpClient) {}

  sendWebsiteEvent(deviceId: string): void {
    const headers = {
      'Content-Type': 'application/json',
    };

    this.http
      .post<string>(
        environment.kpi.website,
        { deviceId },
        {
          observe: 'response',
          headers,
        },
      )
      .subscribe((err) => console.log(err));
  }

  sendInitEvent(deviceId: string, tenantId): Observable<HttpResponse<void>> {
    return this.http.post<null>(
      environment.kpi.init,
      { deviceId, tenantId },
      {
        observe: 'response',
        context: new HttpContext().set(USE_LLT_TOKEN, true),
      },
    );
  }
}
