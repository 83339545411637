/* eslint-disable @typescript-eslint/no-explicit-any */
import { isPlatformBrowser } from '@angular/common';
import { inject, InjectionToken, PLATFORM_ID } from '@angular/core';
import { HotjarFunction } from '../model/hotjar-function';

/**
 * Check if this environment can access Window object and return window or null if false.
 */
export function getWindow(platformId: any) {
  return isPlatformBrowser(platformId) ? (window as Window & { hj?: HotjarFunction }) : null;
}

/**
 * Provide DOM Window reference or null if the environment is not a Browser.
 */
export const HJ_WINDOW = new InjectionToken<(Window & { hj?: HotjarFunction; _hjSettings?: { hjid: string; hjsv: number } }) | null>(
  'hj-window',
  {
    factory: () => getWindow(inject(PLATFORM_ID)),
  }
);
