import { ValueType } from '../data-conversion';
import { KpiThreshold } from '../shared';
import { Data } from './device-source-dataset.model';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class ElementData {
  name: string;
  value: number;
  timeInNanoSeconds: number;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DeviceData {
  deviceData: ElementData;
  mappedUnit: string;
  name: string;
  unit: string;
  valueType?: ValueType;
  sensorPrecision?: number;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class WsDeviceData {
  deviceId: string;
  value: number;
  sensorId?: string;
  formularId?: string;
  timeInNanoSeconds?: number;
}

export enum SensorDataSourceType {
  SENSOR = 'SENSOR',
  FORMULA = 'FORMULA',
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DeviceFormulaData {
  formulaId: string;
  receivedTimeInNanoSeconds: number;
  timeInNanoSeconds: number;
  value: number;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class SourceDataForChart {
  deviceDataList?: Array<DeviceFormulaData>;
  maxValue: number;
  minValue: number;
  meanValue: number;
  medianValue: number;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class ChartData {
  data: Data;
  name: string;
  sourceData?: SourceDataForChart;
  valueType?: ValueType;
  unit?: string;
  sensorPrecision?: number;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class KpiChartData extends ChartData {
  thresholds: Array<KpiThreshold>;
}
