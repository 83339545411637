import { Formula } from '../../formula';
import { CronConfigurationDto, CronDto } from './cron.configuration.model';
import { OperatorDto } from './operator.model';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class CalculationsPageDto {
  crons: CalculationsPageCronDto;
  formulas: Array<Formula>;
  operators: Array<OperatorDto>;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class CalculationsPageCronDto {
  available: Array<CronDto>;
  configured: Array<CronConfigurationDto>;
}
