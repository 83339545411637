import { SecureAction } from '../secure-actions';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class UnitConversionSecureAction extends SecureAction {
  public static readonly VIEW_UNIT_CONVERSION: SecureAction = new UnitConversionSecureAction('device:unit_conversion');
  public static readonly VIEW_CURRENT_mA: SecureAction = new UnitConversionSecureAction('device:unit_conversion:current|mA');
  public static readonly VIEW_CURRENT_A: SecureAction = new UnitConversionSecureAction('device:unit_conversion:current|A');
  public static readonly VIEW_CURRENT_µA: SecureAction = new UnitConversionSecureAction('device:unit_conversion:current|µA');
  public static readonly VIEW_DISTANCE_m: SecureAction = new UnitConversionSecureAction('device:unit_conversion:distance|m');
  public static readonly VIEW_DISTANCE_ft: SecureAction = new UnitConversionSecureAction('device:unit_conversion:distance|ft');
  public static readonly VIEW_DISTANCE_cm: SecureAction = new UnitConversionSecureAction('device:unit_conversion:distance|cm');
  public static readonly VIEW_DISTANCE_mm: SecureAction = new UnitConversionSecureAction('device:unit_conversion:distance|mm');
  public static readonly VIEW_ENERGY_Wh: SecureAction = new UnitConversionSecureAction('device:unit_conversion:energy|Wh');
  public static readonly VIEW_ENERGY_kWh: SecureAction = new UnitConversionSecureAction('device:unit_conversion:energy|kWh');
  public static readonly 'VIEW_FLOW_l/h': SecureAction = new UnitConversionSecureAction('device:unit_conversion:flow|l/h');
  public static readonly 'VIEW_FLOW_m³/h': SecureAction = new UnitConversionSecureAction('device:unit_conversion:flow|m³/h');
  public static readonly 'VIEW_FLOW_l/min': SecureAction = new UnitConversionSecureAction('device:unit_conversion:flow|l/min');
  public static readonly 'VIEW_FLOW_m³/min': SecureAction = new UnitConversionSecureAction('device:unit_conversion:flow|m³/min');
  public static readonly 'VIEW_FLOW_ft³/min': SecureAction = new UnitConversionSecureAction('device:unit_conversion:flow|ft³/min');
  public static readonly VIEW_FLOW_GPM: SecureAction = new UnitConversionSecureAction('device:unit_conversion:flow|GPM');
  public static readonly 'VIEW_FLOW_l/s': SecureAction = new UnitConversionSecureAction('device:unit_conversion:flow|l/s');
  public static readonly 'VIEW_FLOW_ml/h': SecureAction = new UnitConversionSecureAction('device:unit_conversion:flow|ml/h');
  public static readonly VIEW_FORCE_N: SecureAction = new UnitConversionSecureAction('device:unit_conversion:force|N');
  public static readonly VIEW_FORCE_lbf: SecureAction = new UnitConversionSecureAction('device:unit_conversion:force|lbf');
  public static readonly VIEW_FREQUENCY_rpm: SecureAction = new UnitConversionSecureAction('device:unit_conversion:frequency|rpm');
  public static readonly VIEW_FREQUENCY_Hz: SecureAction = new UnitConversionSecureAction('device:unit_conversion:frequency|Hz');
  public static readonly 'VIEW_FREQUENCY_1/min': SecureAction = new UnitConversionSecureAction('device:unit_conversion:frequency|1/min');
  public static readonly VIEW_FREQUENCY_kHz: SecureAction = new UnitConversionSecureAction('device:unit_conversion:frequency|kHz');
  public static readonly VIEW_MASS_kg: SecureAction = new UnitConversionSecureAction('device:unit_conversion:mass|kg');
  public static readonly VIEW_MASS_lb: SecureAction = new UnitConversionSecureAction('device:unit_conversion:mass|lb');
  public static readonly VIEW_MASS_g: SecureAction = new UnitConversionSecureAction('device:unit_conversion:mass|g');
  public static readonly VIEW_MASS_mg: SecureAction = new UnitConversionSecureAction('device:unit_conversion:mass|mg');
  public static readonly VIEW_MASS_t: SecureAction = new UnitConversionSecureAction('device:unit_conversion:mass|t');
  public static readonly VIEW_POWER_W: SecureAction = new UnitConversionSecureAction('device:unit_conversion:power|W');
  public static readonly VIEW_POWER_kW: SecureAction = new UnitConversionSecureAction('device:unit_conversion:power|kW');
  public static readonly VIEW_POWER_PS: SecureAction = new UnitConversionSecureAction('device:unit_conversion:power|PS');
  public static readonly VIEW_POWER_HP: SecureAction = new UnitConversionSecureAction('device:unit_conversion:power|HP');
  public static readonly VIEW_PRESSURE_bar: SecureAction = new UnitConversionSecureAction('device:unit_conversion:pressure|bar');
  public static readonly VIEW_PRESSURE_Pa: SecureAction = new UnitConversionSecureAction('device:unit_conversion:pressure|Pa');
  public static readonly VIEW_PRESSURE_psi: SecureAction = new UnitConversionSecureAction('device:unit_conversion:pressure|psi');
  public static readonly VIEW_PRESSURE_mbar: SecureAction = new UnitConversionSecureAction('device:unit_conversion:pressure|mbar');
  public static readonly VIEW_PRESSURE_hPa: SecureAction = new UnitConversionSecureAction('device:unit_conversion:pressure|hPa');
  public static readonly VIEW_TEMPERATURE_K: SecureAction = new UnitConversionSecureAction('device:unit_conversion:temperature|K');
  public static readonly 'VIEW_TEMPERATURE_°F': SecureAction = new UnitConversionSecureAction('device:unit_conversion:temperature|°F');
  public static readonly 'VIEW_TEMPERATURE_°C': SecureAction = new UnitConversionSecureAction('device:unit_conversion:temperature|°C');
  public static readonly VIEW_TIME_ms: SecureAction = new UnitConversionSecureAction('device:unit_conversion:time|ms');
  public static readonly VIEW_TIME_s: SecureAction = new UnitConversionSecureAction('device:unit_conversion:time|s');
  public static readonly VIEW_TIME_h: SecureAction = new UnitConversionSecureAction('device:unit_conversion:time|h');
  public static readonly VIEW_TIME_d: SecureAction = new UnitConversionSecureAction('device:unit_conversion:time|d');
  public static readonly VIEW_VIBRATION_ACCELERATION_g: SecureAction = new UnitConversionSecureAction(
    'device:unit_conversion:vibration_acceleration|g',
  );
  public static readonly 'VIEW_VIBRATION_ACCELERATION_m/s²': SecureAction = new UnitConversionSecureAction(
    'device:unit_conversion:vibration_acceleration|m/s²',
  );
  public static readonly VIEW_VIBRATION_ACCELERATION_mg: SecureAction = new UnitConversionSecureAction(
    'device:unit_conversion:vibration_acceleration|mg',
  );
  public static readonly VIEW_VIBRATION_DISPLACEMENT_mil: SecureAction = new UnitConversionSecureAction(
    'device:unit_conversion:vibration_displacement|mil',
  );
  public static readonly VIEW_VIBRATION_DISPLACEMENT_µm: SecureAction = new UnitConversionSecureAction(
    'device:unit_conversion:vibration_displacement|µm',
  );
  public static readonly VIEW_VIBRATION_DISPLACEMENT_mm: SecureAction = new UnitConversionSecureAction(
    'device:unit_conversion:vibration_displacement|mm',
  );
  public static readonly 'VIEW_VIBRATION_VELOCITY_mm/s': SecureAction = new UnitConversionSecureAction(
    'device:unit_conversion:vibration_velocity|mm/s',
  );
  public static readonly 'VIEW_VIBRATION_VELOCITY_m/s': SecureAction = new UnitConversionSecureAction(
    'device:unit_conversion:vibration_velocity|m/s',
  );
  public static readonly 'VIEW_VIBRATION_VELOCITY_mil/s': SecureAction = new UnitConversionSecureAction(
    'device:unit_conversion:vibration_velocity|mil/s',
  );
  public static readonly VIEW_VOLTAGE_mV: SecureAction = new UnitConversionSecureAction('device:unit_conversion:voltage|mV');
  public static readonly VIEW_VOLTAGE_V: SecureAction = new UnitConversionSecureAction('device:unit_conversion:voltage|V');
  public static readonly VIEW_VOLTAGE_kV: SecureAction = new UnitConversionSecureAction('device:unit_conversion:voltage|kV');
  public static readonly 'VIEW_VOLUME_ft³': SecureAction = new UnitConversionSecureAction('device:unit_conversion:volume|ft³');
  public static readonly 'VIEW_VOLUME_m³': SecureAction = new UnitConversionSecureAction('device:unit_conversion:volume|m³');
  public static readonly VIEW_VOLUME_l: SecureAction = new UnitConversionSecureAction('device:unit_conversion:volume|l');
  public static readonly 'VIEW_VOLUME_cm³': SecureAction = new UnitConversionSecureAction('device:unit_conversion:volume|cm³');
  public static readonly VIEW_VOLUME_gal: SecureAction = new UnitConversionSecureAction('device:unit_conversion:volume|gal');

  private constructor(name: string) {
    super(name);
  }
}
