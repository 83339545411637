import { BasicGroup } from '../group';
import { MqttUser } from '../mqtt-user';
import { TabFeature } from '../tab-config';
import { BasicUser } from '../user';
import { BasicDevice } from './device.model';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DeviceEditFeaturesAndAccessDTO {
  users: Array<BasicUser>;
  groups: Array<BasicGroup>;
  children: Array<BasicDevice>;
  features: Array<TabFeature>;
  mqttUsers: Array<MqttUser>;
}
