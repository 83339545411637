import { Component, Input } from '@angular/core';
import { IconButtonType } from '../../model/button';
import { Color } from '../../model/color';

@Component({
  selector: 'spx-icon-button',
  templateUrl: './icon-button.component.html',
})
export class IconButtonComponent {
  /**
   * Color for Icon Button
   */
  @Input() color!: Color;

  /**
   * Disabled state
   */
  @Input() disabled = false;

  /**
   * Type of the icon button
   */
  @Input() type!: IconButtonType;

  /**
   * File path to icon file
   */
  @Input() iconFilePath!: string;

  /**
   * Name of the registered Icon
   */
  @Input() iconName!: string;

  /**
   * Namespace in which the icon is registered
   */
  @Input() iconNamespace!: string;

  /**
   * Material icon font class, which is used by the spx-icon
   */
  @Input() fontSetClass!: string;
}
