import { Observable } from 'rxjs';
import { Organization } from './organization.model';
import { Team } from './team.model';
import { Tenant } from './tenant.model';

export enum USER_MANAGEMENT_NODE_TYPE {
  TENANT = 'tenant',
  ORGANIZATION = 'organization',
  TEAM = 'team',
}
export type UserManagementNodeType =
  | USER_MANAGEMENT_NODE_TYPE.TENANT
  | USER_MANAGEMENT_NODE_TYPE.ORGANIZATION
  | USER_MANAGEMENT_NODE_TYPE.TEAM;

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class UserManagementNodeIcon {
  static [USER_MANAGEMENT_NODE_TYPE.TENANT] = 'corporate_fare';
  static [USER_MANAGEMENT_NODE_TYPE.ORGANIZATION] = 'account_tree';
  static [USER_MANAGEMENT_NODE_TYPE.TEAM] = 'groups';
}

export enum USER_MANAGEMENT_DND_TYPES {
  USER = 'user',
  USER_GROUP = 'user-group',
  ASSET = 'asset',
  ASSET_GROUP = 'asset-group',
  LOCATION = 'location',
}
export type UserManagementDnDTypes = USER_MANAGEMENT_DND_TYPES;
export type UserGroupNodeType = USER_MANAGEMENT_DND_TYPES.USER | USER_MANAGEMENT_DND_TYPES.USER_GROUP;
export type DeviceGroupNodeType = USER_MANAGEMENT_DND_TYPES.ASSET | USER_MANAGEMENT_DND_TYPES.ASSET_GROUP;

export enum USER_MANAGEMENT_DROP_ACTIONS {
  MOVE = 'move',
  COPY = 'copy',
  NONE = 'none',
}
export type UserManagementDropAction = USER_MANAGEMENT_DROP_ACTIONS;

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface UserManagementTreeNode {
  id: string;
  name: string;
  type: UserManagementNodeType;
  route?: string[];
  isPlaceholder?: boolean;
  isLoadingOrgs?: boolean;
  isLoadingTeams?: boolean;
  childCount?: number;
  parent?: UserManagementTreeNode;
  childOrganisationCount?: number;
  childTeamCount?: number;
  visited?: boolean;
  children: Array<UserManagementTreeNode>;
  dropAction?: UserManagementDropAction;
  data: Tenant | Organization | Team;
  isAdmin$?: Observable<boolean>;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface UsermanagementDragData {
  id: string;
  type: UserManagementNodeType | UserManagementDnDTypes;
  parent?: UsermanagementDragData;
}
