import { AvailableKpi } from './available-kpi';
import { ConfiguredKpi } from './configured-kpi';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class KpiListDTO {
  availableKpis?: AvailableKpi[];
  configuredKpis?: ConfiguredKpi[];
}
