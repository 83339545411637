/* eslint-disable @typescript-eslint/no-explicit-any */
import { _isNumberValue } from '@angular/cdk/coercion';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSourcePageEvent, MatTableDataSourcePaginator } from '@angular/material/table';
import { BehaviorSubject, Observable, Subject, combineLatest, merge, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SpxDataSource } from './data-source';
import { ISpxFilteredDataSource } from './interfaces/filtered-data-source.interface';
import { ISpxPaginatedDataSource } from './interfaces/paginated-data-source.interface';
import { ISpxSortedDataSource } from './interfaces/sorted-data-source.interface';
import { MAX_SAFE_INTEGER, Pagination } from './types';

export class SpxFeaturedDataSource<T, P extends MatTableDataSourcePaginator = MatTableDataSourcePaginator>
  extends SpxDataSource<T>
  implements ISpxFilteredDataSource, ISpxSortedDataSource, ISpxPaginatedDataSource
{
  protected readonly _internalPageChanges = new Subject<void>();
  protected _filter = new BehaviorSubject<string>('');
  protected _sort: MatSort | null = null;
  protected _paginator: P | null = null;

  public filteredData: T[] = [];

  override get data() {
    return super.data;
  }

  override set data(data: T[]) {
    super.data = data;
    if (!this._renderChangesSubscription) {
      this._filterData(data);
    }
  }

  constructor(initialData: T[] = []) {
    super(initialData);
  }

  getSort(): MatSort | null {
    return this._sort;
  }
  setSort(sort: MatSort | null): void {
    this._sort = sort;
    this._updateChangeSubscription();
  }

  getPaginator(): P | null {
    return this._paginator;
  }
  setPaginator(paginator: P | null): void {
    this._paginator = paginator;
    this._updateChangeSubscription();
  }

  getFilterValue(): string {
    return this._filter.value;
  }
  setFilterValue(filter: string): void {
    this._filter.next(filter);

    if (!this._renderChangesSubscription) {
      this._filterData(this.data);
    }
  }

  sortingDataAccessor: (data: T, sortHeaderId: string) => string | number = (data: T, sortHeaderId: string): string | number => {
    const value = this.accessByString(data, sortHeaderId);

    if (_isNumberValue(value)) {
      const numberValue = Number(value);
      return numberValue < MAX_SAFE_INTEGER ? numberValue : (value as number);
    }

    return value?.toString() || '';
  };

  sortData: (data: T[], sort: MatSort) => T[] = (data: T[], sort: MatSort): T[] => {
    if (!sort) {
      return data;
    }

    const active = sort.active;
    const direction = sort.direction;
    if (!active || direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let valueA = this.sortingDataAccessor(a, active);
      let valueB = this.sortingDataAccessor(b, active);

      const valueAType = typeof valueA;
      const valueBType = typeof valueB;

      if (valueAType !== valueBType) {
        if (valueAType === 'number') {
          valueA += '';
        }
        if (valueBType === 'number') {
          valueB += '';
        }
      }

      return this.comparatorResult(valueA, valueB) * (direction == 'asc' ? 1 : -1);
    });
  };

  private comparatorResult(valueA: string | number, valueB: string | number): number {
    let comparatorResult = 0;
    if (valueA != null && valueB != null) {
      if (valueA > valueB) {
        comparatorResult = 1;
      } else if (valueA < valueB) {
        comparatorResult = -1;
      }
    } else if (valueA != null) {
      comparatorResult = 1;
    } else if (valueB != null) {
      comparatorResult = -1;
    }
    return comparatorResult;
  }

  filterPredicate: (data: T, filter: string) => boolean = (data: T, filter: string): boolean => {
    const dataStr = Object.keys(data as unknown as Record<string, any>)
      .reduce((currentTerm: string, key: string) => {
        return currentTerm + (data as unknown as Record<string, any>)[key] + '◬';
      }, '')
      .toLowerCase();

    const transformedFilter = filter.trim().toLowerCase();

    return dataStr.indexOf(transformedFilter) != -1;
  };

  /**
   * Update the dataSource.
   *
   * @param query Optional query to update the data source with.
   * @returns a BehaviorSubject if the data to be rendered.
   */
  public override update(query?: { filter?: string; sort?: Sort; page?: Pagination }): BehaviorSubject<T[]> {
    if (query?.filter) {
      this._filter?.next(query.filter);
    }
    if (query?.sort) {
      this._sort?.sort({ id: query.sort.active, start: query.sort.direction, disableClear: false });
    }
    if (query?.page && this._paginator) {
      this._paginator.pageIndex = query.page.index;
      this._paginator.pageSize = query.page.size;
      this._internalPageChanges.next();
    }

    this._updateChangeSubscription();

    return this._renderData;
  }

  protected override _updateChangeSubscription(): void {
    const sortChange: Observable<Sort | null | void> = this._sort ? merge(this._sort.sortChange, this._sort.initialized) : of(null);
    const pageChange: Observable<MatTableDataSourcePageEvent | null | void> = this._paginator
      ? merge(this._paginator.page, this._internalPageChanges, this._paginator.initialized)
      : of(null);

    const dataStream = this._data;

    const filteredData = combineLatest([dataStream, this._filter]).pipe(map(([data]) => this._filterData(data)));
    const orderedData = combineLatest([filteredData, sortChange]).pipe(map(([data]) => this._orderData(data)));
    const paginatedData = combineLatest([orderedData, pageChange]).pipe(map(([data]) => this._pageData(data)));

    this._renderChangesSubscription?.unsubscribe();
    this._renderChangesSubscription = paginatedData?.subscribe((data) => this._renderData.next(data));
  }

  protected _filterData(data: T[]): T[] {
    const filter = this.getFilterValue();
    this.filteredData = filter == null || filter === '' ? data : data.filter((obj) => this.filterPredicate(obj, filter));

    if (this.getPaginator()) {
      this._updatePaginator(this.filteredData.length);
    }

    return this.filteredData;
  }

  protected _orderData(data: T[]): T[] {
    const sort = this.getSort();
    if (!sort) {
      return data;
    }

    return this.sortData(data.slice(), sort);
  }

  protected _pageData(data: T[]): T[] {
    const paginator = this.getPaginator();
    if (!paginator) {
      return data;
    }

    const startIndex = paginator.pageIndex * paginator.pageSize;
    return data.slice(startIndex, startIndex + paginator.pageSize);
  }

  protected _updatePaginator(filteredDataLength: number): Promise<void> {
    return Promise.resolve().then(() => {
      const paginator = this._paginator;

      if (!paginator) {
        return;
      }

      paginator.length = filteredDataLength;

      if (paginator.pageIndex > 0) {
        const lastPageIndex = Math.ceil(paginator.length / paginator.pageSize) - 1 || 0;
        const newPageIndex = Math.min(paginator.pageIndex, lastPageIndex);

        if (newPageIndex !== paginator.pageIndex) {
          paginator.pageIndex = newPageIndex;

          this._internalPageChanges.next();
        }
      }
    });
  }

  //https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-and-arrays-by-string-path
  protected accessByString(data: T, sortHeaderId: string) {
    let currentObject: Record<string, any> | string | number;
    currentObject = data as unknown as Record<string, any>;

    sortHeaderId = sortHeaderId.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    sortHeaderId = sortHeaderId.replace(/^\./, ''); // strip a leading dot
    const attributes = sortHeaderId.split('.');

    for (let i = 0, n = attributes.length; i < n; ++i) {
      const k = attributes[i];
      if (typeof currentObject === 'object' && k in currentObject) {
        currentObject = currentObject[k];
      } else {
        return;
      }
    }

    return currentObject;
  }
}
