import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ThemingService } from './services/theming.service';

@NgModule({
  imports: [CommonModule],
  providers: [ThemingService],
})
export class ThemingModule {
}
