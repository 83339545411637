// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  pointer-events: unset;
  white-space: nowrap;
}

.spx-button {
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: center;
  min-width: 24px;
  padding: 0 12px;
  pointer-events: auto;
  text-transform: uppercase;
  width: 100%;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImJ1dHRvbi5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLHFCQUFBO0VBQ0EsbUJBQUE7QUFDRjs7QUFFQTtFQUNFLG1CQUFBO0VBQ0EsYUFBQTtFQUNBLFlBQUE7RUFDQSx1QkFBQTtFQUNBLGVBQUE7RUFDQSxlQUFBO0VBQ0Esb0JBQUE7RUFDQSx5QkFBQTtFQUNBLFdBQUE7QUFDRiIsImZpbGUiOiJidXR0b24uY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XG4gIHBvaW50ZXItZXZlbnRzOiB1bnNldDtcbiAgd2hpdGUtc3BhY2U6IG5vd3JhcDtcbn1cblxuLnNweC1idXR0b24ge1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBkaXNwbGF5OiBmbGV4O1xuICBoZWlnaHQ6IDMwcHg7XG4gIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuICBtaW4td2lkdGg6IDI0cHg7XG4gIHBhZGRpbmc6IDAgMTJweDtcbiAgcG9pbnRlci1ldmVudHM6IGF1dG87XG4gIHRleHQtdHJhbnNmb3JtOiB1cHBlcmNhc2U7XG4gIHdpZHRoOiAxMDAlO1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/shared/ng/base/src/lib/base/button/button.component.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,mBAAA;AACF;;AAEA;EACE,mBAAA;EACA,aAAA;EACA,YAAA;EACA,uBAAA;EACA,eAAA;EACA,eAAA;EACA,oBAAA;EACA,yBAAA;EACA,WAAA;AACF;AACA,wwBAAwwB","sourcesContent":[":host {\n  pointer-events: unset;\n  white-space: nowrap;\n}\n\n.spx-button {\n  align-items: center;\n  display: flex;\n  height: 30px;\n  justify-content: center;\n  min-width: 24px;\n  padding: 0 12px;\n  pointer-events: auto;\n  text-transform: uppercase;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
