export class NumberUtils {
  public static formatValue(value: number, minimumFractionDigits = 2): string {
    if (typeof value !== 'number') {
      return '-';
    } else if (value % 1 !== 0) {
      return value.toLocaleString(undefined, { minimumFractionDigits });
    } else {
      return value.toLocaleString();
    }
  }

  public static firstNumberIsBigger(a: number, b: number): boolean {
    if ([a, b].some((num) => num === undefined || num === null)) {
      return false;
    }
    return a > b;
  }
}
