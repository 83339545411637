import { MatTableDataSourcePaginator } from '@angular/material/table';

export interface ISpxPaginatedDataSource<P extends MatTableDataSourcePaginator = MatTableDataSourcePaginator> {
  getPaginator(): P | null;
  setPaginator(paginator: P | null): void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function instanceOfPaginatedDataSource(object: any): object is ISpxPaginatedDataSource {
  const properties = ['getPaginator', 'setPaginator'];
  return !!object && properties.every((property: string) => property in object);
}
