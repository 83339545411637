import { Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';

export type SpxObservableDataSourceCallbackData<T> = {
  content: T[];
  totalElements: number;
};

export type SpxObservableDataSourceCallback<T> = (
  filter: string | undefined,
  sort: Sort | undefined,
  pagination: Pagination | undefined
) => Observable<SpxObservableDataSourceCallbackData<T>>;

export type Pagination = { size: number; index: number };

export const MAX_SAFE_INTEGER = 9007199254740991;
