import { Device, OverviewDevice } from './device.model';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DeviceUtils {
  public static isOnline(device: Device | OverviewDevice): boolean {
    if (device.state) {
      return device.state.online;
    }

    if (!device.lastActiveNanoSeconds) {
      return false;
    }

    const lastActiveSeconds = device.lastActiveNanoSeconds / 1_000_000_000;
    const currentTime = Date.now() / 1_000;

    // 30 min no data --> offline
    return lastActiveSeconds >= currentTime - 1_800;
  }

  public static isMonitored(device: Device | OverviewDevice): boolean {
    return device.configuration?.['FEATURES']?.includes('MONITORING');
  }

  public static isInProvisioning(device: Device | OverviewDevice): boolean {
    return device?.configuration?.['in_provisioning']?.includes('true');
  }
}
