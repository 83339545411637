import { SecureAction } from '../secure-actions';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DashboardTemplateSecureAction extends SecureAction {
  public static readonly GET: SecureAction = new DashboardTemplateSecureAction('settings:dashboard|view');
  public static readonly EDIT: SecureAction = new DashboardTemplateSecureAction('settings:dashboard|edit');

  private constructor(name: string) {
    super(name);
  }
}
