import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Device } from '@deprecated/api-interfaces';
import { Observable } from 'rxjs';
import { DeviceMetadataModel, FeatureTree, NewDevice, PagedData, RateLimit } from '../models';

/**
 * This sends a request to server
 */
@Injectable()
export class DeviceRepositoryHttp {
  defaultOptions = {
    'Content-Type': 'application/json',
  };

  constructor(
    private http: HttpClient,
    @Inject('ENVIRONMENT') private config: any,
  ) {}

  getDevices(
    page: number,
    pageSize: number,
    query?: string,
    filter?: Array<{ key: string; value: string | boolean }>,
  ): Observable<HttpResponse<PagedData<Device>>> {
    const params = {
      page: String(page),
      size: String(pageSize),
    };

    if (filter && filter.length > 0) {
      filter.forEach((f) => {
        if (f.value) {
          params[f.key] = f.value;
        }
      });
    }

    let formattedQuery = '';

    if (query?.includes('+')) {
      formattedQuery = '&q=' + query.split('+').join('%2B');
    } else if (query) {
      const q = 'q';
      params[q] = query;
    }

    // send get request to APIendPoint
    return this.http.get<PagedData<Device>>(`${this.config.devices.apiEndpoint}?sort=serial_number,asc${formattedQuery}`, {
      observe: 'response',
      headers: this.defaultOptions,
      params,
    });
  }

  getAllDevices(): Observable<any> {
    return this.http.get(`${this.config.devices.apiEndpoint}?pageNumber=0&size=1000&sort=serial_number,asc`, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  getDeviceCountForGroupsIds(groupsIds: string[]): Observable<any> {
    const groups = groupsIds.join(',');
    return this.http.get(this.config.devices.deviceCountForGroupsIds.replace('{groupsIds}', groups), {
      observe: 'body',
      headers: this.defaultOptions,
    });
  }

  /**
   * Get specific device data
   * @param id of device
   */
  getDevice(id: string): Observable<HttpResponse<Device>> {
    const path = this.config.nest.device.detail.getDevice.replace('{id}', id);

    return this.http.get<Device>(path, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  /**
   * Add a new device
   * @param device to add
   */
  setDevice(device: NewDevice): Observable<any> {
    const endPoint: string = this.config.device.post;

    return this.http.post<Device>(endPoint, device, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  /**
   * update device data
   * @param device data
   * @param deviceId id
   */
  updateDevice(device: Device, deviceId: string): Observable<any> {
    const endPoint: string = this.config.device.get.replace('{id}', deviceId);

    return this.http.patch(endPoint, device, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  getGroups(deviceId: string): Observable<any> {
    const path = this.config.device.groups.get.replace('{deviceId}', deviceId);

    return this.http.get(path, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  updateMetadata(metadata: { [key: string]: string }, deviceId: string): Observable<any> {
    const path = this.config.device.metadata.post.replace('{id}', deviceId);

    return this.http.post(path, metadata, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  updateFeatureTree(featureTree: FeatureTree): Observable<HttpResponse<FeatureTree>> {
    const path = this.config.device.features.update.replace('{deviceId}', featureTree.deviceId);

    return this.http.post<FeatureTree>(path, featureTree, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  deleteDevice(deviceId): Observable<any> {
    const path = this.config.device.delete.replace('{deviceId}', deviceId);

    return this.http.delete(path, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  searchDevices(searchString: string): Observable<HttpResponse<any>> {
    const params = {
      q: '',
    };

    const path = this.config.devices.apiEndpoint;

    params.q = searchString;

    return this.http.get(path, {
      observe: 'response',
      headers: this.defaultOptions,
      params,
    });
  }

  assignChildDevice(parentId: string, childId: string): Observable<HttpResponse<any>> {
    const path = this.config.devices.assignChild.replace('{parentId}', parentId).replace('{childId}', childId);

    return this.http.post(path, '', {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  unassignDeviceParent(parentId: string, childId: string): Observable<any> {
    const path = this.config.devices.removeDeviceParent.replace('{parentId}', parentId).replace('{childId}', childId);

    return this.http.post(path, '', {
      headers: this.defaultOptions,
    });
  }

  createReportByTemplateId(deviceId, templateId, backwardsFromNow): Observable<HttpResponse<Blob>> {
    const path = this.config.reports.monitoring.report.byTemplateId
      .replace('{monitoringTemplateId}', templateId)
      .replace('{deviceId}', deviceId)
      .replace('{backwardsFromNow}', backwardsFromNow);

    return this.http.get(path, {
      observe: 'response',
      responseType: 'blob',
      headers: this.defaultOptions,
    });
  }

  createReport(deviceId, body): Observable<HttpResponse<Blob>> {
    const endPoint = this.config.reports.monitoring.report.get;

    return this.http.post(endPoint.replace('{deviceId}', deviceId), body, {
      observe: 'response',
      responseType: 'blob',
      headers: this.defaultOptions,
    });
  }

  /**
   * Get a CSV of device data via email
   * @param deviceId of device
   */
  exportDeviceDataAsCsv(deviceId: string): Observable<HttpResponse<any>> {
    const path = this.config.device.exportAsCSV.replace('{deviceId}', deviceId);

    return this.http.get(path, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  getDeviceRateLimits(deviceId: string): Observable<any> {
    const path = this.config.devices.rateLimits.replace('{deviceId}', deviceId);

    return this.http.get(path, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  rateLimits(pageNumber = 0, pageSize = 500): Observable<PagedData<RateLimit>> {
    const path = this.config.devices.allRateLimits;
    const params = new HttpParams().set('page', pageNumber.toString()).set('size', pageSize.toString());

    return this.http.get<PagedData<RateLimit>>(path, {
      observe: 'body',
      headers: this.defaultOptions,
      params,
    });
  }

  getAllManufacturers(): Observable<any> {
    const path = this.config.manufacturers;

    return this.http.get(path, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }
  getAllProductionSite(): Observable<any> {
    const path = this.config.productionSite;
    return this.http.get(path, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  getAllBrand(): Observable<any> {
    const path = this.config.brand;
    return this.http.get(path, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }
  getFeatures(): Observable<any> {
    const path = this.config.device.features.getAll;

    return this.http.get(path, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  getFeatureTree(deviceId: string): Observable<HttpResponse<FeatureTree>> {
    const path = this.config.device.features.get.replace('{deviceId}', deviceId);

    return this.http.get<FeatureTree>(path, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  getMqttUsers(deviceId: string): Observable<any> {
    const path = this.config.device.getSystemUsers.replace('{deviceId}', deviceId);

    return this.http.get(path, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  getUserDevices(userId: string, page = 0, pageSize = 10): Observable<any> {
    const path = this.config.devices.getByUser.replace('{userId}', userId);

    const params = {
      page: String(page),
      size: String(pageSize),
    };

    return this.http.get(path, {
      observe: 'response',
      headers: this.defaultOptions,
      params,
    });
  }

  setUsers(deviceId: string, userIds: string[]): Observable<HttpResponse<Device>> {
    const path = this.config.device.users.set.replace('{deviceId}', deviceId);

    return this.http.put<Device>(
      path,
      { userIds },
      {
        observe: 'response',
        headers: this.defaultOptions,
      },
    );
  }

  assignUser(deviceId: string, userId: string): Observable<any> {
    const path = this.config.device.users.assign.replace('{deviceId}', deviceId).replace('{userId}', userId);

    return this.http.put(path, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  removeUser(deviceId: string, userId: string): Observable<any> {
    const path = this.config.device.users.delete.replace('{deviceId}', deviceId).replace('{userId}', userId);

    return this.http.delete(path, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  setGroups(deviceId: string, groupIds: string[]): Observable<HttpResponse<Device>> {
    const path = this.config.device.groups.set.replace('{deviceId}', deviceId);

    return this.http.put<Device>(
      path,
      { groupIds },
      {
        observe: 'response',
        headers: this.defaultOptions,
      },
    );
  }

  completeProvision(deviceId: string): Observable<any> {
    const path = this.config.device.completeProvision.replace('{deviceId}', deviceId);

    return this.http.post(path, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  inProvision(deviceId: string): Observable<any> {
    const path = this.config.device.inProvision.replace('{deviceId}', deviceId);

    return this.http.post(path, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  getSimHistory(iccid: string): Observable<any> {
    const path = this.config.device.sim.simHistory.replace('{iccid}', iccid);

    return this.http.get(path, {
      observe: 'body',
      headers: this.defaultOptions,
    });
  }

  getSimDetails(iccid: string): Observable<any> {
    const path = this.config.device.sim.simDetails.replace('{iccid}', iccid);

    return this.http.get(path, {
      observe: 'body',
      headers: this.defaultOptions,
    });
  }

  getDeviceMetadata(deviceId: string): Observable<DeviceMetadataModel> {
    const path = this.config.device.metadata.get.replace('{deviceId}', deviceId);

    return this.http.get<DeviceMetadataModel>(path, {
      headers: this.defaultOptions,
    });
  }

  getDevicesByIdList(ids: Array<string>): Observable<Array<Device>> {
    const path = this.config.nest.device.list.getDevicesByIdList;

    return this.http.get<Array<Device>>(path, {
      params: { ids },
    });
  }
}
