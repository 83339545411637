import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DialogService } from '@ird/ng-base';

import { Subscription } from 'rxjs';
import { ChosenImage } from '../../models/chosen-image.model';
import { Shape } from '../../models/placeholder-shape.enum';
import { FileUploaderComponent } from '../file-uploader/file-uploader.component';

@Component({
  selector: 'shared-icon-picker',
  templateUrl: './icon-picker.component.html',
  styleUrls: ['./icon-picker.component.scss'],
})
export class IconPickerComponent implements OnChanges {
  Shape = Shape;

  @Input() imageProcessSubscription: Subscription;
  @Input() iconUrl?: string;
  @Input() greyedOut = true;
  @Input() hasImage = false;
  @Input() acceptTypes: Array<string>;

  @Output() selectImage: EventEmitter<ChosenImage> = new EventEmitter<ChosenImage>();
  @Output() delete: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('svgImage') svgImage: ElementRef;

  public icon: File = undefined;
  public imageName: string = undefined;

  constructor(private dialogService: DialogService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.picture?.currentValue !== changes.picture?.previousValue && this.icon) {
      this.convertToBlob(this.icon);
    }
  }

  public selectFile(): void {
    this.dialogService
      .openDialogWithComponent(FileUploaderComponent, 'IMAGE.TITLE', '', {
        data: {
          dialogData: {
            accept: this.acceptTypes ?? ['.svg'],
            maxSize: Math.pow(10, 7) * 2,
          },
        },
      })
      .subscribe((response: File) => {
        if (response) {
          this.icon = response;
          this.convertToBlob(response);
        }
      });
  }

  public deleteImage(): void {
    this.icon = null;
    this.iconUrl = null;
    this.imageName = null;
    this.delete.emit();
  }

  private convertToBlob(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    // eslint-disable-next-line @typescript-eslint/no-shadow
    reader.onload = (onLoadEvent) => {
      this.selectImage.emit({ image: file, name: file.name });

      if (file.type.includes('svg')) {
        const image = new Image();
        image.title = file.name;
        image.style.width = '100%';
        image.style.height = '100%';
        this.imageName = file.name;
        if (typeof onLoadEvent.target.result === 'string') {
          image.src = onLoadEvent.target.result;
        }
        this.svgImage.nativeElement.childNodes.forEach((node) => {
          this.svgImage.nativeElement.removeChild(node);
        });
        this.svgImage.nativeElement.appendChild(image);
      }
    };
  }

  getFilename() {
    if (this.imageName) {
      return this.imageName;
    } else if (this.iconUrl) {
      return '';
    } else {
      return 'IMAGE.NO_FILE';
    }
  }
}
