import { SelectionChange } from '@angular/cdk/collections';
import { Directive, HostBinding, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpxSelectionModel } from '../../../collections/selection-model';
import { TableComponent } from '../../components/table/table.component';
import { SpxRowDirective } from '../row/row.directive';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'spx-table[selection]',
})
export class SelectionDirective<T> implements OnInit, OnDestroy {
  @HostBinding('class.spx-selectable') selectable = true;
  @HostBinding('class.spx-single-selection') get singleSelection() {
    return !this.selection.isMultipleSelection;
  }
  @HostBinding('class.spx-multi-selection') get multiSelection() {
    return this.selection.isMultipleSelection;
  }

  @Input() set selection(selection: SpxSelectionModel<T>) {
    this._selection = selection;
  }

  get selection(): SpxSelectionModel<T> {
    return this._selection;
  }

  private _selection!: SpxSelectionModel<T>;
  private _rowClickedSubscription?: Subscription;
  private _changedSubscription?: Subscription;

  constructor(
    private table: TableComponent<T>,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.table.selectionDirective = this;

    if (this.selection.isMultipleSelection()) {
      this.table.displayedColumns.unshift('spxSelectionCheckbox');
    }

    this._rowClickedSubscription = this.table.rowClicked.subscribe((row: T) => {
      if (!this.table.isExpandable) {
        this._selection.toggle(row);
      }
    });

    if (!this.selection.compareWith) {
      this.selection.compareWith = (a, b) => {
        //Male sure to use different indices to not match by index.
        const aTrack = this.table.trackBy(0, a);
        const bTrack = this.table.trackBy(1, b);
        return aTrack === bTrack || a === b;
      };
    }

    this._changedSubscription = this._selection.changed.subscribe((selectionChange: SelectionChange<T>) => {
      const spxRows = this.table.rowDefs.toArray();
      this.addSelected(selectionChange.added, spxRows);
      this.removeSelected(selectionChange.removed, spxRows);
    });
  }

  ngOnDestroy(): void {
    this._rowClickedSubscription?.unsubscribe();
    this._changedSubscription?.unsubscribe();
  }

  public hasValue(): boolean {
    return this.selection.hasValue();
  }

  public hasMultipleValues(): boolean {
    return this.selection.hasMultipleValues();
  }

  public isAllSelected(): boolean {
    return this.selection.selected.length === this.table.dataSource.data.length;
  }

  public toggleAll(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.selection.select(...this.table.dataSource.data);
    }
  }

  private addSelected(added: T[], spxRows: SpxRowDirective<T>[]): void {
    added.forEach((addedRow) => {
      const row = spxRows.find((dir) => dir.spxRow === addedRow);
      if (row) {
        this.renderer.addClass(row.elementRef.nativeElement, 'spx-selected-row');
      }
    });
  }

  private removeSelected(removed: T[], spxRows: SpxRowDirective<T>[]): void {
    removed.forEach((removedRow) => {
      const row = spxRows.find((dir) => dir.spxRow === removedRow);
      if (row) {
        this.renderer.removeClass(row.elementRef.nativeElement, 'spx-selected-row');
      }
    });
  }
}
