import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class FileRepositoryUi {
  constructor(private http: HttpClient) {}

  getTenantImage(fileId: string): string {
    return environment.tenant.tenantLogo.replace('{fileId}', fileId);
  }

  getTenantImageData(fileId: string): Observable<HttpResponse<Blob>> {
    const url = environment.tenant.tenantImageData.replace('{fileId}', fileId);

    return this.http.get<Blob>(url, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }
}
