import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private llt: string;

  public getLlt(): string | null {
    if (!this.llt) {
      this.llt = localStorage.getItem('llt');
    }
    return this.llt;
  }

  public setLlt(v: string) {
    this.llt = v;
    localStorage.setItem('llt', v);
  }
}
