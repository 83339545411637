import { SecureAction } from '../secure-actions';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DataConversionSecureAction extends SecureAction {
  public static readonly VIEW: SecureAction = new DataConversionSecureAction('admin:data_conversion|view');
  private constructor(name: string) {
    super(name);
  }
}
