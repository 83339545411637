/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { Moment } from 'moment';

const MINUTES = 60;
const HOURS = 24;

class UnitSelect {
  option = '';
  limits: Array<number> = [];
}

class UnitSelectRange {
  start: UnitSelect = new UnitSelect();
  end: UnitSelect = new UnitSelect();
}

@Component({
  selector: 'spx-time-value-picker',
  templateUrl: './time-value-picker.component.html',
  styleUrls: ['./time-value-picker.component.scss'],
})
export class TimeValuePickerComponent implements OnChanges {
  @Input() max?: Moment | null;
  @Input() min?: Moment | null;
  @Input() startDate?: Moment;
  @Input() endDate?: Moment;

  @Output() dateChange: EventEmitter<DateRange<Moment>> = new EventEmitter<DateRange<Moment>>();

  public hour: UnitSelectRange = new UnitSelectRange();
  public minute: UnitSelectRange = new UnitSelectRange();
  public date!: DateRange<Moment>;
  public hours = [...Array(24)].map((value, index) => (index < 10 ? '0' + index : index.toString()));
  public minutes = [...Array(60)].map((value, index) => (index < 10 ? '0' + index : index.toString()));

  private static getLimits(
    type: 'hours' | 'minutes',
    limitDate: Moment | null | undefined,
    limitedDate: Moment | null | undefined,
    limit: number
  ): number {
    const additionalConditions = type === 'minutes' ? limitedDate?.isSame(limitDate, 'hour') : true;
    return limitDate && limitedDate?.isSame(limitDate, 'day') && additionalConditions
      ? TimeValuePickerComponent.getLimitUnit(type, limitDate)
      : limit;
  }

  private static getLimitUnit(type: 'hours' | 'minutes', date: Moment): number {
    return type === 'hours' ? date.hours() : date.minutes();
  }

  ngOnChanges() {
    this.date = new DateRange(this.startDate || null, this.endDate || null);

    ['start', 'end'].forEach((period) => {
      if (this.date?.start && this.date?.end) {
        const hours = this.date[period as keyof DateRange<Moment>]!.hours();
        const minutes = this.date[period as keyof DateRange<Moment>]!.minutes();

        this.hour[period as keyof DateRange<Moment>].option = hours >= 10 ? hours.toString() : `0${hours.toString()}`;
        this.minute[period as keyof DateRange<Moment>].option = minutes >= 10 ? minutes.toString() : `0${minutes.toString()}`;

        this.checkLimits();
        this.checkOverlappingTime('end');
      }
    });
  }

  public changeHour(hour: string, period: keyof DateRange<Moment>): void {
    this.date[period]!.set('hour', Number(hour));
    this.hour[period].option = hour;
    this.checkOverlappingTime(period);
    this.dateChange.emit(this.date);
  }

  public changeMinute(minute: string, period: keyof DateRange<Moment>): void {
    this.date[period]!.set('minute', Number(minute));
    this.minute[period].option = minute;
    this.dateChange.emit(this.date);
  }

  private checkLimits(): void {
    const min =
      this.date.start && this.min && this.date.start && this.date.start < this.min && this.date.start.isSame(this.min)
        ? this.min
        : this.date.start;
    const max = this.date.end && this.max && this.date.end > this.max && this.date.end.isSame(this.max) ? this.max : this.date.end;

    this.hour.start.limits = [
      TimeValuePickerComponent.getLimits('hours', this.min, this.date.start, 0),
      TimeValuePickerComponent.getLimits('hours', max, this.date.start, HOURS),
    ];
    this.hour.end.limits = [
      TimeValuePickerComponent.getLimits('hours', this.date.start, this.date.end, 0),
      TimeValuePickerComponent.getLimits('hours', this.max, this.date.end, HOURS),
    ];
    this.minute.start.limits = [
      TimeValuePickerComponent.getLimits('minutes', null, this.date.start, 0),
      TimeValuePickerComponent.getLimits('minutes', max, this.date.start, MINUTES),
    ];
    this.minute.end.limits = [
      TimeValuePickerComponent.getLimits('minutes', min, this.date.end, 0),
      TimeValuePickerComponent.getLimits('minutes', this.max, this.date.end, MINUTES),
    ];
  }

  private checkOverlappingTime(period: keyof DateRange<Moment>): void {
    if (this.date.start && this.date.end?.isSame(this.date.start, 'hour') && this.date.end?.minutes() < this.date.start?.minutes()) {
      this.date[period]!.set('minute', this.date[period === 'start' ? 'end' : 'start']!.minutes());
    }
  }
}
