import { ValueType } from '../data-conversion';
import { ThresholdDTO } from '../shared';
import { DynamicLimitsModel } from '../threshold';
import { SuperSetParam, SuperSetWidgetType, WidgetSourceType } from '../widget';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DashboardSourceModel {
  dynamicThresholds?: DynamicLimitsModel;
  lastActiveNanos?: number;
  max?: number;
  min?: number;
  name: string;
  metadata?: Record<string, string>;
  sourceId?: string;
  sourceName?: string;
  sourceType: WidgetSourceType;
  sourceVersion?: string;
  superSetParams?: Array<SuperSetParam>;
  superSetWidgetType?: SuperSetWidgetType;
  timerange?: number;
  type?: string;
  thresholds?: Array<ThresholdDTO>;
  unit?: string;
  value?: number;
  valueType?: ValueType;
  selected?: boolean;
}
