import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatRippleModule } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@ird/ng-core';
import { TranslateModule } from '@ngx-translate/core';
import { BaseModule } from '../base';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { MenuComponent } from './components/menu/menu.component';

@NgModule({
  declarations: [MenuComponent, MenuItemComponent],
  imports: [CommonModule, BaseModule, MatListModule, MatRippleModule, FlexLayoutModule, TranslateModule, RouterModule, CoreModule],
  exports: [MenuComponent, MenuItemComponent],
})
export class MenuModule {}
