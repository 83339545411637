import { InjectionToken } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { DialogAction, DialogConfirmActionResult, DIALOG_ACTION_CONFIRM_RESULT, DIALOG_ACTION_REJECT_RESULT } from '../model';
import { BaseDialogService } from '../services/base-dialog.service';

/**
 * Confirm Actions Injection Token
 */
export const CONFIRM_ACTIONS = new InjectionToken<DialogAction[]>('confirm-dialog-actions');

/**
 * Danger Confirm Actions Injection Token
 */
export const DANGER_CONFIRM_ACTIONS = new InjectionToken<DialogAction[]>('danger_confirm-dialog-actions');

/**
 * Factory for confirm dialog actions
 * @param dialogService Dialog Service
 * @returns Dialog Actions Array
 */
export const confirmDialogActionFactory = (baseDialogService: BaseDialogService, dialogConfig: MatDialogConfig): DialogAction[]  => {

  return [
    {
      text: dialogConfig.data.rejectTranslateKey,
      type: 'mat-stroked-button',
      color: 'primary',
      action: () => {
        baseDialogService.close<DialogConfirmActionResult>(DIALOG_ACTION_REJECT_RESULT);
        return Subscription.EMPTY;
      }
    },
    {
      text: dialogConfig.data.confirmTranslateKey,
      type: 'mat-raised-button',
      color: 'primary',
      action: () => {
        baseDialogService.close<DialogConfirmActionResult>(DIALOG_ACTION_CONFIRM_RESULT);
        return Subscription.EMPTY;
      }
    }
  ];

}

/**
 * Factory for danger confirm dialog actions
 * @param dialogService Dialog Service
 * @returns Dialog Actions Array
 */
export const dangerConfirmDialogActionFactory = (baseDialogService: BaseDialogService, dialogConfig: MatDialogConfig): DialogAction[]  => {

  return [
    {
      text: dialogConfig.data.rejectTranslateKey,
      type: 'mat-stroked-button',
      color: 'primary',
      action: () => {
        baseDialogService.close<DialogConfirmActionResult>(DIALOG_ACTION_REJECT_RESULT);
        return Subscription.EMPTY;
      }
    },
    {
      text: dialogConfig.data.confirmTranslateKey,
      type: 'mat-raised-button',
      color: 'warn',
      action: () => {
        baseDialogService.close<DialogConfirmActionResult>(DIALOG_ACTION_CONFIRM_RESULT);
        return Subscription.EMPTY;
      }
    }
  ];

}

/**
 * Confirm Actions Injection Token
 */
export const DEFAULT_ACTIONS = new InjectionToken<DialogAction[]>('confirm-dialog-actions');

/**
 * Factory for default dialog actions
 * @returns Dialog Actions Array
 */
export const defaultDialogActionFactory = (): DialogAction[] => {
  return [];
};
