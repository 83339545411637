import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BaseModule } from '../base';
import { ChartDirective } from './directives/chart.directive';
import * as enTranslations from './i18n/en-lang.json';

@NgModule({
  declarations: [ChartDirective],
  imports: [CommonModule, TranslateModule.forChild(), BaseModule],
  exports: [ChartDirective],
})
export class ChartModule {
  constructor(private translate: TranslateService) {
    this.translate.setTranslation('en', enTranslations, true);
  }
}
