import { ApiProperty } from '../../decorators/decorators';
import { DataConversionUserSetting } from '../data-conversion/data-conversion-user-setting';
/**
 * These classes are just a start of saving nummerous UserSettings.
 * The full List of planned Settings can be found here:
 *
 * https://seepex.atlassian.net/browse/SCS-2009
 */

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DeviceView {
  @ApiProperty()
  items_per_page?: number;

  @ApiProperty({
    type: String,
  })
  favorit: 'card' | 'list' | 'map' = 'card';

  @ApiProperty({
    type: String,
  })
  drawerMode: 'push' | 'side' = 'push';
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DevicePreferences {
  @ApiProperty()
  view: DeviceView = new DeviceView();
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class MonitoringPreferences {
  @ApiProperty()
  aggregationType: 'MIN' | 'AVG' | 'MAX' | 'BAND' = 'AVG';
  showAlerts = true;
}
/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class UserSettings {
  devices: DevicePreferences = new DevicePreferences();
  monitoring?: MonitoringPreferences = new MonitoringPreferences();
  locale: string;
  timeZone: string;
  dataConversions?: DataConversionUserSetting[];
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class UserSettingsUUID {
  userId: string;
  settings: string;
}
