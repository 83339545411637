import { SecureAction } from '../secure-actions';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class PermissionGroupSecureAction extends SecureAction {
  public static readonly ASSIGN_ACTIONS: SecureAction = new PermissionGroupSecureAction('admin:permission_groups|assign_actions');
  public static readonly DELETE: SecureAction = new PermissionGroupSecureAction('admin:permission_groups|delete');
  public static readonly SAVE: SecureAction = new PermissionGroupSecureAction('admin:permission_groups|save');
  public static readonly VIEW: SecureAction = new PermissionGroupSecureAction('admin:permission_groups|view');

  private constructor(name: string) {
    super(name);
  }
}
