import { Formula } from '../formula';
import { BaseAlarm } from './base-alarm.model';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class ActiveAlarm extends BaseAlarm {
  lastOccurrence!: number;
  deviceCommNr!: string;
  deviceId!: string;
  nanoSinceEpoch!: number;
  comment!: string;
  index!: number;
  derivedValueConfiguration?: Formula;
  autoack?: boolean;
  heartBeat?: boolean;
  alarmConfigurationId?: string;
}
