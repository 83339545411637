import { NgModule } from '@angular/core';
import { IrdDatePipe, IsExternalUrlPipe, NullishPipe } from './pipes';
import { IMAGE_ORIGIN } from './providers/image-origin';
import { WINDOW, windowFactory } from './providers/window';
import { DragDataService } from './services/drag-data/drag-data.service';

const PIPES = [IsExternalUrlPipe, NullishPipe, IrdDatePipe];

@NgModule({
  imports: [],
  declarations: [...PIPES],
  exports: [...PIPES],
  providers: [
    ...PIPES,
    DragDataService,
    {
      provide: WINDOW,
      useFactory: windowFactory,
    },
    {
      provide: IMAGE_ORIGIN,
      useValue: null,
    },
  ],
})
export class CoreModule {}
