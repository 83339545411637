import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';

class DateFilterModel {
  from!: number | null;
  to!: number | null;
}

@Component({
  selector: 'spx-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
})
export class DateFilterComponent implements OnInit {
  @Output()
  refreshDataNotify = new EventEmitter<DateFilterModel>();
  public filter: DateFilterModel = { from: null, to: null };

  @Input() type!: 'from' | 'to' | 'both';
  @Input() startDatePlaceholder!: string;
  @Input() endDatePlaceholder!: string;
  @Input() startDate!: number;
  @Input() endDate!: number;

  ngOnInit(): void {
    if (this.endDate) {
      this.filter.to = this.endDate;
    }
    if (this.startDate) {
      this.filter.from = this.startDate;
    }
  }

  refreshData(): void {
    this.refreshDataNotify.emit(this.filter);
  }

  fromNanos(date: number | null): string {
    if (date) {
      return moment(new Date(date / 1_000_000)).format();
    }

    return '';
  }

  clearFilter(filterType: 'from' | 'to'): void {
    if (filterType === 'from') {
      this.filter.from = null;
    } else if (filterType === 'to') {
      this.filter.to = null;
    }
    this.refreshData();
  }

  getMaxDate(): moment.Moment {
    return moment();
  }

  getMaxStartDate(): moment.Moment {
    return this.filter.to ? moment(this.filter.to / 1000000) : moment();
  }

  changeDateFrom(m: moment.Moment): void {
    this.filter.from = m.toDate().getTime() * 1000 * 1000;
  }

  changeDateTo(m: moment.Moment): void {
    this.filter.to = m.toDate().getTime() * 1000 * 1000;
  }
}
