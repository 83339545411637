import { KpiThreshold } from '../../shared';
import { KpiScript } from './kpi-script.model';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class Kpi {
  active: boolean;
  deviceId: string;
  kpiName: string;
  kpiScript: KpiScript;
  kpiVersion: string;
  lastActiveNanos: number;
  max: number;
  min: number;
  thresholds: string;
  thresholdsV2: KpiThreshold[];
  timeRange: string;
}
