import { SecureAction } from '../secure-actions';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class BrandManagementSecureAction extends SecureAction {
  public static readonly NAVIGATE: SecureAction = new BrandManagementSecureAction('admin:brand_management');

  //manufacturer
  public static readonly EDIT_ADMIN_BRAND_MANAGEMENT_MANUFACTURER: SecureAction = new BrandManagementSecureAction(
    'admin:brand_management:manufacturers|edit',
  );
  public static readonly DUPLICATE_ADMIN_BRAND_MANAGEMENT_MANUFACTURER: SecureAction = new BrandManagementSecureAction(
    'admin:brand_management:manufacturers|duplicate',
  );
  public static readonly CREATE_ADMIN_BRAND_MANAGEMENT_MANUFACTURER: SecureAction = new BrandManagementSecureAction(
    'admin:brand_management:manufacturers|create',
  );
  public static readonly DELETE_ADMIN_BRAND_MANAGEMENT_MANUFACTURER: SecureAction = new BrandManagementSecureAction(
    'admin:brand_management:manufacturers|delete',
  );

  //brand
  public static readonly CREATE_ADMIN_BRAND_MANAGEMENT_BRAND: SecureAction = new BrandManagementSecureAction(
    'admin:brand_management:brands|create',
  );
  public static readonly EDIT_ADMIN_BRAND_MANAGEMENT_BRAND: SecureAction = new BrandManagementSecureAction(
    'admin:brand_management:brands|edit',
  );
  public static readonly DUPLICATE_ADMIN_BRAND_MANAGEMENT_BRAND: SecureAction = new BrandManagementSecureAction(
    'admin:brand_management:brands|duplicate',
  );
  public static readonly DELETE_ADMIN_BRAND_MANAGEMENT_BRAND: SecureAction = new BrandManagementSecureAction(
    'admin:brand_management:brands|delete',
  );

  //production sites
  public static readonly CREATE_ADMIN_BRAND_MANAGEMENT_PRODUCTION_SITES: SecureAction = new BrandManagementSecureAction(
    'admin:brand_management:productionsite|create',
  );
  public static readonly EDIT_ADMIN_BRAND_MANAGEMENT_PRODUCTION_SITES: SecureAction = new BrandManagementSecureAction(
    'admin:brand_management:productionsite|edit',
  );
  public static readonly DUPLICATE_ADMIN_BRAND_MANAGEMENT_PRODUCTION_SITES: SecureAction = new BrandManagementSecureAction(
    'admin:brand_management:productionsite|duplicate',
  );
  public static readonly DELETE_ADMIN_BRAND_MANAGEMENT_PRODUCTION_SITES: SecureAction = new BrandManagementSecureAction(
    'admin:brand_management:productionsite|delete',
  );

  private constructor(name: string) {
    super(name);
  }
}
