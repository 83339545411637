import { SourceType } from '../../shared';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface AlertConfigurationSourceDto {
  available?: boolean;
  name: string;
  id?: string;
  remarks?: string;
  sourceType?: SourceType;
  type?: string;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class AlertConfigurationSourcesDto {
  formulas: Array<AlertConfigurationSourceDto> = [];
  intelligent: Array<AlertConfigurationSourceDto> = [];
  sensors: Array<AlertConfigurationSourceDto> = [];
  sensorTypes: Array<AlertConfigurationSourceDto> = [];
}
