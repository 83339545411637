/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class ProductionSitesNameAndAddressUpdate {
  id: string;
  name: string;
  description?: string;
  street?: string;
  zip?: string;
  city?: string;
  state?: string;
  countryId?: string;
}
