import { OpcuaServerDeploymentStatusEnum } from './opcua-server-deployment-status.enum';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class OpcuaServerModel {
  id: string;
  name: string;
  deploymentStatus: OpcuaServerDeploymentStatusEnum;
  devices: string[];
  port: number;
  internalIpAddress: string;
  description: string;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class OpcuaDeviceInfoModel {
  id: string;
  name: string;
}
