import { SecureAction } from '../../secure-actions';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class AnalyticsDashboardSecureAction extends SecureAction {
  public static readonly CHANGE_DETECTION_STATUS: SecureAction = new AnalyticsDashboardSecureAction(
    'device:analytics:dashboard|change_status_anomaly_detection',
  );
  public static readonly UNINSTALL_DETECTION: SecureAction = new AnalyticsDashboardSecureAction(
    'device:analytics:dashboard|uninstall_anomaly_detection',
  );
  public static readonly VIEW: SecureAction = new AnalyticsDashboardSecureAction('device:analytics:dashboard|view');

  private constructor(name: string) {
    super(name);
  }
}
