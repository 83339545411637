import { Injectable } from '@angular/core';
import { IrdClassMetadata, IrdProperty } from '@ird/shared-base';

@Injectable()
export class GenericAdminService<Dto> {
  extractVisibleProperties(metadata: IrdClassMetadata<Dto>): Array<IrdProperty<unknown, unknown>> {
    return Object.values(metadata.properties).filter((item: IrdProperty<any, any>) => item.showInUi) as Array<
      IrdProperty<unknown, unknown>
    >;
  }

  getPropertyName(property: IrdProperty<any, any>): string {
    return property.name?.toString();
  }

  formatJson(input: any): string {
    if (input) {
      return JSON.stringify(input);
    } else {
      return '';
    }
  }
}
