// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host-context(.no-padding) .content {
  padding: 0;
}

.content {
  overflow: hidden;
}

.header {
  display: flex;
  width: 100%;
}

.titlebar {
  align-items: baseline;
}

.backbutton {
  margin: 1rem 0;
  width: 24px;
  cursor: pointer;
}
.backbutton spx-icon {
  width: 24px;
  height: 24px;
}

.page-title {
  padding: 16px;
}

.back-icon {
  padding-left: 16px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxheW91dC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRTtFQUNFLFVBQUE7QUFBSjs7QUFJQTtFQUNFLGdCQUFBO0FBREY7O0FBSUE7RUFDRSxhQUFBO0VBQ0EsV0FBQTtBQURGOztBQUlBO0VBQ0UscUJBQUE7QUFERjs7QUFJQTtFQUNFLGNBQUE7RUFDQSxXQUFBO0VBQ0EsZUFBQTtBQURGO0FBR0U7RUFDRSxXQUFBO0VBQ0EsWUFBQTtBQURKOztBQUtBO0VBQ0UsYUFBQTtBQUZGOztBQUtBO0VBQ0Usa0JBQUE7QUFGRiIsImZpbGUiOiJsYXlvdXQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdC1jb250ZXh0KC5uby1wYWRkaW5nKSB7XG4gIC5jb250ZW50IHtcbiAgICBwYWRkaW5nOiAwO1xuICB9XG59XG5cbi5jb250ZW50IHtcbiAgb3ZlcmZsb3c6IGhpZGRlbjtcbn1cblxuLmhlYWRlciB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIHdpZHRoOiAxMDAlO1xufVxuXG4udGl0bGViYXIge1xuICBhbGlnbi1pdGVtczogYmFzZWxpbmU7XG59XG5cbi5iYWNrYnV0dG9uIHtcbiAgbWFyZ2luOiAxcmVtIDA7XG4gIHdpZHRoOiAyNHB4O1xuICBjdXJzb3I6IHBvaW50ZXI7XG5cbiAgc3B4LWljb24ge1xuICAgIHdpZHRoOiAyNHB4O1xuICAgIGhlaWdodDogMjRweDtcbiAgfVxufVxuXG4ucGFnZS10aXRsZSB7XG4gIHBhZGRpbmc6IDE2cHg7XG59XG5cbi5iYWNrLWljb24ge1xuICBwYWRkaW5nLWxlZnQ6IDE2cHg7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./apps/scs-service-point/src/app/layout/components/layout/layout.component.scss"],"names":[],"mappings":"AACE;EACE,UAAA;AAAJ;;AAIA;EACE,gBAAA;AADF;;AAIA;EACE,aAAA;EACA,WAAA;AADF;;AAIA;EACE,qBAAA;AADF;;AAIA;EACE,cAAA;EACA,WAAA;EACA,eAAA;AADF;AAGE;EACE,WAAA;EACA,YAAA;AADJ;;AAKA;EACE,aAAA;AAFF;;AAKA;EACE,kBAAA;AAFF;AACA,4hCAA4hC","sourcesContent":[":host-context(.no-padding) {\n  .content {\n    padding: 0;\n  }\n}\n\n.content {\n  overflow: hidden;\n}\n\n.header {\n  display: flex;\n  width: 100%;\n}\n\n.titlebar {\n  align-items: baseline;\n}\n\n.backbutton {\n  margin: 1rem 0;\n  width: 24px;\n  cursor: pointer;\n\n  spx-icon {\n    width: 24px;\n    height: 24px;\n  }\n}\n\n.page-title {\n  padding: 16px;\n}\n\n.back-icon {\n  padding-left: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
