// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topbar {
  color: var(--app-color-contrast);
  background-color: var(--app-color);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRvcGJhci5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGdDQUFBO0VBQ0Esa0NBQUE7QUFDRiIsImZpbGUiOiJ0b3BiYXIuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIudG9wYmFyIHtcbiAgY29sb3I6IHZhcigtLWFwcC1jb2xvci1jb250cmFzdCk7XG4gIGJhY2tncm91bmQtY29sb3I6IHZhcigtLWFwcC1jb2xvcik7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/shared/ng/base/src/lib/sidenav/components/topbar/topbar.component.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,kCAAA;AACF;AACA,gYAAgY","sourcesContent":[".topbar {\n  color: var(--app-color-contrast);\n  background-color: var(--app-color);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
