// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.permission-mode-description {
  color: var(--app-white);
  position: absolute;
  text-align: center;
  top: 6px;
  width: 100%;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInBlcm1pc3Npb24tb3ZlcmxheS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLHVCQUFBO0VBQ0Esa0JBQUE7RUFDQSxrQkFBQTtFQUNBLFFBQUE7RUFDQSxXQUFBO0FBQ0YiLCJmaWxlIjoicGVybWlzc2lvbi1vdmVybGF5LmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnBlcm1pc3Npb24tbW9kZS1kZXNjcmlwdGlvbiB7XG4gIGNvbG9yOiB2YXIoLS1hcHAtd2hpdGUpO1xuICBwb3NpdGlvbjogYWJzb2x1dGU7XG4gIHRleHQtYWxpZ246IGNlbnRlcjtcbiAgdG9wOiA2cHg7XG4gIHdpZHRoOiAxMDAlO1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/shared/ng/common/src/lib/components/permission-overlay/permission-overlay.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,kBAAA;EACA,kBAAA;EACA,QAAA;EACA,WAAA;AACF;AACA,wgBAAwgB","sourcesContent":[".permission-mode-description {\n  color: var(--app-white);\n  position: absolute;\n  text-align: center;\n  top: 6px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
