import { TenantSettings } from '../tenant';
import { FeUser } from '../user';
import { Authentication } from './authentication.model';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class LoginPayload {
  username: string;
  password: string;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class Login {
  feUser: FeUser;
  authentication: Authentication;
  settings: {
    userId: string;
    settings: string;
  };
  tenantSettings: TenantSettings | undefined;
  actions: string[];
}
