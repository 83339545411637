/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class AvailableIntelligentAlert {
  applicable: boolean;
  description: string;
  id: string;
  name: string;
  remarks: string;
  requiredSensorTypes: string[];
}
