import moment from 'moment';
import { DocumentCardModel, DocumentCommon, DocumentFileType, File } from '../models';
import MimeResolver from './mime-resolver';

export class FileUtils {
  public static downloadFile(name: string, blob: Blob) {
    if ((window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveOrOpenBlob(blob, name);
    } else {
      const blobURL = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      document.body.appendChild(link);

      link.download = name;
      link.href = blobURL;
      link.click();
    }
  }

  public static readonly mapDocumentCommonFile = (file: File): DocumentCommon => {
    const extension = file.displayName.split('.').pop();
    const document: DocumentCommon = {
      id: file.id,
      displayName: file.displayName,
      details: '',
      name: file.displayName,
      extension,
      icon: '',
      date: moment(file.creationDate).format('L LT'),
      parent: file,
      downloadName: file.displayName.includes('.' + extension) ? file.displayName : file.displayName + '.' + extension,
      description: file.description,
      isReport: file.type === 'MAINTENANCE_REPORT',
      type: file.type,
      size: Math.round(file.size / 1000000),
      editable: file.editable,
      malwareCheckResult: file.malwareCheckResult,
    };
    document.details = FileUtils.getDocumentDetailsByMime(file.mime);
    document.icon = FileUtils.getDocumentCardIconNameByMime(file.mime);
    return document;
  };

  static getDocumentCardIconNameByType(document: DocumentCardModel): string {
    const documentType = document.type ?? '';

    if (documentType === DocumentFileType.INTERNAL) {
      return 'icon-document-internal';
    }

    return FileUtils.getDocumentIconNameByMime(document.extension);
  }

  static getDocumentCardIconNameByMime(mimeType: string): string {
    if (!mimeType) {
      return 'icon-document-other';
    } else if (mimeType.includes('image')) {
      return 'icon-document-image';
    } else if (FileUtils.getVideoMimeTypes().some((v) => mimeType.includes('video/' + v))) {
      return 'icon-document-video';
    } else if (mimeType.includes('pdf')) {
      return 'icon-document-pdf';
    }
    return 'icon-document-other';
  }

  static getDocumentIconNameByType(document: DocumentCommon): string {
    const documentType = document.type ?? '';

    if (documentType.includes('manual')) {
      return 'manual';
    }

    if (documentType.includes('drawing')) {
      return 'drawing';
    }

    if (documentType.includes('datasheet')) {
      return 'datasheet';
    }

    return FileUtils.getDocumentIconNameByMime(document.extension);
  }

  static getDocumentIconNameByMime(mimeType: string): string {
    if (!mimeType) {
      return 'other';
    } else if (mimeType.includes('image')) {
      return 'image';
    } else if (mimeType.includes('pdf')) {
      return 'pdf';
    }
    return 'other';
  }

  static getDocumentCardIconNameByExtension(documentExt: string): string {
    const mimeType = MimeResolver.getType(documentExt);

    if (!mimeType) {
      return 'icon-document-other';
    } else if (mimeType.includes('image')) {
      return 'icon-document-image';
    } else if (FileUtils.getVideoMimeTypes().some((v) => mimeType.includes('video/' + v))) {
      return 'icon-document-video';
    } else if (mimeType.includes('pdf')) {
      return 'icon-document-pdf';
    }
    return 'icon-document-other';
  }

  static getDocumentDetailsByMime(mimeType: string): string {
    if (!mimeType) {
      return 'DOCUMENTS.DETAILS.OTHER';
    } else if (mimeType.includes('image')) {
      return 'DOCUMENTS.DETAILS.IMAGE';
    } else if (mimeType.includes('video')) {
      return 'DOCUMENTS.DETAILS.VIDEO';
    } else if (mimeType.includes('pdf')) {
      return 'DOCUMENTS.DETAILS.PDF';
    }

    return 'DOCUMENTS.DETAILS.OTHER';
  }

  static getVideoMimeTypes(): string[] {
    return ['mp4', 'm4a', 'm4p', 'm4b', 'm4r', 'm3u', 'm3u8', '3gp', 'm1v', 'ogg', 'mov', 'qt', 'wmv', 'avi', 'asf', 'wma'];
  }
}
