import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DeviceModelService } from '../../services/device-model.service';

@Component({
  selector: 'shared-metadata',
  templateUrl: './metadata.component.html',
  styleUrls: ['./metadata.component.scss'],
})
export class MetadataComponent {
  @Input() isNewEntryOpen = false;
  @Input() metadataOptions = [];
  @Input() checkIfItemIsRemovable: (key: string) => boolean;
  @Input() checkIfItemIsEditable: (key: string) => boolean;

  @Output() closeNewEntryHandler = new EventEmitter<void>();
  @Output() addNewEntryHandler = new EventEmitter<{ key: string; value: string }>();
  @Output() removeItemHandler = new EventEmitter<string>();
  @Output() editItemValueHandler = new EventEmitter<{ key: string; value: string }>();

  public entryName = '';
  public entryValue = '';
  public entryDropdownValue = '';
  public storeEntryName = '';
  public entryOption = '';
  public metadataOption = '';
  public modelName: string;
  public _metadata = [];

  constructor(
    private translateService: TranslateService,
    private deviceModelService: DeviceModelService,
  ) {
    this.metadataOption = this.translateService.instant('METADATA.NAME');
  }

  @Input() set metadata(value: Array<{ key: string; value: string }>) {
    if (value) {
      this._metadata = [...value].sort((a, b) => a.key.localeCompare(b.key));
    }
    this.setInitialModel();
  }

  get showDiv(): boolean {
    return this.deviceModelService.showMetadata;
  }

  isAutoComplete(): boolean {
    return this.entryName === 'assetModel';
  }

  modelOptionSelected(value: string, key: string): void {
    this.editItemValueHandler.emit({
      key,
      value,
    });
  }

  closeNewEntry(): void {
    this.closeNewEntryHandler.emit();
    this.entryName = '';
    this.entryValue = '';
    this.entryOption = '';
    this.entryDropdownValue = '';
  }

  addNewEntry(): void {
    const entry = {
      key: this.metadataOptions.length && this.entryOption !== this.metadataOption ? this.entryOption : this.entryName,
      value: this.entryValue?.['name'] ?? this.entryValue,
    };

    this.addNewEntryHandler.emit(entry);
    this._metadata.push(entry);

    this.storeEntryName = this.entryValue?.['name'];

    this.entryName = '';
    this.entryValue = '';
    this.entryOption = '';
  }

  removeItem(key: string): void {
    this.removeItemHandler.emit(key);
  }

  isItemRemovable(key: string) {
    return this.checkIfItemIsRemovable ? this.checkIfItemIsRemovable(key) : true;
  }

  isItemEditable(key: string) {
    return this.checkIfItemIsEditable ? this.checkIfItemIsEditable(key) : true;
  }

  private setInitialModel(): void {
    if (this._metadata) {
      const assetModelMetadata = this._metadata.find((value) => value.key === 'assetModel');
      if (assetModelMetadata) {
        this.modelName = assetModelMetadata.value;
      }
    }
  }
}
