/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class Action {
  description!: string;
  generated!: boolean;
  id!: string;
  key!: string;
  name!: string;
  usedByServices!: string[];
}
