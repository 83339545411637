import { InjectionToken } from '@angular/core';
import { MatSnackBarConfig } from '@angular/material/snack-bar';

export const DEFAULT_NOTIFICATION_CONFIG: MatSnackBarConfig = {
  duration: 3000,
  horizontalPosition: 'center',
  verticalPosition: 'bottom',
};

export const NOTIFICATION_CONFIG = new InjectionToken<MatSnackBarConfig>('notification_config');
