import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Device, DeviceUtils } from '@deprecated/api-interfaces';
import { DeviceModelService, DeviceViewConfigService, PageService } from '../../services';

@Component({
  selector: 'shared-device-detail-title',
  templateUrl: './device-detail-title.component.html',
  styleUrls: ['./device-detail-title.component.scss'],
})
export class DeviceDetailTitleComponent implements OnDestroy, OnChanges {
  @Input() device!: Device;
  @Input() displayAssignedTo = false;

  hovered = false;

  public now!: number;
  public modelName!: string;
  public isOnline = false;

  constructor(
    private router: Router,
    private deviceViewConfigService: DeviceViewConfigService,
    private deviceModelService: DeviceModelService,
    private pageService: PageService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.device?.currentValue) {
      this.pageService.setTitle(this.device?.tag ?? this.device?.commNr);
      this.isOnline = DeviceUtils.isOnline(this.device);
      this.getDeviceModel();
    }
  }

  ngOnDestroy(): void {
    this.pageService.setTitle();
  }

  goToDevice(deviceId: string) {
    this.deviceViewConfigService.clearChartConfigurations();
    this.deviceViewConfigService.clearGaugeConfig();
    this.router.navigateByUrl(`/devices/detail/${deviceId}`, { skipLocationChange: true });
  }

  getDeviceModel(): void {
    if (this.device?.modelId) {
      this.deviceModelService.getDeviceModel(this.device.modelId).subscribe((response) => {
        this.modelName = response.name;
      });
    }
  }
}
