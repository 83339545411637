import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MaintenanceReportRepositoryHttp } from '../repositories/maintenance-report.repository.http';
import { FileUtils } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceReportService {
  constructor(
    private maintenanceReportRepositoryHttp: MaintenanceReportRepositoryHttp,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
  ) {}

  sendMaintenanceReportEmail(reportId: string, subscribers: string[]): Observable<HttpResponse<any>> {
    return this.maintenanceReportRepositoryHttp.sendMaintenanceReportEmail(reportId, subscribers).pipe(map((response) => response.body));
  }

  deleteMaintenanceReportPDF(id: number): Observable<HttpResponse<any>> {
    return this.maintenanceReportRepositoryHttp.deleteMaintenanceReport(id);
  }

  getDownloadMaintenanceReport(id: string): Observable<HttpResponse<any>> {
    return this.maintenanceReportRepositoryHttp.getMaintenanceReportPDF(id);
  }

  downloadMaintenanceReportPDF(id: string): void {
    const snackbarRef = this.snackBar.open(this.translateService.instant('MAINTENANCE_REPORT_DOWNLOADING_MESSAGE'));
    this.maintenanceReportRepositoryHttp.getMaintenanceReportPDF(id).subscribe({
      next: (response) => {
        const fileName = response.headers.get('content-disposition')?.replace('filename=', '');
        FileUtils.downloadFile(fileName, response.body);
        snackbarRef.dismiss();
      },
      error: () => {
        snackbarRef.dismiss();
        this.snackBar.open(this.translateService.instant('MAINTENANCE_REPORT_DOWNLOADING_FAIL'), 'X', { duration: 3000 });
      },
    });
  }
}
