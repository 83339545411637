import { SecureAction } from '../../secure-actions';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class AnalyticsStatusReportsSecureAction extends SecureAction {
  public static readonly DELETE: SecureAction = new AnalyticsStatusReportsSecureAction('device:analytics:status_reports|delete');
  public static readonly DOWNLOAD: SecureAction = new AnalyticsStatusReportsSecureAction('device:analytics:status_reports|download');
  public static readonly NOTIFICATION_VIEW: SecureAction = new AnalyticsStatusReportsSecureAction(
    'device:analytics:status_reports|notification',
  );
  public static readonly PREVIEW: SecureAction = new AnalyticsStatusReportsSecureAction('device:analytics:status_reports|preview');
  public static readonly SHARE: SecureAction = new AnalyticsStatusReportsSecureAction('device:analytics:status_reports|share');
  public static readonly SEND: SecureAction = new AnalyticsStatusReportsSecureAction('device:analytics:status_reports|send');
  public static readonly UPLOAD: SecureAction = new AnalyticsStatusReportsSecureAction('device:analytics:status_reports|upload');
  public static readonly VIEW: SecureAction = new AnalyticsStatusReportsSecureAction('device:analytics:status_reports|view');

  private constructor(name: string) {
    super(name);
  }
}
