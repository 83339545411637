import * as uuid from 'uuid';
import { FeatureModel } from './feature.model';
import { RoleModel } from './role.model';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface PermissionGroup {
  id: string;
  name: string;
  level: number;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class PermissionGroupModel implements PermissionGroup {
  level: number;
  childrenIds: Array<string> = [];
  deleteProtection = false;
  features: Array<FeatureModel> = [];
  id: string = uuid.v4();
  name: string;
  parentId?: string;
  roles: Array<RoleModel> = [];
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface PermissionGroupLevelFilter {
  level: number;
  filterType: PermissionGroupLevelFilterType;
}

export enum PermissionGroupLevelFilterType {
  LTE = 'LTE',
  GTE = 'GTE',
}
