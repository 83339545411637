import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BaseModule } from '../base';
import { DialogActionComponent } from './components/dialog-action/dialog-action.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { DialogDirective } from './directives/dialog.directive';
import {
  CONFIRM_ACTIONS,
  DANGER_CONFIRM_ACTIONS,
  DEFAULT_ACTIONS,
  DEFAULT_DIALOG_CONFIG,
  DIALOG_CONFIG,
  confirmDialogActionFactory,
  dangerConfirmDialogActionFactory,
  defaultDialogActionFactory,
} from './provider';
import { BaseDialogService } from './services/base-dialog.service';
import { ConfirmDialogService } from './services/confirm-dialog.service';
import { DialogService } from './services/dialog.service';

import * as deTranslations from './i18n/de-lang.json';
import * as enTranslations from './i18n/en-lang.json';

@NgModule({
  declarations: [DialogComponent, DialogDirective, DialogActionComponent],
  imports: [CommonModule, MatDialogModule, MatProgressBarModule, MatButtonModule, MatIconModule, MatDividerModule, FlexLayoutModule, TranslateModule.forChild(), BaseModule],
  providers: [
    BaseDialogService,
    DialogService,
    ConfirmDialogService,
    { provide: DIALOG_CONFIG, useValue: DEFAULT_DIALOG_CONFIG },
    { provide: DEFAULT_ACTIONS, useFactory: defaultDialogActionFactory, deps: [BaseDialogService, DIALOG_CONFIG] },
    { provide: CONFIRM_ACTIONS, useFactory: confirmDialogActionFactory, deps: [BaseDialogService, DIALOG_CONFIG] },
    { provide: DANGER_CONFIRM_ACTIONS, useFactory: dangerConfirmDialogActionFactory, deps: [BaseDialogService, DIALOG_CONFIG] },
  ],
})
export class DialogModule {
  constructor(private translate: TranslateService) {
    this.translate.setTranslation('en', enTranslations, true);
    this.translate.setTranslation('de', deTranslations, true);
  }
}
