import { BehaviorSubject, Subscription } from 'rxjs';
import { SpxAbstractDataSource } from './abstract-data-source';

export class SpxDataSource<T> extends SpxAbstractDataSource<T> {
  protected readonly _data = new BehaviorSubject<T[]>([]);
  protected readonly _renderData = new BehaviorSubject<T[]>([]);
  protected readonly _loading = new BehaviorSubject<boolean>(false);

  public _renderChangesSubscription: Subscription | null = null;

  get renderedData() {
    return this._renderData.value;
  }

  get data() {
    return this._data.value;
  }
  set data(data: T[]) {
    data = Array.isArray(data) ? data : [];
    this._data.next(data);
  }

  get dataSubject() {
    return this._data;
  }

  get renderedDataSubject() {
    return this._renderData;
  }

  constructor(initialData: T[] = []) {
    super();
    this._data = new BehaviorSubject<T[]>(initialData);
    //this._updateChangeSubscription();
  }

  get loading(): boolean {
    return this._loading.value;
  }

  set loading(loading: boolean) {
    this._loading.next(loading);
  }

  get loadingSubject(): BehaviorSubject<boolean> {
    return this._loading;
  }

  protected _updateChangeSubscription() {
    this._renderChangesSubscription?.unsubscribe();
    this._renderChangesSubscription = this._data.subscribe((data) => this._renderData.next(data));
  }

  update() {
    this._updateChangeSubscription();
  }

  connect() {
    if (!this._renderChangesSubscription) {
      this._updateChangeSubscription();
    }

    return this._renderData;
  }

  disconnect() {
    this._renderChangesSubscription?.unsubscribe();
    this._renderChangesSubscription = null;
  }
}
