export class MatchUtils {
  /**
   * Checks whether two objects are similar by iterating through each property and
   * checking if that property has the same value in both A and B.
   *
   * @param first Object to compare against object B.
   * @param second Object to compare against object A.
   * @returns Whether those objects are similar.
   */
  public static objectsAreTheSame<T>(first: T, second: T): boolean {
    if (!first && !second) {
      return true;
    } else if (!first || !second) {
      return false;
    }

    const entriesA = Object.entries(first)
      .filter((entry) => entry[1] !== undefined)
      .sort((a, b) => (a[0] > b[0] ? 1 : -1));
    const entriesB = Object.entries(second)
      .filter((entry) => entry[1] !== undefined)
      .sort((a, b) => (a[0] > b[0] ? 1 : -1));

    const keysA = entriesA.map((entry) => entry[0]);
    const keysB = entriesB.map((entry) => entry[0]);

    if (keysA.length !== keysB.length || keysA.find((key, i) => key !== keysB[i])) {
      return false;
    }

    const uniqueKeys = new Set([...keysA, ...keysB]);
    for (const key of uniqueKeys) {
      if (typeof first[key] === 'object' && typeof second[key] === 'object') {
        if (!MatchUtils.objectsAreTheSame(first[key], second[key])) {
          return false;
        }

        continue;
      }

      if (first[key] !== second[key]) {
        return false;
      }
    }

    return true;
  }

  /**
   * Checks whether two arrays have the same elements. In case of risk of order difference the
   * objectsAreTheSame method is not enough, because it is comparing objects one by one.
   *
   * @param first Array to compare against array B.
   * @param second Array to compare against array A.
   * @returns Whether those arrays contain the same elements.
   */
  public static arraysHaveTheSameElements<T>(first: Array<T>, second: Array<T>): boolean {
    if (!first && !second) {
      return true;
    } else if (!first || !second) {
      return false;
    }

    if (first?.length !== second?.length) {
      return false;
    }

    return first?.filter((a) => second?.some((b) => MatchUtils.objectsAreTheSame(a, b))).length === first.length;
  }
}
