/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface LoggingEntry {
  level: 'INFO' | 'WARN' | 'ERROR';
  applicationRunId: string;
  url: string;
  dialog?: string;
  function?: string;
  msg?: string;
  localTime?: string;
}
