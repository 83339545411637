import { SourceType, ThresholdLevel } from '../shared';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class AlarmConfig {
  id: string;
  deviceId: string;
  sourceId: string;
  sourceType: SourceType;
  sourceDescription: string;
  name: string;
  description: string;
  thresholdMin: number;
  thresholdMax: number;
  heartbeat: boolean;
  enabled: boolean;
  autoack: boolean;
  lastTriggerednanoSinceEpoch: boolean;
  numValuesToTrigger: number;
  groupsV2: Array<string>;
  usersV2: Array<string>;
  type: ThresholdLevel;
  emails: Array<string>;
  mobiles: Array<string>;
  frequency: string;
  modifiedNanoSinceEpoch: number;
  tenantId: string;
}
