import { AlertCardInfo } from './alert-card-info.model';
import { AnomalyCardInfo } from './anomaly-card-info.model';
import { CardInfo } from './card-info.model';
import { KpiCardInfo } from './kpi-card-info.model';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class AnalyticsDashboardDto {
  alerts = new AlertCardInfo();
  anomaly = new AnomalyCardInfo();
  calculations = new CardInfo();
  dataQuality = new CardInfo();
  kpis = new KpiCardInfo();
}
