import { ApiProperty } from '../../decorators/decorators';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class Model {
  description?: string;
  id: string;
  @ApiProperty({
    type: 'Map<string, string>',
  })
  metadata?: Map<string, string>;
  modelDetails?: Array<unknown>;
  name: string;
  tenantId?: string;
  image_id?: string;
  modelRedirectUrl?: string;
}
