import { AfterViewInit, Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[spxAutofocus]',
})
export class AutofocusDirective implements AfterViewInit, OnChanges {
  @Input() appAutoFocusEnabled = true;

  constructor(private elementRef: ElementRef) {}

  ngOnChanges() {
    this.updateAutoFocus();
  }

  ngAfterViewInit(): void {
    this.updateAutoFocus();
  }

  private updateAutoFocus(): void {
    setTimeout(() => {
      if (this.appAutoFocusEnabled) {
        this.elementRef?.nativeElement?.focus();
      }
    });
  }
}
