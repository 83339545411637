import { Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { DialogComponent } from '../components/dialog/dialog.component';
import { DialogData } from '../model';
import { DEFAULT_DIALOG_CONFIG, DIALOG_CONFIG } from '../provider/dialog.provider';

@Injectable()
export class BaseDialogService {
  // Saves the dialog reference to close the dialog
  private dialogRef!: MatDialogRef<DialogComponent<unknown>>;

  constructor(private dialog: MatDialog, @Inject(DIALOG_CONFIG) private defaultDialogConfig: MatDialogConfig) {}

  /**
   * Opens the dialog with a simple text as content
   *
   * @param dialogData Dialog Data object
   * @param dialogConfig Material dialog configuration to override config settings (optional)
   */
  public openDialog<T, R>(dialogData: DialogData<T>, dialogConfig: MatDialogConfig = {}): Observable<R> {
    const config: MatDialogConfig = this.mergeConfigurations(dialogData, dialogConfig);
    this.dialogRef = this.dialog.open(DialogComponent, config);
    return this.dialogRef.afterClosed();
  }

  /**
   * Closes the dialog
   */
  public close<T>(result?: T): void {
    if (this.dialogRef) {
      this.dialogRef.close(result);
    }
  }

  /**
   * Merges material dialog configurations and set the dialog data into the configuration
   *
   * @param data Dialog data object
   * @param dialogConfig Material dialog configuration for overwriting default values
   * @returns Material dialog config with dialog data object as data of materials configuration
   */
  private mergeConfigurations<T>(data: DialogData<T>, dialogConfig: MatDialogConfig): MatDialogConfig {
    // merge provided dialog config with default dialog config (provided overwrites default values)
    const mergeConfigs = Object.assign({}, this.defaultDialogConfig, DEFAULT_DIALOG_CONFIG, dialogConfig);

    // append dialog data to the data object of the dialog config
    mergeConfigs.data = { ...mergeConfigs.data, ...data };

    // keep default panel class
    if (Array.isArray(mergeConfigs.panelClass)) {
      mergeConfigs.panelClass.unshift(<string>DEFAULT_DIALOG_CONFIG.panelClass);
    } else {
      // check for default panel class and merge
      mergeConfigs.panelClass =
        mergeConfigs.panelClass && mergeConfigs.panelClass !== <string>DEFAULT_DIALOG_CONFIG.panelClass
          ? [mergeConfigs.panelClass, <string>DEFAULT_DIALOG_CONFIG.panelClass]
          : <string>DEFAULT_DIALOG_CONFIG.panelClass;
    }

    return mergeConfigs;
  }
}
