import { UserManagementElementType } from '../../user-management';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface PrincipalAlarmConfigTemplateAttachDTO {
  feGroupingId: string;
  principalId: string;
  principalType: UserManagementElementType;
  emails: Array<string>;
  mobiles: Array<string>;
  notifyPrincipalThresholdContacts: boolean;
  userIds: Array<string>;
  enabled: boolean;
}
