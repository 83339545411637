import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidators {
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  static mustContainLowerCase(error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const containsLowerCase = /[a-z]/.test(control.value);

      return containsLowerCase ? null : error;
    };
  }

  static mustContainUpperCase(error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const containsUpperCase = /[A-Z]/.test(control.value);

      return containsUpperCase ? null : error;
    };
  }

  static mustContainSpecialCharacter(error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      // eslint-disable-next-line no-useless-escape
      const containsSpecialCharacter = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(control.value);

      return containsSpecialCharacter ? null : error;
    };
  }

  static mustContainNumber(error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const containsNumber = /\d/.test(control.value);

      return containsNumber ? null : error;
    };
  }

  static maxDateValidator(maxDate: Date, error?: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      const valid = value <= maxDate;

      return valid ? null : error ?? { maxDate: true };
    };
  }

  static urlValidator(error?: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      const regexPattern = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
      if (!value) {
        return null;
      }
      const valid = regexPattern.test(control.value);
      return valid ? null : error;
    };
  }

  static genericValidator(isValid: (value: any) => boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }
      return isValid(control.value) ? null : {generic: {value: control.value}};
    };
  }
}
