import { Group } from '../group';

/**
 * @deprecated The method should not be used
 */
/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class BasicUser {
  id: string;
  username: string;
}

/**
 * @deprecated The method should not be used
 */
/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class UserDto extends BasicUser {
  email: string;
  firstname: string;
  lastname: string;
  roles: string[];
  rights: string[];
  groupsV2: string[];
  isActive: boolean;
  lastLogin: number;
  locked: boolean;
  systemUser: boolean;
  metadata: { [key: string]: string };
  businessPhoneNumber?: string;
  city?: string;
  company?: string;
  country?: string;
  industry?: string;
  jobTitle?: string;
  password?: string;
  postcode?: string;
  region?: string;
  regionCode?: string;
  streetAddress?: string;
  tenantId?: string;
}

/**
 * @deprecated The method should not be used
 */
/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class User extends BasicUser {
  password: string;
  firstname: string;
  lastname: string;
  roles: Array<string>;
  rights: Array<string>;
  groupsV2: Array<string>;
  isActive: boolean;
  createdAt: string;
  lastLogin: number;
  failedLogins: number;
  tenantId: string;
  image?: File;
  imageId?: string;
  base64EncodedImage?: string;
  email: string;
  metadata: { [key: string]: string };
  locked: boolean;
  systemUser: boolean;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class FeUser extends BasicUser {
  accountNonExpired: boolean;
  accountNonLocked: boolean;
  authorities: string[];
  base64EncodedImage: string;
  createdAt: string;
  credentialsNonExpired: boolean;
  email: string;
  enabled: boolean;
  firstname: string;
  fullName: string;
  imageId: string;
  image: unknown;
  groups: Group[];
  groupsV2: string[];
  tenantId: string;
  idAsString: string;
  identitySync: boolean;
  isActive: boolean;
  locked: boolean;
  lastLogin: number;
  lastLoginAsDate: Date;
  lastname: string;
  roles: string[];
  rights: string[];
  isInternal: boolean;
  metadata: unknown;
  systemUser: boolean;
  groupRoleTree: unknown;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class ServiceAccountDto {
  email?: string;
  username: string;
  password1: string;
  password2: string;
  tenantId: string;
  lastname?: string;
  isAdmin?: boolean;
  active?: string;
  metadata?: { [key: string]: string };
  isActive?: boolean;
  firstname?: string;
  company?: string;
  admin?: string;
  groupsV2?: Array<string>;
  rights?: Array<string>;
}
/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class UserDTOv2 {
  username: string;
  password1: string;
  password2: string;
  email: string;
  firstname?: string;
  lastname?: string;
  company?: string;
  isActive?: boolean;
  isAdmin?: boolean;
  rights?: string[];
  groupsV2?: string[];
  tenantId: string;
  metadata?: { [key: string]: string };
}
