import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DialogAction, DialogIcon, DialogService } from '../dialog';
import { WizardStep } from './models/wizard-step';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { WizardMode } from './models/wizard-mode.enum';
import { WizardTabComponent } from './components/wizard-tab/wizard-tab.component';

@Component({
  selector: 'spx-dialog-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
})
export class WizardComponent implements AfterContentInit, OnInit, OnDestroy {
  @Input() mode?: WizardMode;

  @Output() stepChange: EventEmitter<WizardStep> = new EventEmitter<WizardStep>();
  @Output() save: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('stepper') stepper!: MatStepper;
  @ContentChildren(WizardTabComponent, { descendants: true }) tabs?: QueryList<WizardTabComponent>;

  public disabledState: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public stepDisabledAriaLabelledBy = 'mat-step-disabled';
  public WizardMode = WizardMode;
  public currentTabKey = 'FIRST_TAB';

  private completedSubscriptions: Array<Subscription> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly dialogData: {
      actions: Array<DialogAction>;
      icon?: DialogIcon;
    },
    private dialogService: DialogService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  @HostListener('window:keydown.ArrowLeft', ['$event'])
  onArrowLeft() {
    this.switchStep('previous');
  }

  @HostListener('window:keydown.ArrowRight', ['$event'])
  onArrowRight() {
    this.switchStep('next');
  }

  ngOnInit() {
    if (this.mode === WizardMode.VIEW_EDITABLE) {
      this.dialogData.icon = {
        name: 'edit',
        action: () => {
          this.mode = WizardMode.EDIT;
          this.dialogData.icon = undefined;
          this.updateActions();
        },
      };
    }
  }

  ngAfterContentInit() {
    if (this.tabs) {
      this.dialogData.actions = this.getActions();
      this.changeDetectorRef.detectChanges();

      this.tabs.toArray().forEach((tab, index) => {
        this.completedSubscriptions.push(tab.completedChange.subscribe((res) => this.stepCompletedStateChange(tab.key, res, index)));
      });

      this.updateActions();
      const firstTab = this.tabs.get(0);

      if (firstTab) {
        this.stepCompletedStateChange(firstTab.key, firstTab.completed);
      }
    }
  }

  ngOnDestroy() {
    this.completedSubscriptions?.forEach((sub) => sub.unsubscribe());
  }

  public stepperEvent(event: StepperSelectionEvent): void {
    if (this.tabs && event.previouslySelectedIndex === this.stepper.selectedIndex) {
      const currentTab = this.tabs.get(event.selectedIndex);
      this.setStep(event.selectedIndex, false);

      if (currentTab) {
        this.currentTabKey = currentTab.key;
        this.disabledState.next(!currentTab.completed);
        this.updateActions();
      }
    }
  }

  public setStep(step: number, setStepper = true): void {
    if (this.stepper.steps.get(step)?.editable) {
      this.dialogData.actions = this.getActions();

      if (setStepper) {
        this.stepper.selectedIndex = step;
      }
    }
  }

  public getAriaLabel(i: number, disabled?: boolean, key?: string): string {
    if (disabled === undefined || !key) {
      return '';
    }

    const previousTabIndex = this.getNextAvailableTabIndex(i, 'previous');
    const previousTabCompleted = i === 0 || this.tabs?.get(previousTabIndex)?.completed;

    if (disabled || !previousTabCompleted) {
      return this.stepDisabledAriaLabelledBy;
    }

    return '';
  }

  public changeTab(tabKey: string): void {
    this.currentTabKey = tabKey;
  }

  public stepCompletedStateChange(tabKey: string, completed: boolean, tabIndex = 0): void {
    if (this.mode === WizardMode.STEPPER && tabKey === this.currentTabKey) {
      this.disabledState.next(!completed);
    }
  }

  private getNextAvailableTabIndex(index: number, direction: 'next' | 'previous'): number {
    let newTabIndex = index + (direction === 'next' ? 1 : -1);

    if (this.tabs?.get(newTabIndex)?.disabled) {
      newTabIndex += direction === 'next' ? 1 : -1;
    }

    return newTabIndex;
  }

  private switchStep(direction: 'next' | 'previous'): void {
    if (!this.tabs) {
      return;
    }

    const currentTabIndex = this.tabs.toArray().findIndex((tab) => tab.key === this.currentTabKey);
    const newTabIndex = this.getNextAvailableTabIndex(currentTabIndex, direction);
    const newTab = this.tabs.get(newTabIndex);
    let completedSwitchEnabled = true;

    if (direction === 'next' && !this.tabs.get(currentTabIndex)?.completed) {
      completedSwitchEnabled = false;
    }

    if (newTab && !newTab?.disabled && completedSwitchEnabled) {
      this.currentTabKey = newTab.key;
      this.disabledState.next(!newTab.completed);

      if (this.mode === WizardMode.STEPPER) {
        this.setStep(newTabIndex);
      }
    }
  }

  private getActions(): Array<DialogAction> {
    let additionalActions: Array<DialogAction> = [];

    if (this.mode === WizardMode.STEPPER) {
      additionalActions = this.getCreateActions();
    } else if (this.mode === WizardMode.EDIT) {
      additionalActions = this.getEditActions();
    }

    return [
      {
        text: 'BUTTON_CANCEL',
        leftAlign: true,
        action: () => {
          this.dialogService.close();
          return Subscription.EMPTY;
        },
      },
      ...additionalActions,
    ];
  }

  private updateActions(actions = this.getActions()): void {
    this.dialogData.actions = actions;
  }

  private getCreateActions(): Array<DialogAction> {
    if (!this.tabs) {
      return [];
    }

    const currentIndex = this.tabs?.toArray().findIndex((tab) => tab?.key === this.currentTabKey) ?? 0;
    const actions: Array<DialogAction> = [];
    const tabs = this.tabs?.toArray();

    // show the go back button only if there is a previous tab, which is not disabled
    if (currentIndex > 0 && tabs.slice(0, currentIndex).find((tab) => !tab?.disabled)) {
      actions.push({
        type: 'mat-stroked-button',
        color: 'primary',
        text: 'BUTTON_BACK',
        action: () => {
          this.switchStep('previous');
          return Subscription.EMPTY;
        },
      });
    }

    // show the go back button only if there is a next tab, which is not disabled
    if (currentIndex < this.tabs.length - 1 && tabs.slice(currentIndex).find((tab) => !tab?.disabled)) {
      actions.push({
        type: 'mat-raised-button',
        text: 'BUTTON_NEXT',
        action: () => {
          this.switchStep('next');
          return Subscription.EMPTY;
        },
        disable: () => this.disabledState.asObservable(),
      });
    } else {
      actions.push({
        type: 'mat-raised-button',
        text: 'BUTTON_SAVE',
        action: () => {
          this.save.emit();
          return Subscription.EMPTY;
        },
        disable: () => this.disabledState.asObservable(),
      });
    }

    return actions;
  }

  private getEditActions(): Array<DialogAction> {
    if (!this.tabs) {
      return [];
    }

    return [
      {
        type: 'mat-raised-button',
        text: 'BUTTON_SAVE',
        action: () => {
          this.save.emit();
          return Subscription.EMPTY;
        },
        disable: () => this.disabledState.asObservable(),
      },
    ];
  }
}
