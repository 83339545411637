import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateRange, MatCalendar } from '@angular/material/datepicker';
import moment, { Moment } from 'moment';
import { ReplaySubject } from 'rxjs';
import { MaxRange, TimeRangeType, TimeRangeTypeEvent } from '../../models';

export const MY_FORMATS = {
  parse: { dateInput: 'LL' },
  display: { dateInput: 'LL' },
};

enum DIRECTION {
  'PREVIOUS' = 'PREVIOUS',
  'NEXT' = 'NEXT',
}

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

@Component({
  selector: 'spx-range-selector',
  templateUrl: './range-selector.component.html',
  styleUrls: ['./range-selector.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class RangeSelectorComponent implements OnInit {
  @Input() selectedRangeValue: DateRange<Moment> = new DateRange<Moment>(null, null);
  @Input() maxDate?: Moment;
  @Input() minDate?: Moment;
  @Input() rangeType?: TimeRangeType;
  @Input() rangeTypeQuantity?: number;
  @Input() configuredOptions?: Array<TimeRangeType>;
  @Input() maxRange?: MaxRange;
  @Input() onlyCalendar = false;

  @Output() selectedRangeValueChange = new EventEmitter<DateRange<Moment>>();
  @Output() revert = new EventEmitter<void>();
  @Output() save = new EventEmitter<void>();
  @Output() rangeTypeChange = new EventEmitter<TimeRangeType>();
  @Output() rangeTypeQuantityChange = new EventEmitter<number>();

  @ViewChildren(MatCalendar) calendars?: QueryList<MatCalendar<Moment>>;

  public firstCalendarStart!: Moment;
  public secondCalendarStart!: Moment;
  public previousSelector = DIRECTION.PREVIOUS;
  public nextSelector = DIRECTION.NEXT;
  public display = true;
  public displayFirst = true;
  public navigationDisabled = {
    left: false,
    right: false,
  };
  public customLabelTrigger: ReplaySubject<void> = new ReplaySubject<void>();
  public maxCalculatedDate?: Moment;
  public minCalculatedDate?: Moment;

  private static getDayBoundary(boundary: Moment | undefined, boundaryType: 'endOf' | 'startOf', event: Moment): Moment {
    return boundary && event.isSame(boundary, 'day') ? boundary : moment(event)[boundaryType]('day');
  }

  ngOnInit() {
    if (this.selectedRangeValue || this.maxDate) {
      this.firstCalendarStart = moment(this.selectedRangeValue.end ?? this.maxDate);
      this.secondCalendarStart = moment(this.selectedRangeValue.end ?? this.maxDate);
    } else {
      this.firstCalendarStart = moment();
      this.secondCalendarStart = moment();
    }
    this.firstCalendarStart.subtract(1, 'month').startOf('month');

    if (this.minDate || this.maxDate) {
      setTimeout(() => {
        this.displayBoundaryDates(this.selectedRangeValue.start?.isSame(this.selectedRangeValue.end, 'day') ? 'start' : 'end');
      });
    }
  }

  public selectedChange(event: Moment): void {
    this.rangeTypeChange.next('custom' as TimeRangeType);
    this.rangeTypeQuantityChange.next(0);
    if (!this.selectedRangeValue.end?.isSame(this.selectedRangeValue.start, 'day') || !this.selectedRangeValue.start) {
      const start = RangeSelectorComponent.getDayBoundary(this.minCalculatedDate, 'startOf', event);
      const end = RangeSelectorComponent.getDayBoundary(this.maxCalculatedDate, 'endOf', event);

      this.selectedRangeValue = new DateRange<Moment>(start, end);
      this.selectedRangeValueChange.next(this.selectedRangeValue);
      this.displayBoundaryDates('start');
    } else if (this.selectedRangeValue.start > event) {
      const start = RangeSelectorComponent.getDayBoundary(this.minCalculatedDate, 'startOf', event);
      const end = moment(this.selectedRangeValue.end);
      this.selectedRangeValue = new DateRange(start, end);
      this.selectedRangeValueChange.next(this.selectedRangeValue);
      this.displayBoundaryDates();
    } else {
      const end =
        this.maxCalculatedDate &&
        (event.isSame(this.maxCalculatedDate, 'day') || (this.maxRange && moment(event).endOf('day').isAfter(this.maxCalculatedDate)))
          ? this.maxCalculatedDate
          : moment(event).endOf('day');

      this.selectedRangeValue = new DateRange<Moment>(this.selectedRangeValue.start, end);
      this.selectedRangeValueChange.next(this.selectedRangeValue);
      this.displayBoundaryDates();
    }
    this.customLabelTrigger.next();
  }

  public changeMonth(direction: DIRECTION): void {
    this.display = false;

    if (direction === DIRECTION.PREVIOUS) {
      this.firstCalendarStart.subtract(1, 'month');
      this.secondCalendarStart.subtract(1, 'month');
    } else if (direction === DIRECTION.NEXT) {
      this.firstCalendarStart.add(1, 'month');
      this.secondCalendarStart.add(1, 'month');
    }

    this.checkDisabledNav();

    setTimeout(() => {
      this.display = true;
    });
  }

  public setDate(range: DateRange<Moment>): void {
    const start = moment(range.start).startOf('minute');
    const end = moment(range.end).startOf('minute');
    this.selectedRangeValue = new DateRange<Moment>(start, end);
    this.selectedRangeValueChange.next(this.selectedRangeValue);
    this.customLabelTrigger.next();
    this.rangeTypeChange.next('custom' as TimeRangeType);
    this.rangeTypeChange.next('custom' as TimeRangeType);
    this.rangeTypeQuantityChange.next(0);
    this.displayBoundaryDates(range.start?.isSame(range.end, 'day') ? 'start' : 'end');
  }

  private checkDisabledNav(): void {
    this.navigationDisabled = {
      left: !!this.minCalculatedDate && this.firstCalendarStart.startOf('month') < this.minCalculatedDate,
      right: !!this.maxCalculatedDate && this.secondCalendarStart.endOf('month') > this.maxCalculatedDate,
    };
  }

  public pickRangeFromList(event: TimeRangeTypeEvent): void {
    if (event?.range) {
      const { range, type, quantity } = event;
      this.selectedRangeValue = new DateRange<Moment>(range.start, range.end);
      this.displayFirst = !!this.maxDate && !!this.minDate && this.maxDate.isSame(this.minDate, 'month');
      this.display = false;

      if (this.minDate && moment(range.end).isSame(this.minDate, 'month')) {
        this.secondCalendarStart = moment(range.end).add(1, 'month');
        this.firstCalendarStart = moment(range.end);
      } else {
        this.secondCalendarStart = moment(range.end);
        this.firstCalendarStart = moment(range.end).subtract(1, 'month');
      }

      this.selectedRangeValueChange.next(range);
      this.rangeTypeChange.next(type);
      this.rangeTypeQuantityChange.next(quantity ?? 0);

      if (event.apply) {
        this.save.emit();
      }

      setTimeout(() => {
        this.displayBoundaryDates(range.end?.isSame(range.start, 'day') ? 'start' : 'end');
        this.display = true;
        this.displayFirst = true;
      }, 500);
    }
  }

  public isSaveDisabled(): boolean {
    const isDateSame = this.selectedRangeValue.start?.isSame(this.selectedRangeValue.end, 'day') ?? false;
    return this.onlyCalendar && isDateSame;
  }

  private displayBoundaryDates(selection: 'start' | 'end' = 'end'): void {
    this.display = false;
    if (selection === 'start' && this.maxRange) {
      const { quantity, type } = this.maxRange;
      const maxRangeDate = moment(this.selectedRangeValue.start).add(quantity, type);
      const minRangeDate = moment(this.selectedRangeValue.start).subtract(quantity, type);
      this.maxCalculatedDate = moment(!this.maxDate || maxRangeDate.isBefore(this.maxDate) ? maxRangeDate : this.maxDate);
      this.minCalculatedDate = moment(!this.minDate || minRangeDate.isAfter(this.minDate) ? minRangeDate : this.minDate);
    } else {
      this.maxCalculatedDate = this.maxDate ? moment(this.maxDate) : undefined;
      this.minCalculatedDate = this.minDate ? moment(this.minDate) : undefined;
    }

    this.display = true;
    this.checkDisabledNav();
  }
}
