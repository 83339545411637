import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UnleashRepository } from './repositories/unleash.repository';
import { UnleashService } from './services/unleash.service';

@NgModule({
  imports: [CommonModule],
  providers: [UnleashService, UnleashRepository],
})
export class CoreModule {}
