import { Injectable } from '@angular/core';
import { IrdValidator } from '@ird/shared-base';
import { ValidationErrors, Validators } from '@angular/forms';
import { CustomValidators } from '../validators';

@Injectable()
export class ValidatorService {
  mapValidator(validators: IrdValidator[]): any | undefined {
    let resp: ValidationErrors[] | undefined;

    if (validators && Array.isArray(validators)) {
      resp = validators.map((item: IrdValidator) => {
        switch (item.name) {
          case 'required': {
            return Validators.required;
            break;
          }
          case 'generic': {
            return CustomValidators.genericValidator(item.isValid);
            break;
          }
        }
      });
    }
    return resp;
  }
}
