import { AfterContentInit, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MenuItemComponent } from '../menu-item/menu-item.component';

@Component({
  selector: 'spx-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements AfterContentInit {
  /**
   * Configuration for expanding the parent
   *
   * - active: only the active parent menu item is expanded. All others will be closed
   * - all: the expand state is not connected to the active route state and will be triggered independenty
   */
  @Input() expandConfig: 'active' | 'all' = 'active';

  /**
   * All 1st level Menu items for active state
   */
  @ContentChildren(MenuItemComponent) contentChildren!: QueryList<MenuItemComponent>;

  /**
   * All descendent Menu items for changes detection
   */
  @ContentChildren(MenuItemComponent, { descendants: true }) contentChildrenAll!: QueryList<MenuItemComponent>;

  constructor(private router: Router) {
    // set active states of menu after each navigation event
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.setActiveStates();
    });
  }

  /**
   * Initializes the active states
   */
  public ngAfterContentInit(): void {
    // Initialize the active states
    this.setActiveStates();

    // Whenever a async change to the menu items happens, we ne to update the active states
    this.contentChildrenAll.changes.subscribe(() => {
      setTimeout(() => this.setActiveStates());
    });
  }

  /**
   * Sets the active states of all child menu items
   */
  private setActiveStates(): void {
    // loop over all first level menu items
    this.contentChildren?.forEach((cC) => {
      // loop over all possible sub menu items from the menu item component's content children
      cC.contentChildren.forEach((cCChild) => {
        // check if the current route is active
        if (this.isActiveRoute(cCChild.route)) {
          cCChild.active = true;
        } else {
          cCChild.active = false;
        }
        cCChild.child = true;
      });

      // checks, if at least one child menu items is active
      const isChildActive = cC.contentChildren.some((childMenuItem) => childMenuItem.active);

      // set menu item to active, when the route is active or at least one child menu item is active
      cC.active = (this.isActiveRoute(cC.route) || isChildActive) ?? false;

      if (this.expandConfig === 'active') {
        // set expanded state from parent active state, when config is set to active
        cC.expanded = cC.active;
      } else if (cC.active) {
        // if parent is active, expand it always
        cC.expanded = true;
      }
    });
  }

  /**
   * Check, if a current route is active or not
   *
   * @param route Route to check, if active
   * @returns boolean, if it is a active route or not
   */
  private isActiveRoute(route: string): boolean {
    if (!route) {
      return false;
    }

    return this.router.isActive(route, { fragment: 'exact', matrixParams: 'exact', paths: 'subset', queryParams: 'exact' });
  }
}
