import { SecureAction } from '../secure-actions';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class PendingAlarmSecureAction extends SecureAction {
  public static readonly VIEW: SecureAction = new PendingAlarmSecureAction('device:alarms:pending|view');
  public static readonly EXPORT_CSV: SecureAction = new PendingAlarmSecureAction('device:alarms:pending:csv|save');

  private constructor(name: string) {
    super(name);
  }
}
