import { Sensor } from '../sensor';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DeviceConfiguration {
  date: string;
  deviceId: string;
  sensors: Sensor[];
  color: string;
  max: number;
  min: number;
  sensorId: string;
  sensorIdAsString: string;
}
