import { PagedData } from '@ird/shared-base';
import { Device } from '../device';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface UserManagementDetails {
  assets: PagedData<Device>;
  users: PagedData<Device>;
}
