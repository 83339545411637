export enum WidgetType {
  ALERT = 'ALERT',
  ALARM_HISTORY = 'ALARM_HISTORY',
  BARCHART = 'BARCHART',
  BASE = 'BASE',
  DONUT_CHART = 'DONUT_CHART',
  DIVIDER = 'DIVIDER',
  GAUGE = 'GAUGE',
  IMAGE = 'IMAGE',
  LINECHART = 'LINECHART',
  LIQUID = 'LIQUID',
  MAP = 'MAP',
  PENDING_ALERTS = 'PENDING_ALERTS',
  PIECHART = 'PIECHART',
  SENSOR_LIST = 'SENSOR_LIST',
  TABLE = 'TABLE',
  TEXT = 'TEXT',
  TRAFFIC_LIGHT = 'TRAFFIC_LIGHT',
  VALUE = 'VALUE',
}
