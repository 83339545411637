import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SpxIcon } from './icon.model';

export abstract class SpxIconRegistryProxyService {
  constructor(protected matIconRegistry: MatIconRegistry, protected sanitizer: DomSanitizer) {}

  public abstract getNamespace(): string;

  public addIcon(icon: SpxIcon) {
    this.addIconToNamespace(this.getNamespace(), icon);
  }

  protected addIconToNamespace(namespace: string, icon: SpxIcon) {
    this.matIconRegistry.addSvgIconLiteralInNamespace(namespace, icon.name, this.sanitizer.bypassSecurityTrustHtml(icon.data));
  }
}
