// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  align-items: center;
  display: flex;
  justify-content: center;
}

:host(.display) {
  height: 100%;
  width: 100%;
}

:host(.overlay.display) {
  /* stylelint-disable-next-line stylelint-plugins/no-hardcoded-colors */
  background: rgba(1, 1, 1, 0.3);
  left: 0;
  position: absolute;
  top: 0;
  z-index: 200;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNwaW5uZXIuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxtQkFBQTtFQUNBLGFBQUE7RUFDQSx1QkFBQTtBQUNGOztBQUVBO0VBQ0UsWUFBQTtFQUNBLFdBQUE7QUFDRjs7QUFFQTtFQUNFLHNFQUFBO0VBQ0EsOEJBQUE7RUFDQSxPQUFBO0VBQ0Esa0JBQUE7RUFDQSxNQUFBO0VBQ0EsWUFBQTtBQUNGIiwiZmlsZSI6InNwaW5uZXIuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGp1c3RpZnktY29udGVudDogY2VudGVyO1xufVxuXG46aG9zdCguZGlzcGxheSkge1xuICBoZWlnaHQ6IDEwMCU7XG4gIHdpZHRoOiAxMDAlO1xufVxuXG46aG9zdCgub3ZlcmxheS5kaXNwbGF5KSB7XG4gIC8qIHN0eWxlbGludC1kaXNhYmxlLW5leHQtbGluZSBzdHlsZWxpbnQtcGx1Z2lucy9uby1oYXJkY29kZWQtY29sb3JzICovXG4gIGJhY2tncm91bmQ6IHJnYmEoMSwgMSwgMSwgMC4zKTtcbiAgbGVmdDogMDtcbiAgcG9zaXRpb246IGFic29sdXRlO1xuICB0b3A6IDA7XG4gIHotaW5kZXg6IDIwMDtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/shared/ng/base/src/lib/base/spinner/spinner.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;AACF;;AAEA;EACE,sEAAA;EACA,8BAAA;EACA,OAAA;EACA,kBAAA;EACA,MAAA;EACA,YAAA;AACF;AACA,g3BAAg3B","sourcesContent":[":host {\n  align-items: center;\n  display: flex;\n  justify-content: center;\n}\n\n:host(.display) {\n  height: 100%;\n  width: 100%;\n}\n\n:host(.overlay.display) {\n  /* stylelint-disable-next-line stylelint-plugins/no-hardcoded-colors */\n  background: rgba(1, 1, 1, 0.3);\n  left: 0;\n  position: absolute;\n  top: 0;\n  z-index: 200;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
