import { MatColumnDef, MatRowDef } from '@angular/material/table';

export interface ColumnConfig {
  name: string;
  label?: string | null;
  sort?: boolean;
  sortProperty?: string;
  filter?: boolean;
}

export interface Column extends ColumnConfig {
  columnDef: MatColumnDef;
}

export interface RowConfig {
  sticky?: boolean;
  expandable?: boolean | RowExpandableConfig;
}

export interface Row<T> extends RowConfig {
  rowDef: MatRowDef<T>;
}

export interface RowExpandableConfig {
  enable: boolean;
  colCount?: number;
}

export interface TableConfig {
  columns: ColumnConfig[];
  row?: RowConfig;
}
