/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface Bucket {
  deviceId: string;
  value: number;
  sum: number;
  min: number;
  max: number;
  numValues: number;
  sourceId: string;
  timeInNanoSeconds?: number;
  window: number;
}
