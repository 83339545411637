import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'spx-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
  @Input() title!: string;
  @Input() unit!: string;
  @Input() error: string | null = null;
  @Input() current!: number;
  @Input() total?: number;

  @Output() cancel = new EventEmitter<void>();

  public cancelable = false;

  ngOnInit() {
    this.cancelable = this.cancel.observed;
  }

  public isIndeterminate() {
    return !this.total;
  }

  public getPercent(): number | undefined {
    if (this.total) {
      return (this.current / this.total) * 100;
    }
    return undefined;
  }

  public isFinished(): boolean {
    const percentage = this.getPercent();
    return percentage && percentage >= 100 ? true : false;
  }

  public cancelProgress() {
    this.cancel.emit();
  }
}
