import { SecureAction } from '../../secure-actions';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class AnalyticsKpiSecureAction extends SecureAction {
  public static readonly CREATE_CONFIGURATION: SecureAction = new AnalyticsKpiSecureAction('device:analytics:kpi|create_configuration');
  public static readonly DELETE_CONFIGURATION: SecureAction = new AnalyticsKpiSecureAction('device:analytics:kpi|delete_configuration');
  public static readonly UPDATE_CONFIGURATION: SecureAction = new AnalyticsKpiSecureAction('device:analytics:kpi|update_configuration');
  public static readonly UPDATE_ENABLED_STATE: SecureAction = new AnalyticsKpiSecureAction('device:analytics:kpi|update_enabled');
  public static readonly UPDATE_TIMERANGE: SecureAction = new AnalyticsKpiSecureAction('device:analytics:kpi|update_timerange');
  public static readonly VIEW: SecureAction = new AnalyticsKpiSecureAction('device:analytics:kpi|view');

  private constructor(name: string) {
    super(name);
  }
}
