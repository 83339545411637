import { AfterContentInit, Directive } from '@angular/core';
import { TableComponent } from '../../components/table/table.component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'spx-table[multiExpansion]',
})
export class MultiExpansionDirective implements AfterContentInit {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(private table: TableComponent<any>) {}

  ngAfterContentInit(): void {
    this.table.multiExpansion = true;
  }
}
