import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { CookieService } from 'ngx-cookie-service';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/service/auth.service';
import { NO_SESSION_TRACKING } from '../../interceptor/httprest.interceptor';
import { NO_TOKEN, USE_LLT_TOKEN } from '../../interceptor/token.interceptor';
import { TenantConfigService } from './tenant-config.service';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly tenantConfigService: TenantConfigService,
    private readonly tokenService: TokenService,
    private readonly gtmService: GoogleTagManagerService,
    private readonly cookieService: CookieService,
    private readonly authService: AuthService,
  ) {}

  public initApp(): Promise<void> {
    const httpParams = new HttpParams({ fromString: window.location.search.split('?')[1] });
    const devOrigin = httpParams?.get('devOrigin');

    let url = document.location.origin;
    if (!environment.production && devOrigin) {
      url = devOrigin;
    }

    localStorage.removeItem('deviceId');
    localStorage.removeItem('machineToken');
    this.gtmService.addGtmToDom();

    return this.initToken(url).then(() => {
      return this.initTenantConfiguration();
    });
  }

  private initToken(url: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      firstValueFrom(
        this.httpClient.head(url, {
          observe: 'response',
          context: new HttpContext().set(NO_TOKEN, true).set(NO_SESSION_TRACKING, true),
        }),
      )
        .then((res) => {
          const token = res.headers.get('x-auth-token');
          if (token) {
            this.tokenService.setLlt(token);
            if (!this.cookieService.get(environment.xTokenCookieName) || !this.authService.isTokenValid()) {
              this.cookieService.set(environment.xTokenCookieName, token, undefined, '/');
            }
          } else if (environment.devOrigin && !environment.devOrigin.startsWith(url) && !environment.production) {
            return this.initToken(environment.devOrigin);
          } else {
            reject('No app token found for this origin');
          }
        })
        .then(() => resolve())
        .catch(() => reject());
    });
  }

  private initTenantConfiguration(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      firstValueFrom(
        this.httpClient.get(environment.tenant.configuration, {
          headers: { 'x-auth-token': this.tokenService.getLlt() },
          withCredentials: true,
          context: new HttpContext().set(USE_LLT_TOKEN, true),
        }),
      )
        .then((response) => {
          this.tenantConfigService.setConfig(response);
          resolve();
        })
        .catch(() => reject());
    });
  }
}
