import { DestroyRef, Inject, Injectable } from '@angular/core';
import { WsEventSubscription, eventSubscriptionToTopic } from '@deprecated/api-interfaces';
import { Observable, Subject, finalize, fromEvent } from 'rxjs';
import { Socket } from 'socket.io-client';
import { takeUntilDestroyedOrObservable } from '../utils';
import { LoginService } from './login.service';
import { SocketIoProvider } from './socket-io.provider';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class NestWebSocketService {
  private socket: Socket;

  private subscriptions = new Map<string, Observable<any>>();

  public connection$: Observable<Socket>;
  public connectError$: Observable<string>;
  public disconnect$: Observable<any>;
  public error$: Observable<any>;

  constructor(
    private socketIOProvider: SocketIoProvider,
    private userService: UserService,
    private loginService: LoginService,
    @Inject('ENVIRONMENT') private config: any,
  ) {
    const token = this.loginService.getToken();

    this.socket = this.socketIOProvider.getSocket('', {
      path: this.config.nest?.websocket.path,
      transports: ['websocket'],
      auth: {
        'X-Auth-Token': token,
      },
      reconnection: true,
      reconnectionAttempts: 10,
      autoConnect: !!token,
    });

    this.connection$ = fromEvent(this.socket, 'connect');
    this.disconnect$ = fromEvent(this.socket, 'disconnect');

    this.connectError$ = fromEvent(this.socket, 'connect_error');
    this.error$ = fromEvent(this.socket, 'error');

    this.connection$.subscribe(() => {
      for (const key of this.subscriptions.keys()) {
        this.socket.emit('subscribe', key);
      }
    });

    this.userService.onUserEvent().subscribe((user) => {
      if (this.socket.connected) {
        this.socket.disconnect();
      }

      if (user && this.loginService.isTokenValid()) {
        this.socket.auth['X-Auth-Token'] = localStorage.getItem('token');
        this.socket.connect();
      }
    });

    this.handleUserPermissionsChangeEvents();
  }

  public watch<T>(
    topic: string,
    parameters?: unknown,
    destroyRef: Observable<unknown> | DestroyRef = new Observable<unknown>(),
  ): Observable<T> {
    const subscription = { topic, parameters } as WsEventSubscription;
    const topicName = eventSubscriptionToTopic(subscription);

    if (this.subscriptions.has(topicName)) {
      return this.subscriptions.get(topicName).pipe(takeUntilDestroyedOrObservable(destroyRef));
    } else {
      const subject = new Subject<T>();

      this.socket.on(topicName, (data: T) => {
        subject.next(data);
      });

      const observable = subject.pipe(
        finalize(() => {
          if (!subject.observed) {
            this.subscriptions.delete(topicName);
            this.socket.off(topicName);
            this.socket.emit('unsubscribe', subscription);
          }
        }),
      );

      try {
        this.socket.emit('subscribe', subscription, (response: string[] | boolean) => {
          if (!response) {
            subject.error(
              new Error(
                parameters
                  ? `Subscription to topic ${topic} with params: ${JSON.stringify(parameters, null, 1)} denied`
                  : `Subscription to topic ${topic} denied`,
              ),
            );
            subject.complete();
          }
        });
      } catch (_error) {
        //Do nothing. Subscriptions are restored after reconnect
      }

      this.subscriptions.set(topicName, observable);

      return observable.pipe(takeUntilDestroyedOrObservable(destroyRef));
    }
  }

  public request<T>(topic: string, data?: unknown): Observable<T> {
    return new Observable((subscriber) => {
      this.socket.emit(topic, data, (response) => {
        subscriber.next(response);
        subscriber.complete();
      });
    });
  }

  public send(topic: string, data: unknown) {
    this.socket.emit(topic, data);
  }

  /**
   * When user is logged in and something changes like permissionGroups in the backend,
   * reload user with fresh data
   */
  handleUserPermissionsChangeEvents(): void {
    this.watch('user/permissiongroup/updated').subscribe(async () => {
      await this.userService.initUser();
    });
  }
}
