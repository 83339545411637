/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface StatusReport {
  id: string;
  period?: string;
  periodStart: string;
  periodEnd: string;
  displayName: string;
  createdDate: string | number;
}
