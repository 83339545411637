import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BaseModule } from '../base';
import { BrandingDirective } from './components/sidenav/branding.directive';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { TopbarComponent } from './components/topbar/topbar.component';

@NgModule({
  declarations: [SidenavComponent, TopbarComponent, BrandingDirective],
  imports: [
    CommonModule,
    BaseModule,
    MatSidenavModule,
    MatRippleModule,
    MatDividerModule,
    FlexLayoutModule,
    TranslateModule,
    RouterModule,
    MatToolbarModule,
    MatSidenavModule,
  ],
  exports: [SidenavComponent, TopbarComponent, BrandingDirective],
})
export class SidenavModule {}
