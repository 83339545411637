/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class TenantDto {
  id: string;
  name: string;
  brandname: string;
  hosts: string[];
  bqSlug: string;
  companyLegalName: string;
  address: string;
  contactEmail: string;
}
