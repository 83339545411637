import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { DeviceService } from '../services/device.service';
import { TenantConfigService } from '../services/tenant-config.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureGuard {
  constructor(
    private router: Router,
    private tenantConfigService: TenantConfigService,
    private deviceService: DeviceService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
    if (!route.data?.feature || this.tenantConfigService.isFeatureEnabled(route.data.feature)) {
      return true;
    } else {
      const deviceId = this.deviceService.getDeviceId();
      const route = deviceId ? `home/${deviceId}` : 'home';
      this.router.navigate([route]);
      return false;
    }
  }
}
