import { SecureAction } from '../secure-actions';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class SensorSecureAction extends SecureAction {
  public static readonly CREATE_SENSOR_TYPE: SecureAction = new SensorSecureAction('sensor:types|create');
  public static readonly UPDATE_SENSOR_TYPE: SecureAction = new SensorSecureAction('sensor:types|update');
  public static readonly DELETE_SENSOR_TYPE: SecureAction = new SensorSecureAction('sensor:types|delete');
  public static readonly VIEW_SENSOR_TYPE: SecureAction = new SensorSecureAction('sensor:types|view');
  public static readonly EDIT_SENSOR: SecureAction = new SensorSecureAction('sensor:edit|sensors');

  private constructor(name: string) {
    super(name);
  }
}
