import { ApiProperty } from '../../decorators/decorators';
import { UserManagementTreePathDto } from '../alarm';
import { Group } from '../group';
import { Location } from '../location';
import { Tenant } from '../user-management';
import { DeviceStatus } from './device-status';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class BasicDevice {
  id!: string;
  tag!: string;
  commNr!: string;

  public static mapToBasicDevice(device: Device): BasicDevice {
    const { tag, id, commNr } = device;
    return { tag, id, commNr };
  }
}

export class BasicSepoDevice {
  id!: string;
  serialNumber!: string;

  public static mapToBasicSepoDevice(device: SepoDevice): BasicSepoDevice {
    const { id, serialNumber } = device;
    return { id, serialNumber };
  }
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class OverviewDevice extends BasicDevice {
  description!: string;
  status!: string;
  type!: string;
  thumbnailId!: string | null;
  pictureId!: string | null;
  lastActiveNanoSeconds!: number;
  createDateNanoSeconds!: number;
  @ApiProperty({
    type: 'Map<string, string>',
  })
  configuration!: Record<string, string>;
  customer?: string;
  state?: DeviceStatus;
  children: Array<Device>;
  tenant: Tenant;
  timeoutDuration?: number;
  treePath?: UserManagementTreePathDto[];

  public static mapToOverviewDevice(device: Device): OverviewDevice {
    const {
      tag,
      id,
      commNr,
      customer,
      description,
      status,
      lastActiveNanoSeconds,
      configuration,
      thumbnailId,
      pictureId,
      type,
      state,
      children,
      tenant,
      createDateNanoSeconds,
      timeoutDuration,
    } = device;
    return {
      tag,
      id,
      commNr,
      customer,
      description,
      status,
      lastActiveNanoSeconds,
      configuration,
      thumbnailId,
      pictureId,
      type,
      state,
      children,
      tenant,
      createDateNanoSeconds,
      timeoutDuration,
    };
  }
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class Device extends OverviewDevice {
  position!: number;
  groups!: Group[];
  groupsV2!: string[];
  idAsString!: string;
  manufacturerId!: string;
  productionSiteId?: string;
  brandId?: string;
  parent!: Device;
  isOnline!: string;
  location!: Location;
  locations!: Location[] | string[];
  notes!: string;
  time!: string;
  usersV2!: string[];
  modelId!: string;
  tenantId!: string;
}

export class SepoDevice extends BasicSepoDevice {
  modelId: string;
  modelName: string;
  tenantId: string;
  redirectUrl?: string;
  metadata: { [key: string]: string };
  thumbnailId?: string
}

export type DeviceDisplayType = 'id' | 'commNr';

export enum DeviceDisplayEnum {
  id = 'id',
  commNr = 'commNr',
}
