import { ChangeDetectorRef, Directive, ElementRef, OnInit, Renderer2, ViewContainerRef } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[type="password"][showPassword]',
})
export class PasswordDirective implements OnInit {
  private icon!: MatIcon;

  showPassword = false;

  constructor(
    private renderer: Renderer2,
    private element: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private changeDetectionRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    try {
      const parentNode = this.element.nativeElement.parentNode;
      if (parentNode) {
        const componentInstance = this.viewContainerRef.createComponent(MatIcon);

        this.icon = componentInstance.instance;
        this.icon.fontIcon = 'visibility';

        this.icon._elementRef.nativeElement.addEventListener('click', () => {
          this.toggleType();
        });

        this.renderer.addClass(this.icon._elementRef.nativeElement, 'password-icon');

        this.renderer.appendChild(parentNode, componentInstance);
        this.changeDetectionRef.detectChanges();
      }
    } catch (error: unknown) {
      //TODO remove try / catch
      //! appendChilds fires => TypeError: Failed to execute 'appendChild' on 'Node': parameter 1 is not of type 'Node'.
      console.log(error);
    }
  }

  toggleType() {
    this.showPassword = !this.showPassword;
    this.element.nativeElement.type = this.showPassword ? 'text' : 'password';
    this.icon.fontIcon = this.showPassword ? 'visibility_off' : 'visibility';
  }
}
