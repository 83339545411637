import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { HJ_SETTINGS } from './providers/hotjar-settings.provider';
import { HotjarUserAttributesFakeLoader, HotjarUserAttributesLoader } from './providers/hotjar-user-attributes.provider';
import { HJ_PROVIDER } from './providers/hotjar.provider';

@NgModule({})
export class HotjarModule {
  static forRoot(
    trackingCode: string,
    enabled: boolean,
    userAttributesProvider?: Provider,
    version = 6,
  ): ModuleWithProviders<HotjarModule> {
    return {
      ngModule: HotjarModule,
      providers: [
        {
          provide: HJ_SETTINGS,
          useValue: {
            trackingCode,
            enabled,
            version,
          },
        },
        userAttributesProvider || { provide: HotjarUserAttributesLoader, useClass: HotjarUserAttributesFakeLoader },
        HJ_PROVIDER,
      ],
    };
  }
}
