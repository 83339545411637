import { WidgetConfig } from '../widget';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DashboardConfigEntry {
  id?: string;
  posX?: number;
  posY?: number;
  sizeX?: number;
  sizeY?: number;
  widgetConfig?: WidgetConfig;
}
