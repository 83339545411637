// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  height: 100%;
  overflow: hidden;
}
:host shared-search-bar {
  margin: 24px 24px 0;
}
:host mat-card {
  margin: 24px;
  overflow-y: hidden;
}
:host mat-card-content {
  height: 100%;
}
:host spx-table {
  overflow-y: auto;
}
:host .table-scroll {
  height: 100%;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImdlbmVyaWMtYWRtaW4tcGFnZS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGNBQUE7RUFDQSxZQUFBO0VBQ0EsZ0JBQUE7QUFDRjtBQUNFO0VBQ0UsbUJBQUE7QUFDSjtBQUVFO0VBQ0UsWUFBQTtFQUNBLGtCQUFBO0FBQUo7QUFHRTtFQUNFLFlBQUE7QUFESjtBQUlFO0VBQ0UsZ0JBQUE7QUFGSjtBQUtFO0VBQ0UsWUFBQTtBQUhKIiwiZmlsZSI6ImdlbmVyaWMtYWRtaW4tcGFnZS5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgZGlzcGxheTogYmxvY2s7XG4gIGhlaWdodDogMTAwJTtcbiAgb3ZlcmZsb3c6IGhpZGRlbjtcblxuICBzaGFyZWQtc2VhcmNoLWJhciB7XG4gICAgbWFyZ2luOiAyNHB4IDI0cHggMDtcbiAgfVxuXG4gIG1hdC1jYXJkIHtcbiAgICBtYXJnaW46IDI0cHg7XG4gICAgb3ZlcmZsb3cteTogaGlkZGVuO1xuICB9XG5cbiAgbWF0LWNhcmQtY29udGVudCB7XG4gICAgaGVpZ2h0OiAxMDAlO1xuICB9XG5cbiAgc3B4LXRhYmxlIHtcbiAgICBvdmVyZmxvdy15OiBhdXRvO1xuICB9XG5cbiAgLnRhYmxlLXNjcm9sbCB7XG4gICAgaGVpZ2h0OiAxMDAlO1xuICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/shared/ng/common/src/lib/components/generic-admin-page/generic-admin-page.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,YAAA;EACA,gBAAA;AACF;AACE;EACE,mBAAA;AACJ;AAEE;EACE,YAAA;EACA,kBAAA;AAAJ;AAGE;EACE,YAAA;AADJ;AAIE;EACE,gBAAA;AAFJ;AAKE;EACE,YAAA;AAHJ;AACA,g4BAAg4B","sourcesContent":[":host {\n  display: block;\n  height: 100%;\n  overflow: hidden;\n\n  shared-search-bar {\n    margin: 24px 24px 0;\n  }\n\n  mat-card {\n    margin: 24px;\n    overflow-y: hidden;\n  }\n\n  mat-card-content {\n    height: 100%;\n  }\n\n  spx-table {\n    overflow-y: auto;\n  }\n\n  .table-scroll {\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
