import { ValueType } from '../data-conversion';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class BaseSensorType {
  id: string;
  name?: string;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class SensorType extends BaseSensorType {
  type?: string;
  iconImageFile?: string;
  color?: string;
  tenantId?: string;
  valueType?: ValueType;
  metadata?: any;
  unit?: string;
  precision?: number;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class EditSensorTypeDTO extends SensorType {
  iconChanged?: boolean = false;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface SensorTypeImage {
  description: string;
  fileType: string;
  gcpFileName: string;
  id: string;
  name: string;
  nanoSinceEpoch: string;
  objectId: string;
  objectType: string;
  payload: string;
  uploaderUserId: string;
}

export enum SensorValueType {
  TEMPERATURE = 'TEMPERATURE',
  PRESSURE = 'PRESSURE',
  VIBRATION = 'VIBRATION',
  FREQUENCY = 'FREQUENCY',
}
