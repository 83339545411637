/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface Filter {
  key: string;
  value: string;
}
/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface DeviceGroup {
  id: string;
  name: string;
  deviceCount?: number;
  filter?: Filter[];
}
