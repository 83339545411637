import { Directive, Input } from '@angular/core';
import { instanceOfFilteredDataSource } from '../../../collections';
import { TableComponent } from '../../components/table/table.component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'spx-table[filter]',
})
export class FilterDirective<T> {
  @Input() set filter(filter: HTMLInputElement | string | null) {
    if (typeof filter !== 'string' && (filter instanceof HTMLInputElement || filter === null)) {
      this.setFilterElement(filter);
    } else if (instanceOfFilteredDataSource(this.table.dataSource)) {
      this.table.dataSource.setFilterValue(filter);
    }
  }

  private _filterELement: HTMLInputElement | null = null;

  constructor(private table: TableComponent<T>) {}

  private setFilterElement(filter: HTMLInputElement | null): void {
    this._filterELement?.removeEventListener('keyup', this.filterListener);
    this._filterELement = filter;
    this._filterELement?.addEventListener('keyup', this.filterListener);
  }

  private filterListener = (event: Event): void => {
    if (instanceOfFilteredDataSource(this.table.dataSource)) {
      const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
      if (filterValue !== this.table.dataSource.getFilterValue()) {
        this.table.dataSource.setFilterValue(filterValue);
      }
    }
  };
}
