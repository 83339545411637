/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class FreescoutService {
  public show() {
    const btn = document.getElementById('fsw-btn');
    (window as any).FreeScoutW.show(btn);
  }

  public hide() {
    (window as any).FreeScoutW.minimize();
  }

  public toggle() {
    const freeScout = (window as any).FreeScoutW;
    if (freeScout?.showing.getValue()) {
      this.hide();
    } else {
      this.show();
    }
  }

  public isShowing(): Observable<boolean> {
    return ((window as any).FreeScoutW?.showing as BehaviorSubject<boolean>).asObservable();
  }
}
