// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spx-progress-bar-info {
  margin-top: 1em;
}

.spx-progress-bar-error {
  color: var(--warn-text);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInByb2dyZXNzLWJhci5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGVBQUE7QUFDRjs7QUFFQTtFQUNFLHVCQUFBO0FBQ0YiLCJmaWxlIjoicHJvZ3Jlc3MtYmFyLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnNweC1wcm9ncmVzcy1iYXItaW5mbyB7XG4gIG1hcmdpbi10b3A6IDFlbTtcbn1cblxuLnNweC1wcm9ncmVzcy1iYXItZXJyb3Ige1xuICBjb2xvcjogdmFyKC0td2Fybi10ZXh0KTtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/shared/ng/base/src/lib/progress-bar/components/progress-bar.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAEA;EACE,uBAAA;AACF;AACA,wbAAwb","sourcesContent":[".spx-progress-bar-info {\n  margin-top: 1em;\n}\n\n.spx-progress-bar-error {\n  color: var(--warn-text);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
