/* eslint-disable @typescript-eslint/no-namespace */
export type Color = 'primary' | 'accent' | 'warn' | 'status-info' | 'status-success' | 'status-warn' | 'status-alert' | 'status-error' | 'status-absent';

export namespace Colors {
  export enum ColorTypes {
    primary = 'primary',
    accent = 'accent',
    warn = 'warn',
    statusInfo = 'status-info',
    statusSuccess = 'status-success',
    statusWarn = 'status-warn',
    statusAlert = 'status-alert',
    statusError = 'status-error',
    statusAbsent = 'status-absent'
  }

  export function all(): Color[] {
    return Object.values(ColorTypes).map((v) => v as Color);
  }

  export function status(): Color[] {
    return Object.values(ColorTypes)
      .filter((s) => s.startsWith('status-'))
      .map((v) => v as Color);
  }
}
