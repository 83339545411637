import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PasswordErrorService } from '../../services';

@Component({
  selector: 'shared-password-input',
  templateUrl: './password-input.html',
  styleUrls: ['./password-input.scss'],
})
export class PasswordInputComponent {
  @Input() control!: FormControl;

  constructor(private passwordErrorService: PasswordErrorService) {}

  getPasswordErr() {
    return this.passwordErrorService.getPasswordErr(this.control);
  }
}
