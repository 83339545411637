import { Directive, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { DragDataService, SpxDragEvent } from '@ird/ng-core';
import { TableComponent } from '../../components/table/table.component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'spx-table[dragOver]',
})
export class DropDirective<T> implements OnInit {
  @HostBinding('class.spx-droppable') get draggableClass(): boolean {
    return !!this.dragOver;
  }

  @Input() dragOver!: (event: SpxDragEvent<unknown>) => boolean;

  @Output() dragEntered = new EventEmitter<SpxDragEvent<unknown>>();
  @Output() dragLeft = new EventEmitter<SpxDragEvent<unknown>>();
  @Output() dropped = new EventEmitter<SpxDragEvent<unknown>>();

  constructor(
    private table: TableComponent<T>,
    private dragDataService: DragDataService,
  ) {}

  ngOnInit(): void {
    this.table.dropDirective = this;
  }

  public isDropAllowed(event: DragEvent): boolean {
    const dragData = this.dragDataService.getDragData(event);
    const allowed = this.dragOver(new SpxDragEvent(event, dragData));
    if (allowed) {
      event.preventDefault();
    }
    return allowed;
  }

  public onDragEntered(event: DragEvent) {
    const dragData = this.dragDataService.getDragData(event);
    this.dragEntered.emit(new SpxDragEvent(event, dragData));
  }

  public onDragLeft(event: DragEvent) {
    const dragData = this.dragDataService.getDragData(event);
    this.dragLeft.emit(new SpxDragEvent(event, dragData));
  }

  public onDrop(event: DragEvent) {
    const dragData = this.dragDataService.getDragData(event);
    this.dropped.emit(new SpxDragEvent(event, dragData));
  }
}
