export enum OpcuaServerDeploymentStatusEnum {
  UNKNOWN = 'UNKNOWN',
  FAILED = 'FAILED',
  DEPLOYMENT = 'DEPLOYMENT',
  RUNNING = 'RUNNING',
  DELETING = 'DELETING',
  STOPPED = 'STOPPED',
  ONLINE = 'online',
  OFFLINE = 'offline',
}
