import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Pageable, PagedData } from '../models';
import { DeviceModel, DeviceModelDetails } from '../models/device-model.model';

@Injectable()
export class DeviceModelRepositoryHttp {
  constructor(
    private http: HttpClient,
    @Inject('ENVIRONMENT') private config: any,
  ) {}

  getDeviceModel(modelId: string): Observable<DeviceModel> {
    const path = this.config.ui.model.get.replace('{id}', modelId);

    return this.http.get<DeviceModel>(path);
  }

  getModelsByNameAndCategory(
    searchText: string,
    categoryName: string,
    pageable: Pageable,
    deviceId: string,
  ): Observable<PagedData<DeviceModel>> {
    const path = this.config.nest.device.edit.general.getModels.replace('{deviceId}', deviceId);
    const params = new HttpParams()
      .set('searchText', searchText)
      .set('categoryName', categoryName)
      .set('page', pageable.pageNumber)
      .set('size', pageable.pageSize);

    return this.http.get<PagedData<DeviceModel>>(path, { params });
  }

  /**
   * Creates a device model on the server
   *
   * @param model Device Model
   * @returns Created device model stream
   */
  createDeviceModel(model: DeviceModel): Observable<DeviceModel> {
    return this.http.post<DeviceModel>(this.config.ui.model.post, model);
  }

  exportModelData(model: DeviceModel): Observable<DeviceModel> {
    // eslint-disable-next-line @typescript-eslint/ban-types
    const requestOptions: Object = {
      headers: Headers,
      responseType: 'text',
    };
    return this.http.get<DeviceModel>(this.config.ui.model.exportFile, requestOptions);
  }

  cleanUpDevices(tenantId: string) {
    const path = this.config.ui.model.cleanUpDevices.replace('{tenantId}', tenantId);
    return this.http.post(path, '', { responseType: 'text' });
  }

  /**
   * Edits a device model on the server
   *
   * @param modelId Device Model Id
   * @param model Device Model to change
   * @returns Modified device model stream
   */
  editDeviceModel(modelId: string, model: DeviceModel): Observable<DeviceModel> {
    const path = this.config.ui.model.update.replace('{id}', modelId);
    return this.http.post<DeviceModel>(path, model);
  }

  /**
   * Deletes a device on the server
   *
   * @param modelId Device Model ID
   * @returns Deleted device model stream
   */
  deleteDeviceModel(modelId: string): Observable<DeviceModel> {
    const path = this.config.ui.model.delete.replace('{id}', modelId);

    return this.http.delete<DeviceModel>(path);
  }

  /**
   * Attaches a file to a device model detail
   *
   * @param modelDetailId Device Model Detail ID
   * @param fileId File ID
   * @returns Modified device model detail stream
   */
  attachFileToModelDetail(modelDetailId: string, fileId: string): Observable<DeviceModelDetails> {
    const path = this.config.ui.modelDetails.attachFile.replace('{modelDetailId}', modelDetailId);

    return this.http.put<DeviceModelDetails>(path, `"${fileId}"`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  /**
   * Detaches a file from a device model detail
   *
   * @param modelDetailId Device Model Detail ID
   * @param fileId File ID
   */
  detachFileFromModelDetail(modelDetailId: string, fileId: string): Observable<DeviceModelDetails> {
    let path = this.config.ui.modelDetails.detachFile.replace('{modelDetailId}', modelDetailId);
    path = path.replace('{fileId}', fileId);

    return this.http.patch<DeviceModelDetails>(path, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  /**
   * Attaches a device model detail to a device model
   *
   * @param modelId Device Model ID
   * @param modelDetail Device Model Details
   * @returns Deleted device model stream
   */
  attachDeviceModelDetailToDeviceModel(modelId: string, modelDetails: DeviceModelDetails): Observable<DeviceModel> {
    const path = this.config.ui.model.attach.replace('{id}', modelId);

    return this.http.post<DeviceModel>(path, modelDetails);
  }

  uploadDeviceModelImage(modelId: string, formData: FormData): Observable<any> {
    const path = this.config.ui.model.uploadImage.replace('{modelId}', modelId);

    return this.http.post(path, formData, {
      observe: 'response',
      headers: {},
    });
  }

  deleteDeviceModelImage(modelId: string): Observable<any> {
    const path = this.config.ui.model.deleteImage.replace('{modelId}', modelId);

    return this.http.delete(path);
  }

  getDeviceModelImage(modelId: string): Observable<HttpResponse<Blob>> {
    const path = this.config.ui.model.getImage.replace('{modelId}', modelId);
    const options = {
      'Content-Type': 'application/blob',
    };

    return this.http.get<Blob>(path, {
      observe: 'response',
      headers: options,
      responseType: 'blob' as 'json',
    });
  }
  uploadDeviceModelDetailImage(modelId: string, formData: FormData): Observable<any> {
    const path = this.config.ui.modelDetails.uploadImage.replace('{modelDetailId}', modelId);

    return this.http.post(path, formData, {
      observe: 'response',
      headers: {},
    });
  }

  deleteDeviceModelDetailImage(modelId: string): Observable<any> {
    const path = this.config.ui.modelDetails.deleteImage.replace('{modelDetailId}', modelId);

    return this.http.delete(path);
  }

  getDeviceModelDetailImage(modelId: string): Observable<HttpResponse<Blob>> {
    const path = this.config.ui.modelDetails.getImage.replace('{modelDetailId}', modelId);
    const options = {
      'Content-Type': 'application/blob',
    };

    return this.http.get<Blob>(path, {
      observe: 'response',
      headers: options,
      responseType: 'blob' as 'json',
    });
  }

  /**
   * Creates a device model detail on the server
   *
   * @param deviceModelDetail Device model detail object
   * @returns Created device model stream
   */
  createDeviceModelDetail(deviceModelDetail: DeviceModelDetails): Observable<DeviceModelDetails> {
    const path = this.config.ui.modelDetails.post;

    return this.http.post<DeviceModelDetails>(path, deviceModelDetail);
  }

  /**
   * Updates a device model detail on the server
   *
   * @param deviceModelDetail Device model detail object
   * @returns Update device model stream
   */
  editDeviceModelDetail(deviceModelDetail: DeviceModelDetails): Observable<DeviceModelDetails> {
    const path = this.config.ui.modelDetails.put;

    return this.http.put<DeviceModelDetails>(path, deviceModelDetail);
  }

  getModelDetail(modelDetailsId: string): Observable<DeviceModelDetails> {
    const path = this.config.ui.modelDetails.get.replace('{id}', modelDetailsId);

    return this.http.get<DeviceModelDetails>(path, {});
  }

  /**
   * Requests model details as pageable object
   *
   * @param page current page
   * @param pageSize page size
   * @param name name to be filtered
   * @returns pageable device mode details stream
   */
  getModelDetails(page: number, size: number, name: string): Observable<PagedData<DeviceModelDetails>> {
    const path = this.config.ui.modelDetails.getAll;

    let params: HttpParams = new HttpParams();
    params = params.set('page', page);
    params = params.set('size', size);
    params = params.set('name', name);

    return this.http.get<PagedData<DeviceModelDetails>>(path, {
      params,
    });
  }

  getModels(
    page: number,
    size: number,
    query: string,
    filter?: Array<{ key?: string; value: string }>,
  ): Observable<PagedData<DeviceModel>> {
    const path = this.config.ui.model.find;
    const params: any = {
      page,
      size,
    };

    if (query) {
      params.name = query;
    }

    if (filter && filter.length > 0) {
      filter.forEach((f) => {
        if (f.value) {
          params[f.value] = true;
        }
      });
    }

    return this.http.get<PagedData<DeviceModel>>(path, {
      observe: 'body',
      params,
    });
  }

  getDeviceModelDetailsFile(modelDetailsId: string, fileId: string): Observable<Blob> {
    const options = {
      'Content-Type': 'application/blob',
    };

    const path = this.config.file.model.get.replace('{modeldetailsid}', modelDetailsId).replace('{fileid}', fileId);

    return this.http.get(path, {
      observe: 'body',
      headers: options,
      responseType: 'blob',
    });
  }
}
