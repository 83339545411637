import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { DOCUMENT, NgIf } from '@angular/common';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  ElementRef,
  EmbeddedViewRef,
  Inject,
  Injector,
  OnDestroy,
  Optional,
  Pipe,
  PipeTransform,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResourceType, SecureAction } from '@deprecated/api-interfaces';
import { Subscription } from 'rxjs';
import { OverlayButtonComponent } from '../components/overlay-button/overlay-button.component';
import { SecureActionKey } from '../models';
import { ActionsService, ResourceResolverService } from '../services';

@Pipe({
  name: 'allowed',
  pure: false,
})
export class AllowedPipe implements PipeTransform, OnDestroy {
  private firstRun = true;
  private readonly selectionModeSubscription: Subscription;
  private observer: MutationObserver;
  private embeddedView: EmbeddedViewRef<any>;

  constructor(
    private actionsService: ActionsService,
    private elementRef: ElementRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private resourceResolverService: ResourceResolverService,
    @Optional() private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    @Optional() private ngIfDir: NgIf,
    @Optional() private templateRef: TemplateRef<any>,
    @Optional() private viewContainerRef: ViewContainerRef,
  ) {
    const dialog = this.document.querySelector('spx-dialog');
    if (dialog && actionsService.hasAction(SecureActionKey.NAVIGATION_ADMIN_ACTION_OVERLAY)) {
      setTimeout(() => {
        let element = elementRef.nativeElement?.parentElement;
        while (element) {
          element = element.parentElement ?? element.parentNode;
          if (element?.nodeName.toLowerCase() === 'spx-dialog' && !this.document.querySelector('.action-overlay-button')) {
            const componentPortal = new ComponentPortal(OverlayButtonComponent, undefined, this.injector);
            const header = this.document.querySelector<HTMLElement>('.mat-mdc-dialog-title');

            if (!header) {
              return;
            }

            header.setAttribute('fxlayout', 'row');
            header.style.flexDirection = 'row';

            const bodyPortalHost = new DomPortalOutlet(header, this.componentFactoryResolver, this.appRef, this.injector);
            bodyPortalHost.attach(componentPortal);

            break;
          }
        }
      }, 500);
    }

    this.selectionModeSubscription = this.actionsService.selectionMode$.subscribe((selectionMode: boolean) => {
      if (this.ngIfDir) {
        const isAttached = (this.ngIfDir as any)._context.ngIf;
        if (selectionMode && !isAttached) {
          this.embeddedView = this.viewContainerRef.createEmbeddedView(this.templateRef);
          this.embeddedView.detectChanges();
        } else if (!selectionMode && this.embeddedView) {
          const index = this.viewContainerRef.indexOf(this.embeddedView);
          this.viewContainerRef.remove(index);
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
    if (this.selectionModeSubscription) {
      this.selectionModeSubscription.unsubscribe();
    }
  }

  transform(
    secureAction: string | string[] | SecureAction | Array<SecureAction>,
    resource?: {
      id: string;
      type: string;
    },
  ): boolean {
    const secureActionName = SecureAction.convertToString(secureAction);

    if (this.firstRun) {
      let key: string;
      if (secureActionName instanceof Array) {
        key = secureActionName.join(' OR ');
      } else {
        key = secureActionName;
      }

      this.setAttribute(this.elementRef, key);
      this.firstRun = false;
    } else if (this.embeddedView) {
      const childElement = this.embeddedView.rootNodes[0];
      if (!(childElement instanceof Comment)) {
        childElement.setAttribute('app-secured', secureActionName);
      }
    }

    const snapshotRes = this.route ? this.resourceResolverService.getResource(this.route?.snapshot) : null;
    const pipeRes = resource ? { id: resource?.id, type: ResourceType[resource?.type] } : undefined;

    if (Array.isArray(secureActionName)) {
      return secureActionName.some((key) => this.actionsService.hasAction(key, pipeRes || snapshotRes));
    }

    return this.actionsService.hasAction(secureActionName, pipeRes || snapshotRes);
  }

  private setAttribute(elementRef: ElementRef, secureActionKey: string) {
    if (elementRef.nativeElement instanceof Element) {
      this.elementRef.nativeElement.setAttribute('app-secured', secureActionKey);
    } else if (this.ngIfDir && elementRef.nativeElement instanceof Comment) {
      if ((this.ngIfDir as any)._thenTemplateRef?.createEmbeddedViewImpl) {
        (this.ngIfDir as any)._thenTemplateRef.createEmbeddedViewImpl = new Proxy(
          (this.ngIfDir as any)._thenTemplateRef.createEmbeddedViewImpl,
          {
            apply: function (methodTarget, thisArg, argArray) {
              const embeddedView = methodTarget.apply(thisArg, argArray);
              const childElement = embeddedView.rootNodes[0];
              if (!(childElement instanceof Comment)) {
                childElement.setAttribute('app-secured', secureActionKey);
              }
              return embeddedView;
            },
          },
        );
      }

      if ((this.ngIfDir as any)._elseTemplateRef?.createEmbeddedViewImpl) {
        (this.ngIfDir as any)._elseTemplateRef.createEmbeddedViewImpl = new Proxy(
          (this.ngIfDir as any)._elseTemplateRef.createEmbeddedViewImpl,
          {
            apply: function (methodTarget, thisArg, argArray) {
              const embeddedView = methodTarget.apply(thisArg, argArray);
              const childElement = embeddedView.rootNodes[0];
              if (!(childElement instanceof Comment)) {
                childElement.setAttribute('app-secured', secureActionKey);
              }
              return embeddedView;
            },
          },
        );
      }
    }
  }
}
