import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nullish',
})
export class NullishPipe implements PipeTransform {
  transform<T>(value: T, nullishValue = '---'): T | string {
    return value ?? nullishValue;
  }
}
