import { Injectable } from '@angular/core';

@Injectable()
export class DragDataService {
  private static readonly DATA_FORMAT = 'text/json';

  public hasDragData(event: DragEvent): boolean {
    if (event?.dataTransfer) {
      return event.dataTransfer.items?.length > 0;
    }
    return false;
  }

  public setDragData(event: DragEvent, dragData: unknown): DragEvent {
    const dragDataString: string = JSON.stringify(dragData);

    event.dataTransfer?.setData(DragDataService.DATA_FORMAT, dragDataString);
    event.dataTransfer?.setData(dragDataString, '');

    return event;
  }

  public getDragData(event: DragEvent): unknown {
    if (event?.dataTransfer) {
      const stringData = event.dataTransfer.getData(DragDataService.DATA_FORMAT);
      if (stringData) {
        return JSON.parse(stringData);
      } else if (event.dataTransfer?.items.length === 2) {
        const item = this.findJsonKeyItem(event.dataTransfer.items);
        if (item?.type) {
          return JSON.parse(item.type);
        }
      }
    }

    return undefined;
  }

  private findJsonKeyItem(items: DataTransferItemList): DataTransferItem | undefined {
    if (items[0].type !== DragDataService.DATA_FORMAT) {
      return items[0];
    } else if (items[1].type !== DragDataService.DATA_FORMAT) {
      return items[1];
    }
    return undefined;
  }
}
