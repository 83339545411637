import { PricespiderConfig, PricespiderConfigLoader } from '@ird/ng-tools';
import { Observable, filter, from, map } from 'rxjs';

import { FeatureFlag } from '@deprecated/api-interfaces';
import { UnleashService } from '../core/services/unleash.service';

export class PricespiderFactory extends PricespiderConfigLoader {
  constructor(private unleashService: UnleashService) {
    super();
  }

  getConfig(): Observable<PricespiderConfig> {
    return from(this.unleashService.isEnabled(FeatureFlag.PRICESPIDER, false)).pipe(
      filter((enabled) => enabled),
      map((_enabled) => {
        return {
          apiKey: '1572-61b7b896edb8a800970992d3',
          country: 'US',
          language: 'en',
        } as PricespiderConfig;
      }),
    );
  }
}
