import { DataQualityCheckInfoModel } from './data-quality-check-info.model';
import { DataQuality } from './data-quality.model';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DataQualityInfoDto {
  configured = false;
  enabled = false;
  emails?: Array<string>;
  dataQuality?: DataQuality;
  info?: Record<string, DataQualityCheckInfoModel>;
}
