import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  position = {
    coords: {
      latitude: '',
      longitude: '',
      accuracy: '',
    },
  };

  setLocation(): void {
    navigator.geolocation.getCurrentPosition((pos: GeolocationPosition) => {
      this.position.coords.latitude = pos.coords.latitude.toString();
      this.position.coords.longitude = pos.coords.longitude.toString();
      this.position.coords.accuracy = pos.coords.accuracy.toString();
    });
  }

  getLocationApiString(): string {
    if (this.position.coords.latitude) {
      return 'geo:' + this.position.coords.latitude + ',' + this.position.coords.longitude + ';cgen=gps;u=' + this.position.coords.accuracy;
    } else {
      return '';
    }
  }
}
