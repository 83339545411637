import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { Observable } from 'rxjs';
import { SpxObservableDataSourceCallbackData } from './types';

export type SpxVirtualObservableDataSourceCallback<T> = (lastItem: T | undefined, offset: number) => Observable<SpxObservableDataSourceCallbackData<T>>;

export class SpxVirtualObservableDataSource<T> extends TableVirtualScrollDataSource<T> {
  constructor(protected readonly callback: SpxVirtualObservableDataSourceCallback<T>) {
    super([]);
  }

  public fetchNextBatch(): Promise<ReadonlyArray<T>> {
    return new Promise<ReadonlyArray<T>>((resolve) => {
      this.callback(this.data[this.data.length - 1], this.data.length).subscribe({
        next: (nextBatch) => {
          this.data = [...this.data, ...nextBatch.content];
          resolve(nextBatch.content);
        },
      });
    });
  }
}
