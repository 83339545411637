import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ButtonType } from '../../model/button';
import { Color } from '../../model/color';

/**
 * Dialog Action Definition
 */
export interface DialogAction {
  text: string;
  type?: ButtonType;
  color?: Color;
  icon?: {
    position: string;
    name: string;
  };
  leftAlign?: boolean;
  action?(): Subscription;
  disable?(): Observable<boolean> | FormGroup | FormControl;
}

/**
 * Base Dialog Action Result
 */
export interface DialogActionResult {
  data?: unknown;
}

/**
 * Special Confirm Dialog Action Result
 */
export interface DialogConfirmActionResult extends DialogActionResult {
  confirmed: boolean;
  rejected: boolean;
}

/**
 * CONSTANTS
 */
export const DIALOG_ACTION_CONFIRM_RESULT: DialogConfirmActionResult = {
  confirmed: true,
  rejected: false,
};

export const DIALOG_ACTION_REJECT_RESULT: DialogConfirmActionResult = {
  confirmed: false,
  rejected: true,
};
