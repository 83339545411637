import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'matchCypress' })
export class MatchCypressPipe implements PipeTransform {
  private regex = new RegExp(/cypress-test|cypress-sensor|cypress-tenant\S/g);

  transform(testString: string): boolean {
    return this.regex.test(testString);
  }
}
