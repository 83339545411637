import { DOCUMENT } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { usersnapInitializer } from './initializer/usersnap.initializer';
import { UsersnapConfig } from './model/usersnap-config';
import { US_CONFIG } from './providers/usersnap-config.provider';
import { UsersnapUserDataFakeLoader, UsersnapUserDataLoader } from './providers/usersnap-userdata.provider';

@NgModule()
export class UsersnapModule {
  static forRoot(
    settings: UsersnapConfig = {
      userApiKey: undefined,
    }
  ): ModuleWithProviders<UsersnapModule> {
    return {
      ngModule: UsersnapModule,
      providers: [
        {
          provide: US_CONFIG,
          useValue: {
            userApiKey: settings.userApiKey,
            globalApiKey: settings.globalApiKey ?? 'af717ea0-8416-41f2-ab39-282736025f93',
          },
        },
        settings.userDataLoader || { provide: UsersnapUserDataLoader, useClass: UsersnapUserDataFakeLoader },
        {
          provide: APP_INITIALIZER,
          multi: true,
          useFactory: usersnapInitializer,
          deps: [US_CONFIG, DOCUMENT, UsersnapUserDataLoader],
        },
      ],
    };
  }
}
