// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  height: 100%;
  overflow: hidden;
}
:host mat-card {
  margin: 24px;
  overflow-y: hidden;
}
:host mat-card-content {
  height: 100%;
}
:host spx-table {
  overflow-y: auto;
}
:host .table-scroll {
  height: 100%;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImdlbmVyaWMtdGFibGUuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxjQUFBO0VBQ0EsWUFBQTtFQUNBLGdCQUFBO0FBQ0Y7QUFDRTtFQUNFLFlBQUE7RUFDQSxrQkFBQTtBQUNKO0FBRUU7RUFDRSxZQUFBO0FBQUo7QUFHRTtFQUNFLGdCQUFBO0FBREo7QUFJRTtFQUNFLFlBQUE7QUFGSiIsImZpbGUiOiJnZW5lcmljLXRhYmxlLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICBkaXNwbGF5OiBibG9jaztcbiAgaGVpZ2h0OiAxMDAlO1xuICBvdmVyZmxvdzogaGlkZGVuO1xuXG4gIG1hdC1jYXJkIHtcbiAgICBtYXJnaW46IDI0cHg7XG4gICAgb3ZlcmZsb3cteTogaGlkZGVuO1xuICB9XG5cbiAgbWF0LWNhcmQtY29udGVudCB7XG4gICAgaGVpZ2h0OiAxMDAlO1xuICB9XG5cbiAgc3B4LXRhYmxlIHtcbiAgICBvdmVyZmxvdy15OiBhdXRvO1xuICB9XG5cbiAgLnRhYmxlLXNjcm9sbCB7XG4gICAgaGVpZ2h0OiAxMDAlO1xuICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/shared/ng/common/src/lib/components/generic-table/generic-table.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,YAAA;EACA,gBAAA;AACF;AACE;EACE,YAAA;EACA,kBAAA;AACJ;AAEE;EACE,YAAA;AAAJ;AAGE;EACE,gBAAA;AADJ;AAIE;EACE,YAAA;AAFJ;AACA,4wBAA4wB","sourcesContent":[":host {\n  display: block;\n  height: 100%;\n  overflow: hidden;\n\n  mat-card {\n    margin: 24px;\n    overflow-y: hidden;\n  }\n\n  mat-card-content {\n    height: 100%;\n  }\n\n  spx-table {\n    overflow-y: auto;\n  }\n\n  .table-scroll {\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
