export enum WarningStatusEnum {
  NO_STATUS = 'NO_STATUS',
  OK = 'OK',
  WARN = 'WARN',
  ERROR = 'ERROR',
  INFO = 'INFO',
  LOADING = 'LOADING',
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class WarningStatusResponse {
  status: WarningStatusEnum;
}
