import { SecureAction } from '../secure-actions';

export enum SensorTypeAction {
  EDIT_DEFAULT_TEXTS = 'admin:sensor_types:default_texts|edit',
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class SensorTypeSecureAction extends SecureAction {
  public static readonly EDIT_DEFAULT_TEXTS: SecureAction = new SensorTypeSecureAction('admin:sensor_types:default_texts|edit');

  private constructor(name: string) {
    super(name);
  }
}
