import { Moment } from 'moment/moment';
import { DataConversionCalculationDto } from './data-conversion.model';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DataConversionCacheItem {
  data: DataConversionCalculationDto;
  expiresAt: Moment;
}
