import { DashboardConfigEntry } from './dashboard-config-entry.model';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class CreateDashboardConfigV2 {
  configEntries?: Array<DashboardConfigEntry> = [];
  template: boolean;
  title: string;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DashboardConfigV2 extends CreateDashboardConfigV2 {
  id: string;
  createdBy: string;
  createNanosSinceEpoch: number;
  modifiedNanosSinceEpoch: number;
  createdByName?: string;
}
