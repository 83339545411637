import { ValueType } from './data-conversion-user-setting';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DataConversionDto {
  sourceUnit: string;
  valueType: ValueType;
  targetUnit?: string;
  id?: string;
  formula?: string;
  sensorPrecision?: number;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DataConversionCalculationDto {
  toReferenceUnit: DataConversionDto;
  fromReferenceUnit: DataConversionDto;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DataConversionReferenceUnit {
  id: string;
  valueType: ValueType;
  unit: string;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DataConversionParameter {
  unit: string;
  valueType: ValueType;
  sensorPrecision?: number;
}
