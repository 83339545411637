import { SecureAction } from '../../secure-actions';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class AnalyticsDataQualitySecureAction extends SecureAction {
  public static readonly CHANGE_STATUS: SecureAction = new AnalyticsDataQualitySecureAction('device:analytics:data_quality|change_status');
  public static readonly MANAGE: SecureAction = new AnalyticsDataQualitySecureAction('device:analytics:data_quality|manage');
  public static readonly VIEW_INTERNAL: SecureAction = new AnalyticsDataQualitySecureAction('device:analytics:data_quality|view_internal');
  public static readonly VIEW: SecureAction = new AnalyticsDataQualitySecureAction('device:analytics:data_quality|view');

  private constructor(name: string) {
    super(name);
  }
}
