/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DataQuality {
  checks: Array<DataQualityCheck>;
  deviceId: string;
  id: string;
  nanosSinceEpoch: number;
}

export enum DataQualityCheckResultType {
  FAILED = 'FAILED',
  NOT_EXECUTED = 'NOT_EXECUTED',
  OK = 'OK',
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DataQualityCheck {
  errors: Array<DataQualityError>;
  info: Record<string, string | number | boolean>;
  key: string;
  result: DataQualityCheckResultType | string;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DataQualityError {
  name: string;
  sensorId?: string;
  type: string;
}
