import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BaseModule } from '../base';
import * as deTranslations from './assets/i18n/de-lang.json';
import * as enTranslations from './assets/i18n/en-lang.json';
import { ProgressBarComponent } from './components/progress-bar.component';

@NgModule({
  declarations: [ProgressBarComponent],
  exports: [ProgressBarComponent],
  imports: [CommonModule, MatProgressBarModule, MatCardModule, TranslateModule.forChild(), FlexLayoutModule, BaseModule],
})
export class ProgressBarModule {
  constructor(private translate: TranslateService) {
    this.translate.setTranslation('en', enTranslations, true);
    this.translate.setTranslation('de', deTranslations, true);
  }
}
