import { SecureAction } from '../secure-actions';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class AlarmAcknowledgeSecureAction extends SecureAction {
  public static readonly VIEW: SecureAction = new AlarmAcknowledgeSecureAction('device:alarms|acknowledge');

  private constructor(name: string) {
    super(name);
  }
}
