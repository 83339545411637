import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Sensor } from '@deprecated/api-interfaces';
import { Observable } from 'rxjs';
import { ChartConfiguration } from '../models/chart-configuration.model';
import { DeviceData } from '../models/device-data';
import DeviceSensorModel from '../models/device-sensor.model';
import { GaugeConfiguration } from '../models/gauge-configuration.model';
import { ViewConfiguration } from '../models/view-configuration.model';
import { DeviceViewConfigRepositoryHttp } from '../repositories/device-view-config.repository.http';

/**
 * Service to get and save each device configuration
 */
@Injectable({
  providedIn: 'root',
})
export class DeviceViewConfigService {
  deviceConfigList: ChartConfiguration[];
  gaugeConfigList: GaugeConfiguration[];
  config: ViewConfiguration;
  viewConfig: any;
  unconfigSensors: Sensor[];
  lastdata: DeviceData[];
  private deviceSensorsList: Array<DeviceSensorModel>;

  constructor(private deviceViewConfigHttp: DeviceViewConfigRepositoryHttp) {
    this.lastdata = [];
  }

  getDeviceViewConfig(deviceId: string): Observable<ViewConfiguration> {
    return this.deviceViewConfigHttp.getDeviceViewConfiguration(deviceId);
  }

  setDeviceConfig(deviceConfig: ChartConfiguration[]): void {
    if (deviceConfig) {
      this.deviceConfigList = deviceConfig;
    } else {
      this.deviceConfigList = null;
    }
  }

  clearChartConfigurations() {
    this.deviceConfigList = null;
  }

  setGaugeConfig(gaugeConfig: GaugeConfiguration[]): void {
    if (gaugeConfig) {
      this.gaugeConfigList = gaugeConfig;
    } else {
      this.gaugeConfigList = null;
    }
  }

  clearGaugeConfig() {
    this.gaugeConfigList = [];
  }

  getViewConfig(): any {
    return this.viewConfig;
  }

  setViewConfigList(viewConfig: any): void {
    this.viewConfig = viewConfig;
  }

  getDeviceConfigList(): ChartConfiguration[] {
    if (this.deviceConfigList) {
      return this.deviceConfigList;
    }
  }

  getGaugeConfigList(): GaugeConfiguration[] {
    if (this.gaugeConfigList) {
      return this.gaugeConfigList;
    }
  }

  /**
   * Get Devices Last data Service
   */
  getLastData(deviceId: string, sensorId: string): Observable<DeviceData> {
    return this.deviceViewConfigHttp.getDeviceLastData(deviceId, sensorId);
  }
  /**
   * Get last data of device to check if was active in the last 20 min
   */
  getLastDataFromAllSensors(deviceId: string): Observable<DeviceData[]> {
    return this.deviceViewConfigHttp.getLastDataFromAllSensors(deviceId);
  }

  setDeviceSensorsList(sensorsList: Array<DeviceSensorModel>) {
    this.deviceSensorsList = sensorsList;
  }

  getDeviceSensorsList(): Array<DeviceSensorModel> {
    return this.deviceSensorsList;
  }

  /**
   * set Chart configuration
   * @param configuration object
   */
  setDeviceViewConfigurationSettings(config: ViewConfiguration): Observable<HttpResponse<ViewConfiguration>> {
    return this.deviceViewConfigHttp.setDeviceViewConfiguration(config);
  }
}
