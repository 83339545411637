export type Palette = 'primary' | 'accent' | 'warn';
export type Hue = '50' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900' | 'A100' | 'A200' | 'A400' | 'A700';
export type Status = 'info' | 'success' | 'warn' | 'alert' | 'error' | 'absent';

export class ColorDefinition {
  hex!: string;
  contrast!: string;
}

export class PaletteColorDefinition extends ColorDefinition {
  name!: Hue;
}

export class StatusColors {
  alert!: ColorDefinition;
  error!: ColorDefinition;
  info!: ColorDefinition;
  success!: ColorDefinition;
  warn!: ColorDefinition;
  absent!: ColorDefinition;
}

export class ColorPalette {
  baseColor!: string;
  baseHue!: Hue;

  default!: Hue;
  lighter!: Hue;
  darker!: Hue;

  colors: PaletteColorDefinition[] = [];
}

export class MatColorTheme {
  primary!: ColorPalette;
  accent!: ColorPalette;
  warn!: ColorPalette;

  status!: StatusColors;
  app!: ColorDefinition;
  menu!: ColorDefinition;
}

export class HueMapItem {
  hue!: Hue;
  saturation?: number;
  brightness?: number;
}
