import { ThresholdAlarmConfiguration } from '../alarm';
import { ConfiguredThresholdDTO, DefaultThresholdColor, KpiThreshold, ThresholdDTO, ThresholdLevel } from '../shared';

type ThresholdMapperType = ConfiguredThresholdDTO | ThresholdDTO;

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class ThresholdMapperUtils {
  public static mapAlertThresholdsToThresholdDTO(
    alerts: Array<ThresholdAlarmConfiguration>,
    includeValuesToTrigger = false,
  ): Array<ThresholdMapperType> | null {
    const thresholds: Array<ThresholdMapperType> = [];

    if (!alerts || alerts.length === 0) {
      return null;
    }

    [ThresholdLevel.INFO, ThresholdLevel.WARN, ThresholdLevel.ERROR].forEach((level) => {
      const basicThreshold = new ConfiguredThresholdDTO();
      basicThreshold.type = level;

      const configuredAlert: ThresholdAlarmConfiguration | undefined = alerts?.find((alert) => alert?.type === level);

      if (!configuredAlert) {
        return;
      }

      const { thresholdMax, thresholdMin, type, enabled, numValuesToTrigger } = configuredAlert;

      if (includeValuesToTrigger) {
        basicThreshold.numValuesToTrigger = numValuesToTrigger;
      }

      if (typeof thresholdMin === 'number') {
        thresholds.push({ ...basicThreshold, min: thresholdMin, type: type as ThresholdLevel, enabled });
      }

      if (typeof thresholdMax === 'number') {
        thresholds.push({ ...basicThreshold, max: thresholdMax, type: type as ThresholdLevel, enabled });
      }
    });

    return thresholds.sort((a, b) => {
      const aValue = a.min ?? a.max;
      const bValue = b.min ?? b.max;

      return aValue && bValue ? aValue - bValue : aValue || bValue || 0;
    });
  }

  public static mapKpiThresholdsToThresholdDTO(kpiThresholds: Array<KpiThreshold> | undefined): Array<ThresholdDTO> {
    const thresholdColorEntries = Object.entries(DefaultThresholdColor);

    // Determine the direction based on the first element's color
    const direction = kpiThresholds?.[0]?.color === DefaultThresholdColor.SUCCESS ? 'max' : 'min';

    // If kpiThresholds is undefined or empty, return an empty array
    if (!kpiThresholds || kpiThresholds.length === 0) {
      return [];
    }

    return kpiThresholds.map((threshold) => {
      const { color, startValue } = threshold;
      const mappedThreshold: ThresholdDTO = {
        enabled: true,
        type: thresholdColorEntries.find((entry) => entry[1] === color)?.[0] as ThresholdLevel,
      };

      // Ensure startValue is a valid number and assign it correctly
      if (startValue !== undefined && startValue !== null) {
        mappedThreshold[direction] = startValue; // Ensure startValue is a valid number
      }

      return mappedThreshold;
    });
  }

  public static mapThresholdDTOsToKpiThresholds(thresholdDTOs: Array<ThresholdDTO>): Array<KpiThreshold> {
    return thresholdDTOs.map(
      (threshold) =>
        ({
          startValue: threshold.min ?? threshold.max,
          color: threshold.type && DefaultThresholdColor[threshold.type],
        }) as KpiThreshold,
    );
  }
}
