export enum ThresholdLevel {
  ERROR = 'ERROR',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARN = 'WARN',
  MIN_MAX = 'MIN_MAX',
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export const getAlarmLevelIcon = (level?: ThresholdLevel): string => {
  switch (level?.valueOf()) {
    case ThresholdLevel.INFO:
      return 'info';
    case ThresholdLevel.ERROR:
      return 'cancel';
    case ThresholdLevel.SUCCESS:
      return 'check_circle_outline';
    case ThresholdLevel.WARN:
    default:
      return 'warning';
  }
};
