import { Cron } from './cron.model';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class CronDto extends Cron {
  applicable: boolean;
  reason: string;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class CronConfigurationDto extends Cron {
  cronId: string;
  deviceId: string;
  enabled: boolean;
}
