import { Component } from '@angular/core';

@Component({
  selector: 'shared-warranty-terms',
  templateUrl: './warranty-terms.component.html',
  styleUrls: ['./warranty-terms.component.scss'],
})
export class WarrantyTermsComponent {
  constructor() {}
}
