import { MatSort } from '@angular/material/sort';

export interface ISpxSortedDataSource {
  getSort(): MatSort | null;
  setSort(sort: MatSort | null): void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function instanceOfSortedDataSource(object: any): object is ISpxSortedDataSource {
  const properties = ['getSort', 'setSort'];
  return !!object && properties.every((property: string) => property in object);
}
