import moment from 'moment';
import { DataConversionCacheItem } from '../models/data-conversion-cache-item.model';
import { DataConversionCalculationDto } from '../models/data-conversion-calculation-dto.model';

export class DataConversionCacheService {
  private _itemExpiresInSeconds = 60;

  private cache: Map<string, DataConversionCacheItem>;

  constructor() {
    this.cache = new Map<string, DataConversionCacheItem>();
  }

  set(key: string, dataConversionCalculationDto: DataConversionCalculationDto): void {
    if (this.cache.has(key)) {
      this.clear(key);
    }

    this.cache.set(key, {
      data: dataConversionCalculationDto,
      expiresAt: moment().add(this._itemExpiresInSeconds, 'seconds'),
    });
  }

  get(key: string): DataConversionCalculationDto | void {
    const data = this.cache.get(key);
    if (data) {
      if (moment().isAfter(moment(data.expiresAt))) {
        return this.clear(key);
      }
      return data.data;
    }
  }

  set itemExpiresInSeconds(value: number) {
    this._itemExpiresInSeconds = value;
  }

  clear(key: string): void {
    this.cache.delete(key);
  }
}
