import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const language = localStorage.getItem('language');
    request = request.clone({
      setHeaders: {
        'X-Language': language ?? 'en',
      },
    });
    return next.handle(request);
  }
}
