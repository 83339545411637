import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class MaintenanceReportRepositoryHttp {
  constructor(
    private http: HttpClient,
    @Inject('ENVIRONMENT') private environment: any,
  ) {}

  getMaintenanceReportPDF(id: string): Observable<HttpResponse<any>> {
    const endpoint = this.environment.file.reports.getPdf;
    return this.http.get(endpoint.replace('{reportId}', id), {
      responseType: 'blob',
      observe: 'response',
      headers: this.getOptions(),
    });
  }

  sendMaintenanceReportEmail(reportId: string, subscribers: string[]): Observable<HttpResponse<any>> {
    const endpoint = this.environment.maintenance.report.sendReportEmail;
    return this.http.post(endpoint.replace('{reportId}', reportId), subscribers, {
      observe: 'response',
      headers: this.getOptions(),
    });
  }

  deleteMaintenanceReport(id: number): Observable<HttpResponse<any>> {
    const endpoint = this.environment.maintenance.report.actionDelete;
    return this.http.delete(endpoint.replace('{id}', String(id)), {
      observe: 'response',
      headers: this.getOptions(),
    });
  }

  private getOptions(): any {
    return {
      'Content-Type': 'application/json',
    };
  }
}
