export enum FeatureNames {
  SHOP = 'shop',
  MODEL_CREATION = 'model_creation',
  CONNECTED_SERVICES = 'connected_services',
  SERVICE_POINT = 'service_point',
  USER_REGISTRATION = 'UserRegistration',
}

export enum ShopFeatures {
  ENABLED = 'enabled',
  LINK = 'link',
  NAME = 'name',
}

export enum ModelCreationFeatures {
  AUTO_CREATE_MODEL = 'autoCreateModel',
  AUTO_CREATE_MODEL_DETAIL = 'autoCreateModelDetail',
  NAME = 'name',
  REGEX = 'regex',
}

export enum ConnectedServicesFeatures {
  NAME = 'name',
  MONITORING_ENABLED = 'monitoringEnabled',
  ASSET_MANAGEMENT_ENABLED = 'assetManagementEnabled',
}

export enum ServicePointFeatures {
  APP_URL = 'appUrl',
  DOMAIN = 'domain',
  ENABLED = 'enabled',
  IMPRINT_URL = 'imprintUrl',
  NAME = 'name',
  BRAND_NAME = 'brandName',
  APP_NAME = 'appName',
  INTERNAL_CONTACT_EMAIL = 'internalContactEmail',
  SERVICE_DEPARTMENT_EMAIL = 'serviceDepartmentEmail',
  PURCHASING_DEPARTMENT_EMAIL = 'purchasingDepartmentEmail',
  SERVICE_EMAIL_SENDGRID_TEMLATE_ID = 'serviceEmailSendgridTemplateId',
  CONTACT_EMAIL_SENDGRID_TEMPLATE_ID = 'contactEmailSendgridTemplateId',
  PURCHASING_DEPARTMENT_SENDGRID_TEMPLATE_ID = 'purchasingDepartmentSendgridTemplateId',
  ELOQUA_TRANSFER_ENABLED_TILE = 'eloquaTransferEnabled',
  ELOQUA_BRAND_CODE = 'eloquaBrandCode',
  DOCUMENTS_TILE = 'documentsTileEnabled',
  SPARE_PART_TILE = 'sparePartTileEnabled',
  CONTACT_TILE = 'contactTileEnabled',
  CREATE_ACCOUNT_TILE = 'createAccountTileEnabled',
  SERIAL_NUMBER_SEARCH_TILE = 'serialNumberPageEnabled',
  LOGIN_TILE = 'loginTileEnabled',
  SCAN_TILE = 'scanTileEnabled',
  WARRANTY_EXTENSION = 'warrantyExtensionTileEnabled',
  WARRANTY_EXTENSION_TILE_VISIBLE_IF_ELIGIBLE = 'warrantyExtensionTileVisibleIfEligable',
  WARRANTY_EXTENSION_URL = 'warrantyExtensionTileUrl',
  WEBSITE_TILE = 'websiteTileEnabled',
  TUBE_SETTINGS_TILE = 'tubeSettingsTileEnabled',
  NOTES_TILE = 'notesTileEnabled',
  ECOPLANT_TILE = 'ecoplantTileEnabled',
  MY_ASSETS_TILE = 'myAssetsEnabled',
  PRICE_SPIDER = 'priceSpiderEnabled',
  ASSET_IMAGE = 'assetImageEnabled',
}

export enum UserRegistration {
  name = 'user_registration',
  SELF_REGISTRATION_ENABLED = 'selfRegistrationEnabled',
}
