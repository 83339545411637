import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import { BaseModule } from '../base';
import { NotificationComponent } from './notification.component';
import { DEFAULT_NOTIFICATION_CONFIG, NOTIFICATION_CONFIG } from './provider/notification.provider';
import { NotificationService } from './services/notification.service';

@NgModule({
  declarations: [NotificationComponent],
  imports: [CommonModule, MatSnackBarModule, MatIconModule, TranslateModule.forChild(), BaseModule],
  providers: [
    {
      provide: NOTIFICATION_CONFIG,
      useValue: DEFAULT_NOTIFICATION_CONFIG,
    },
    NotificationService,
  ],
})
export class NotificationModule {}
