/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class TableSensorListWidget {
  id: string;
  name?: string;
  type?: string;
  virtual?: boolean;
  cron?: boolean;
  lastActiveNanos?: number;
  booleanType?: boolean;
  metadata?: { [key: string]: string };
}
/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class SensorsListCSVHeaders {
  Sensor_Name: string;
  Sensor_value: number | string;
  Sensor_Unit: number | string;
  Subsystem: string;
  Timeframe: string;
  'Sortable Timeframe (Unix Timestamp ms)': number;
}
