import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Action, ResourceType } from '@deprecated/api-interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PageableRequest, PagedData } from '../models';
import { HttpUtils } from '../utils';

@Injectable()
export class ActionsHttpRepository {
  constructor(
    private http: HttpClient,
    @Inject('ENVIRONMENT') private config: any,
  ) {}

  public getRolesByResourceIdAndType(resourceId: string, type: ResourceType): Observable<string[]> {
    const path = this.config.nest.roles.getRolesByResourceIdAndType.replace('{id}', resourceId).replace('{type}', type);

    return this.http.get<string[]>(path);
  }

  public getByRightId(id: string): Observable<Action[]> {
    const path = this.config.actions.getByRightId.replace('{id}', id);
    const pagination: PageableRequest = { size: 5000, page: 0 };
    const params: HttpParams = HttpUtils.toHttpParams(pagination);

    return this.http.get<PagedData<Action>>(path, { params }).pipe(map((a: PagedData<Action>) => a.content));
  }

  public create(action: Action): Observable<Action> {
    const headers: HttpHeaders = new HttpHeaders().append('Content-Type', 'application/json');

    return this.http.post<Action>(this.config.actions.post, action, {
      observe: 'body',
      headers,
    });
  }

  public update(action: Action): Observable<Action> {
    const path = this.config.actions.put.replace('{actionId}', action.id);
    return this.http.put<Action>(path, action);
  }

  public deleteAction(actionId: string): Observable<void> {
    const path = this.config.actions.delete.replace('{actionId}', actionId);

    return this.http.delete<void>(path, {
      observe: 'body',
    });
  }

  public assignRight(actionId: string, rightId: string): Observable<any> {
    const path = this.config.actions.assignRight.replace('{actionId}', actionId).replace('{rightId}', rightId);

    return this.http.post<Action>(path, null, { observe: 'body' });
  }

  public unassignRight(actionId: string, rightId: string): Observable<any> {
    const path = this.config.actions.unassignRight.replace('{actionId}', actionId).replace('{rightId}', rightId);

    return this.http.delete<Action>(path, { observe: 'body' });
  }

  public getAll(): Observable<Action[]> {
    const path = this.config.actions.get;
    const pagination: PageableRequest = { size: 5000, page: 0 };
    const params: HttpParams = HttpUtils.toHttpParams(pagination);

    return this.http.get<PagedData<Action>>(path, { params }).pipe(map((a: PagedData<Action>) => a.content));
  }

  getUserActions(): Observable<Action[]> {
    const path = this.config.actions.getUserActions;
    return this.http.get<Action[]>(path);
  }
}
