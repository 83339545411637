import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CustomValidators } from '../validators';

interface PasswordPart {
  startSet: boolean;
  passwordPart: string;
}

@Injectable({ providedIn: 'root' })
export class PasswordErrorService {
  constructor(private translateService: TranslateService) {}

  createFormControl() {
    return new FormControl('', [
      Validators.required,
      Validators.minLength(10),
      CustomValidators.mustContainUpperCase({ mustContainUpperCase: true }),
      CustomValidators.mustContainLowerCase({ mustContainLowerCase: true }),
      CustomValidators.mustContainSpecialCharacter({ mustContainSpecialCharacter: true }),
      CustomValidators.mustContainNumber({ mustContainNumber: true }),
    ]);
  }

  public createPasswordMatchValidator(passwordName: string, passwordRepeatName: string) {
    return (control: AbstractControl) => {
      if (control.get(passwordName).value !== control.get(passwordRepeatName).value) {
        if (control.get(passwordRepeatName).errors) {
          control.get(passwordRepeatName).errors.notMatching = true;
        } else {
          control.get(passwordRepeatName).setErrors({ notMatching: true });
        }
      } else {
        if (control.get(passwordRepeatName).errors) {
          control.get(passwordRepeatName).setErrors({ notMatching: null });
          control.get(passwordRepeatName).updateValueAndValidity({ onlySelf: true });
        } else {
          control.get(passwordRepeatName).setErrors(null);
        }
      }
      return null;
    };
  }

  public getPasswordErr(control: AbstractControl) {
    let result = '';
    let minLength = false;
    const startSet = false;
    if (control.hasError('required')) {
      return this.translateService.instant('ADMINISTRATION.VALIDATIONS.REQUIREMENTS');
    }

    if (control.hasError('minlength')) {
      result += this.translateService.instant('ADMINISTRATION.VALIDATIONS.ATLEAST_TEN_CHARS') + ' ';
      result += this.translateService.instant('ADMINISTRATION.VALIDATIONS.CHARACTERS') + ' ';
      minLength = true;
    }

    const upper = this.getUpperPart(control, minLength, startSet);
    const lower = this.getLowerPart(control, minLength, upper.startSet);
    const special = this.getSpecialPart(control, minLength, lower.startSet);
    const number = this.getNumberPart(control, minLength, special.startSet);

    result += upper.passwordPart;
    result += lower.passwordPart;
    result += special.passwordPart;
    result += number.passwordPart;

    if (upper.passwordPart || lower.passwordPart || special.passwordPart || number.passwordPart) {
      result = result.substring(0, result.length - 2);
      result += ' ';
      result += this.translateService.instant('ADMINISTRATION.VALIDATIONS.CHARACTER');
    }

    return result;
  }

  private getStartingPart(control: AbstractControl, minLength: boolean, startSet: boolean): PasswordPart {
    let startingPart = '';
    if (!startSet) {
      startSet = true;
      if (minLength) {
        startingPart += this.translateService.instant('ADMINISTRATION.VALIDATIONS.WITH') + ' ';
      } else {
        startingPart += this.translateService.instant('ADMINISTRATION.VALIDATIONS.CONTAINING') + ' ';
      }
    }

    return {
      passwordPart: startingPart,
      startSet: startSet,
    };
  }

  private getUpperPart(control: AbstractControl, minLength: boolean, startSet: boolean): PasswordPart {
    let passwordPart = '';
    if (control.hasError('mustContainUpperCase')) {
      const startingPart = this.getStartingPart(control, minLength, startSet);
      startSet = startingPart.startSet;

      passwordPart += startingPart.passwordPart + this.translateService.instant('ADMINISTRATION.VALIDATIONS.UPPER') + ', ';
    }

    return {
      startSet: startSet,
      passwordPart: passwordPart,
    };
  }

  private getLowerPart(control: AbstractControl, minLength: boolean, startSet: boolean): PasswordPart {
    let passwordPart = '';
    if (control.hasError('mustContainLowerCase')) {
      const startingPart = this.getStartingPart(control, minLength, startSet);
      startSet = startingPart.startSet;

      passwordPart += startingPart.passwordPart + this.translateService.instant('ADMINISTRATION.VALIDATIONS.LOWER') + ', ';
    }

    return {
      startSet: startSet,
      passwordPart: passwordPart,
    };
  }

  private getSpecialPart(control: AbstractControl, minLength: boolean, startSet: boolean): PasswordPart {
    let passwordPart = '';
    if (control.hasError('mustContainSpecialCharacter')) {
      const startingPart = this.getStartingPart(control, minLength, startSet);
      startSet = startingPart.startSet;

      passwordPart += startingPart.passwordPart + this.translateService.instant('ADMINISTRATION.VALIDATIONS.SPECIAL') + ', ';
    }

    return {
      startSet: startSet,
      passwordPart: passwordPart,
    };
  }

  private getNumberPart(control: AbstractControl, minLength: boolean, startSet: boolean): PasswordPart {
    let passwordPart = '';
    if (control.hasError('mustContainNumber')) {
      const startingPart = this.getStartingPart(control, minLength, startSet);
      startSet = startingPart.startSet;

      passwordPart += startingPart.passwordPart + this.translateService.instant('ADMINISTRATION.VALIDATIONS.NUMBER') + ', ';
    }

    return {
      startSet: startSet,
      passwordPart: passwordPart,
    };
  }

  getConfirmPwdErr(control: AbstractControl) {
    if (control.hasError('required')) {
      return this.translateService.instant('ADMINISTRATION.VALIDATIONS.REQUIRED');
    } else if (control.hasError('notMatching')) {
      return this.translateService.instant('ADMINISTRATION.VALIDATIONS.DO_NOT_MATCH');
    } else {
      return '';
    }
  }
}
