import { ApiProperty } from '../../decorators/decorators';
import { BasicDevice } from '../device';
import { Group } from '../group';

/* shared/models/location.model */
/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class OpcuaClientModel {
  id: string;
  deviceId?: string;
  config?: string;
  monitoredNodes: string[];
  customNodes: string[];
  namespaces?: string[];
  version: string;
  description: string;
  metadata: OpcuaClientMetadata;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class OpcuaClientMetadata {
  mqtt_server_status: string;
  namespace_selection_disabled?: string;
  security_mode?: string;
  monitoring_mode?: string;
  opcua_server_url: string;
  namespace: string;
  enable_logs: string;
  pull_frequency?: string;
  opcua_server_status: string;
  'running.version': string;
  opcua_proxy_system_time_ms?: string;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface IClientConfigTreeNodes {
  nodeId: string;
  namespace: number;
  browseName: string;
  displayName: string;
  children: {
    nodeId: string;
    namespace: number;
    browseName: string;
    displayName: string;
    children: {
      nodeId: string;
      namespace: number;
      browseName: string;
      displayName: string;
      children: IClientConfigTreeChildrenNodes[];
    }[];
  }[];
}

/**
 * separate interface was required as Location interface is different for existing Device interface,
 * While the response for Device has different structure which has been added in below IOPCUADeviceLocation interface
 */
/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface IClientConfigTreeChildrenNodes {
  nodeId: string;
  namespace: number;
  browseName: string;
  displayName: string;
  children: IClientConfigTreeChildrenNodes[];
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class IOpcuaClientDevice extends BasicDevice {
  children: Array<IOpcuaClientDevice>;
  position: number;

  @ApiProperty({
    type: 'Map<string, string>',
  })
  configuration: Record<string, string>;
  customer: string;
  description: string;
  groups: Group[];
  groupsV2: string[];
  idAsString: string;
  manufacturerId: string;
  lastActiveNanoSeconds: number;
  parent: IOpcuaClientDevice;
  isOnline: string;
  location: IOPCUADeviceLocation;
  locations: IOPCUADeviceLocation[];
  notes: string;
  pictureId: string;
  status: string;
  thumbnailId: string;
  time: string;
  type: string;
  usersV2: string[];
  modelId: string;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class IOPCUADeviceLocation {
  devices?: string[];
  children?: string[];
  description?: string;
  groups?: string[];
  groupsV2?: string[];
  id?: string;
  idAsString?: string;
  name?: string;
  numberOfDevices?: number;
  parent?: IOPCUADeviceLocation | string;
  users?: string[];
  usersV2?: string[];
  treeName?: any;
  deviceCount?: number;
  subLocationCount?: number;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface INodeInfo {
  node_id: string;
  sampling_rate: string;
  value: string;
}
