import { ApiProperty } from '../../../decorators/decorators';
import { KpiThreshold } from '../../shared';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class ConfiguredKpi {
  @ApiProperty()
  name: string;

  @ApiProperty()
  version: number;

  @ApiProperty()
  info: string;

  @ApiProperty()
  description: string;

  @ApiProperty()
  unit: string;

  @ApiProperty()
  timeRange: string;

  @ApiProperty()
  enabled: boolean;

  @ApiProperty({
    isArray: true,
    type: KpiThreshold,
  })
  thresholds: KpiThreshold[];

  isValid?: boolean = true;

  actionCompleted: boolean;
  @ApiProperty()
  displayedName: string;
}
