// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host mat-select {
  /* stylelint-disable-next-line stylelint-plugins/no-hardcoded-colors */
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  padding-bottom: 4px;
  width: 42px;
}
:host .range-minute-selector {
  margin-left: 12px;
}
:host .range-hour-selector {
  margin-left: 20px;
  margin-right: 12px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRpbWUtdmFsdWUtcGlja2VyLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFO0VBQ0Usc0VBQUE7RUFDQSw0Q0FBQTtFQUNBLG1CQUFBO0VBQ0EsV0FBQTtBQUFKO0FBR0U7RUFDRSxpQkFBQTtBQURKO0FBSUU7RUFDRSxpQkFBQTtFQUNBLGtCQUFBO0FBRkoiLCJmaWxlIjoidGltZS12YWx1ZS1waWNrZXIuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XG4gIG1hdC1zZWxlY3Qge1xuICAgIC8qIHN0eWxlbGludC1kaXNhYmxlLW5leHQtbGluZSBzdHlsZWxpbnQtcGx1Z2lucy9uby1oYXJkY29kZWQtY29sb3JzICovXG4gICAgYm9yZGVyLWJvdHRvbTogMXB4IHNvbGlkIHJnYmEoMCwgMCwgMCwgMC40Mik7XG4gICAgcGFkZGluZy1ib3R0b206IDRweDtcbiAgICB3aWR0aDogNDJweDtcbiAgfVxuXG4gIC5yYW5nZS1taW51dGUtc2VsZWN0b3Ige1xuICAgIG1hcmdpbi1sZWZ0OiAxMnB4O1xuICB9XG5cbiAgLnJhbmdlLWhvdXItc2VsZWN0b3Ige1xuICAgIG1hcmdpbi1sZWZ0OiAyMHB4O1xuICAgIG1hcmdpbi1yaWdodDogMTJweDtcbiAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/shared/ng/base/src/lib/range-selector-input/components/time-value-picker/time-value-picker.component.scss"],"names":[],"mappings":"AACE;EACE,sEAAA;EACA,4CAAA;EACA,mBAAA;EACA,WAAA;AAAJ;AAGE;EACE,iBAAA;AADJ;AAIE;EACE,iBAAA;EACA,kBAAA;AAFJ;AACA,o1BAAo1B","sourcesContent":[":host {\n  mat-select {\n    /* stylelint-disable-next-line stylelint-plugins/no-hardcoded-colors */\n    border-bottom: 1px solid rgba(0, 0, 0, 0.42);\n    padding-bottom: 4px;\n    width: 42px;\n  }\n\n  .range-minute-selector {\n    margin-left: 12px;\n  }\n\n  .range-hour-selector {\n    margin-left: 20px;\n    margin-right: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
