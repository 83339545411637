/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import {
  ColorDefinition,
  ColorPalette,
  Hue,
  HueMapItem,
  MatColorTheme,
  Palette,
  PaletteColorDefinition,
  Status,
  StatusColors,
} from '@ird/shared-base';

@Injectable()
export class ThemingService {
  private mainTheme!: MatColorTheme;
  private previewCSS: HTMLStyleElement | undefined;
  private hueMap: Array<HueMapItem> = [
    { hue: '50', saturation: 0.05, brightness: 0.96 },
    { hue: '100', saturation: 0.1, brightness: 0.95 },
    { hue: '200', saturation: 0.16, brightness: 0.94 },
    { hue: '300', saturation: 0.26, brightness: 0.91 },
    { hue: '400', saturation: 0.37, brightness: 0.86 },
    { hue: '500', saturation: 0.48, brightness: 0.78 },
    { hue: '600', saturation: 0.58, brightness: 0.67 },
    { hue: '700', saturation: 0.69, brightness: 0.54 },
    { hue: '800', saturation: 0.79, brightness: 0.39 },
    { hue: '900', saturation: 0.9, brightness: 0.25 },
    { hue: 'A100' },
    { hue: 'A200' },
    { hue: 'A400' },
    { hue: 'A700' },
  ];
  private palettes: Array<Palette> = ['primary', 'accent', 'warn'];
  private statusColors: Array<Status> = ['info', 'success', 'warn', 'alert', 'error', 'absent'];

  constructor(@Inject(DOCUMENT) private document: Document) {}

  private static setHueProperties(cssRule: CSSStyleRule, palette: Palette, defaultHue: Hue, lighterHue: Hue, darkerHue: string) {
    cssRule.style.setProperty(`--${palette}`, `var(--${palette}-${defaultHue})`);
    cssRule.style.setProperty(`--${palette}-text`, `var(--${palette}-${defaultHue})`);
    cssRule.style.setProperty(`--${palette}-contrast`, `var(--${palette}-${defaultHue}-contrast)`);

    cssRule.style.setProperty(`--${palette}-default`, `var(--${palette}-${defaultHue})`);
    cssRule.style.setProperty(`--${palette}-default-contrast`, `var(--${palette}-${defaultHue}-contrast)`);

    cssRule.style.setProperty(`--${palette}-lighter`, `var(--${palette}-${lighterHue})`);
    cssRule.style.setProperty(`--${palette}-lighter-contrast`, `var(--${palette}-${lighterHue}-contrast)`);

    cssRule.style.setProperty(`--${palette}-darker`, `var(--${palette}-${darkerHue})`);
    cssRule.style.setProperty(`--${palette}-darker-contrast`, `var(--${palette}-${darkerHue}-contrast)`);
  }

  private createCssStyleSheet(): CSSStyleSheet {
    this.previewCSS = this.document.createElement('style');
    this.previewCSS = this.document.head.appendChild(this.previewCSS);

    return <CSSStyleSheet>this.previewCSS.sheet;
  }

  public loadTheme(theme: MatColorTheme) {
    /**
     * Methods that modify the DOMTokenList automatically remove duplicate values from the list.
     * Source: https://developer.mozilla.org/en-US/docs/Web/API/DOMTokenList
     */
    this.document.body.className = '';
    if (theme) {
      // Because we added status.absent subsequent, we have to migrate tenants without this field.
      this.migrateStatusAbsent(theme);
      this.document.body.classList.add('theme');
      const cssRule: CSSStyleRule = this.getStyleRule('.theme');
      this.updatePaletteProperties(cssRule, theme);
      this.mainTheme = theme;
    }
  }

  /**
   * If absent was not found in theme add it with color from default theme
   *
   * @param theme
   */
  private migrateStatusAbsent(theme: MatColorTheme): void {
    if (!Object.keys(theme.status).includes('absent')) {
      const rootConfig = this.document.querySelector(':root');
      if (rootConfig) {
        const config = this.getConfigFromStyle(getComputedStyle(rootConfig));
        theme.status['absent'] = {
          hex: config.status.absent.hex,
          contrast: config.status.absent.contrast,
        };
      }
    }
  }

  private loadPreviewTheme() {
    this.document.body.className = '';
    this.document.body.classList.add('theme-preview');
  }

  private getStyleRule(selectorText: string): CSSStyleRule {
    if (!this.previewCSS) {
      this.createCssStyleSheet();
    }
    const rules: CSSRuleList = this.previewCSS!.sheet!.cssRules;
    let rule: CSSStyleRule = Array.from(rules).find(
      (r) => r instanceof CSSStyleRule && r.selectorText.toLowerCase() === selectorText.toLowerCase(),
    ) as CSSStyleRule;

    if (!rule) {
      const ruleIndex = this.previewCSS!.sheet!.insertRule(selectorText + '{ }', rules.length);
      rule = rules[ruleIndex] as CSSStyleRule;
    }
    return rule;
  }

  public previewTheme(matColorTheme: MatColorTheme) {
    const cssRule: CSSStyleRule = this.getStyleRule('.theme-preview');
    this.updatePaletteProperties(cssRule, matColorTheme);
    this.loadPreviewTheme();

    return () => {
      this.stopThemePreview();
      return matColorTheme;
    };
  }

  private stopThemePreview() {
    if (this.previewCSS) {
      this.document.head.removeChild(this.previewCSS);
      this.previewCSS = undefined;
    }

    this.loadTheme(this.mainTheme);
  }

  private updatePaletteProperties(cssRule: CSSStyleRule, matColorTheme: MatColorTheme) {
    this.palettes.forEach((palette) => {
      const colorPalette: ColorPalette = matColorTheme[palette];
      const defaultHue: Hue = colorPalette.default;
      const { lighter, darker } = colorPalette;
      for (const colorDef of colorPalette.colors) {
        cssRule.style.setProperty(`--${palette}-${colorDef.name}`, `${colorDef.hex}`);
        cssRule.style.setProperty(`--${palette}-${colorDef.name}-contrast`, `${colorDef.contrast}`);
      }
      ThemingService.setHueProperties(cssRule, palette, defaultHue, lighter, darker);
    });

    this.statusColors.forEach((color: Status) => {
      const statusColor: ColorDefinition = matColorTheme.status[color];
      const { hex, contrast } = statusColor;
      cssRule.style.setProperty(`--status-${color}`, hex);
      cssRule.style.setProperty(`--status-${color}-contrast`, contrast);
    });

    cssRule.style.setProperty(`--app-color`, matColorTheme.app.hex);
    cssRule.style.setProperty(`--app-color-contrast`, matColorTheme.app.contrast);

    cssRule.style.setProperty(`--app-color-menu`, matColorTheme.menu.hex);
    cssRule.style.setProperty(`--app-color-menu-contrast`, matColorTheme.menu.contrast);
  }

  public getConfigFromStyle(style: CSSStyleDeclaration): MatColorTheme {
    const config: MatColorTheme = new MatColorTheme();
    config.status = new StatusColors();
    this.statusColors.forEach((color: Status) => {
      const hex = style.getPropertyValue(`--status-${color}`).trim();
      const contrast = style.getPropertyValue(`--status-${color}`).trim();
      config.status[color] = { contrast, hex };
    });
    config.app = {
      contrast: style.getPropertyValue(`--app-color-contrast`).trim(),
      hex: style.getPropertyValue(`--app-color`).trim(),
    };
    config.menu = {
      contrast: style.getPropertyValue(`--app-color-menu-contrast`).trim(),
      hex: style.getPropertyValue(`--app-color-menu`).trim(),
    };
    this.palettes.forEach((palette) => {
      const colorPalette = new ColorPalette();
      const defaultVal = style.getPropertyValue(`--${palette}`).trim();
      const lighterVal = style.getPropertyValue(`--${palette}-lighter`).trim();
      const darkerVal = style.getPropertyValue(`--${palette}-darker`).trim();
      colorPalette.baseColor = defaultVal;
      this.hueMap.forEach((hueItem) => {
        const colorConf: PaletteColorDefinition = {
          name: hueItem.hue,
          hex: style.getPropertyValue(`--${palette}-${hueItem.hue}`).trim(),
          contrast: style.getPropertyValue(`--${palette}-${hueItem.hue}-contrast`).trim(),
        };
        if (colorConf.hex === defaultVal) {
          colorPalette.default = colorConf.name;
        }
        if (colorConf.hex === lighterVal) {
          colorPalette.lighter = colorConf.name;
        }
        if (colorConf.hex === darkerVal) {
          colorPalette.darker = colorConf.name;
        }
        colorPalette.colors.push(colorConf);
      });
      config[palette] = colorPalette;
    });
    return config;
  }

  public getHueMap(): Array<HueMapItem> {
    return this.hueMap;
  }
}
