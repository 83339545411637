export class SpxDragEvent<T> {
  constructor(private _dragEvent?: DragEvent, private _dragData?: T) {}

  public get dragEvent(): DragEvent | undefined {
    return this._dragEvent;
  }

  public get dragData(): T | undefined {
    return this._dragData;
  }
}
