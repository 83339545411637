export enum ValueType {
  TEMPERATURE = 'TEMPERATURE',
  PRESSURE = 'PRESSURE',
  FREQUENCY = 'FREQUENCY',
  POWER = 'POWER',
  CURRENT = 'CURRENT',
  DISTANCE = 'DISTANCE',
  ENERGY = 'ENERGY',
  FLOW = 'FLOW',
  FORCE = 'FORCE',
  MASS = 'MASS',
  TIME = 'TIME',
  VIBRATION_ACCELERATION = 'VIBRATION_ACCELERATION',
  VOLTAGE = 'VOLTAGE',
  VOLUME = 'VOLUME',
  VIBRATION_DISPLACEMENT = 'VIBRATION_DISPLACEMENT',
  VIBRATION_VELOCITY = 'VIBRATION_VELOCITY',
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface DataConversionUserSetting {
  valueType: ValueType;
  targetUnit: string;
}
