import { IrdValidator } from './validator';

export class IrdValidatorFactory {
  static create(name: string, isValid: (value: unknown) => boolean, errorMsg?: string): IrdValidator {
    return {
      name: name,
      errorMsg: errorMsg ?? '',
      getParams(instance): { isActive: boolean; [p: string]: any } {
        return { isActive: false };
      },
      isValid: isValid,
      isActive: true,
    };
  }
}
