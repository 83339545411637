import { Sensor } from '../sensor';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface DeviceSensorsConfiguration {
  id: string;
  deviceId: string;
  nanoSinceEpoch: number;
  resolvedSensors: Array<Sensor>;
}
