import { SecureAction } from '../secure-actions';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DeviceSecureAction extends SecureAction {
  public static readonly DOCUMENT_UPLOAD_CATEGORY: SecureAction = new DeviceSecureAction('device:documents:upload|category');
  public static readonly DOCUMENT_UPLOAD_LANGUAGE: SecureAction = new DeviceSecureAction('device:documents:upload|language');
  public static readonly EDIT_DEVICE: SecureAction = new DeviceSecureAction('device:edit|devices');
  public static readonly EDIT_VIEW_SENSOR_CONFIGURATION: SecureAction = new DeviceSecureAction('device:edit:sensor_configuration|view');
  public static readonly ENABLE_ALL_FEATURES: SecureAction = new DeviceSecureAction('device:features|enabled');
  public static readonly OVERVIEW_VIEW: SecureAction = new DeviceSecureAction('device:overview|view');
  public static readonly OVERVIEW_ADD: SecureAction = new DeviceSecureAction('device:overview|add');
  public static readonly OVERVIEW_LOAD_VIEW: SecureAction = new DeviceSecureAction('device:overview|load_view');
  public static readonly OVERVIEW_CHANGE_VIEW: SecureAction = new DeviceSecureAction('device:overview|change_view');
  public static readonly OVERVIEW_CHANGE_VIEW_ANY: SecureAction = new DeviceSecureAction('device:overview:change_view');
  public static readonly OVERVIEW_CHANGE_VIEW_CARD: SecureAction = new DeviceSecureAction('device:overview:change_view|card');
  public static readonly OVERVIEW_CHANGE_VIEW_LIST: SecureAction = new DeviceSecureAction('device:overview:change_view|list');
  public static readonly OVERVIEW_CHANGE_VIEW_MAP: SecureAction = new DeviceSecureAction('device:overview:change_view|map');
  public static readonly OVERVIEW_DOWNLOAD_CSV: SecureAction = new DeviceSecureAction('device:overview|download');
  public static readonly OVERVIEW_NO_MODELS_FILTER: SecureAction = new DeviceSecureAction('device:overview:filter|no_models');
  public static readonly OVERVIEW_IN_PROVISIONING_FILTER: SecureAction = new DeviceSecureAction('device:overview:filter|in_provisioning');
  public static readonly OVERVIEW_MONITORED_FILTER: SecureAction = new DeviceSecureAction('device:overview:filter|monitored');
  public static readonly OVERVIEW_ONLINE_FILTER: SecureAction = new DeviceSecureAction('device:overview:filter|online');
  public static readonly RESET_OPERATING_HOURS: SecureAction = new DeviceSecureAction('device:actions|reset_operating_hours');

  private constructor(name: string) {
    super(name);
  }
}
