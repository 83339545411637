import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'shared-color-picker-input',
  templateUrl: './color-picker-input.component.html',
  styleUrls: ['./color-picker-input.component.scss'],
})
export class ColorPickerInputComponent implements OnInit, OnChanges {
  private HEX_PATTERN = '#([0-9a-fA-F]){6}';

  @Input() color: string;
  @Input() label: string;
  @Input() disabled = false;
  @Input() disabledInput = false;
  @Input() mode: 'color' | 'grayscale' | 'presets' = 'color';
  @Input() presetColors: Array<string> = [];
  @Input() hideHueAlpha = false;
  @Input() hex: FormControl;

  @Output() colorChange: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit(): void {
    const disabled = this.disabledInput ? this.disabledInput : this.disabled;
    if (!this.hex) {
      this.hex = new FormControl({ value: this.color, disabled }, Validators.pattern(this.HEX_PATTERN));
    }
    this.hex.valueChanges.subscribe((color) => {
      if (this.color !== color && !this.hex.errors) {
        this.color = color;
        this.colorChange.emit(color);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.hex) {
      return;
    }
    const { disabled, color } = changes;
    if (disabled?.previousValue !== disabled?.currentValue) {
      if (!disabled.currentValue) {
        this.hex.enable();
      } else {
        this.hex.disable();
      }
    }
    if (color?.previousValue !== color?.currentValue) {
      this.hex.setValue(color.currentValue);
    }
  }

  setColorConfig(color: string) {
    this.hex.setValue(color);
    this.colorChange.emit(color);
  }
}
