import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Tenant, TenantConfigurationResponse } from '../models';

@Injectable()
export class TenantRepositoryHttp {
  readonly headers = {
    'Content-Type': 'application/json',
  };

  constructor(
    private http: HttpClient,
    @Inject('ENVIRONMENT') private config: any,
  ) {}

  getTenants(): Observable<Tenant[]> {
    const path = this.config.tenant.get;

    const params = {
      page: 0,
      size: 300,
      sort: `name,ASC`,
    };

    return this.http.get<Tenant[]>(path, {
      observe: 'body',
      headers: this.headers,
      params,
    });
  }

  getTenant(): Observable<TenantConfigurationResponse> {
    const path = this.config.ui.tenant.getCurrent;

    return this.http.get<TenantConfigurationResponse>(path, { observe: 'body' });
  }

  getTenantToken(tenantId: string): Observable<any> {
    const path = this.config.tenant.getToken.replace('{tenantId}', tenantId);

    return this.http.post(path, null, {
      responseType: 'text',
    });
  }

  createTenant(tenant: Tenant): any {
    const path = this.config.tenant.post;

    return this.http.post<Tenant>(path, tenant, {
      headers: this.headers,
    });
  }

  editTenant(tenant: Tenant): Observable<Tenant> {
    const { id, ...rest } = tenant;
    const path = this.config.tenant.patch.replace('{id}', id);

    return this.http.post<Tenant>(path, rest, {
      headers: this.headers,
    });
  }
}
