import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { ActionsService } from '../../services/actions.service';
import { PermissionOverlayComponent } from './permission-overlay.component';

@Injectable({
  providedIn: 'root',
})
export class PermissionOverlayService {
  constructor(
    private actionsService: ActionsService,
    private overlay: Overlay,
  ) {}

  toggle: () => void = () => {
    this.actionsService.selectionMode$.next(true);
    const overlayRef = this.overlay.create({
      hasBackdrop: true,
      minWidth: '100%',
      minHeight: '100%',
      scrollStrategy: this.overlay.scrollStrategies.reposition({
        scrollThrottle: 10,
      }),
    });
    const filePreviewPortal = new ComponentPortal(PermissionOverlayComponent);

    // Attach ComponentPortal to PortalHost
    const ref = overlayRef.attach(filePreviewPortal);
    ref.instance.setOverlayRef(overlayRef);

    ref.onDestroy(() => {
      this.actionsService.selectionMode$.next(false);
    });
  };
}
