import { DATE_PIPE_DEFAULT_OPTIONS, DATE_PIPE_DEFAULT_TIMEZONE, DatePipe, DatePipeConfig } from '@angular/common';
import { Inject, InjectionToken, LOCALE_ID, Optional, Pipe, PipeTransform } from '@angular/core';

// type IrdDateConfig = {
//   predicate: () => boolean;
//   formats: { [key: string]: string };
// };

type IrdDateConfig = () => { [key: string]: string } | null;

export const IRD_DATE_FORMAT_CONFIG = new InjectionToken<IrdDateConfig>('IRD_DATE_FORMAT_CONFIG', {
  factory: () => {
    return () => null;
  },
});

@Pipe({
  name: 'irdDate',
})
export class IrdDatePipe extends DatePipe implements PipeTransform {
  constructor(
    @Inject(IRD_DATE_FORMAT_CONFIG) private getConfig: IrdDateConfig,
    @Inject(LOCALE_ID) locale: string,
    @Inject(DATE_PIPE_DEFAULT_TIMEZONE) @Optional() defaultTimezone?: string | null,
    @Inject(DATE_PIPE_DEFAULT_OPTIONS) @Optional() defaultOptions?: DatePipeConfig | null,
  ) {
    super(locale, defaultTimezone, defaultOptions);
  }

  override transform(value: Date | string | number, format?: string, timezone?: string, locale?: string): string | null;
  override transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
  override transform(value: Date | string | number | null | undefined, format?: string, timezone?: string, locale?: string): string | null;
  override transform(value: Date | string | number | null | undefined, format?: string, timezone?: string, locale?: string): string | null {
    format = this.getFormat(format);
    return super.transform(value, format, timezone, locale);
  }

  private getFormat(format?: string): string | undefined {
    const config = this.getConfig();
    if (format && config && config[format]) {
      return config[format];
    } else {
      return format;
    }
  }
}
