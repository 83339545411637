import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class LayoutService {
  /**
   * Sidebar toggle Event
   */
  private sidebarToggle$: Subject<void> = new Subject<void>();
  public sidebarToggle: Observable<void> = this.sidebarToggle$.asObservable();

  /**
   * Is Mobile Event
   */
  private isMobile$: Subject<boolean> = new Subject<boolean>();
  public isMobile: Observable<boolean> = this.isMobile$.asObservable();

  /**
   * Is Handset Event
   */
  private isHandset$: Subject<boolean> = new Subject<boolean>();
  public isHandset: Observable<boolean> = this.isHandset$.asObservable();

  constructor(private breakpointObserver: BreakpointObserver) {
    this.isMobile = this.breakpointObserver.observe(Breakpoints.XSmall).pipe(map((result) => result.matches));
    this.isHandset = this.breakpointObserver.observe(Breakpoints.Handset).pipe(map((result) => result.matches));
  }

  /**
   * Triggers the Sidebar event
   */
  public toggleSidebar(): void {
    this.sidebarToggle$.next();
  }
}
