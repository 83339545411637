import { BaseSensorType } from '../../sensor';
import { DeviceData } from '../device-data.model';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface DeviceSensorTypeData {
  sensorId?: string;
  sensorType?: BaseSensorType;
  data?: DeviceData;
}
