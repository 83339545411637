import { SecureAction } from '../../secure-actions';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class AnalyticsPredictionsSecureAction extends SecureAction {
  public static readonly VIEW: SecureAction = new AnalyticsPredictionsSecureAction('device:analytics:predictions|view');

  private constructor(name: string) {
    super(name);
  }
}
