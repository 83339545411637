import { ThresholdAlarmConfigurationGroup } from '../../alarm';
import { ValueType } from '../../data-conversion';
import { ThresholdDTO } from '../../shared';
import { DynamicLimitsModel } from '../../threshold';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class ElementLastDataDto {
  alertConfig?: ThresholdAlarmConfigurationGroup;
  dynamicThresholds?: DynamicLimitsModel;
  metadata?: Record<string, string>;
  thresholds?: Array<ThresholdDTO>;
  unit?: string;
  value: number;
  valueType?: ValueType;
}
