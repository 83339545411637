import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { UserSettings, UserSettingsUUID } from '@deprecated/api-interfaces';
import { PathReplaceUtils } from '@ird/shared-utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Log, LogLevel, Login, PagedData, RabbitPermissions, User, UserCreate } from '../models';

interface ChangePasswordBody {
  password: string;
  passwordCheck: string;
  oldPassword?: string;
}

@Injectable()
export class UserRepositoryHttp {
  defaultOptions = {
    'Content-Type': 'application/json',
  };

  constructor(
    private http: HttpClient,
    @Inject('ENVIRONMENT') private config: any,
  ) {}

  getUser(): Observable<Login> {
    return this.http.get<Login>(`${this.config.user.apiEndpoint}`, {
      headers: this.defaultOptions,
    });
  }

  /**
   * Save user
   * @param user data to save
   */
  saveUserData(user: User): Observable<void> {
    const path = this.config.user.apiPostpoint + user.id;

    return this.http.put<void>(path, user, {
      headers: this.defaultOptions,
    });
  }

  saveMetadata(userId: string, metadata: Map<string, string>): Observable<HttpResponse<void>> {
    const path = this.config.nest.user.metadata.save.replace('{id}', userId);

    return this.http.post<void>(path, metadata, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  getAllUsers(page = 0, pageSize = 20, query?: string, sort?: Sort): Observable<any> {
    const queryName = 'q';

    const params = {
      page: page.toString(),
      size: pageSize.toString(),
      //sort: sort ? sort.active + ',' + sort.direction : '', TODO uncomment when backend is ready
    };

    if (query) {
      params[queryName] = query;
    }

    const path = this.config.user.getAllUsers;

    return this.http.get(path, {
      observe: 'response',
      headers: this.defaultOptions,
      params,
    });
  }

  getUserById(userId: string): Observable<User> {
    const path = this.config.user.getUser.replace('{userId}', userId);

    return this.http.get<User>(path, {
      observe: 'body',
      headers: this.defaultOptions,
    });
  }

  deleteUser(userId: string): Observable<void> {
    const path = this.config.user.deleteUser.replace('{userId}', userId);

    return this.http.delete<void>(path, {
      observe: 'body',
      headers: this.defaultOptions,
    });
  }

  unlockUser(userId: string): Observable<void> {
    const path = this.config.user.unlockUser.replace('{userId}', userId);
    return this.http.post<void>(path, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  changePassword(userId: string, password: string, passwordRepeat: string, oldPassword?: string): Observable<any> {
    const path = this.config.user.changePassword.replace('{userId}', userId);
    const data: ChangePasswordBody = {
      password: password,
      passwordCheck: passwordRepeat,
    };

    if (oldPassword) {
      data.oldPassword = oldPassword;
    }

    return this.http.patch(path, data, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  createUser(user: UserCreate): Observable<HttpResponse<string>> {
    const path = this.config.user.apiPostpoint;

    return this.http.post<string>(path, user, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  getUserSettings(): Observable<UserSettings> {
    return this.http
      .get<UserSettingsUUID>(this.config.user.userSettings, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          return JSON.parse(response.body.settings);
        }),
      );
  }

  saveUserSettings(settings: UserSettings): Observable<any> {
    const path = this.config.user.userSettings;

    return this.http.post(path, settings, {
      headers: this.defaultOptions,
    });
  }

  getUsersByGroup(groupId: string, page: number, size: number): Observable<PagedData<User>> {
    const path = this.config.user.getUsersByGroup.replace('{groupId}', groupId);

    const params = {
      page: page.toString(),
      size: size.toString(),
    };

    return this.http.get<PagedData<User>>(path, {
      observe: 'body',
      headers: this.defaultOptions,
      params,
    });
  }

  getUsersCountForGroupsIds(groupsIds: string[]): Observable<any> {
    const groups = groupsIds.join(',');
    const path = this.config.user.usersCountForGroupsIds.replace('{groupsIds}', groups);

    return this.http.get(path, {
      observe: 'body',
      headers: this.defaultOptions,
    });
  }

  assignMqttRights(userId: string, mqttPassword: string): Observable<any> {
    const path = this.config.user.mqtt.post.replace('{userId}', userId);

    return this.http.post(
      path,
      { mqttPassword },
      {
        observe: 'body',
        headers: this.defaultOptions,
      },
    );
  }

  removeMqttRights(userId: string): Observable<any> {
    const path = this.config.user.mqtt.delete.replace('{userId}', userId);

    return this.http.delete(path, {
      observe: 'body',
      headers: this.defaultOptions,
    });
  }

  getMqttDetails(userId: string): Observable<RabbitPermissions> {
    const path = this.config.user.mqtt.get.replace('{userId}', userId);

    return this.http.get<RabbitPermissions>(path, {
      observe: 'body',
      headers: this.defaultOptions,
    });
  }

  addMqttDevice(userId: string, deviceId: string): Observable<any> {
    const path = this.config.user.device.add.replace('{id}', userId).replace('{deviceId}', deviceId);

    return this.http.post(path, {
      observe: 'body',
      headers: this.defaultOptions,
    });
  }

  removeMqttDevice(userId: string, deviceId: string): Observable<any> {
    const path = this.config.user.device.remove.replace('{id}', userId).replace('{deviceId}', deviceId);

    return this.http.delete(path, {
      observe: 'body',
      headers: this.defaultOptions,
    });
  }

  getUserLogs(
    userId: string,
    from: string,
    to: string,
    pageNumber: number,
    pageSize: number,
    type: LogLevel,
    query: string,
  ): Observable<PagedData<Log>> {
    const path = this.config.user.getLogs.replace('{userId}', userId);

    const params: any = {
      from,
      to,
      page: pageNumber.toString(),
      size: pageSize.toString(),
    };

    if (type) {
      params.msgLevel = type;
    }

    if (query) {
      params.searchBy = query;
    }

    return this.http.get<PagedData<Log>>(path, {
      observe: 'body',
      params,
    });
  }

  alignUserTenantToKeycloakRealm(id: string): Observable<HttpResponse<boolean>> {
    const url = PathReplaceUtils.replace(this.config.nest.user.alignUserTenantToKeycloakRealm.post, {
      id,
    });
    return this.http.post<HttpResponse<boolean>>(url, { observe: 'body', headers: this.defaultOptions });
  }

  migrateUserToKeyCloak(userName: string): Observable<void> {
    const url = this.config.nest.user.migrateUserToKeyCloak;
    return this.http.post<void>(url, { userName }, { observe: 'body', headers: this.defaultOptions });
  }
}
