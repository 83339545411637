import { Directive, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmDialogService } from '@ird/ng-base';
import { TranslateService } from '@ngx-translate/core';
import { LoginService, UserService } from '../services';

@Directive({ selector: '[sharedImpersonate]' })
export class ImpersonateDirective {
  @Input() username: string;

  constructor(
    private confirmDialogService: ConfirmDialogService,
    private translateService: TranslateService,
    private loginService: LoginService,
    private router: Router,
    private userService: UserService,
  ) {}

  @HostListener('click', ['$event']) onClick() {
    this.confirmDialogService
      .openConfirmDialog(
        this.translateService.instant('ADMINISTRATION.USERS.IMPERSONATE_CONTENT', {
          username: this.username,
        }),
        'ADMINISTRATION.USERS.IMPERSONATE_TITLE',
      )
      .subscribe((confirmationResult) => {
        if (confirmationResult.confirmed) {
          this.loginService.getImpersonatedToken(this.username).subscribe((token) => {
            const originalToken = localStorage.getItem('token');
            localStorage.removeItem('token');
            if (token) {
              localStorage.setItem('token', token);
            }
            localStorage.setItem('imp', 'true');

            // make sure that if preview stopped the original user will be displayed
            if (!localStorage.getItem('origin-token')) {
              localStorage.setItem('origin-token', originalToken);
            }

            this.userService.initUser().then(() => {
              this.router.navigate(['/']);
            });
          });
        }
      });
  }
}
