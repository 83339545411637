import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TenantService } from './tenant.service';

@Injectable({
  providedIn: 'root',
})
export class PageService {
  constructor(
    private tenantService: TenantService,
    private titleService: Title,
  ) {}

  setTitle(title?: string) {
    if (title) {
      this.titleService.setTitle(title);
    } else {
      // If no title given, set default page title
      this.titleService.setTitle(this.tenantService.getTenantBrandName());
    }
  }
}
