// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  background-color: var(--warn-100);
  border-radius: 4px;
  color: var(--disabled-color);
  display: flex;
  padding: 16px;
}

.spx-body2 {
  width: 100%;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIndhcm5pbmctcGFuZWwuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxpQ0FBQTtFQUNBLGtCQUFBO0VBQ0EsNEJBQUE7RUFDQSxhQUFBO0VBQ0EsYUFBQTtBQUNGOztBQUVBO0VBQ0UsV0FBQTtBQUNGIiwiZmlsZSI6Indhcm5pbmctcGFuZWwuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XG4gIGJhY2tncm91bmQtY29sb3I6IHZhcigtLXdhcm4tMTAwKTtcbiAgYm9yZGVyLXJhZGl1czogNHB4O1xuICBjb2xvcjogdmFyKC0tZGlzYWJsZWQtY29sb3IpO1xuICBkaXNwbGF5OiBmbGV4O1xuICBwYWRkaW5nOiAxNnB4O1xufVxuXG4uc3B4LWJvZHkyIHtcbiAgd2lkdGg6IDEwMCU7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/shared/ng/common/src/lib/components/warning-panel/warning-panel.component.scss"],"names":[],"mappings":"AAAA;EACE,iCAAA;EACA,kBAAA;EACA,4BAAA;EACA,aAAA;EACA,aAAA;AACF;;AAEA;EACE,WAAA;AACF;AACA,4kBAA4kB","sourcesContent":[":host {\n  background-color: var(--warn-100);\n  border-radius: 4px;\n  color: var(--disabled-color);\n  display: flex;\n  padding: 16px;\n}\n\n.spx-body2 {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
