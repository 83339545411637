import { Inject, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { Notification, NotificationAction, NotificationData, NotificationType } from '../model';
import { NotificationComponent } from '../notification.component';
import { NOTIFICATION_CONFIG } from '../provider/notification.provider';

@Injectable()
export class NotificationService {
  private buttonClickSubject = new Subject<NotificationAction>();
  buttonClick$ = this.buttonClickSubject.asObservable();

  constructor(
    private snackBar: MatSnackBar,
    @Inject(NOTIFICATION_CONFIG) private defaultNotificationConfig: MatSnackBarConfig,
  ) {}

  /**
   * Shows a user notification based on its Notification Type
   *
   * It can be used with a simple text string or a translation key (e.g. "HELLO") with params from the translation file.
   *
   * @param notificationData Notification data object
   * @param notificationConfig Angular Material Snackbar configuration object
   */
  public showNotification(notificationData: NotificationData, notificationConfig?: MatSnackBarConfig) {
    // set panel classes for global styling
    const panelClasses = ['spx-notification'];
    panelClasses.push(this.getNotificationTypeCssClass(notificationData.type ? notificationData.type : NotificationType.Primary));

    // set default configuration
    let config = this.defaultNotificationConfig;

    // overwrite default config with provided one
    if (notificationConfig) {
      config = Object.assign(config, notificationConfig);
    }

    // set necessary Data
    config.data = notificationData;
    config.panelClass = panelClasses;

    // open the snack bar
    return this.snackBar.openFromComponent(NotificationComponent, config);
  }

  notifyButtonClicked(notificationButtonAction: NotificationAction) {
    this.buttonClickSubject.next(notificationButtonAction);
  }

  /**
   * Maps the notification type to a css class, which is styled in the overrides.scss
   */
  private getNotificationTypeCssClass(notificationType: Notification): string {
    switch (notificationType) {
      case NotificationType.Primary:
        return 'primary';
      case NotificationType.Accent:
        return 'accent';
      case NotificationType.Info:
        return 'status-info';
      case NotificationType.Success:
        return 'status-success';
      case NotificationType.Warn:
        return 'status-warn';
      case NotificationType.Alert:
        return 'status-alert';
      case NotificationType.Error:
        return 'status-error';
      default:
        return 'primary';
    }
  }
}
