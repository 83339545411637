import { DataConversionUserSetting } from '../models/data-conversion-user-setting.model';
import { Precision } from '../models/precision.model';
import { ValueType } from '../models/value-type.model';

export class PrecisionBaseService {
  private static readonly SIGNIFICANT_DIGITS = 4;

  setSignificantDigits(value: number): number {
    if (value < Math.pow(10, PrecisionBaseService.SIGNIFICANT_DIGITS)) {
      return parseFloat(value.toPrecision(PrecisionBaseService.SIGNIFICANT_DIGITS)) / 1;
    } else {
      return Math.round(value);
    }
  }

  findPrecision(
    dataConversions: DataConversionUserSetting[],
    precisions: Precision[],
    valueType: ValueType,
    sourceUnit?: string,
  ): number | undefined {
    const targetUnit = dataConversions.find((conversion: DataConversionUserSetting) => conversion.valueType === valueType)?.targetUnit;

    const unit = targetUnit ?? sourceUnit;

    if (precisions?.length >= 1 && unit) {
      const item = precisions.find((precision: Precision) => precision.unit === unit);
      if (item) {
        return item.precision;
      }
    }
    return undefined;
  }

  protected applyPrecision(value: number, precision?: number): number {
    if (precision === 0) {
      return Math.round(value);
    }
    if (precision) {
      return +Number(value).toFixed(precision);
    } else {
      return this.setSignificantDigits(value);
    }
  }
}
