import { ComponentType } from '@angular/cdk/portal';
import { Inject, Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { DialogAction, DialogData } from '../model';
import { DEFAULT_ACTIONS } from '../provider';
import { BaseDialogService } from './base-dialog.service';

@Injectable()
export class DialogService {
  constructor(private baseDialogService: BaseDialogService, @Inject(DEFAULT_ACTIONS) private defaultActions: DialogAction[]) {}

  /**
   * Opens the dialog with a simple text as content
   *
   * @param content Simple content string
   * @param title Title of the dialog
   * @param subtitle Subtitle of the dialog (optional)
   * @param actions Array of dialog actions to control the dialog (optional)
   * @param dialogConfig Material dialog configuration to override config settings (optional)
   */
  public openDialog<R>(content: string, title: string, subtitle?: string, dialogConfig?: MatDialogConfig, actions: DialogAction[] = this.defaultActions): Observable<R> {
    const dialogData: DialogData<string> = {
      title,
      subtitle,
      content,
      actions,
    };

    return this.baseDialogService.openDialog<string, R>(dialogData, dialogConfig);
  }

  /**
   * Opens the dialog with a custom component as content
   *
   * @param component Component type of content component
   * @param title Title of the dialog
   * @param subtitle Subtitle of the dialog (optional)
   * @param actions Array of dialog actions to control the dialog (optional)
   * @param dialogConfig Material dialog configuration to override config settings (optional)
   */
  public openDialogWithComponent<T, R>(
    component: ComponentType<T>,
    title: string,
    subtitle?: string,
    dialogConfig?: MatDialogConfig,
    actions: DialogAction[] = this.defaultActions
  ): Observable<R> {
    const dialogData: DialogData<T> = {
      title,
      subtitle,
      component,
      actions,
    };

    return this.baseDialogService.openDialog<T, R>(dialogData, dialogConfig);
  }

  /**
   * Closes the dialog
   */
  public close<T>(result?: T): void {
    this.baseDialogService.close(result);
  }
}
