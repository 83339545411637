import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SepoDevice } from '@deprecated/api-interfaces';
import { NotificationService } from '@ird/ng-base';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { AuthService } from '../../auth/service/auth.service';
import { DeviceRepository } from '../repositories/device.repository';
import { KpiLogsService } from './kpi-logs.service';
import { TenantConfigService } from './tenant-config.service';
import { WarrantyService } from './warranty.service';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  // Device behavior subject
  private device$ = new BehaviorSubject<SepoDevice>(null);

  private deviceId: string;

  /**
   * Device changed observable
   */
  public deviceChanged = this.device$.asObservable();

  constructor(
    private deviceRepository: DeviceRepository,
    private authService: AuthService,
    private kpiLogsService: KpiLogsService,
    private warrantyService: WarrantyService,
    private tenantConfigService: TenantConfigService,
    private router: Router,
    private notificationService: NotificationService,
  ) {}

  /**
   * Sets the device ID and gets the device from the server
   *
   * @param deviceId device ID
   */
  setDeviceId(deviceId: string): void {
    this.deviceId = deviceId;
    localStorage.removeItem('deviceId');
    localStorage.setItem('deviceId', deviceId);
  }

  /**
   * Returns the device id, when it was set
   *
   * @returns ID of device
   */
  public getDeviceId(): string {
    return this.deviceId ?? localStorage.getItem('deviceId');
  }

  getDevice(deviceId: string): Promise<SepoDevice> {
    return new Promise((resolve, reject) => {
      this.deviceRepository.getDevice(deviceId).subscribe({
        next: (device) => {
          if (device) {
            this.device$.next(device);
            resolve(device);
          } else {
            reject();
          }
        },
        error: (err) => {
          this.device$.next(null);
          reject(err);
        },
      });
    });
  }

  getDeviceWithoutUpdating(deviceId: string): Promise<SepoDevice> {
    return firstValueFrom(this.deviceRepository.getDevice(deviceId));
  }

  addToPrivateTeam(deviceId: string): Promise<void> {
    return new Promise((resolve) => {
      if (this.authService.isTokenValid()) {
        firstValueFrom(this.deviceRepository.addToPrivateTeam(deviceId)).finally(() => {
          resolve();
        });
      } else {
        resolve();
      }
    });
  }

  async initNewDevice(deviceId: string, navigateToHome?: boolean): Promise<void> {
    await this.initDevice(deviceId);
    this.kpiLogsService.sendInitEvent(deviceId);
    await this.addToPrivateTeam(deviceId);

    try {
      const asset = await this.getDevice(deviceId);
      if (asset.redirectUrl) {
        window.open(asset.redirectUrl, '_self');
      } else {
        this.updateLastScannedDevicesList(deviceId);
        this.warrantyService.checkWarrantyStatus(deviceId, asset.tenantId);
        if (navigateToHome) {
          const urlTree = this.router.createUrlTree(['home', deviceId]);
          this.router.navigateByUrl(urlTree);
        }
      }
    } catch (e: any) {
      this.handleDeviceErrorSnackbarAndRedirect(e);
    }
  }

  initDevice(deviceId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const uuidRegex = /^([0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12})$/;
      if (uuidRegex.test(deviceId)) {
        this.setDeviceId(deviceId);
        resolve();
      } else {
        reject();
      }
    });
  }

  private handleDeviceErrorSnackbarAndRedirect(e: HttpErrorResponse) {
    let navigate = ['qrassignment'];
    let message = 'SERVICEPOINT.HOME.ERROR.COMMON';

    if (e.status === 403) {
      navigate = ['home'];
      message = 'SERVICEPOINT.HOME.ERROR.WRONG_TENANT';
    }

    if (e.status === 404) {
      message = 'SERVICEPOINT.HOME.ERROR.NO_DEVICE';
    }

    this.notificationService.showNotification({ message, type: 'error' });
    this.router.navigate(navigate);
  }

  public getAssetThumbnail(assetId: string, thumbnailId: string): string {
    return this.deviceRepository.getAssetThumbnail(assetId, thumbnailId);
  }

  getLastScannedAssets(): Promise<SepoDevice[]> {
    const tenantId = this.tenantConfigService.getTenantId();
    const lastScannedAssets = localStorage.getItem(`last-scanned-assets-${tenantId}`);
    const lastScannedAssetIds = lastScannedAssets?.split(',').filter((asset) => !!asset) || [];

    return Promise.all([...lastScannedAssetIds.map((id) => this.getDeviceWithoutUpdating(id))]);
  }

  private updateLastScannedDevicesList(assetId: string): void {
    const tenantId = this.tenantConfigService.getTenantId();
    const lastScannedAssets = localStorage.getItem(`last-scanned-assets-${tenantId}`);
    const scanned = lastScannedAssets?.split(',').filter((asset) => !!asset) || [];

    const index = scanned.indexOf(assetId);

    if (index > -1) {
      scanned.splice(index, 1);
      scanned.unshift(assetId);
    } else {
      if (scanned.length === 3) {
        scanned.pop();
      }

      scanned.unshift(assetId);
    }

    localStorage.setItem(`last-scanned-assets-${tenantId}`, scanned.join(','));
  }
}
