import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FreescoutConfig } from '../model/freescout-config.model';

export abstract class FreescoutConfigLoader {
  abstract getConfig(): Observable<FreescoutConfig>;
}

@Injectable()
export class FreescoutConfigFakeLoader extends FreescoutConfigLoader {
  override getConfig(): Observable<FreescoutConfig> {
    return of({} as FreescoutConfig);
  }
}
