import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HotjarUserAttributes } from '../model/hotjar-user-attributes';

/**
 * Abstract Hotjar User Attributes Loader class. This needs to be provided,
 * if a custom User Attributes Loader will be implemented
 */
export abstract class HotjarUserAttributesLoader {
  abstract getUserAttributes(): Observable<HotjarUserAttributes>;
}

/**
 * This loader is just a placeholder that does nothing, in case you don't need a loader at all
 */
@Injectable()
export class HotjarUserAttributesFakeLoader implements HotjarUserAttributesLoader {
  getUserAttributes(): Observable<HotjarUserAttributes> {
    return of({} as HotjarUserAttributes);
  }
}
