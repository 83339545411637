import { ApiProperty } from '../../../decorators/decorators';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class AvailableKpi {
  @ApiProperty()
  name: string;

  @ApiProperty()
  info: string;

  @ApiProperty()
  version: string;

  @ApiProperty()
  description: string;

  @ApiProperty()
  remarks?: string;

  @ApiProperty()
  unit: string;

  @ApiProperty()
  isApplicable: boolean;

  actionCompleted?: boolean;
  @ApiProperty()
  displayedName: string;
}
