import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatColumnDef } from '@angular/material/table';
import { instanceOfSortedDataSource } from '../../../collections';
import { ColumnHeaderDirective, ColumnTemplateDirective } from '../../directives';
import { Column } from '../../model/table.config';
import { TABLE_INJECTION_TOKEN, TableComponent } from '../table/table.component';
import { VirtualizedTableComponent } from '../virtualized-table/virtualized-table.component';

@Component({
  selector: 'spx-column',
  templateUrl: './column.component.html',
})
export class ColumnComponent<T> implements OnInit, OnChanges, OnDestroy {
  @Input() name!: string;
  @Input() label?: string | null;
  @Input() filter?: boolean = true;
  @Input() sort?: boolean = true;
  @Input() sortProperty?: string;
  @Input() hidden = false;
  @Input() disableClearSort: boolean = false;

  @ContentChild(ColumnHeaderDirective, { static: true }) columnHeaderTemplate?: ColumnHeaderDirective;
  @ContentChild(ColumnTemplateDirective, { static: true }) columnTemplate?: ColumnTemplateDirective;

  @ViewChild(MatColumnDef) columnDef!: MatColumnDef;

  private column: Column = {} as Column;

  constructor(
    @Optional() @Inject(TABLE_INJECTION_TOKEN) private table: TableComponent<T> | VirtualizedTableComponent<T>,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hidden) {
      if (changes.hidden.currentValue) {
        this.table?.removeDisplayedColumn(this.name);
      } else {
        this.createColumn();
        this.table?.addDisplayedColumn(this.name);
      }
    }
  }

  ngOnInit(): void {
    if (this.table) {
      this.changeDetectorRef.detectChanges();
      this.createColumn();
      this.table.addColumn(this.column);
      if (this.hidden) {
        this.table.removeDisplayedColumn(this.name);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.table) {
      this.table.removeColumn(this.column);
    }
  }

  public enableSort(): boolean {
    return instanceOfSortedDataSource(this.table.dataSource) && !!this.sort;
  }

  public getSortProperty(): string {
    return this.sortProperty ?? this.name;
  }

  private createColumn(): void {
    this.columnDef.name = this.name;
    this.column.columnDef = this.columnDef;
    this.column.name = this.name;
    this.column.label = this.label;
    this.column.filter = this.filter;
    this.column.sort = this.sort;
    this.column.sortProperty = this.sortProperty;
  }

  getElementProperty(element: T, propertyName: string): string | undefined {
    if (element && propertyName.indexOf('.') > 0) {
      const separatorIndex = propertyName.indexOf('.');
      const key = propertyName.slice(0, separatorIndex);
      const rest = propertyName.slice(separatorIndex + 1);
      return this.getElementProperty((element as any)[key], rest);
    } else if (element) {
      return (element as any)[propertyName];
    }
    return undefined;
  }
}
