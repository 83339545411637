import { SecureAction } from '../../secure-actions';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class AnalyticsAnomalyDetectionSecureAction extends SecureAction {
  public static readonly CHANGE_STATUS: SecureAction = new AnalyticsAnomalyDetectionSecureAction(
    'device:analytics:anomaly_detection|change_status',
  );
  public static readonly INSTALL_DETECTION: SecureAction = new AnalyticsAnomalyDetectionSecureAction(
    'device:analytics:anomaly_detection|install',
  );
  public static readonly TEST_ANOMALY_NOTIFICATION: SecureAction = new AnalyticsAnomalyDetectionSecureAction(
    'device:analytics:anomaly_detection|send_test_notification',
  );
  public static readonly UPDATE_ANOMALY_DETECTION: SecureAction = new AnalyticsAnomalyDetectionSecureAction(
    'device:analytics:anomaly_detection|update',
  );
  public static readonly VIEW: SecureAction = new AnalyticsAnomalyDetectionSecureAction('device:analytics:anomaly_detection|view');

  private constructor(name: string) {
    super(name);
  }
}
