import { UserManagementElementType } from '../../user-management';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class UserManagementTreePathDto {
  id: string;
  name: string;
  parent?: UserManagementTreePathDto;
  type: UserManagementElementType;

  public static flattenPath(
    association: UserManagementTreePathDto,
    templateAssociations?: Array<UserManagementTreePathDto>,
  ): Array<UserManagementTreePathDto> {
    templateAssociations = templateAssociations ?? [association];
    if (association.parent) {
      templateAssociations.unshift(association.parent);
      return this.flattenPath(association.parent, templateAssociations);
    }
    return templateAssociations;
  }
}
