import { SuperSetWidgetType } from '../widget';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class SuperSetDashboardDto {
  id: number;
  title: string;
  changed_on: Date;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class SuperSetDashboardDetailDto extends SuperSetDashboardDto {
  config: SuperSetDashboardConfig;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class SuperSetDashboardConfig {
  rows: {
    widgets: SuperSetDashboardWidget[];
  }[];

  constructor() {
    this.rows = [];
  }
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class SuperSetDashboardWidget {
  name: string;
  height: number;
  width: number;
  id: number;
  type: SuperSetWidgetType;
}
