import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ViewConfiguration } from '../models';

@Injectable()
export class DeviceViewConfigRepositoryHttp {
  defaultOptions = {
    'Content-Type': 'application/json',
  };

  constructor(
    private http: HttpClient,
    @Inject('ENVIRONMENT') private config: any,
  ) {}

  public getDeviceViewConfiguration(deviceId: string): Observable<any> {
    const path = this.config.viewConfiguration.getDeviceViewConfiguration.replace('{deviceId}', deviceId);

    return this.http.get(path, {
      observe: 'body',
      headers: this.defaultOptions,
    });
  }

  public deleteGaugeConfig(deviceId: string): Observable<any> {
    const path = this.config.viewConfiguration.deleteDeviceGaugeConfiguration.replace('{deviceId}', deviceId);

    return this.http.delete(path, {
      observe: 'body',
      headers: this.defaultOptions,
    });
  }

  /**
   * Create a new ViewConfiguration
   * @param deviceViewConfig
   */
  public createAGaugeConfig(deviceViewConfig: ViewConfiguration[]): Observable<any> {
    const path = this.config.viewConfiguration.createDeviceGaugeConfig;

    return this.http.post(path, deviceViewConfig, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  /**
   * Get last data of sensors and formulas
   * @param deviceId
   * @param sensorId
   */
  public getDeviceLastData(deviceId: string, sensorId: string): Observable<any> {
    const path = this.config.viewConfiguration.getDeviceLastData.replace('{deviceId}', deviceId).replace('{sensorId}', sensorId);

    return this.http.get(path, {
      observe: 'body',
      headers: this.defaultOptions,
    });
  }

  public getLastDataFromAllSensors(deviceId: string): Observable<any> {
    const path = this.config.viewConfiguration.getDeviceLastDataFromAllSensors.replace('{deviceId}', deviceId);

    return this.http.get(path, {
      observe: 'body',
      headers: this.defaultOptions,
    });
  }

  /**
   * Chart and Gauge settings to be updated
   * @param config object
   */
  public setDeviceViewConfiguration(config: ViewConfiguration): Observable<HttpResponse<ViewConfiguration>> {
    const path = this.config.viewConfiguration.setDeviceViewConfiguration;

    return this.http.post<ViewConfiguration>(path, config, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }
}
