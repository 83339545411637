import { PagedData } from '@ird/shared-base';
import { Manufacturer } from '../brand-management/manufacturer';
import { CountryModel } from '../country';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class ProductionSite {
  id: string;
  name: string;
  city?: string;
  countryId?: string;
  description?: string;
  manufacturers?: Array<Manufacturer>;
  state?: string;
  street?: string;
  zip?: string;
  selected?: boolean;
  country?: CountryModel;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface ProductionSiteSelection {
  productionSiteArray: PagedData<ProductionSite>;
  selectedProductionSitesCount?: number;
  allProductionSitesCount?: number;
}
