/* eslint-disable @typescript-eslint/prefer-for-of */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Address } from '../models/address';

@Injectable({
  providedIn: 'root',
})
export class GooglePlacesService {
  private address$ = new BehaviorSubject<Address>({
    city: '',
    country: '',
    region: '',
    regionCode: '',
    postcode: '',
    route: '',
    streetNumber: '',
  });
  private addressDelivery$ = new BehaviorSubject<Address>({
    city: '',
    country: '',
    region: '',
    regionCode: '',
    postcode: '',
    route: '',
    streetNumber: '',
  });
  updatedAddress$ = this.address$.asObservable();
  updatedDeliveryAddress$ = this.addressDelivery$.asObservable();
  address: Address = { city: '', country: '', region: '', regionCode: '', postcode: '', route: '', streetNumber: '' };
  addressDelivery: Address = { city: '', country: '', region: '', regionCode: '', postcode: '', route: '', streetNumber: '' };
  s;

  handleAddressChange(place: google.maps.places.PlaceResult) {
    this.address.city = this.extractFromAddress(place.address_components, 'locality', false);
    this.address.country = this.extractFromAddress(place.address_components, 'country', true);
    this.address.region = this.extractFromAddress(place.address_components, 'administrative_area_level_1', false);
    this.address.regionCode =
      this.extractFromAddress(place.address_components, 'country', true) +
      '-' +
      this.extractFromAddress(place.address_components, 'administrative_area_level_1', true);
    this.address.postcode = this.extractFromAddress(place.address_components, 'postal_code', false);
    this.address.route = this.extractFromAddress(place.address_components, 'route', false);
    this.address.streetNumber = this.extractFromAddress(place.address_components, 'street_number', false);
    this.address$.next(this.address);
  }

  handleAddressChangeDelivery(event: google.maps.places.PlaceResult) {
    this.addressDelivery.city = this.extractFromAddress(event.address_components, 'locality', false);
    this.address.country = this.extractFromAddress(event.address_components, 'country', true);
    this.address.region = this.extractFromAddress(event.address_components, 'administrative_area_level_1', false);
    this.address.regionCode =
      this.extractFromAddress(event.address_components, 'country', true) +
      '-' +
      this.extractFromAddress(event.address_components, 'administrative_area_level_1', true);
    this.addressDelivery.postcode = this.extractFromAddress(event.address_components, 'postal_code', false);
    this.addressDelivery.route = this.extractFromAddress(event.address_components, 'route', false);
    this.addressDelivery.streetNumber = this.extractFromAddress(event.address_components, 'street_number', false);
    this.addressDelivery$.next(this.addressDelivery);
  }

  extractFromAddress(components, type, shortname): string {
    for (let i = 0; i < components.length; i++) {
      for (let j = 0; j < components[i].types.length; j++) {
        if (components[i].types[j] === type) {
          if (shortname) {
            return components[i].short_name.toLowerCase();
          } else {
            return components[i].long_name;
          }
        }
      }
    }
    return '';
  }
}
