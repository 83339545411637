import { SecureAction } from '../../secure-actions';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class AnalyticsCalculationsSecureAction extends SecureAction {
  public static readonly CRON_ASSIGN: SecureAction = new AnalyticsCalculationsSecureAction('device:analytics:cron|assign');
  public static readonly CRON_UPDATE: SecureAction = new AnalyticsCalculationsSecureAction('device:analytics:cron|edit');
  public static readonly CRON_VIEW: SecureAction = new AnalyticsCalculationsSecureAction('device:analytics:cron|view');
  public static readonly FORMULA_CREATE: SecureAction = new AnalyticsCalculationsSecureAction('device:analytics:formula|create');
  public static readonly FORMULA_DELETE: SecureAction = new AnalyticsCalculationsSecureAction('device:analytics:formula|delete');
  public static readonly FORMULA_UPDATE: SecureAction = new AnalyticsCalculationsSecureAction('device:analytics:formula|edit');
  public static readonly FORMULA_VIEW: SecureAction = new AnalyticsCalculationsSecureAction('device:analytics:formula|view');
  public static readonly OPERATOR_ASSIGNMENT: SecureAction = new AnalyticsCalculationsSecureAction(
    'device:analytics:custom_operators|assignment',
  );
  public static readonly OPERATOR_CREATE: SecureAction = new AnalyticsCalculationsSecureAction('device:analytics:custom_operators|create');
  public static readonly OPERATOR_DELETE: SecureAction = new AnalyticsCalculationsSecureAction('device:analytics:custom_operators|delete');
  public static readonly OPERATOR_UPDATE: SecureAction = new AnalyticsCalculationsSecureAction('device:analytics:custom_operators|update');
  public static readonly OPERATOR_VIEW: SecureAction = new AnalyticsCalculationsSecureAction('device:analytics:custom_operators|view');
  public static readonly VIEW: SecureAction = new AnalyticsCalculationsSecureAction('device:analytics:calculations|view');

  private constructor(name: string) {
    super(name);
  }
}
