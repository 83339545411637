import { PagedData } from '@ird/shared-base';
import { BrandModel } from '../../brand-management/brand/brand.model';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface Manufacturer {
  id: string;
  name: string;
  description?: string;
  brands?: Array<BrandModel>;
  productionSites?: Array<any>;
  selected?: boolean;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface ManufacturerSelection {
  manufacturerArray: PagedData<Manufacturer>;
  selectedManufacturersCount?: number;
  allManufacturersCount?: number;
  selected?: boolean;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface RemoveResponse {
  removed: boolean;
  linkedCount: number;
}
