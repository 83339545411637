/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class MqttTopicRight {
  id: string;
  tenantId: string;
  userId: string;
  topicReadRights: string[];
  topicWriteRights: string[];
}
