import { RecipientSettings } from './recipient-settings.model';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface Organization {
  id: string;
  name: string;
  parentId?: string;
  tenantId?: string;
  children?: Array<Organization>;
  childOrganisationCount: number;
  childTeamCount: number;
  settings: RecipientSettings;
  path?: string;
  tags: string[];
}
