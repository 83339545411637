import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Observable, finalize } from 'rxjs';
import { NotificationAction, NotificationData } from './model';

@Component({
  selector: 'spx-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
  constructor(
    private snackBarRef: MatSnackBarRef<NotificationComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: NotificationData,
  ) {}

  /**
   * Closes the Snackbar
   */
  public dismiss(): void {
    this.snackBarRef.dismiss();
  }

  /**
   * Handles the click action for a notification button.
   * Disables the button during action execution and re-enables it after completion.
   * @param notificationButtonAction The notification button action configuration.
   * @param event The mouse click event.
   */
  action(notificationButtonAction: NotificationAction, event: MouseEvent) {
    // Prevent the click event from propagating further in the DOM tree
    event.stopPropagation();

    // Execute the action function with the click event
    const actionResult = notificationButtonAction.action(event);

    // Disable the button to prevent multiple clicks during action execution
    notificationButtonAction.disabled = true;

    // Re-enable the button after action is complete
    if (actionResult instanceof Promise) {
      // If the action result is a Promise, wait for it to resolve and then re-enable the button
      actionResult.finally(() => {
        notificationButtonAction.disabled = false;
      });
    } else if (actionResult instanceof Observable) {
      // If the action result is an Observable, subscribe to it and re-enable the button after it completes
      actionResult
        .pipe(
          finalize(() => {
            notificationButtonAction.disabled = false;
          }),
        )
        .subscribe();
    } else {
      // If the action does not return a Promise or Observable, re-enable the button immediately
      notificationButtonAction.disabled = false;
    }
  }
}
