import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenService } from '../shared/services/token.service';

export const USE_LLT_TOKEN = new HttpContextToken<boolean>(() => false);
export const NO_TOKEN = new HttpContextToken<boolean>(() => false);

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private tokenService: TokenService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.context.get(NO_TOKEN)) {
      return next.handle(request);
    }

    if (request.context.get(USE_LLT_TOKEN)) {
      request = this.useLltToken(request);
    } else {
      request = this.useUserToken(request);
    }

    return next.handle(request);
  }

  private useLltToken(request: HttpRequest<unknown>): HttpRequest<unknown> {
    const lltToken = this.tokenService.getLlt();
    if (lltToken) {
      request = request.clone({
        setHeaders: {
          'X-Auth-Token': lltToken,
          'Accept-Language': 'EN',
        },
      });
    }
    return request;
  }

  private useUserToken(request: HttpRequest<unknown>): HttpRequest<unknown> {
    const isUserTokenExpired = parseInt(localStorage.getItem('user_token_expires_at'), 10) <= Date.now() / 1000;
    const userToken = localStorage.getItem('user_token');

    if (isUserTokenExpired) {
      localStorage.removeItem('user_token_expires_at');
      localStorage.removeItem('user_token');
    } else if (userToken) {
      request = request.clone({
        setHeaders: {
          'X-Auth-Token': localStorage.getItem('user_token'),
        },
      });
    } else {
      request = this.useLltToken(request);
    }
    return request;
  }
}
