import { ModuleWithProviders, NgModule } from '@angular/core';
import { DialogModule } from '@ird/ng-base';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PwaConfig } from './model/pwa-config';
import { PwaUpdateAction } from './model/pwa-update-action';
import { PWA_CONFIG } from './providers/pwa-config.provider';
import { PwaCountdownDialogComponent } from './pwa-countdown-dialog/pwa-countdown-dialog.component';
import { PwaService } from './services/pwa.service';

import { RouterModule } from '@angular/router';
import * as deTranslations from './i18n/de-lang.json';
import * as enTranslations from './i18n/en-lang.json';
@NgModule({
  imports: [TranslateModule.forChild(), DialogModule, RouterModule],
  exports: [],
  declarations: [PwaCountdownDialogComponent],
})
export class PwaModule {
  private static DEFAULT_PWA_CONFIG: PwaConfig = {
    checkForUpdate: 60 * 60 * 1000,
    forceTimeout: 60 * 1000,
    updateOnRouting: false,
    actions: {
      major: PwaUpdateAction.INFORM,
      minor: PwaUpdateAction.ASK,
      patch: PwaUpdateAction.AFTER_RELOAD,
      force: PwaUpdateAction.INFORM,
      silent: PwaUpdateAction.FORCE_RELOAD,
      default: PwaUpdateAction.ASK,
    },
  };

  constructor(
    //Instantiate the PwaService by injecting while initializing the module.
    private _pwaService: PwaService,
    private translate: TranslateService,
  ) {
    this.translate.setTranslation('en', enTranslations, true);
    this.translate.setTranslation('de', deTranslations, true);
  }

  static forRoot(pwaConfig?: PwaConfig): ModuleWithProviders<PwaModule> {
    const finalConfig = Object.assign({}, PwaModule.DEFAULT_PWA_CONFIG, pwaConfig);

    return {
      ngModule: PwaModule,
      providers: [
        {
          provide: PWA_CONFIG,
          useValue: finalConfig,
        },
        PwaService,
      ],
    };
  }
}
