import { AlarmHistory, RaisedAlarm } from '../../alarm';
import { DeviceSourceDatasetDto } from '../../device';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class AnomalyPageChartDto {
  sources: Array<DeviceSourceDatasetDto>;
  alarms: Array<AlarmHistory>;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class AnomalyPageDataDto {
  data: AnomalyPageChartDto;
  enabled?: boolean;
  installed?: boolean;
  pendingAlarm?: RaisedAlarm;
}
