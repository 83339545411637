// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: inline-flex;
}

mat-form-field mat-hint {
  visibility: hidden;
}

mat-form-field:focus-within mat-hint {
  visibility: visible;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInBhc3N3b3JkLWlucHV0LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxvQkFBQTtBQUNGOztBQUVBO0VBQ0Usa0JBQUE7QUFDRjs7QUFFQTtFQUNFLG1CQUFBO0FBQ0YiLCJmaWxlIjoicGFzc3dvcmQtaW5wdXQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgZGlzcGxheTogaW5saW5lLWZsZXg7XG59XG5cbm1hdC1mb3JtLWZpZWxkIG1hdC1oaW50IHtcbiAgdmlzaWJpbGl0eTogaGlkZGVuO1xufVxuXG5tYXQtZm9ybS1maWVsZDpmb2N1cy13aXRoaW4gbWF0LWhpbnQge1xuICB2aXNpYmlsaXR5OiB2aXNpYmxlO1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/shared/ng/common/src/lib/components/password-input/password-input.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,mBAAA;AACF;AACA,ogBAAogB","sourcesContent":[":host {\n  display: inline-flex;\n}\n\nmat-form-field mat-hint {\n  visibility: hidden;\n}\n\nmat-form-field:focus-within mat-hint {\n  visibility: visible;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
