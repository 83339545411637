import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DeviceConfiguration, Formula, Sensor } from '@deprecated/api-interfaces';
import { PathReplaceUtils } from '@ird/shared-utils';
import { Observable } from 'rxjs';

/**
 * This sends a request to server
 */
@Injectable()
export class DeviceConfigRepositoryHttp {
  defaultOptions = {
    'Content-Type': 'application/json',
  };

  constructor(
    private http: HttpClient,
    @Inject('ENVIRONMENT') private config: any,
  ) {}

  getSensorIdsList(deviceId: string): Observable<HttpResponse<Array<Sensor>>> {
    const path = this.config.device.getConfiguration.replace('{id}', deviceId);

    return this.http.get<Array<Sensor>>(path, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  /**
   * Get List of Formulas
   * @param deviceId
   */
  getFormulasList(deviceId: string): Observable<any> {
    const path = this.config.formulas.get.replace('{deviceId}', deviceId);

    return this.http.get(path, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  setDeviceConfiguration(deviceConfiguration: DeviceConfiguration): Observable<any> {
    const path = this.config.device.setConfiguration.replace('{id}', deviceConfiguration.deviceId);

    return this.http.post(path, deviceConfiguration, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  getSensorsByDeviceIdAndTypeId(deviceId: string, typeId: string): Observable<Sensor[]> {
    const endPoint = this.config.viewConfiguration.getDeviceSensorsByType.replace('{sensorTypeId}', typeId).replace('{deviceId}', deviceId);

    return this.http.get<Sensor[]>(endPoint, {
      observe: 'body',
      headers: this.defaultOptions,
    });
  }

  public getFormulaListConfiguration(deviceId: string): Observable<Array<Formula>> {
    const path = PathReplaceUtils.replace(this.config.nest.dashboard.config.template.formulaTypeConfig, { deviceId });

    return this.http.get<Array<Formula>>(path);
  }

  getPaginatedDeviceConfiguration(deviceId: string, filter = '', pageNumber = 0, pageSize = 5): Observable<any> {
    const params = new HttpParams()
      .set('courseId', deviceId)
      .set('page', pageNumber.toString())
      .set('size', pageSize.toString())
      .set('q', filter);

    const path = this.config.device.getConfigurationV2.replace('{id}', deviceId);

    return this.http.get(path, {
      observe: 'response',
      headers: this.defaultOptions,
      params,
    });
  }
}
