import { evaluate } from 'mathjs';
import { DataConversionCalculationDto } from '../models/data-conversion-calculation-dto.model';
import { ValueType } from '../models/value-type.model';
import { DataConversionCacheService } from './data-conversion-cache.service';

export class DataConversionBaseService {
  private dataConversionCacheService = new DataConversionCacheService();

  async convert<T>(value: string | number, label: boolean, calculationDto?: DataConversionCalculationDto): Promise<T> {
    // Return raw value if no calculationDto was found:
    if ((!calculationDto?.toReferenceUnit && !calculationDto?.fromReferenceUnit) || !value) {
      return value as T;
    }

    // Convert TO referenceUnit
    if (!calculationDto?.fromReferenceUnit && calculationDto?.toReferenceUnit) {
      return label
        ? (calculationDto.toReferenceUnit.targetUnit as T)
        : (this.calculateWithCheck(+value, calculationDto.toReferenceUnit.formula) as unknown as T);
    }

    // Convert FROM referenceUnit
    if (!calculationDto?.toReferenceUnit && calculationDto?.fromReferenceUnit) {
      return label
        ? (calculationDto.fromReferenceUnit.targetUnit as T)
        : (this.calculateWithCheck(+value, calculationDto.fromReferenceUnit.formula) as unknown as T);
    }

    // Convert TO referenceUnit and then FROM referenceUnit
    if (calculationDto?.toReferenceUnit && calculationDto?.fromReferenceUnit) {
      return label
        ? (calculationDto.fromReferenceUnit.targetUnit as T)
        : (this.calculateWithCheck(
            this.calculateWithCheck(+value, calculationDto.toReferenceUnit.formula),
            calculationDto.fromReferenceUnit.formula,
          ) as unknown as T);
    }

    return value as T;
  }

  getCacheItem(key: string): DataConversionCalculationDto | void {
    return this.dataConversionCacheService.get(key);
  }

  setCacheItem(dataConversionCalculationDto: DataConversionCalculationDto) {
    const valueType = dataConversionCalculationDto.toReferenceUnit?.valueType ?? dataConversionCalculationDto.fromReferenceUnit?.valueType;
    const sourceUnit =
      dataConversionCalculationDto.toReferenceUnit?.sourceUnit ?? dataConversionCalculationDto.fromReferenceUnit?.sourceUnit;

    // Ensure targetUnit is defined
    const targetUnit =
      dataConversionCalculationDto.fromReferenceUnit?.targetUnit ?? dataConversionCalculationDto.toReferenceUnit?.targetUnit;

    if (!valueType || !sourceUnit || !targetUnit) {
      throw new Error('ValueType, SourceUnit, and TargetUnit must be defined.');
    }

    this.dataConversionCacheService.set(this.formatCacheKey(valueType, sourceUnit, targetUnit), dataConversionCalculationDto);
  }

  protected formatCacheKey(valueType: ValueType, sourceUnit: string, targetUnit?: string): string {
    return `${valueType}-${sourceUnit}-${targetUnit ?? ''}`;
  }

  private calculate(value: number, formula?: string): number {
    if (value && formula) {
      return evaluate(formula.replace('x', value.toString()));
    }
    return value;
  }

  private calculateWithCheck(value: number, formula?: string): number {
    if (formula) {
      return this.calculate(value, formula);
    }
    throw new Error('Formula cannot be undefined');
  }
}
