import { SecureAction } from '../secure-actions';
import { AlertType } from '../shared';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class HeartbeatAlertConfigSecureAction extends SecureAction {
  public static readonly CREATE: SecureAction = new HeartbeatAlertConfigSecureAction('device:alarms:configuration:heartbeat|create');
  public static readonly DELETE: SecureAction = new HeartbeatAlertConfigSecureAction('device:alarms:configuration:heartbeat|delete');
  public static readonly EDIT: SecureAction = new HeartbeatAlertConfigSecureAction('device:alarms:configuration:heartbeat|edit');
  public static readonly VIEW: SecureAction = new HeartbeatAlertConfigSecureAction('device:alarms:configuration:heartbeat|view');

  private constructor(name: string) {
    super(name);
  }
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class ThresholdAlertConfigSecureAction extends SecureAction {
  public static readonly CREATE: SecureAction = new ThresholdAlertConfigSecureAction('device:alarms:configuration:threshold|create');
  public static readonly DELETE: SecureAction = new ThresholdAlertConfigSecureAction('device:alarms:configuration:threshold|delete');
  public static readonly EDIT: SecureAction = new ThresholdAlertConfigSecureAction('device:alarms:configuration:threshold|edit');
  public static readonly VIEW: SecureAction = new ThresholdAlertConfigSecureAction('device:alarms:configuration:threshold|view');

  private constructor(name: string) {
    super(name);
  }
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class IntelligentAlertConfigSecureAction extends SecureAction {
  public static readonly CREATE: SecureAction = new IntelligentAlertConfigSecureAction('device:alarms:configuration:intelligent|create');
  public static readonly DELETE: SecureAction = new IntelligentAlertConfigSecureAction('device:alarms:configuration:intelligent|delete');
  public static readonly EDIT: SecureAction = new IntelligentAlertConfigSecureAction('device:alarms:configuration:intelligent|edit');
  public static readonly VIEW: SecureAction = new IntelligentAlertConfigSecureAction('device:alarms:configuration:intelligent|view');

  private constructor(name: string) {
    super(name);
  }
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class TemplateAlertConfigSecureAction extends SecureAction {
  public static readonly VIEW: SecureAction = new TemplateAlertConfigSecureAction('device:alarms:configuration:template|view');

  private constructor(name: string) {
    super(name);
  }
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class ControllerAlertConfigSecureAction extends SecureAction {
  public static readonly VIEW: SecureAction = new ControllerAlertConfigSecureAction('device:alarms:configuration:controller|view');
  private constructor(name: string) {
    super(name);
  }
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class AlarmConfigWizardSecureAction extends SecureAction {
  public static readonly AUTO_ACKNOWLEDGE_STEP: SecureAction = new AlarmConfigWizardSecureAction(
    'device:alarms:configuration:wizard|auto_acknowledge_step',
  );
  public static readonly THRESHOLD_STEP: SecureAction = new AlarmConfigWizardSecureAction(
    'device:alarms:configuration:wizard|threshold_step',
  );
  public static readonly TITLE_STEP: SecureAction = new AlarmConfigWizardSecureAction('device:alarms:configuration:wizard|title_step');
  public static readonly THRESHOLDS: SecureAction = new AlarmConfigWizardSecureAction('device:alarms:configuration:wizard|thresholds');

  private constructor(name: string) {
    super(name);
  }
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class AlarmConfigSecureAction extends SecureAction {
  public static readonly ALARM_CONFIG_WIZARD = AlarmConfigWizardSecureAction;
  public static readonly CONTROLLER_ALERT = ControllerAlertConfigSecureAction;
  public static readonly HEARTBEAT_ALERT = HeartbeatAlertConfigSecureAction;
  public static readonly INTELLIGENT_ALERT = IntelligentAlertConfigSecureAction;
  public static readonly TEMPLATE_ALERT = TemplateAlertConfigSecureAction;
  public static readonly THRESHOLD_ALERT = ThresholdAlertConfigSecureAction;

  private constructor(name: string) {
    super(name);
  }
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export const AlarmConfigActions = {
  [AlertType.EXTERNAL]: ControllerAlertConfigSecureAction,
  [AlertType.HEARTBEAT]: HeartbeatAlertConfigSecureAction,
  [AlertType.INTELLIGENT]: IntelligentAlertConfigSecureAction,
  [AlertType.TEMPLATE]: TemplateAlertConfigSecureAction,
  [AlertType.THRESHOLD]: ThresholdAlertConfigSecureAction,
  WIZARD_ACTION: AlarmConfigWizardSecureAction,
};
