import { UserGroupNodeType } from './hierarchy-node.model';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface UserGroupNode {
  id: string;
  name: string;
  type: UserGroupNodeType;
  isPlaceholder?: boolean;
  isLoading?: boolean;
  childCount?: number;
  children?: UserGroupNode[];
  parent?: UserGroupNode;
  visited?: boolean;
}
