/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class Cron {
  cronExpression: string;
  customParameters: { [k: string]: string };
  customParamsAllowed: boolean;
  description: string;
  id: string;
  name: string;
  tenantId: string;
  unit: string;
}
