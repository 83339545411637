import { HttpStatus } from '@nestjs/common';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DataQualityCheckInfoMessageModel {
  cronId: string;
  qualityCheckInfo: Record<string, DataQualityCheckInfoModel>;
  status: keyof HttpStatus;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DataQualityCheckInfoModel {
  description: string;
  errors_label: string;
  internal: boolean;
  params: Record<string, unknown>;
  title: string;
}
