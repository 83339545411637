import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSourcePageEvent, MatTableDataSourcePaginator } from '@angular/material/table';
import { BehaviorSubject, Observable, Subscription, combineLatest, merge, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SpxFeaturedDataSource } from './featured-data-source';
import { Pagination, SpxObservableDataSourceCallback, SpxObservableDataSourceCallbackData } from './types';

export class SpxObservableDataSource<T, P extends MatTableDataSourcePaginator = MatTableDataSourcePaginator> extends SpxFeaturedDataSource<
  T,
  P
> {
  protected readonly _callback: SpxObservableDataSourceCallback<T>;
  protected _queryChangeSubscription?: Subscription;
  protected _executeCallbackSubscription?: Subscription;

  protected connected = false;

  protected _querySubject = new BehaviorSubject<{
    filter: string | undefined;
    sort: Sort | undefined;
    page: Pagination | undefined;
  }>({
    filter: undefined,
    sort: undefined,
    page: undefined,
  });

  constructor(
    callback: SpxObservableDataSourceCallback<T>,
    private initialize = true,
  ) {
    super();
    this._callback = callback;
  }

  override setFilterValue(filter: string): void {
    this._filter.next(filter);
  }

  override setSort(sort: MatSort | null): void {
    this._sort = sort;
    this._updateChangeSubscription();
  }

  override setPaginator(paginator: P | null): void {
    this._paginator = paginator;
    this._updateChangeSubscription();
  }

  override connect(): BehaviorSubject<T[]> {
    this.connected = true;
    if (this.initialize) {
      this.connectQuery();
    }
    return super.connect();
  }

  /**
   * Update the dataSource.
   *
   * @param query Optional query to update the data source with.
   * @returns a BehaviorSubject of the data to be rendered.
   */
  override update(query?: { filter?: string; sort?: Sort; page?: Pagination }): BehaviorSubject<T[]> {
    this.disconnectQuery();

    if (query?.filter) {
      this._filter?.next(query.filter);
    }
    if (query?.sort && this._sort) {
      this._sort.active = query.sort.active;
      this._sort.direction = query.sort.direction;
      this._sort.sortChange.next(query.sort);
    }
    if (query?.page && this._paginator) {
      this._paginator.pageIndex = query.page.index;
      this._paginator.pageSize = query.page.size;
      this._internalPageChanges.next();
    }

    this.connectQuery();
    return this._renderData;
  }

  protected override _updateChangeSubscription() {
    if (this._callback !== undefined) {
      const sortChange: Observable<Sort | null | void> = this._sort ? merge(this._sort.sortChange, this._sort.initialized) : of(null);

      const pageChange: Observable<MatTableDataSourcePageEvent | null | void> = this._paginator
        ? merge(this._paginator.page, this._internalPageChanges, this._paginator.initialized)
        : of(null);

      const combined = combineLatest([this._filter, sortChange, pageChange]).pipe(
        map(([filter, sort, page]) => {
          const pagination = this.createPagination(page);
          const sorting = this.createSort(sort);
          return { filter: filter ? filter : undefined, sort: sorting, page: pagination };
        }),
      );

      this._queryChangeSubscription?.unsubscribe();
      this._queryChangeSubscription = combined.subscribe((value) => {
        this._querySubject.next(value);
      });
    }
  }

  private connectQuery() {
    //Ignore of subscription already exists or dataSource is not connected yet.
    if (!this._executeCallbackSubscription && this.connected) {
      this._executeCallbackSubscription = this._querySubject.subscribe((value: { filter?: string; sort?: Sort; page?: Pagination }) => {
        this.executeCallback(value);
      });
    }
  }

  private disconnectQuery() {
    this._executeCallbackSubscription?.unsubscribe();
    this._executeCallbackSubscription = undefined;
  }

  private executeCallback(value: { filter?: string; sort?: Sort; page?: Pagination | undefined }) {
    if (this._callback !== undefined) {
      this._loading.next(true);
      this._renderChangesSubscription?.unsubscribe();
      this._renderChangesSubscription = this._callback(value.filter, value.sort, value.page).subscribe(
        (data: SpxObservableDataSourceCallbackData<T>) => {
          this._loading.next(false);
          this._updatePaginator(data.totalElements).then(() => {
            this._data.next(data.content);
            this._renderData.next(data.content);
          });
        },
      );
    }
  }

  private createPagination(page: void | MatTableDataSourcePageEvent | null): Pagination | undefined {
    let pagination: Pagination | undefined = page ? { size: page.pageSize, index: page.pageIndex } : undefined;
    if (!pagination && this._paginator && this._paginator?.pageIndex !== null && this._paginator?.pageSize !== null) {
      pagination = { size: this._paginator.pageSize, index: this._paginator.pageIndex };
    }
    return pagination;
  }

  private createSort(sort: void | Sort | null): Sort | undefined {
    let sorting: Sort | undefined = sort ? { active: sort.active, direction: sort.direction } : undefined;
    if (!sorting && this._sort && this._sort?.active && this._sort?.direction) {
      sorting = { active: this._sort.active, direction: this._sort.direction };
    }
    return sorting;
  }
}
