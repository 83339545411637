import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Injectable, InjectionToken, Injector, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ImageSliderComponent } from './image-slider.component';

export type ImageSliderData = { imagePaths: string[]; startIndex: number };
export const IMAGE_SLIDER_TOKEN = new InjectionToken<ImageSliderData>('IMAGE_SLIDER_DATA');

@Injectable({
  providedIn: 'root',
})
export class ImageSliderService implements OnDestroy {
  private overlayRef: OverlayRef;
  private imageSliderComponentRef: ComponentRef<ImageSliderComponent>;
  private panelCloseSubscription: Subscription;

  constructor(
    private overlay: Overlay,
    private injector: Injector,
  ) {}

  ngOnDestroy(): void {
    this.close();
  }

  open(data: ImageSliderData): void {
    this.overlayRef = this.overlay.create({
      width: '100vw',
      height: '100vh',
      disposeOnNavigation: true,
      hasBackdrop: false,
    });
    const imageSliderPortal = new ComponentPortal(ImageSliderComponent, null, this.createInjector(data));
    this.imageSliderComponentRef = this.overlayRef.attach(imageSliderPortal);
    this.panelCloseSubscription = this.imageSliderComponentRef.instance.panelClose.subscribe(() => this.close());
  }

  close(): void {
    this.overlayRef.detach();
    this.panelCloseSubscription?.unsubscribe();
  }

  private createInjector(data: ImageSliderData): Injector {
    return Injector.create({ parent: this.injector, providers: [{ provide: IMAGE_SLIDER_TOKEN, useValue: data }] });
  }
}
