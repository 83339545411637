import { Injectable } from '@angular/core';
import { API, BrowserConfig, Faro, getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';

@Injectable({
  providedIn: 'root',
})
export class FaroService {
  private faro: Faro;

  private defaultConf: Partial<BrowserConfig> = {
    instrumentations: [
      // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
      ...getWebInstrumentations(),

      // Initialization of the tracing package.
      // This packages is optional because it increases the bundle size noticeably. Only add it if you want tracing data.
      new TracingInstrumentation(),
    ],
  };

  initialize(options: BrowserConfig): Faro {
    this.faro = initializeFaro({ ...this.defaultConf, ...options });
    return this.faro;
  }

  public setUser(user: { id: string; username: string; email: string }) {
    if (user) {
      this.faro?.api.setUser({
        id: user.id,
        username: user.username,
        email: user.email,
      });
    } else {
      this.faro?.api.resetUser();
    }
  }

  getFaro(): Faro {
    return this.faro;
  }

  getApi(): API {
    return this.faro?.api;
  }
}
