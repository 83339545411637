import { SecureAction } from '../secure-actions';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DashboardSecureAction extends SecureAction {
  public static readonly GET: SecureAction = new DashboardSecureAction('device:dashboard|view');
  public static readonly EDIT: SecureAction = new DashboardSecureAction('device:dashboard|edit');
  public static readonly CLONE: SecureAction = new DashboardSecureAction('device:dashboard|clone');
  public static readonly DELETE: SecureAction = new DashboardSecureAction('device:dashboard|delete');
  public static readonly EXPORT_CSV: SecureAction = new DashboardSecureAction('device:csv|save');

  private constructor(name: string) {
    super(name);
  }
}
