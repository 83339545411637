import { NgModule } from '@angular/core';
import { WizardComponent } from './wizard.component';
import { MatStepperModule } from '@angular/material/stepper';
import { TranslateModule } from '@ngx-translate/core';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { BaseModule } from '../base';
import { MatListModule } from '@angular/material/list';
import { WizardBaseTabComponent } from './components/wizard-base-tab/wizard-base-tab.component';
import { WizardTabComponent } from './components/wizard-tab/wizard-tab.component';

@NgModule({
  declarations: [WizardBaseTabComponent, WizardComponent, WizardTabComponent],
  imports: [CommonModule, BaseModule, MatDividerModule, MatStepperModule, TranslateModule.forChild(), MatListModule],
  exports: [WizardBaseTabComponent, WizardComponent, WizardTabComponent],
})
export class WizardModule {}
