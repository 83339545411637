import { DOCUMENT } from '@angular/common';
import { ApplicationRef, Inject, Injectable, InjectionToken } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { ConfirmDialogService, DialogService } from '@ird/ng-base';
import { TranslateService } from '@ngx-translate/core';
import { concat, interval } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { PwaAppData } from '../model/pwa-app-data';
import { PwaConfig } from '../model/pwa-config';
import { PwaUpdateAction } from '../model/pwa-update-action';
import { PWA_CONFIG } from '../providers/pwa-config.provider';
import { PwaCountdownDialogComponent } from '../pwa-countdown-dialog/pwa-countdown-dialog.component';
import { IRD_PWA_DEFAULT_CONFIG, IrdPwaConfig } from '../model/ird-pwa-config';

export const IRD_PWA_CONFIG = new InjectionToken<IrdPwaConfig>('IRD_PWA_CONFIG', {
  factory: () => {
    return () => {
      return null;
    };
  },
});

@Injectable()
export class PwaService {
  private updatePending = false;

  constructor(
    @Inject(IRD_PWA_CONFIG) private getPwaConfig: IrdPwaConfig,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PWA_CONFIG) private pwaConfig: PwaConfig,
    private appRef: ApplicationRef,
    private swUpdate: SwUpdate,
    private confirmDialogService: ConfirmDialogService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private router: Router,
  ) {
    this.swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe((event: VersionReadyEvent) => {
        this.updatePending = true;

        const oldAppData = event.currentVersion.appData as PwaAppData;
        const appData = event.latestVersion.appData as PwaAppData;

        if (oldAppData?.version && appData?.version) {
          if (appData.silentUpdate) {
            this.doPwaAction(appData, oldAppData, this.pwaConfig.actions?.silent);
          } else if (appData.forceUpdate) {
            this.doPwaAction(appData, oldAppData, this.pwaConfig.actions?.force);
          } else {
            const [major, minor, patch] = appData.version.split('.');
            const [majorOld, minorOld, patchOld] = oldAppData.version.split('.');

            if (major !== majorOld) {
              this.doPwaAction(appData, oldAppData, this.pwaConfig.actions?.major);
            } else if (minor !== minorOld) {
              this.doPwaAction(appData, oldAppData, this.pwaConfig.actions?.minor);
            } else if (patch !== patchOld) {
              this.doPwaAction(appData, oldAppData, this.pwaConfig.actions?.patch);
            }
          }
        } else {
          this.doPwaAction(appData, oldAppData, this.pwaConfig.actions?.default);
        }
      });

    if (this.pwaConfig.updateOnRouting) {
      this.router.events.forEach((event) => {
        if (this.updatePending && event instanceof NavigationEnd) {
          this.forceReload();
        }
      });
    }

    this.swUpdate.unrecoverable.subscribe(() => {
      this.showError();
    });

    const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable === true));
    const everySixHours$ = interval(this.pwaConfig.checkForUpdate);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    everySixHoursOnceAppIsStable$.subscribe(() => {
      if (this.swUpdate.isEnabled) {
        this.swUpdate.checkForUpdate();
      }
    });
  }

  private doPwaAction(appData: PwaAppData, oldAppData: PwaAppData, action?: PwaUpdateAction) {
    const config = this.getPwaConfig() ?? IRD_PWA_DEFAULT_CONFIG()
    switch (action) {
      case PwaUpdateAction.FORCE_RELOAD:
        if (config[PwaUpdateAction.FORCE_RELOAD]) {
         this.forceReload();
        }
        break;
      case PwaUpdateAction.INFORM:
        if (config[PwaUpdateAction.INFORM]) {
          this.informAboutReload(appData, oldAppData);
        }
        break;
      case PwaUpdateAction.ASK:
        if (config[PwaUpdateAction.ASK]) {
          this.askForReload(appData, oldAppData);
        }
        break;
    }
  }

  private forceReload() {
    this.swUpdate.activateUpdate().finally(() => {
      this.reload();
    });
  }

  private showError() {
    this.confirmDialogService
      .openConfirmDialog('SPX.PWA.ERROR.CONTENT', 'SPX.PWA.ERROR.TITLE', undefined, {
        disableClose: true,
      })
      .subscribe(() => {
        this.reload();
      });
  }

  private informAboutReload(appData: PwaAppData, oldAppData: PwaAppData) {
    this.dialogService
      .openDialogWithComponent(PwaCountdownDialogComponent, 'SPX.PWA.FORCE_UPDATE.TITLE', undefined, {
        disableClose: true,
        data: {
          newVersion: appData?.version,
          oldVersion: oldAppData?.version,
          forceTimeout: this.pwaConfig.forceTimeout,
        },
      })
      .subscribe(() => {
        this.swUpdate.activateUpdate().finally(() => this.reload());
      });
  }

  private askForReload(appData: PwaAppData, oldAppData: PwaAppData) {
    const content = this.translateService.instant('SPX.PWA.UPDATE.CONTENT', {
      newVersion: appData?.version,
      oldVersion: oldAppData?.version,
    });
    this.confirmDialogService.openConfirmDialog(content, 'SPX.PWA.UPDATE.TITLE').subscribe((result) => {
      if (result.confirmed) {
        this.swUpdate.activateUpdate().finally(() => this.reload());
      }
    });
  }

  private reload() {
    this.document.location.reload();
  }
}
