import { Device } from '../../device';
import { DashboardConfigV2 } from '../dashboard-config-v2.model';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DashboardDto {
  dashboards: Array<DashboardConfigV2>;
  device: Device;
}
