import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { KpiLogRepository } from '../repositories/kpi-logs.repository';
import { TenantConfigService } from './tenant-config.service';

@Injectable({
  providedIn: 'root',
})
export class KpiLogsService {
  constructor(
    private kpiLogsRepository: KpiLogRepository,
    private readonly tenantConfigService: TenantConfigService,
  ) {}

  sendInitEvent(deviceId: string): Promise<HttpResponse<void>> {
    const tenantId = this.tenantConfigService.getTenantId();
    return firstValueFrom(this.kpiLogsRepository.sendInitEvent(deviceId, tenantId));
  }

  sendWebsiteEvent(deviceId?: string) {
    this.kpiLogsRepository.sendWebsiteEvent(deviceId);
  }
}
