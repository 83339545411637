/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class ApplicableResponse {
  applicable: boolean;
  reason: string;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class CloudCronApplicableResponse extends ApplicableResponse {
  asynchronous: boolean;
  cronId: string;
  deviceId: string;
}
