import { ApiProperty } from '../../decorators/decorators';
import { SuperSetQueryParameterType } from '../superset';
import { WidgetType } from './widget-type.enum';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export enum WidgetSourceType {
  ALARMS = 'ALARMS',
  DEVICE = 'DEVICE',
  SENSOR = 'SENSOR',
  SENSORTYPE = 'SENSORTYPE',
  KPI = 'KPI',
  FORMULA = 'FORMULA',
  FORMULATYPE = 'FORMULATYPE',
  SUPER_SET = 'SS_CHART',
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class WidgetConfig {
  id?: string;
  title?: string;
  type: WidgetType;

  metadata?: Record<string, string>;

  @ApiProperty({ type: Array })
  superSetParams?: Array<SuperSetParam>;

  sourceId?: string;
  sourceType?: WidgetSourceType;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class SuperSetParam {
  name: string;
  type: SuperSetQueryParameterType;
  value: string;
}
