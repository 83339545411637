import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SepoDevice } from '@deprecated/api-interfaces';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PathReplaceUtils } from '@ird/shared-utils';

@Injectable({
  providedIn: 'root',
})
export class DeviceRepository {
  constructor(private http: HttpClient) {}

  getDevice(deviceId: string): Observable<SepoDevice> {
    const url = environment.device.getDevice(deviceId);
    return this.http.get<SepoDevice>(url);
  }

  addToPrivateTeam(deviceId: string): Observable<void> {
    const path = environment.device.addToPrivateTeam.replace('{deviceId}', deviceId);
    return this.http.post<void>(path, {});
  }

  public getAssetThumbnail(assetId: string, thumbnailId: string): string {
    return PathReplaceUtils.replace(environment.file.device.getDeviceThumbnailImage, { assetId, thumbnailId });
  }
}
