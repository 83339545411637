import { DeviceGroupNodeType } from './hierarchy-node.model';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface DeviceGroupNode {
  id: string;
  name: string;
  type: DeviceGroupNodeType;
  isPlaceholder?: boolean;
  isLoading?: boolean;
  childCount?: number;
  children?: DeviceGroupNode[];
  parent?: DeviceGroupNode;
  visited?: boolean;
}
