import { Injectable } from '@angular/core';
import { FeatureFlag } from '@deprecated/api-interfaces';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UnleashRepository } from '../repositories/unleash.repository';

@Injectable({
  providedIn: 'root',
})
export class UnleashService {
  constructor(private unleashRepository: UnleashRepository) {}

  public enabled = new BehaviorSubject<boolean>(false);

  isEnabled(flagName: FeatureFlag, onTenant = false): Promise<boolean> {
    if (!environment.production) {
      const overrides = this.getFeatureFlagOverrides();
      if (flagName in overrides) {
        console.warn(`Overriding '${flagName}' feature flag value to '${overrides[flagName]}'.`);
        return Promise.resolve(overrides[flagName]);
      }
    }
    const result = firstValueFrom(this.unleashRepository.isFeatureFlagEnabled(flagName, onTenant));
    result.then((result) => {
      this.enabled.next(result);
    });
    return result;
  }

  private getFeatureFlagOverrides() {
    try {
      return JSON.parse(window.localStorage.getItem('feature_flags')) ?? {};
    } catch (error) {
      console.error('Failed to parse feature flag overrides:', error);
      return {};
    }
  }
}
