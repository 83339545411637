import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DataConversionCalculationDto, DataConversionDto } from '@deprecated/api-interfaces';
import { Observable } from 'rxjs';

@Injectable()
export class DataConversionRepositoryHttp {
  defaultOptions = {
    'Content-Type': 'application/json',
  };
  constructor(
    private http: HttpClient,
    @Inject('ENVIRONMENT') private config: any,
  ) {}

  public findAll(): Observable<DataConversionDto[]> {
    const url = this.config.nest.dataConversion.getAll;
    return this.http.get<DataConversionDto[]>(url);
  }

  public findAllUnits(): Observable<DataConversionDto[]> {
    const url = this.config.nest.dataConversion.getAllUnits;
    return this.http.get<DataConversionDto[]>(url);
  }

  public findConversionByUnits(dataConversionDto: DataConversionDto): Observable<DataConversionDto> {
    const url = this.config.nest.dataConversion.findConversionByUnits;

    return this.http.post<DataConversionDto>(url, dataConversionDto, {
      observe: 'body',
      headers: this.defaultOptions,
    });
  }

  public getCalculationDto(dataConversionDto: DataConversionDto): Observable<DataConversionCalculationDto> {
    const url = this.config.nest.dataConversion.getCalculationDto;

    return this.http.post<DataConversionCalculationDto>(url, dataConversionDto, {
      observe: 'body',
      headers: this.defaultOptions,
    });
  }
}
