import { PwaUpdateAction } from './pwa-update-action';

export type IrdPwaConfig = () => {
  [PwaUpdateAction.FORCE_RELOAD]: boolean;
  [PwaUpdateAction.ASK]: boolean;
  [PwaUpdateAction.INFORM]: boolean;
} | null;

export const IRD_PWA_DEFAULT_CONFIG: IrdPwaConfig = () => ({
  [PwaUpdateAction.FORCE_RELOAD]: true,
  [PwaUpdateAction.ASK]: true,
  [PwaUpdateAction.INFORM]: true,
})
