import { Pageable, Sort } from '../pageable';
import { SearchableSortableHttpParam } from '../searchable-sortable-http-param';
import { CrudGetAllDto } from '../crud';

export const SearchableSortableHttpParamFactory = (query: CrudGetAllDto) => {
  const sort = new Sort(query.sortOrders ?? []);
  const pageable = new Pageable(query.size, query.page, sort);
  return SearchableSortableHttpParamValidator({ query: query.searchQuery, pageable: pageable });
};

export const SearchableSortableHttpParamValidator = (
  searchableSortableHttpParam: SearchableSortableHttpParam,
): SearchableSortableHttpParam => {
  if (!searchableSortableHttpParam.query || searchableSortableHttpParam.query === 'undefined') {
    searchableSortableHttpParam.query = '';
  }

  const validatedSortOrders: { property: string; direction: string; }[] = [];
  if (searchableSortableHttpParam?.pageable?.sort?.sortOrders) {
    Object.values(searchableSortableHttpParam.pageable.sort.sortOrders).forEach((order: { property: string; direction: string; }) => {
      if (!order.direction || !order.property) {
        return;
      }

      if (order.direction !== 'undefined' && order.property !== 'undefined') {
        validatedSortOrders.push(order);
      }
    });
    searchableSortableHttpParam.pageable.sort.sortOrders = validatedSortOrders;
  }
  return searchableSortableHttpParam;
};
