/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class ResendActivation {
  email: string;
  onboardingMailTemplateLinks: OnboardingMailTemplateLinks;
  assetId?: string;
}

class OnboardingMailTemplateLinks {
  verificationLink: string;
  systemLoginLink: string;
  systemLink: string;
}
