import { FeatureFlag } from '@deprecated/api-interfaces';
import { FaroService } from '@ird/ng-tools';
import packageJson from '../../../package.json';
import { environment } from '../../environments/environment';
import { UnleashService } from '../core/services/unleash.service';

export function faroInitializerFactory(faroService: FaroService, unleashService: UnleashService) {
  return async () => {
    const enabled = await unleashService.isEnabled(FeatureFlag.FARO);
    if (enabled && environment.grafanaFaro?.url) {
      return faroService.initialize({
        url: environment.grafanaFaro?.url,
        app: {
          name: environment.grafanaFaro?.name || packageJson.name,
          version: packageJson.version,
          environment: window.location.host,
        },
      });
    }
  };
}
