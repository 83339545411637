import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { Pageable, PagedData } from '../models';
import { DeviceModel, DeviceModelDetails } from '../models/device-model.model';
import { DeviceModelRepositoryHttp } from '../repositories';

export interface ImageDetailsObject {
  defaultDeviceImageName: string;
  defaultDeviceImageUrl: SafeUrl;
  defaultEmailImageName: string;
  defaultEmailImageUrl: SafeUrl;
  defaultDeviceImage: File;
  defaultEmailImage: File;
}
@Injectable({
  providedIn: 'root',
})
export class DeviceModelService {
  showMetadata = false;
  constructor(private deviceModelRepository: DeviceModelRepositoryHttp) {}

  getModels(
    page: number,
    pageSize: number,
    query: string,
    filter?: Array<{ key?: string; value: string }>,
  ): Observable<PagedData<DeviceModel>> {
    return this.deviceModelRepository.getModels(page, pageSize, query, filter);
  }

  getModelsByNameAndCategory(searchText: string, categoryName: string, pageable: Pageable, deviceId: string) {
    return this.deviceModelRepository.getModelsByNameAndCategory(searchText, categoryName, pageable, deviceId);
  }

  getDeviceModel(modelId: string): Observable<DeviceModel> {
    return this.deviceModelRepository.getDeviceModel(modelId);
  }

  getDeviceFile(modelDetailsId: string, fileId: string): Observable<Blob> {
    return this.deviceModelRepository.getDeviceModelDetailsFile(modelDetailsId, fileId);
  }

  createDeviceModel(model: DeviceModel): Observable<DeviceModel> {
    return this.deviceModelRepository.createDeviceModel(model);
  }

  editDeviceModel(modelId: string, model: DeviceModel): Observable<DeviceModel> {
    return this.deviceModelRepository.editDeviceModel(modelId, model);
  }

  deleteDeviceModel(modelId: string): Observable<DeviceModel> {
    return this.deviceModelRepository.deleteDeviceModel(modelId);
  }

  attachFileToModelDetail(modelDetailId: string, fileId: string): Observable<DeviceModelDetails> {
    return this.deviceModelRepository.attachFileToModelDetail(modelDetailId, fileId);
  }

  detachFileFromModelDetai(modelDetailId: string, fileId: string): Observable<DeviceModelDetails> {
    return this.deviceModelRepository.detachFileFromModelDetail(modelDetailId, fileId);
  }

  attachDeviceModelDetailToDeviceModel(modelId: string, modelDetails: DeviceModelDetails): Observable<DeviceModel> {
    return this.deviceModelRepository.attachDeviceModelDetailToDeviceModel(modelId, modelDetails);
  }

  uploadDeviceModelImage(modelId: string, formData: FormData): Observable<any> {
    return this.deviceModelRepository.uploadDeviceModelImage(modelId, formData);
  }

  deleteDeviceModelImage(modelId: string): Observable<any> {
    return this.deviceModelRepository.deleteDeviceModelImage(modelId);
  }

  getDeviceModelImage(modelId: string): Observable<HttpResponse<Blob>> {
    return this.deviceModelRepository.getDeviceModelImage(modelId);
  }

  uploadDeviceModelDetailImage(modelId: string, formData: FormData): Observable<any> {
    return this.deviceModelRepository.uploadDeviceModelDetailImage(modelId, formData);
  }

  deleteDeviceModelDetailImage(modelId: string): Observable<any> {
    return this.deviceModelRepository.deleteDeviceModelDetailImage(modelId);
  }

  getDeviceModelDetailImage(modelId: string): Observable<HttpResponse<Blob>> {
    return this.deviceModelRepository.getDeviceModelDetailImage(modelId);
  }

  fetchImageDetails(imageResponse: HttpResponse<Blob>): Promise<ImageDetailsObject> {
    return new Promise((resolve, reject) => {
      const blob: Blob = imageResponse.body;

      const contentDispositionHeader = imageResponse.headers.get('Content-Disposition');
      const filenameMatch = contentDispositionHeader.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
      let defaultDeviceImageName = filenameMatch?.[1] || 'default.jpg';
      let defaultEmailImageName = filenameMatch?.[1] || 'default.jpg';

      // Content disposition filename includes a timestamp. Removing the timestamp.
      const underscoreIndex = defaultDeviceImageName.indexOf('_');
      if (underscoreIndex !== -1) {
        defaultDeviceImageName = defaultDeviceImageName.substring(underscoreIndex + 1);
      }
      const underscoreIndex_ = defaultEmailImageName.indexOf('_');
      if (underscoreIndex_ !== -1) {
        defaultEmailImageName = defaultEmailImageName.substring(underscoreIndex_ + 1);
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const arrayBuffer = reader.result as ArrayBuffer;
        const uint8Array = new Uint8Array(arrayBuffer);
        const firstTwoBytes = uint8Array.subarray(0, 2);

        let mimeType = 'image/jpeg'; // Default MIME type

        if (firstTwoBytes[0] === 0x89 && firstTwoBytes[1] === 0x50) {
          mimeType = 'image/png';
        } else if (firstTwoBytes[0] === 0x47 && firstTwoBytes[1] === 0x49) {
          mimeType = 'image/gif';
        } else if (firstTwoBytes[0] === 0x3c && firstTwoBytes[1] === 0x3f) {
          mimeType = 'image/svg+xml';
        }

        const imageUrl = URL.createObjectURL(blob);

        const imageDetails: ImageDetailsObject = {
          defaultDeviceImageName,
          defaultEmailImageName,
          defaultDeviceImageUrl: imageUrl,
          defaultEmailImageUrl: imageUrl,
          defaultDeviceImage: new File([blob], defaultDeviceImageName, { type: mimeType }),
          defaultEmailImage: new File([blob], defaultEmailImageName, { type: mimeType }),
        };

        resolve(imageDetails);
      };

      reader.onerror = () => {
        reject(new Error('Failed to read the image.'));
      };

      reader.readAsArrayBuffer(blob);
    });
  }

  getModelDetails(page: number, pageSize: number, name: string): Observable<PagedData<DeviceModelDetails>> {
    return this.deviceModelRepository.getModelDetails(page, pageSize, name);
  }

  createDeviceModelDetail(deviceModelDetail: DeviceModelDetails): Observable<DeviceModelDetails> {
    return this.deviceModelRepository.createDeviceModelDetail(deviceModelDetail);
  }

  editDeviceModelDetail(deviceModelDetail: DeviceModelDetails): Observable<DeviceModelDetails> {
    return this.deviceModelRepository.editDeviceModelDetail(deviceModelDetail);
  }

  exportModelData(deviceModel: DeviceModel): Observable<DeviceModel | string> {
    return this.deviceModelRepository.exportModelData(deviceModel);
  }

  cleanUpDevices(tenantId: string) {
    return this.deviceModelRepository.cleanUpDevices(tenantId);
  }
}
