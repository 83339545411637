import { Directive, ElementRef, HostBinding, Input } from '@angular/core';

@Directive({
  selector: 'tr[spxRow]',
  exportAs: 'spx-row-directive',
})
export class SpxRowDirective<T> {
  @HostBinding('class.spx-row') spxRowClass = true;

  @Input() spxRow!: T;

  constructor(protected _elementRef: ElementRef) {}

  get elementRef(): ElementRef {
    return this._elementRef;
  }
}
