import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { IMAGE_ORIGIN } from '@ird/ng-core';
import { Color } from '../../model/color';

@Component({
  selector: 'spx-icon',
  templateUrl: './icon.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class IconComponent implements OnInit {
  /**
   * Name of a Material Icon
   */
  @Input() name?: string;

  /**
   * File path to the icon
   */
  @Input() filePath?: string;

  @Input() namespace?: string;

  /**
   * Name of already registered svg icon
   */
  @Input() svgRegistered = false;

  /**
   * Icon color
   */
  @Input() color!: Color;

  /**
   * Icon font set class
   *
   * default is the material icons default icon class
   */
  @Input() fontSetClass = 'material-icons';

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    @Inject(IMAGE_ORIGIN) private imageOrigin: string,
  ) {}

  /**
   * Returns a random icon name for a given file name. This is used to bind it to the mat-icon selector
   *
   * @param filePath File path of custom svg icon
   * @returns random icon name
   */
  private static getRandomIconName(filePath: string): string {
    // Regex to get the file name without path and file extension
    const fileNameReg = new RegExp(/([^/]+)(?=\.\w+$)/);

    // Match the regex to the file path
    const fileName = filePath.match(fileNameReg);

    // if no file name was found, return a random name based on some random numbers
    return fileName ? fileName[0] : (Math.random() + 1).toString(36).substring(7);
  }

  ngOnInit(): void {
    if (this.filePath) {
      // get random icon name
      const name = IconComponent.getRandomIconName(this.filePath);

      const absolutePath = this.imageOrigin ? this.imageOrigin + this.filePath : this.filePath;
      // register custom svg icon
      this.matIconRegistry.addSvgIcon(
        IconComponent.getRandomIconName(this.filePath),
        this.domSanitizer.bypassSecurityTrustResourceUrl(absolutePath),
      );

      // set the random name as icon name
      this.name = name;
    }
  }

  getIcon() {
    let name = '';
    if (this.namespace) {
      name = this.namespace + ':';
    }
    return name + this.name;
  }
}
