export class PathReplaceUtils {
  public static replace(path: string, obj: Record<string, number | string>): string {
    let replacedPath = path;
    Object.entries(obj).forEach((entry) => {
      const value = typeof entry[1] === 'number' ? entry[1].toString() : entry[1];
      replacedPath = replacedPath.replace(`{${entry[0]}}`, value);
    });

    return replacedPath;
  }
}
