import { ValueType } from '../data-conversion';
import { Device } from '../device';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class Sensor {
  deviceData: unknown;
  attachedToDevices?: string[];
  deprecated: boolean;
  devices: Array<Device>;
  fieldId: string;
  groupsV2: string[];
  id: string;
  lastActiveNanos?: number;
  location: string;
  mappedOrRawUnit: string;
  metadata: { [k: string]: string };
  name: string;
  notes: string;
  sensorType: string;
  time: string;
  type: string;
  valueType?: ValueType;
  precision?: number;
  unit: string;
  userName: string;
  usersV2: string[];
  virtual?: boolean;
}
