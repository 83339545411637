import { Inject, Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Observable, map } from 'rxjs';
import { DIALOG_ACTION_REJECT_RESULT, DialogAction, DialogConfirmActionResult } from '../model';
import { CONFIRM_ACTIONS, DANGER_CONFIRM_ACTIONS } from '../provider/actions.provider';
import { DialogService } from './dialog.service';

@Injectable()
export class ConfirmDialogService {
  constructor(
    private dialogService: DialogService,
    @Inject(CONFIRM_ACTIONS) private confirmDialogActions: DialogAction[],
    @Inject(DANGER_CONFIRM_ACTIONS) private dangerConfirmDialogActions: DialogAction[],
  ) {}

  /**
   * Opens a confirmation dialog
   *
   * @param content Content of the confirmation dialog
   * @param title Title of the confirmation dialog
   * @param subtitle Subtitle of the confirmation dialog
   * @param dialogConfig Material dialog configuration to override config settings (optional)
   * @returns Dialog Confirm Action Result Stream as Observable
   */
  public openConfirmDialog(
    content: string,
    title: string,
    subtitle?: string,
    dialogConfig?: MatDialogConfig,
  ): Observable<DialogConfirmActionResult> {
    const actions = [...this.confirmDialogActions];

    if (dialogConfig?.disableClose) {
      actions.shift();
    }

    return this.dialogService.openDialog<DialogConfirmActionResult>(content, title, subtitle, dialogConfig, actions).pipe(
      map((result: DialogConfirmActionResult) => {
        return result ?? DIALOG_ACTION_REJECT_RESULT;
      }),
    );
  }

  /**
   * Opens a danger confirmation dialog
   *
   * @param content Content of the danger confirmation dialog
   * @param title Title of the danger confirmation dialog
   * @param subtitle Subtitle of the danger confirmation dialog
   * @param dialogConfig Material dialog configuration to override config settings (optional)
   * @returns Dialog Confirm Action Result Stream as Observable
   */
  public openDangerConfirmDialog(
    content: string,
    title: string,
    subtitle?: string,
    dialogConfig?: MatDialogConfig,
  ): Observable<DialogConfirmActionResult> {
    const actions = [...this.dangerConfirmDialogActions];

    if (dialogConfig?.disableClose) {
      actions.shift();
    }

    return this.dialogService.openDialog<DialogConfirmActionResult>(content, title, subtitle, dialogConfig, actions);
  }
}
