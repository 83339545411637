import { ApiProperty } from '../../decorators/decorators';
import { Precision } from '../sensor';
import { ExtendedMatColorTheme } from './tenant-configuration.model';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class TenantSettings {
  tenantId: string;
  tenantName: string;
  brandName: string;
  tenantCompanyName: string;
  tenantAddress: string;
  tenantEmail: string;
  hosts: string[];
  themeDefinition: ExtendedMatColorTheme;
  logoFileId: string;
  emailFileId: string;
  outboundNotificationEnabled = true;
  defaultDeviceImageFileId: string;
  emailLogoFileId: string;
  contactPersonList?: Array<unknown>;
  @ApiProperty({
    type: '{ [key: string]: any }[]',
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  features: { [key: string]: any }[];
  timeoutDuration?: number;
  precisions?: Precision[];
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface TenantConfigurationDto {
  contactPersonList?: Array<unknown>;
  defaultDeviceImageFileId: string;
  defaultModelId: string;
  emailLogoFileId?: string;
  features: { [key: string]: any }[];
  logoFileId: string;
  outboundNotificationEnabled: boolean;
  tenantId: string;
  themeDefinition: ExtendedMatColorTheme;
  timeoutDuration: number;
}
