import { Formula } from '../../formula';
import { Sensor } from '../../sensor';
import { FeatureStatusEnum } from '../../shared';
import { RaisedAlarm } from '../raised-alarm.model';
import { ThresholdAlarmConfiguration } from './config-alarm.dto';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class PendingAlertWithConfig extends RaisedAlarm {
  configs?: Array<ThresholdAlarmConfiguration>;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class PendingHeartbeatAlertWithConfig extends PendingAlertWithConfig {
  configsLength?: number;
  sources: Array<Formula | Sensor>;
  ids: Array<string>;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class PendingAlertsResponseDto {
  alarms?: Array<PendingAlertWithConfig | PendingHeartbeatAlertWithConfig>;
  status: FeatureStatusEnum;
}
