import { SecureAction } from '../secure-actions';
import { AlarmConfigSecureAction } from './alarm-config.action';
import { AlarmHistorySecureAction } from './alarm-history.action';
import { AlarmAcknowledgeSecureAction } from './alert-acknowledge-reason.action';
import { PendingAlarmSecureAction } from './pending-alarm.action';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class AlarmSecureAction extends SecureAction {
  public static readonly ALARM_CONFIG = AlarmConfigSecureAction;
  public static readonly ALARM_HISTORY = AlarmHistorySecureAction;
  public static readonly ALARM_ACKNOWLEDGE = AlarmAcknowledgeSecureAction;
  public static readonly ALARM_PENDING = PendingAlarmSecureAction;

  private constructor(name: string) {
    super(name);
  }
}
