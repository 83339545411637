import { Injectable } from '@angular/core';

import { io, ManagerOptions, Socket, SocketOptions } from 'socket.io-client';

@Injectable({
  providedIn: 'root',
})
export class SocketIoProvider {
  getSocket(url: string, options: Partial<ManagerOptions & SocketOptions>): Socket {
    return io(url, options);
  }
}
