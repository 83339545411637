import { SourceType } from '../../shared';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface ActiveAlarmDto {
  acknowledged: boolean;
  description: string;
  heartbeat: boolean;
  id: string;
  level: string;
  name: string;
  nanoSinceEpoch: number;
  sensor?: {
    type: string;
  };
  sourceDescription: string;
  sourceType: SourceType;
  thresholdMax: number;
  thresholdMin: number;
}
