/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface KpiValue {
  year: number;
  deviceId: string;
  timeInNanoSeconds: number;
  kpiName: string;
  kpiVersion: string;
  value: number;
  min: number;
  max: number;
  mean: number;
  count: number;
  median: number;
  metadata: { [k: string]: string };
}
