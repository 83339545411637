import { SensorDataSourceType } from '../device-data.model';
import { DeviceSourceDataset } from '../device-source-dataset.model';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DeviceSourceDatasetDto extends DeviceSourceDataset {
  lastActiveNanos: number;
  name: string;
  sourceId: string;
  type: SensorDataSourceType;
}
