import { PagedData } from '@ird/shared-base';
import { ApiProperty } from '../../../decorators/decorators';
import { AlarmHistory } from '../../alarm';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class AnomalyDetectionHistoryDto {
  @ApiProperty({ type: PagedData })
  alarmHistory: PagedData<AlarmHistory>;
  enabled?: boolean;
  installed?: boolean;
}
