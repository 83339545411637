import { CrudRepository } from './crud.repository';
import { DtoMapper } from '../dto-mapper';
import { PagedData } from '../paged-data';
import { UUID } from '../uuid';
import { SearchableSortableHttpParamFactory } from '../searchable-sortable-http-param';
import { CrudGetAllDto } from './crud-get-all-dto';

export abstract class CrudService<Dao,Dto> {
  protected constructor(
    protected readonly repository: CrudRepository<Dao>,
    protected readonly dtoMapper: DtoMapper<Dao, Dto>
  ) {
  }

  async getAll(dto: CrudGetAllDto ): Promise<PagedData<Dto>> {
    const {query, pageable} = SearchableSortableHttpParamFactory(dto)

    const dao: PagedData<Dao> = await this.repository.getAll({ query, pageable })
    return this.dtoMapper.fromPagedDao(dao,{})
  }

  async create(resource: Dao): Promise<Dto> {
    const dao = await this.repository.create({ resource });
    return this.dtoMapper.fromDao(dao, {})
  }

  async update(resource: Dao): Promise<Dto> {
    const dao = await this.repository.update({ resource });
    return this.dtoMapper.fromDao(dao, {})
  }

  async delete(id: UUID): Promise<UUID | null> {
    return  await this.repository.delete({ id });
  }

  async getById(id: UUID): Promise<Dto | null> {
    const dao = await this.repository.getById({ id });
    return this.dtoMapper.fromDao(dao,{})
  }

  async getByIds(ids: UUID[]): Promise<Awaited<Dto | null>[]> {
    return  Promise.all(ids.map((id: UUID) => this.getById(id)))
  }
}
