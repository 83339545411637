/* eslint-disable max-len */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import endpoints from './endpoints';

export const environment = {
  ...endpoints(),
  production: false,
  imprintUrl: '',
  devOrigin: 'https://beta-lmi.ir-connect.net',
  xTokenCookieName: 'x_token_test',
  recaptchaSiteKey: '6Le3y2EpAAAAAFD7hboOLd4iyAHKo_5K84jjKA9m',
  grafanaFaro: {
    url: 'https://faro-collector-prod-eu-west-0.grafana.net/collect/6bc7f577f31cdc7874f8dce18d67b459',
    name: 'scs-service-point-test',
  },
};
