import { DOCUMENT } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { pricespiderInitializer } from './pricespider.initializer';
import { PricespiderConfigFakeLoader, PricespiderConfigLoader } from './providers';

@NgModule()
export class PricespiderModule {
  static forRoot(configLoader: Provider): ModuleWithProviders<PricespiderModule> {
    return {
      ngModule: PricespiderModule,
      providers: [
        configLoader || { provide: PricespiderConfigLoader, useClass: PricespiderConfigFakeLoader },
        {
          provide: APP_INITIALIZER,
          multi: true,
          useFactory: pricespiderInitializer,
          deps: [DOCUMENT, PricespiderConfigLoader],
        },
      ],
    };
  }
}
