import { BrandModel } from '../brand-management/brand';
import { Manufacturer } from '../brand-management/manufacturer';
import { Location } from '../location';
import { Model } from '../model';
import { ProductionSite } from '../production-site';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DeviceEditGeneralModelDTO {
  device: DeviceEditGeneralModel;
  manufacturers: Array<Manufacturer>;
  productionSites?: Array<ProductionSite>;
  brand?: Array<BrandModel>;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DeviceEditGeneralModel {
  brand?: BrandModel;
  commNr: string;
  customer: string;
  description: string;
  id: string;
  manufacturer: Manufacturer;
  productionSite?: ProductionSite;
  locations: Location[];
  notes: string;
  tag: string;
  type: string;
  model: Model;
  imageId: string;
}
