import { SepoInteractionEnum } from './sepo-interaction.enum';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface SepoInteractions {
  id: string;
  createdDate: string;
  createdBy: string;
  tenantId: string;
  deviceId: string;
  event: SepoInteractionEnum;
  metadata?: [];
}
