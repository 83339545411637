import { ThresholdLevel } from '../shared';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface ThresholdLevelSelection {
  [ThresholdLevel.ERROR]?: boolean;
  [ThresholdLevel.WARN]?: boolean;
  [ThresholdLevel.INFO]?: boolean;
}
