import { Directive, EventEmitter, HostBinding, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { DragDataService, SpxDragEvent } from '@ird/ng-core';
import { TableComponent } from '../../components/table/table.component';
import { SpxRowDirective } from '../row/row.directive';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'spx-table[drag]',
})
export class DragDirective<T> implements OnInit {
  @HostBinding('class.spx-draggable') get draggableClass() {
    return this.drag;
  }

  @Input() drag!: boolean;
  @Input() dragAllowed!: (event: SpxDragEvent<T>, index: number) => boolean;
  @Input() transformDragData?: (row: T) => unknown;

  @Output() dragCanceled: EventEmitter<SpxDragEvent<T>> = new EventEmitter<SpxDragEvent<T>>();
  @Output() dragEnd: EventEmitter<SpxDragEvent<T>> = new EventEmitter<SpxDragEvent<T>>();

  constructor(
    private table: TableComponent<T>,
    private renderer: Renderer2,
    private dragDataService: DragDataService,
  ) {}

  ngOnInit(): void {
    this.table.dragDirective = this;
  }

  public isDragAllowed(rowDir: SpxRowDirective<T>, index: number): boolean {
    let allowed = !this.table.selectionDirective?.hasValue();
    if (allowed) {
      allowed = this.dragAllowed(new SpxDragEvent(undefined, rowDir.spxRow), index);
    }

    if (allowed) {
      this.renderer.addClass(rowDir.elementRef?.nativeElement, 'spx-draggable');
    } else {
      if (rowDir.elementRef?.nativeElement) {
        this.renderer.removeClass(rowDir.elementRef?.nativeElement, 'spx-draggable');
      }
    }
    //Value may be undefined because of selectionDirective -> !!
    return !!allowed;
  }

  public startDrag(event: DragEvent, rowDir: SpxRowDirective<T>, index: number): boolean {
    const allowed = this.dragAllowed(new SpxDragEvent(event, rowDir.spxRow), index);

    if (!allowed) {
      event.preventDefault();
      if (rowDir.elementRef?.nativeElement) {
        this.renderer.removeClass(rowDir.elementRef.nativeElement, 'spx-dragging');
      }
    } else {
      if (rowDir.elementRef?.nativeElement) {
        this.renderer.addClass(rowDir.elementRef?.nativeElement, 'spx-dragging');
      }
      const dragData: unknown = this.transformDragData ? this.transformDragData.call(this, rowDir.spxRow) : rowDir.spxRow;
      this.dragDataService.setDragData(event, dragData);
    }

    return allowed;
  }

  public onDragEnded(event: DragEvent, rowDir: SpxRowDirective<T>) {
    if (rowDir.elementRef?.nativeElement) {
      this.renderer.removeClass(rowDir.elementRef.nativeElement, 'spx-dragging');
    }
    if (event.dataTransfer?.dropEffect !== 'none') {
      this.dragEnd.emit(new SpxDragEvent(event, rowDir.spxRow));
    } else {
      this.dragCanceled.emit(new SpxDragEvent(event, rowDir.spxRow));
    }
  }
}
