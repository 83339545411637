/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DeviceSourceDataset {
  area?: Data = [];
  data: Data = [];
  metadata?: DeviceSourceMetadata;
}

export type Data = Array<Array<number>>;

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DeviceSourceMetadata {
  min: number;
  max: number;
  precissionInPercent: number;
  number_of_decimal_places: number;
  aggregation_level: string;

  [k: string]: string | number;
}
