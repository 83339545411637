import { IrdProperty, IrdPropertyType } from './property';

export interface IrdClassMetadata<T> {
  primaryKey: keyof T;
  translationKeyPrefix: string;
  //backendClass?: string;
  //t_ClassName?: string
  mapper?: any;
  properties: Record<keyof T, IrdProperty<T, IrdPropertyType>>;
}

export class IrdClassMetadataImpl<T> implements IrdClassMetadata<T> {
  public readonly properties: Record<keyof T, IrdProperty<T, IrdPropertyType>> = {} as Record<keyof T, IrdProperty<T, IrdPropertyType>>; //TODO type conversin

  constructor(
    public readonly primaryKey: keyof T,
    public readonly translationKeyPrefix: string,
  ) {}
}
