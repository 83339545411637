import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CoreModule } from '@ird/ng-core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { BaseModule } from '../base';
import { ColumnComponent } from './components/column/column.component';
import { TableComponent } from './components/table/table.component';
import { VirtualizedTableComponent } from './components/virtualized-table/virtualized-table.component';
import {
  ColumnHeaderDirective,
  ColumnTemplateDirective,
  DragDirective,
  DropDirective,
  FilterDirective,
  MultiExpansionDirective,
  PaginatorDirective,
  SelectionDirective,
  SortDirective,
  SpxRowDirective,
} from './directives';
import * as deTranslations from './i18n/de-lang.json';
import * as enTranslations from './i18n/en-lang.json';

@NgModule({
  declarations: [
    TableComponent,
    VirtualizedTableComponent,
    ColumnComponent,
    FilterDirective,
    PaginatorDirective,
    SelectionDirective,
    SpxRowDirective,
    MultiExpansionDirective,
    ColumnHeaderDirective,
    ColumnTemplateDirective,
    DragDirective,
    DropDirective,
    SortDirective,
  ],
  imports: [
    CoreModule,
    CommonModule,
    BaseModule,
    FlexLayoutModule,
    MatTableModule,
    MatSortModule,
    MatToolbarModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    ScrollingModule,
    TableVirtualScrollModule,
    TranslateModule.forChild(),
  ],
  exports: [
    TableComponent,
    VirtualizedTableComponent,
    ColumnComponent,
    FilterDirective,
    PaginatorDirective,
    SelectionDirective,
    MultiExpansionDirective,
    ColumnHeaderDirective,
    ColumnTemplateDirective,
    DragDirective,
    DropDirective,
    SortDirective,
  ],
})
export class TableModule {
  constructor(private translate: TranslateService) {
    this.translate.setTranslation('en', enTranslations, true);
    this.translate.setTranslation('de', deTranslations, true);
  }
}
