import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { PlacementCodeService } from '../shared/placementcode/placementcode.service';

@Injectable({
  providedIn: 'root',
})
export class QueryParamsResolver {
  constructor(private placementCode: PlacementCodeService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (route.queryParamMap.get('p')) {
      this.placementCode.setPlacementcode(route.queryParamMap.get('p'));
    }
    return of(true);
  }
}
