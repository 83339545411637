import { registerLocaleData } from '@angular/common';

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  private static readonly MOMENT_DEFAULT_LOCALE = 'en';

  private static DEFAULT_LOCALE = 'en';

  private loadedLocale: string;

  constructor(private translate: TranslateService) {}

  initializeLanguage(userLanguage?: string): Promise<string> {
    const language = this.seperateLocaleFromBrowserLanguage(userLanguage ?? navigator.language ?? LocaleService.DEFAULT_LOCALE);

    //Set translation language
    this.translate.setDefaultLang(LocaleService.DEFAULT_LOCALE);
    this.translate.use(language);

    localStorage.setItem('language', language);
    return this.loadLocales(language);
  }

  seperateLocaleFromBrowserLanguage(value: string) {
    const dashIndex = value.indexOf('-');
    if (dashIndex === -1) {
      return value;
    } else {
      return value.slice(0, dashIndex);
    }
  }

  private async importNgLocale(localeId: string): Promise<any> {
    /**
     * For dateformatting de.js is enought. Therefore exlude all Sub-location-Files like de-at.js with
     */
    try {
      const module = await import(
        /* webpackInclude: /(en|de|fr)\.js$/ */
        `@/../node_modules/@angular/common/locales/${localeId}.mjs`
      );

      const extrasModule = await import(
        /* webpackInclude: /(en|de|fr)\.js$/ */
        `@/../node_modules/@angular/common/locales/extra/${localeId}.js`
      );
      registerLocaleData(module.default, extrasModule.default);
      return module.default;
    } catch (error) {
      console.warn(
        'LocaleService',
        'importNgLocale',
        `Could not dynamically import module: @angular/common/locales/${localeId}.js ${error}`,
      );
    }
  }

  private async importMomentLocale(localeId: string): Promise<any> {
    //Moment does not ship an en.js, because thats the default, so dont try to load this dynamically.
    if (localeId !== LocaleService.MOMENT_DEFAULT_LOCALE) {
      try {
        await import(
          /* webpackInclude: /\/(en|de|fr)\.js$/ */
          `moment/locale/${localeId}.js`
        );
        return true;
      } catch (error) {
        console.warn('LocaleService', 'importMomentLocale', `Could not dynamically import module: moment/locale/${localeId}.js ${error}`);
      }
    } else {
      return true;
    }
  }

  async loadLocales(locale?: string) {
    this.loadedLocale = locale ?? localStorage.getItem('language') ?? LocaleService.DEFAULT_LOCALE;
    // if (await this.importNgLocale(lang)) {
    // } else {
    //   this.logger.warn('LocaleService', 'loadLocales', `Could not load ngLocale for: ${lang}`);
    // }

    if (await this.importMomentLocale(this.loadedLocale)) {
      moment.locale(this.loadedLocale);
    } else {
      console.warn('LocaleService', 'loadLocales', `Could not load moment-Locale for: ${this.loadedLocale}`);
    }
    return this.loadedLocale || LocaleService.DEFAULT_LOCALE;
  }

  getLocale(): string {
    return this.loadedLocale || LocaleService.DEFAULT_LOCALE;
  }
}
