import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';
import localePt from '@angular/common/locales/pt';
import { LocaleService } from '@ird/ng-common';

import { registerLocaleData } from '@angular/common';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeEnExtra from '@angular/common/locales/extra/en';
import localeEsExtra from '@angular/common/locales/extra/es';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeItExtra from '@angular/common/locales/extra/it';
import localeJaExtra from '@angular/common/locales/extra/ja';
import localePtExtra from '@angular/common/locales/extra/pt';

export function registerLocaledate() {
  registerLocaleData(localeEn, localeEnExtra);
  registerLocaleData(localeDe, localeDeExtra);
  registerLocaleData(localeFr, localeFrExtra);
  registerLocaleData(localeIt, localeItExtra);
  registerLocaleData(localeEs, localeEsExtra);
  registerLocaleData(localePt, localePtExtra);
  registerLocaleData(localeJa, localeJaExtra);
}

export function localeImportProviderFactory(localeService: LocaleService) {
  return () => {
    return new Promise((resolve) => {
      registerLocaledate();
      localeService.initializeLanguage().finally(() => {
        resolve(null);
      });
    });
  };
}

export function localeProviderFactory(localeService: LocaleService) {
  return localeService.getLocale();
}
