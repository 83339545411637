import { addDays, addHours, addMinutes, addMonths, addWeeks, subDays, subHours, subMinutes, subMonths, subWeeks } from 'date-fns';

export type TimeRangeOption =
  | TimeRangeOptions.DAY
  | TimeRangeOptions.WEEK
  | TimeRangeOptions.MONTH
  | TimeRangeOptions.HOUR
  | TimeRangeOptions.MINUTES;

export enum TimeRangeOptions {
  MONTH = 'month',
  WEEK = 'week',
  DAY = 'day',
  HOUR = 'hour',
  MINUTES = 'minutes',
}

export class DefaultRangeValues {
  static [TimeRangeOptions.DAY] = 1;
  static [TimeRangeOptions.MONTH] = 1;
  static [TimeRangeOptions.HOUR] = 1;
  static [TimeRangeOptions.WEEK] = 1;
  static [TimeRangeOptions.MINUTES] = 5;
}

export class TimeRangeOptionCalc {
  static add = {
    [TimeRangeOptions.DAY]: addDays,
    [TimeRangeOptions.MONTH]: addMonths,
    [TimeRangeOptions.HOUR]: addHours,
    [TimeRangeOptions.WEEK]: addWeeks,
    [TimeRangeOptions.MINUTES]: addMinutes,
  };
  static subtract = {
    [TimeRangeOptions.DAY]: subDays,
    [TimeRangeOptions.MONTH]: subMonths,
    [TimeRangeOptions.HOUR]: subHours,
    [TimeRangeOptions.WEEK]: subWeeks,
    [TimeRangeOptions.MINUTES]: subMinutes,
  };
}
