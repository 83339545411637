import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as deTranslations from './assets/i18n/de-lang.json';
import * as enTranslations from './assets/i18n/en-lang.json';
import { FormFieldComponent } from './components/form-field.component';

@NgModule({
  declarations: [FormFieldComponent],
  exports: [FormFieldComponent],
  imports: [CommonModule, MatInputModule, MatFormFieldModule, TranslateModule.forChild()],
})
export class FormFieldModule {
  constructor(private translate: TranslateService) {
    this.translate.setTranslation('en', enTranslations, true);
    this.translate.setTranslation('de', deTranslations, true);
  }
}
