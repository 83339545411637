import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { UserSettings } from '@deprecated/api-interfaces';
import { ThemingService } from '@ird/ng-base';
import { LocaleService, Login, LoginService, TenantService, UserRepositoryHttp, UserService } from '@ird/ng-common';
import { AuthService } from '../../auth/service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class SpUserService extends UserService {
  constructor(
    protected override userRepository: UserRepositoryHttp,
    protected override loginService: LoginService,
    protected override tenantService: TenantService,
    protected override themingService: ThemingService,
    protected localeService: LocaleService,
    private authService: AuthService,
    @Inject(DOCUMENT) protected override _document: Document,
  ) {
    super(userRepository, loginService, tenantService, themingService, localeService, _document);

    this.authService.onLogin((login: Login) => {
      this.initApplication(login);
    });

    this.authService.onLogout(() => {
      this.clearUser();
    });
  }

  override initUser(): Promise<Login> {
    return new Promise((resolve, reject) => {
      if (localStorage.getItem('user_token')) {
        this.userRepository.getUser().subscribe({
          next: (response: Login) => {
            localStorage.setItem('user_token_expires_at', response?.authentication?.validTo + '');
            this.initApplication(response);
            resolve(response);
          },
          error: (error) => {
            reject(error);
          },
        });
      } else {
        reject();
      }
    });
  }

  override initApplication(data: Login) {
    this.settings = (JSON.parse(data.settings.settings) ?? {}) as UserSettings;
    const userSettings = data?.settings;
    this.settings.locale = userSettings?.locale ?? navigator.language;

    // set the user actions in the action service
    this.actions = data.actions;
    this.setUser(data.feUser);
  }
}
