import { Observable } from 'rxjs';
import { Notification } from './notification.type';

export interface NotificationData {
  message: string;
  type?: Notification;
  params?: Record<string, string>;
  closeNotificationButton?: boolean;
  title?: string;
  actionButtons?: NotificationAction[];
}

export interface NotificationAction {
  name: string;
  disabled: boolean;
  action: (event: MouseEvent) => Promise<unknown> | Observable<unknown>;
  iconName?: string;
}
