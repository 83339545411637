// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .expand-hide {
  max-height: 50px;
  overflow: hidden;
}
:host ::ng-deep .expand-hide spx-icon-button {
  display: none;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm5hdmlnYXRpb24uY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0U7RUFDRSxnQkFBQTtFQUNBLGdCQUFBO0FBQUo7QUFHRTtFQUNFLGFBQUE7QUFESiIsImZpbGUiOiJuYXZpZ2F0aW9uLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICAuZXhwYW5kLWhpZGUge1xuICAgIG1heC1oZWlnaHQ6IDUwcHg7XG4gICAgb3ZlcmZsb3c6IGhpZGRlbjtcbiAgfVxuXG4gIDo6bmctZGVlcCAuZXhwYW5kLWhpZGUgc3B4LWljb24tYnV0dG9uIHtcbiAgICBkaXNwbGF5OiBub25lO1xuICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./apps/scs-service-point/src/app/layout/components/navigation/navigation.component.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,gBAAA;AAAJ;AAGE;EACE,aAAA;AADJ;AACA,wfAAwf","sourcesContent":[":host {\n  .expand-hide {\n    max-height: 50px;\n    overflow: hidden;\n  }\n\n  ::ng-deep .expand-hide spx-icon-button {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
