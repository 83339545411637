/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class KpiThreshold {
  color!: string;
  position?: number;
  startValue?: number;
}

export enum KpiThresholdLevels {
  SUCCESS = 'SUCCESS',
  WARN = 'WARN',
  ERROR = 'ERROR',
}
