/* eslint-disable @typescript-eslint/no-explicit-any */
import { SuperSetWidgetType } from '../widget';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class SuperSetWidgetDto {
  id: number;
  title: string;
  description: string;
  changed_on: string;
  type: SuperSetWidgetType;
  height: number;
  width: number;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class SuperSetWidgetDataDto {
  id: number;
  data: any[];
  type: SuperSetWidgetType;
  name: string;
  config: any;
}

export type SuperSetQueryParametersMap = Record<
  string,
  {
    type: SuperSetQueryParameterType;
    value: string;
  }
>;

export enum SuperSetQueryParameterType {
  DEVICE = 'DEVICE',
  SENSOR = 'SENSOR',
  SENSOR_TYPE = 'SENSOR_TYPE',
  FORMULA_TYPE = 'FORMULA_TYPE',
  KPI = 'KPI',
}
