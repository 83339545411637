import { SecureAction } from '../secure-actions';
import { AnalyticsAdditionalSourcesSecureAction } from './additional-sources';
import { AnalyticsAdvancedPlottingSecureAction } from './advanced-plotting';
import { AnalyticsAnomalyDetectionSecureAction } from './anomaly-detection';
import { AnalyticsCalculationsSecureAction } from './calculations';
import { AnalyticsDashboardSecureAction, AnalyticsDataQualitySecureAction } from './dashboard';
import { AnalyticsKpiSecureAction } from './kpi';
import { AnalyticsPredictionsSecureAction } from './predictions';
import { AnalyticsStatusReportsSecureAction } from './status-reports';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class AnalyticsSecureAction extends SecureAction {
  public static readonly ADDITIONAL_SOURCES = AnalyticsAdditionalSourcesSecureAction;
  public static readonly ADVANCED_PLOTTING = AnalyticsAdvancedPlottingSecureAction;
  public static readonly ANOMALY_DETECTION = AnalyticsAnomalyDetectionSecureAction;
  public static readonly CALCULATION = AnalyticsCalculationsSecureAction;
  public static readonly DASHBOARD = AnalyticsDashboardSecureAction;
  public static readonly DATA_QUALITY = AnalyticsDataQualitySecureAction;
  public static readonly KPI = AnalyticsKpiSecureAction;
  public static readonly PREDICTION = AnalyticsPredictionsSecureAction;
  public static readonly STATUS_REPORTS = AnalyticsStatusReportsSecureAction;

  private constructor(name: string) {
    super(name);
  }
}
