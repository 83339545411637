import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SensorTypeImage } from '@deprecated/api-interfaces';
import { DialogService } from '@ird/ng-base';
import { PagedData } from '@ird/shared-base';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileUploaderComponent } from '../components/file-uploader/file-uploader.component';
import { File as CustomFile, FileWithDetails } from '../models';
import { FileRepository } from '../repositories';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(
    private fileRepository: FileRepository,
    private dialogService: DialogService,
  ) {}

  /**
   * Upload an image to device
   * @param deviceId the id of the device to which the image should be added
   * @param formData image in FormData format
   */
  uploadDeviceImage(deviceId: string, formData: FormData): Observable<any> {
    return this.fileRepository.uploadDeviceImage(deviceId, formData);
  }

  /**
   * Get a thumbnail(small) image from a device
   * @param deviceId the id of the device to get the thumbnail image
   * @param thumbnailId the id of the image
   */
  getDeviceThumbnailImage(deviceId: string, thumbnailId: string): string {
    return this.fileRepository.getDeviceThumbnailImage(deviceId, thumbnailId);
  }

  /**
   * Get a large image from a device
   * @param deviceId the id of the device to get the large image
   * @param pictureId the id of the image
   */
  getDeviceLargeImage(deviceId: string, pictureId: string): string {
    return this.fileRepository.getDeviceLargeImage(deviceId, pictureId);
  }

  getPictureId(deviceId: string): Observable<string> {
    return this.fileRepository.getPictureId(deviceId);
  }

  getThumbnailId(deviceId: string): Observable<string> {
    return this.fileRepository.getThumbnailId(deviceId);
  }

  /**
   * Delete all images for a specific device
   * @param deviceId the id of the device to delete all images from
   */
  deleteDeviceImage(deviceId: string): Observable<any> {
    return this.fileRepository.deleteDeviceImage(deviceId);
  }

  getDeviceImageData(deviceId: string, pictureId: string): Observable<HttpResponse<Blob>> {
    return this.fileRepository.getDeviceImageData(deviceId, pictureId);
  }

  /**
   * Get user profile image
   * @param imageId the id of the image
   */
  getUserProfileImage(imageId: string): string {
    return this.fileRepository.getUserImage(imageId);
  }

  /**
   * Delete all images for the user
   */
  deleteUserProfileImage(): Observable<any> {
    return this.fileRepository.deleteUserImage();
  }

  /**
   * Delete all images for a specific device
   * @param formData image in FormData format
   */
  uploadUserProfileImage(formData: FormData): Observable<any> {
    return this.fileRepository.uploadUserImage(formData);
  }

  /**
   * Get sensor type image
   * @param sensorTypeId the id of the sensor
   */
  getSensorTypeImage(sensorTypeId: string): Observable<Blob> {
    return this.fileRepository.getSensorTypeImage(sensorTypeId);
  }

  /**
   * Get sensor type image
   * @param sensorTypeId the id of the sensor
   */
  deleteSensorTypeImage(sensorTypeId: string): Observable<any> {
    return this.fileRepository.deleteSensorTypeImage(sensorTypeId);
  }

  /**
   * Get sensor type image
   * @param sensorTypeId the id of the sensor
   * @param formData image in FormData format
   */
  uploadSensorTypeImage(sensorTypeId: string, formData: FormData): Observable<SensorTypeImage> {
    return this.fileRepository.uploadSensorTypeImage(sensorTypeId, formData);
  }

  /**
   * Get sensor type image
   * @param noteId the id of the user note
   * @param formData image in FormData format
   */
  uploadNoteImages(noteId: string, formData: FormData): Observable<any> {
    return this.fileRepository.uploadNoteImages(noteId, formData);
  }

  /**
   * Get user note thumbnail(small) image
   * @param noteId the id of the user note
   * @param imageId UUID of the image
   */
  getNoteThumbnailImage(noteId, imageId): Observable<Blob> {
    return this.fileRepository.getNoteThumbnailImage(noteId, imageId);
  }

  /**
   * Get user note large image
   * @param noteId the id of the user note
   * @param imageId UUID of the image
   */
  getNoteImage(noteId: string, imageId: string): Observable<Blob> {
    return this.fileRepository.getNoteImage(noteId, imageId);
  }

  /**
   * Delete user note image
   * @param noteId the id of the note
   * @param imageId UUID of the image
   */
  deleteNoteImage(noteId: string, imageId: string): Observable<any> {
    return this.fileRepository.deleteNoteImage(noteId, imageId);
  }

  /**
   * Delete all images for one note
   * @param noteId the id of the note
   */
  deleteAllNoteImages(noteId: string): Observable<any> {
    return this.fileRepository.deleteAllNoteImages(noteId);
  }

  /**
   * Get all files from a device (generic, internal, manufacturer)
   * @param deviceId the id of the device
   */
  getAllDeviceFiles(deviceId: string): Observable<Array<CustomFile>> {
    return this.fileRepository.getAllDeviceFiles(deviceId).pipe(
      map((response) => [
        ...(response.deviceDocuments ?? []),
        ...(response.seepexDocuments ?? []),
        ...(response.reportDocuments ?? []),
        ...(response.modelDocuments?.map((model) => {
          return { ...model, isModelDocument: true };
        }) ?? []),
      ]),
    );
  }

  /**
   * Get all files by search
   * @param page the id of the device
   * @param pageSize the id of the device
   * @param name the id of the device
   */
  getFiles(page: number, pageSize: number, name: string): Observable<PagedData<CustomFile>> {
    return this.fileRepository.getFiles(page, pageSize, name);
  }

  getSingleDeviceFile(deviceId: string, fileId: string): Observable<Blob> {
    return this.fileRepository.getSingleDocument(deviceId, fileId);
  }

  deleteSingleDeviceFile(deviceId: string, fileId: string): Observable<HttpResponse<any>> {
    return this.fileRepository.deleteSingleDocument(deviceId, fileId);
  }

  deleteGenericDeviceFile(fileId: string): Observable<HttpResponse<void>> {
    return this.fileRepository.deleteGenericDocument(fileId);
  }

  uploadDeviceFiles(deviceId: string, fileType: string, data: FormData): Observable<Array<CustomFile>> {
    return this.fileRepository.uploadDeviceDocuments(deviceId, fileType, data);
  }

  getModelDetailsFileUrl(modelDetailsId: string, fileId: string): Observable<string> {
    return this.fileRepository.getModelDetailsFileUrl(modelDetailsId, fileId);
  }

  uploadModelDetailsFile(modelDetailsId: string, fileWithDetails: FileWithDetails): Observable<CustomFile[]> {
    const formData = new FormData();
    formData.append('details', new Blob([JSON.stringify([fileWithDetails.details])], { type: 'application/json' }));
    formData.append('files', fileWithDetails.file);
    return this.fileRepository.uploadModelDetailsDocuments(modelDetailsId, formData);
  }

  public uploadGenericFile(data: FormData): Observable<Array<CustomFile>> {
    return this.fileRepository.uploadGenericFile(data);
  }

  public getGenericFile(id: string): Observable<string> {
    return of(this.fileRepository.getGenericFile(id));
  }

  public getImageAsBlob(imgUrl: string): Observable<HttpResponse<Blob>> {
    return this.fileRepository.getFileAsBlob(imgUrl);
  }

  selectImage(): Observable<File> {
    return this.dialogService.openDialogWithComponent(FileUploaderComponent, 'IMAGE.TITLE', '', {
      data: {
        dialogData: {
          accept: ['.jpg', 'jpeg', '.svg', '.png'],
          maxSize: 20 * 1000000,
        },
      },
    });
  }
}
