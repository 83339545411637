export function eventSubscriptionToTopic(subscription: WsEventSubscription): string {
  let topicName: string = subscription.topic;
  if (subscription.parameters) {
    Object.entries(subscription.parameters).forEach(([key, value]) => {
      topicName = topicName.replace(`{${key}}`, value);
    });
  }
  return topicName;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface WsEventSubscription {
  topic: string;
  parameters?: { [k: string]: string };
}
