import { HttpParams } from '@angular/common/http';

export class HttpUtils {
  public static toHttpParams(obj: any): HttpParams {
    return Object.entries(obj).reduce((params, [key, value]) => {
      if (value || value === 0) {
        return params.set(key, String(value));
      } else {
        return params;
      }
    }, new HttpParams());
  }
}
