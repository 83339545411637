/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface AvailableIntelligentAlarmModel {
  id: string;
  name: string;
  description: string;
  requiredSensorTypes: Array<string>;
  applicable: boolean;
  remarks: string;
}
