export enum SourceType {
  AUTOMATIC = 'AUTOMATIC',
  DEFAULT = 'DEFAULT',
  EVENT = 'EVENT',
  FIELD = 'Field',
  FORMULA = 'FORMULA',
  INTELLIGENT_ALARM = 'INTELLIGENT_ALARM',
  KPI = 'KPI',
  SENSOR = 'SENSOR',
  SENSOR_TYPE = 'SENSOR_TYPE',
  SPM = 'SPM',
}
