import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseModule } from '../base';
import { ChipListExpandDirective } from './directives/chip-list-expand.directive';

@NgModule({
  declarations: [ChipListExpandDirective],
  imports: [CommonModule, BaseModule],
  exports: [ChipListExpandDirective],
})
export class ChipListModule {}
