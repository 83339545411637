import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ResourceDefinition, ResourceType } from '@deprecated/api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class ResourceResolverService {
  getResource(route: ActivatedRouteSnapshot): ResourceDefinition | undefined {
    if (route?.data.resourceIdParam) {
      const paramName: string = route.data.resourceIdParam;
      const resourceType: ResourceType = route.data.resourceType;
      const resourceId: string = route.paramMap.get(paramName);

      return { id: resourceId, type: resourceType };
    }
  }

  async resolve(route: ActivatedRouteSnapshot): Promise<ResourceDefinition> {
    return this.getResource(route);
  }
}
