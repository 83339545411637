/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class KpiScript {
  active: boolean;
  description: string;
  displayedName: string;
  fullDescription: string;
  metadata: object;
  name: string;
  unit: string;
  version: string;
  isApplicable: boolean;
  remarks: string;
}
