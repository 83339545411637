import { SecureAction } from '../secure-actions';
import { MonitoringAlertConfigurationTemplatesSecureAction } from './alert-configuration-templates';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class MonitoringSecureAction extends SecureAction {
  public static readonly ALERT_CONFIGURATION_TEMPLATES = MonitoringAlertConfigurationTemplatesSecureAction;
  public static readonly EDIT: SecureAction = new MonitoringSecureAction('monitoring:alert_configuration_template|edit');
  public static readonly DELETE: SecureAction = new MonitoringSecureAction('monitoring:alert_configuration_template|delete');
  public static readonly VIEW: SecureAction = new MonitoringSecureAction('monitoring:alert_configuration_template|view');

  private constructor(name: string) {
    super(name);
  }
}
