export enum FeatureStatusEnum {
  DISABLED = 'DISABLED',
  ERROR = 'ERROR',
  LOCKED = 'LOCKED',
  LOADING = 'LOADING',
  NOT_SET_UP = 'NOT_SET_UP',
  OK = 'OK',
  UPCOMING = 'UPCOMING',
  VISUALISATION_UPCOMING = 'VISUALISATION_UPCOMING',
}
