import { DOCUMENT } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { freescoutInitializer } from './freescout.initializer';
import { FreescoutConfigFakeLoader, FreescoutConfigLoader } from './providers/freescout-config.provder';
import { FreescoutService } from './services/freescout.service';

@NgModule()
export class FreescoutModule {
  static forRoot(configLoader: Provider): ModuleWithProviders<FreescoutModule> {
    return {
      ngModule: FreescoutModule,
      providers: [
        configLoader || { provide: FreescoutConfigLoader, useClass: FreescoutConfigFakeLoader },
        {
          provide: APP_INITIALIZER,
          multi: true,
          useFactory: freescoutInitializer,
          deps: [DOCUMENT, FreescoutConfigLoader],
        },
        FreescoutService,
      ],
    };
  }
}
