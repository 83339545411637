import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  OverviewDevice,
  USER_MANAGEMENT_NODE_TYPE,
  UserManagementElementType,
  UserManagementNodeIcon,
  UserManagementNodeType,
  UserManagementTreePathDto,
} from '@deprecated/api-interfaces';
import { Breadcrumb } from '@ird/ng-base';
import * as lodash from 'lodash';

@Component({
  selector: 'shared-tree-path-breadcrumbs',
  templateUrl: './tree-path-breadcrumbs.component.html',
  styleUrls: ['./tree-path-breadcrumbs.component.scss'],
})
export class TreePathBreadcrumpsComponent implements OnInit {
  @Input() device!: OverviewDevice;

  public breadcrumbs: Breadcrumb[][] = [];

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.breadcrumbs = this.createBreadcrumps(this.device);
  }

  private createBreadcrumps(device: OverviewDevice): Breadcrumb[][] {
    const result: Breadcrumb[][] = [];
    device.treePath?.forEach((value) => {
      const flattened = UserManagementTreePathDto.flattenPath(value);
      const breadcrumps = this.mapTreePathesToBreadcrumbs(flattened, device);
      result.push(breadcrumps);
    });
    return result;
  }

  private mapTreePathesToBreadcrumbs(treePathes: UserManagementTreePathDto[], device: OverviewDevice): Breadcrumb[] {
    const breadcrumbs: Breadcrumb[] = [];
    const path = ['administration', 'user-management', treePathes[0].id, 'assets'];

    treePathes.forEach((treePath) => {
      if (treePath.type === UserManagementElementType.ORGANISATION) {
        path[3] = treePath.id;
        path[4] = 'assets';
      } else if (treePath.type === UserManagementElementType.TEAM) {
        path[4] = treePath.id;
        path[5] = 'assets';
      }
      const personalPath = lodash.cloneDeep(path);
      const breadcrumb = this.mapTreePathToBreadcrump(treePath, personalPath, device);
      breadcrumbs.push(breadcrumb);
    });
    return breadcrumbs;
  }

  private mapTreePathToBreadcrump(treePath: UserManagementTreePathDto, path: string[], device: OverviewDevice): Breadcrumb {
    const nodeType = this.mapElementTypeToNodeType(treePath.type);

    return {
      name: treePath.name,
      onClick: (event) => {
        this.router.navigate(path, { queryParams: { filter: device.commNr } });
      },
      icon: this.getIcon(nodeType),
    };
  }

  private mapElementTypeToNodeType(elementType: UserManagementElementType): UserManagementNodeType | null {
    let type: UserManagementNodeType | null = null;
    switch (elementType) {
      case UserManagementElementType.TENANT: {
        type = USER_MANAGEMENT_NODE_TYPE.TENANT;
        break;
      }
      case UserManagementElementType.ORGANISATION: {
        type = USER_MANAGEMENT_NODE_TYPE.ORGANIZATION;
        break;
      }
      case UserManagementElementType.TEAM: {
        type = USER_MANAGEMENT_NODE_TYPE.TEAM;
        break;
      }
      default:
        break;
    }
    return type;
  }

  private getIcon(type: UserManagementNodeType) {
    return UserManagementNodeIcon[type];
  }
}
