export type Notification = 'primary' | 'accent' | 'info' | 'success' | 'warn' | 'alert' | 'error';

export const NotificationType = {
  get Primary(): Notification {
    return 'primary';
  },
  get Accent(): Notification {
    return 'accent';
  },
  get Info(): Notification {
    return 'info';
  },
  get Success(): Notification {
    return 'success';
  },
  get Warn(): Notification {
    return 'warn';
  },
  get Alert(): Notification {
    return 'alert';
  },
  get Error(): Notification {
    return 'error';
  },
};
