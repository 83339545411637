import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { BaseModule, MenuModule, SidenavModule } from '@ird/ng-base';
import { SharedDomainNgModule } from '@ird/ng-common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { HeaderComponent } from './components/header/header.component';
import { LayoutComponent } from './components/layout/layout.component';
import { NavigationComponent } from './components/navigation/navigation.component';

@NgModule({
  declarations: [LayoutComponent, NavigationComponent, HeaderComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatSidenavModule,
    MatToolbarModule,
    BaseModule,
    MenuModule,
    SidenavModule,
    SharedDomainNgModule,
    TranslateModule,
    RouterModule,
    SharedModule,
  ],
})
export class LayoutModule {}
