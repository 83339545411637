import { PipeTransform } from '@angular/core';
import { IrdValidator } from './validator';

//?? is there a better solution to convert the type to strings
export type IrdPropertyType = 'number' | 'string' | 'boolean' | 'Date' | 'Object' | 'select' | 'icon' | 'color' | 'json';

export type IrdFormatter = PipeTransform | ((value: any) => any);
export type IrdParser = PipeTransform | ((value: any) => any);

//binding name against record key in properties?
/**
 * T = Representing DAO (or other class)
 * O = type of value
 */
export interface IrdProperty<T, O> {
  name: keyof T;
  //metadata: Metadata<T>; nice2Have
  type: IrdPropertyType;

  //array or map => map direct access e.g. to required. array order or validation
  validator: IrdValidator[];
  asyncValidator: IrdValidator[]; //?? IrdAsyncValidator

  formatter: IrdFormatter[];
  parser: IrdParser[];

  //component: //TODO visual representation e.g. AlertLevel ERROR to icon (what about color picker / show color / edit color)

  showInUi: boolean;
  isArray: boolean;
  getTranslationKey(): string;
}

export class IrdPropertyImpl<T, O> implements IrdProperty<T, O> {
  public validator: IrdValidator[] = [];
  public asyncValidator: IrdValidator[] = [];
  public formatter: IrdFormatter[] = [];
  public parser: IrdParser[] = [];

  public showInUi: boolean = true;
  public isArray: boolean = false;

  constructor(
    public readonly name: keyof T,
    public readonly type: IrdPropertyType,
    private translationKeyPrefix: string,
  ) {}

  public getTranslationKey(): string {
    return this.translationKeyPrefix + '.' + this.name.toString().toUpperCase();
  }
}
