import { SecureAction } from '../secure-actions';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class DeviceModelSecureAction extends SecureAction {
  public static readonly CREATE: SecureAction = new DeviceModelSecureAction('settings:model|create');
  public static readonly DELETE: SecureAction = new DeviceModelSecureAction('settings:model|delete');
  public static readonly UPDATE: SecureAction = new DeviceModelSecureAction('settings:model|update');
  public static readonly VIEW: SecureAction = new DeviceModelSecureAction('settings:model|view');

  private constructor(name: string) {
    super(name);
  }
}
