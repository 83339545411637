import { SecureAction } from '../secure-actions';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class SuperSetSecureAction extends SecureAction {
  public static readonly VIEW: SecureAction = new SuperSetSecureAction('device:dashboard:superset|view');
  public static readonly EDIT: SecureAction = new SuperSetSecureAction('device:dashboard:superset|edit');
  public static readonly WIDGET_VIEW: SecureAction = new SuperSetSecureAction('device:dashboard:superset:widget|view');
  public static readonly WIDGET_EDIT: SecureAction = new SuperSetSecureAction('device:dashboard:superset:widget|edit');

  private constructor(name: string) {
    super(name);
  }
}
