export interface ISpxFilteredDataSource {
  getFilterValue(): string | undefined;
  setFilterValue(filterValue: string): void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function instanceOfFilteredDataSource(object: any): object is ISpxFilteredDataSource {
  const properties = ['getFilterValue', 'setFilterValue'];
  return !!object && properties.every((property: string) => property in object);
}
