import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CoreModule } from '@ird/ng-core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BaseModule as SpxBaseModule } from '../base';
import { PeriodOptionListComponent } from './components/period-option-list/period-option-list.component';
import { RangeSelectorComponent } from './components/range-selector/range-selector.component';
import { TimeValuePickerComponent } from './components/time-value-picker/time-value-picker.component';
import { IntegerInputDirective } from './directives/integer-input.directive';
import * as deTranslations from './i18n/de-lang.json';
import * as enTranslations from './i18n/en-lang.json';
import { RangeSelectorInputComponent } from './range-selector-input.component';

@NgModule({
  declarations: [
    RangeSelectorInputComponent,
    RangeSelectorComponent,
    TimeValuePickerComponent,
    PeriodOptionListComponent,
    IntegerInputDirective,
  ],
  imports: [
    MatSelectModule,
    MatDividerModule,
    MatFormFieldModule,
    SpxBaseModule,
    MatDatepickerModule,
    FlexLayoutModule,
    MatInputModule,
    CommonModule,
    OverlayModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    MomentDateModule,
    MatTooltipModule,
    CoreModule,
  ],
  exports: [RangeSelectorInputComponent],
})
export class RangeSelectorInputModule {
  constructor(private translate: TranslateService) {
    this.translate.setTranslation('en', enTranslations, true);
    this.translate.setTranslation('de', deTranslations, true);
  }
}
