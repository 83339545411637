/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Login } from '@ird/ng-common';
import { Observable, Subscription, firstValueFrom, of } from 'rxjs';
import { Token } from '../models/token';
import { UserProfile } from '../models/user-profile';
import { AuthRepositoryService } from '../repositories/auth.repository';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private loginEmitter$ = new EventEmitter<Login>();
  private logoutEmitter$ = new EventEmitter<any>();

  constructor(
    private authRepository: AuthRepositoryService,
    private router: Router,
  ) {}

  onLogin(next: (login: Login) => void): Subscription {
    return this.loginEmitter$.subscribe(next);
  }

  onLogout(next: () => void): Subscription {
    return this.logoutEmitter$.subscribe(next);
  }

  /**
   * Sets the authentication token into the local storage
   *
   * @param authentication Authentication Response
   */
  public setToken(token: Token): void {
    localStorage.setItem('user_token', token.token);
    localStorage.setItem('user_token_expires_at', token.validTo.toString());
  }

  /**
   * Logs the user into the server
   *
   * @param username User name
   * @param password Password
   * @returns Authentication stream
   */
  public login(username: string, password: string): Promise<Login> {
    return new Promise<Login>((resolve, reject) => {
      this.authRepository.getLoginToken(username, password).subscribe({
        next: (value) => {
          this.setToken(value.authentication);
          this.loginEmitter$.next(value);
          resolve(value);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  /**
   * Checks if the token is valid or not
   *
   * @returns true, if the token is valid
   */
  isTokenValid(): boolean {
    const currentDate = new Date();
    const expiration: number = JSON.parse(localStorage.getItem('user_token_expires_at')!);
    return !expiration ? false : currentDate.getTime() < expiration;
  }

  async logout(): Promise<boolean> {
    try {
      await firstValueFrom(this.authRepository.logout());
    } catch (error) {
      console.error(error);
    }

    localStorage.removeItem('user_token');
    localStorage.removeItem('user_token_expires_at');
    const deviceId = localStorage.getItem('deviceId') || '';
    this.logoutEmitter$.next(undefined);

    return this.router.navigateByUrl('/home/' + deviceId);
  }

  resetPassword(email: string): Observable<HttpResponse<void>> {
    return this.authRepository.resetPassword(email);
  }

  resetNewPassword(newPassword: string, confirmPassword: string, username: string, resetToken: string): Observable<any> {
    return this.authRepository.resetNewPassword(newPassword, confirmPassword, username, resetToken);
  }

  getUserProfile(): Observable<UserProfile> {
    if (this.isTokenValid()) {
      return this.authRepository.getUserProfile();
    } else {
      return of<UserProfile>(null);
    }
  }
}
