import { ActiveAlarmDto, AlarmHistory } from '../alarm';
import { AlertType } from './alert-type.enum';
import { SourceType } from './source-type.enum';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class AlarmTypeUtils {
  public static getType(alarm: ActiveAlarmDto | AlarmHistory): AlertType {
    const { thresholdMax, thresholdMin, sourceType } = alarm;
    if ((thresholdMax !== undefined && thresholdMax !== null) || (thresholdMin !== undefined && thresholdMin !== null)) {
      return AlertType.THRESHOLD;
    } else if (sourceType === SourceType.INTELLIGENT_ALARM) {
      return AlertType.INTELLIGENT;
    } else if ((alarm as ActiveAlarmDto).heartbeat) {
      return AlertType.HEARTBEAT;
    }

    return AlertType.EXTERNAL;
  }

  public static getTypeIcon(alarm: ActiveAlarmDto | AlarmHistory): string {
    const type = AlarmTypeUtils.getType(alarm);
    return 'icon-' + type.toLowerCase();
  }
}
