import { UserDto } from '../user/user.model';
import { PermissionGroup } from './permission-group.model';
import { Tenant } from './tenant.model';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface UserManagementUserDto extends UserManagementUserDetailDto {
  admin: boolean;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export interface UserManagementUserDetailDto extends UserDto {
  permissionGroup?: PermissionGroup;
  tenant?: Tenant;
}
