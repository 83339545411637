import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SensorTypeImage } from '@deprecated/api-interfaces';
import { PagedData } from '@ird/shared-base';
import { PathReplaceUtils } from '@ird/shared-utils';
import { Observable } from 'rxjs';
import { File as CustomFile, DocumentResponse } from '../models';

@Injectable()
export class FileRepository {
  defaultOptions = {
    'Content-Type': 'application/json',
  };

  constructor(
    @Inject('ENVIRONMENT') private config: any,
    private http: HttpClient,
  ) {}

  uploadDeviceImage(deviceId: string, formData: FormData): Observable<any> {
    const path = this.config.file.device.uploadDeviceImage.replace('{deviceId}', deviceId);

    return this.http.post(path, formData, {
      observe: 'response',
      headers: {},
    });
  }

  getDeviceThumbnailImage(deviceId: string, thumbnailId: string): string {
    return this.config.file.device.getDeviceThumbnailImage.replace('{deviceId}', deviceId).replace('{thumbnailId}', thumbnailId);
  }

  getDeviceLargeImage(deviceId: string, pictureId: string): string {
    return this.config.file.device.getDeviceLargeImage.replace('{deviceId}', deviceId).replace('{pictureId}', pictureId);
  }

  deleteDeviceImage(deviceId: string): Observable<any> {
    const path = this.config.file.device.deleteDeviceImage.replace('{deviceId}', deviceId);

    return this.http.delete(path, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  getDeviceImageData(deviceId: string, pictureId: string): Observable<HttpResponse<Blob>> {
    const options = {
      'Content-Type': 'application/blob',
    };

    const path = this.config.file.device.getDeviceLargeImage.replace('{deviceId}', deviceId).replace('{pictureId}', pictureId);
    return this.http.get<Blob>(path, {
      observe: 'response',
      headers: options,
      responseType: 'blob' as 'json',
    });
  }

  getUserImage(imageId: string): string {
    return this.config.file.user.getUserImage.replace('{imageId}', imageId);
  }

  getPictureId(deviceId: string): Observable<string> {
    const path = this.config.nest.device.image.getPictureId.replace('{deviceId}', deviceId);

    return this.http.get(path, {
      observe: 'body',
      headers: {},
      responseType: 'text',
    });
  }

  getThumbnailId(deviceId: string): Observable<string> {
    const path = this.config.nest.device.image.getThumbnailId.replace('{deviceId}', deviceId);

    return this.http.get(path, {
      observe: 'body',
      headers: {},
      responseType: 'text',
    });
  }

  deleteUserImage(): Observable<any> {
    const path = this.config.file.user.userImage;

    return this.http.delete(path, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  uploadUserImage(formData: FormData): Observable<any> {
    const path = this.config.file.user.userImage;

    return this.http.post(path, formData, {
      observe: 'response',
      headers: {},
    });
  }

  getSensorTypeImage(sensorTypeId: string): Observable<Blob> {
    const options = {
      'Content-Type': 'application/blob',
    };

    const path = this.config.file.sensorType.replace('{sensorId}', sensorTypeId);

    return this.http.get<Blob>(path, {
      observe: 'body',
      headers: options,
      responseType: 'blob' as 'json',
    });
  }

  getSensorTypeImageUrl(sensorTypeId: string): string {
    return sensorTypeId ? this.config.file.sensorType.replace('{sensorId}', sensorTypeId) : undefined;
  }

  deleteSensorTypeImage(sensorTypeId: string): Observable<any> {
    const path = this.config.file.sensorType.replace('{sensorId}', sensorTypeId);

    return this.http.delete(path, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  uploadSensorTypeImage(sensorTypeId: string, formData: FormData): Observable<SensorTypeImage> {
    const path = this.config.file.sensorType.replace('{sensorId}', sensorTypeId);

    return this.http.post<SensorTypeImage>(path, formData, {
      headers: {},
    });
  }

  uploadNoteImages(noteId: string, formData: FormData): Observable<any> {
    const path = this.config.file.userNotes.uploadFile.replace('{noteId}', noteId);

    return this.http.post(path, formData, {
      headers: {},
    });
  }

  deleteNoteImage(noteId: string, imageId: string): Observable<any> {
    const path = this.config.file.userNotes.getFile.replace('{noteId}', noteId).replace('{fileId}', imageId);

    return this.http.delete(path, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  getNoteImage(noteId: string, imageId: string): Observable<Blob> {
    const options = {
      'Content-Type': 'application/blob',
    };

    const path = this.config.file.userNotes.getFile.replace('{noteId}', noteId).replace('{fileId}', imageId);

    return this.http.get<Blob>(path, {
      observe: 'body',
      headers: options,
      responseType: 'blob' as 'json',
    });
  }

  getNoteThumbnailImage(noteId: string, imageId: string): Observable<Blob> {
    const options = {
      'Content-Type': 'application/blob',
    };

    const path = this.config.file.userNotes.getThumbnailImage.replace('{noteId}', noteId).replace('{fileId}', imageId);

    return this.http.get<Blob>(path, {
      observe: 'body',
      headers: options,
      responseType: 'blob' as 'json',
    });
  }

  deleteAllNoteImages(noteId: string): Observable<any> {
    const path = this.config.file.userNotes.deleteAllFiles.replace('{noteId}', noteId);

    return this.http.delete(path, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  getAllDeviceFiles(deviceId: string): Observable<DocumentResponse> {
    const path = this.config.file.documents.getAllDeviceFiles.replace('{deviceId}', deviceId);
    return this.http.get<DocumentResponse>(path, {
      observe: 'body',
      headers: this.defaultOptions,
    });
  }

  getFiles(page: number, pageSize: number, name: string): Observable<PagedData<CustomFile>> {
    let params: HttpParams = new HttpParams();
    params = params.set('page', page);
    params = params.set('pageSize', pageSize);
    params = params.set('name', name);
    const path = this.config.file.getAll;
    return this.http.get<PagedData<CustomFile>>(path, { params });
  }

  getSingleDocument(deviceId: string, fileId: string): Observable<any> {
    const path = this.config.file.documents.getSingleFile.replace('{deviceId}', deviceId).replace('{fileId}', fileId);

    return this.http.get(path, {
      responseType: 'blob',
      observe: 'body',
      headers: this.defaultOptions,
    });
  }

  deleteSingleDocument(deviceId: string, fileId: string): Observable<HttpResponse<any>> {
    const path = this.config.file.documents.deleteFile.replace('{deviceId}', deviceId).replace('{fileId}', fileId);

    return this.http.delete(path, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  deleteGenericDocument(fileId: string): Observable<HttpResponse<void>> {
    const path = this.config.file.generic.delete.replace('{fileId}', fileId);

    return this.http.delete<HttpResponse<void>>(path, {
      headers: this.defaultOptions,
    });
  }

  uploadDeviceDocuments(deviceId: string, fileType: string, data: FormData): Observable<Array<CustomFile>> {
    const path = this.config.file.documents.uploadContentFiles.replace('{fileType}', fileType).replace('{deviceId}', deviceId);

    return this.http.post<Array<CustomFile>>(path, data, {
      headers: {},
    });
  }

  getModelDetailsFileUrl(modelDetailsId: string, fileId: string): Observable<string> {
    const path = this.config.file.model.get.replace('{modelDetailsId}', modelDetailsId).replace('{fileId}', fileId);

    return this.http.get<string>(path, { headers: this.defaultOptions });
  }

  uploadModelDetailsDocuments(modelDetailsId: string, data: FormData): Observable<CustomFile[]> {
    const path = this.config.file.model.uploadContentFiles.replace('{modelDetailsId}', modelDetailsId);

    return this.http.post<CustomFile[]>(path, data, {
      headers: {},
    });
  }

  getSparePartKitFile(sparePartKitId: string, fileId: string): Observable<HttpResponse<any>> {
    const path = this.config.file.sparePartKits.getFile.replace('{sparePartKitId}', sparePartKitId).replace('{fileId}', fileId);

    return this.http.get(path, {
      responseType: 'blob',
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  deleteSparePartKitFile(sparePartKitId: string, fileId: string): Observable<any> {
    const path = this.config.file.sparePartKits.delete.replace('{sparePartKitId}', sparePartKitId).replace('{fileId}', fileId);

    return this.http.delete(path, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  getSparePartKitImageInfos(sparePartKitId: string): Observable<HttpResponse<any>> {
    const path = this.config.file.sparePartKits.getImageInfos.replace('{sparePartKitId}', sparePartKitId);

    return this.http.get(path, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  markSparePartKitImageAsPreview(sparePartKitId: string, imageId: string): Observable<any> {
    const path = this.config.file.sparePartKits.markAsPreview.replace('{sparePartKitId}', sparePartKitId).replace('{imageId}', imageId);

    return this.http.post(path, null, {
      headers: {},
    });
  }

  getSparePartKitPreviewUri(sparePartKitId: string): Observable<any> {
    const path = this.config.file.sparePartKits.getPreviewUri.replace('{sparePartKitId}', sparePartKitId);

    return this.http.get(path, {
      observe: 'response',
      headers: this.defaultOptions,
    });
  }

  uploadSparePartKitImage(sparePartKitId: string, formData: FormData): Observable<any> {
    const path = this.config.file.sparePartKits.uploadImages.replace('{sparePartKitId}', sparePartKitId);

    return this.http.post(path, formData, {
      headers: {},
    });
  }

  public uploadGenericFile(data: FormData): Observable<Array<CustomFile>> {
    const path = this.config.file.generic.post;

    return this.http.post<Array<CustomFile>>(path, data, { observe: 'body' });
  }

  public getGenericFile(id: string): string {
    return PathReplaceUtils.replace(this.config.file.generic.get, { id });
  }

  public getFileAsBlob(path: string): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(path, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }
}
